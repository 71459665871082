import React, { useEffect, useState, useRef } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Tooltip,
  tooltipClasses,
  Box,
  TextField,
  Alert,
  CircularProgress,
  MenuItem,
  Checkbox,
  IconButton,
  InputLabel,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import "./model.css";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { spacing } from "@mui/system";
import { styled } from "@mui/system";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { languagesData } from "../../../../assets/defaultLanguage";
import { api } from "../../../../contexts/JWTContext";
import Collapsible from "../../../../components/CustomComponents/Collapsible";
import TestPromptModel from "./TestPromptModel";
import TranslateIcon from "../../../../assets/Icons/translateIcon.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  checkboxStyle: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    display: "flex",
    padding: "12px",
    marginY: "2px",
    alignItems: "center",
    width: "100%",
    height: "72px",
  },
  gridStyle: {
    paddingBottom: "8px",
  },
  mainHeadingContainer: {
    //  paddingTop: "10px",
    //  paddingLeft: "20px",
    // paddingRight: "20px",
    //  paddingBottom: "20px",
    width: "100%",
  },
  mainKeyWordContainer: {
    paddingTop: "15px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    paddingBottom: "8px",
  },
  keywordLabelText: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    paddingBottom: "8px",
    paddingTop: "8px",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  tickAddressIcon: {
    color: "#06BDFF",
    cursor: "pointer",
    marginTop: "8px",
  },
  tickAddressIconRequested: {
    color: "#13CF8F",
    cursor: "pointer",
    marginTop: "8px",
  },
}));

const ReviewTextPresent = ({
  textPresentPrompt,

  formalChecked,
  setFormalChecked,
  reviewToneChecked,
  setReviewToneChecked,
  friendlyChecked,
  setFriendlyChecked,
  includeChecked,
  setIncludeChecked,
  excludeChecked,
  setExcludeChecked,
  yesChecked,
  setYesChecked,
  noChecked,
  setNoChecked,
  moreLanguageChecked,
  setMoreLanguageChecked,
  userLanguageChecked,
  setUserLanguageChecked,
  userLanguage,
  setUserLanguage,
  reviewLanguageChecked,
  setReviewLanguageChecked,
  tags,
  setTags,
  openTestModel,
  setTextPresentGeneratedContent,
  textPresentGeneratedContent,
  handleCloseTestModel,
  shortChecked,
  setShortChecked,
  detailedCheked,
  setDetailedChecked,
  extraData,
  setExtraData,
  setTextPresentPrompt,
  present,
  reviewsData,
  dummyReviewsData,
  allReviewSource,
  openDefaultModel,
  selectedTab,
  setDisplayToast,
  displayToast,
  selectedRating,
  modelPresentPrompt,
  setModelPresentPrompt,
  userTranslationLanguage,
  setUserTranslationLanguage,
  selectAllPresent,
  setSelectAllPresent,
  handleDisplayNames,
  getLocation,
  defaultLocation,
  singleChecked,
  setSingleChecked,
  multiChecked,
  setMultiChecked,
  tagsRequired,
  setTagsRequired,
  tagsGridRef,
  langGridRef,
  languageRequired,
  setLanguageRequired,

  scrollStateLang,
  setScrollStateLang,

  scrollStateTags,
  setScrollStateTags,
}) => {
  const classes = useStyles();
  const textArea = useRef();
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [expandedPanels, setExpandedPanels] = useState(["responseTone"]);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [textToReplace, setTextToReplace] = useState("");
  const [tagsToReplace, setTagsToReplace] = useState(tags);
  const i18nextLng = localStorage.getItem("i18nextLng");
  const [detectedLanguage, setDetectedLanguage] = useState("");
  const [translatePrompLoading, setTranslatePrompLoading] = useState(false);
  const [displayExtraWarning, setDisplayExtraWarning] = useState(false);
  const [detectLanguageLoading, setDetectedLanguageLoading] = useState(false);
  const [textReplaced, setTextToReplaced] = useState(true);
  const [textToReplaceforPronoun, setTextToReplaceforPronoun] = useState("");
  const [limitTextToReplace, setLimitTextToReplace] = useState("");
  const filter = createFilterOptions();

  useEffect(() => {
    if (openDefaultModel) {
      //  setModelPresentPrompt(textPresentPrompt);
      handleLanguageChangeForTranslationOnOpen(i18nextLng);
    }
  }, [openDefaultModel]);

  useEffect(() => {
    if (scrollStateTags) {
      //  setModelPresentPrompt(textPresentPrompt);
      scrollToGrid();
      if (expandedPanels?.includes("style") === false) {
        expandedPanels.push("style");
        setExpandedPanels([...expandedPanels]);
      }
    }
  }, [scrollStateTags]);
  useEffect(() => {
    if (scrollStateLang) {
      //  setModelPresentPrompt(textPresentPrompt);
      scrollToLangGrid();
      if (expandedPanels?.includes("langs") === false) {
        expandedPanels.push("langs");
        setExpandedPanels([...expandedPanels]);
      }
    }
  }, [scrollStateLang]);

  useEffect(() => {
    if (includeChecked === false && excludeChecked === false) {
      setTags([]);
      setTagsRequired(false);
    }
  }, [includeChecked, excludeChecked]);
  useEffect(() => {
    if (moreLanguageChecked === false) {
      setUserLanguage([]);
      setLanguageRequired(false);
    }
  }, [moreLanguageChecked]);

  useEffect(() => {
    if (tags?.length > 0 || tagsToReplace?.length > 0) {
      setTagsRequired(false);
      if (modelPresentPrompt?.includes("{{-keywords-}}")) {
        let newStr = modelPresentPrompt.replace(
          "{{-keywords-}}",
          tags?.toString()
        );

        setModelPresentPrompt(newStr);
        setTagsToReplace(tags);
      } else {
        if (tags?.length > 0) {
          let strToFind =
            tagsToReplace?.length > 0
              ? tagsToReplace?.toString()
              : tags?.toString();
          if (modelPresentPrompt?.includes(strToFind)) {
            let newStr = modelPresentPrompt.replace(
              strToFind,
              tags?.toString()
            );

            setModelPresentPrompt(newStr);
            setTagsToReplace(tags);
          }
        } else {
          let strToFind =
            tagsToReplace?.length > 0 ? tagsToReplace?.toString() : "";
          if (modelPresentPrompt?.includes("{{-keywords-}}") === false) {
            let newStr = modelPresentPrompt.replace(
              strToFind,
              "{{-keywords-}}"
            );
            setModelPresentPrompt(newStr);
            setTagsToReplace(tags);
          }
        }
      }
    }
  }, [tags]);
  useEffect(() => {
    if (extraData !== "") {
      const delayDebounceFn = setTimeout(() => {
        getDetectedLanguage(extraData);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [extraData]);

  useEffect(() => {
    handleLanguageChangeForTranslationOnOpen();
    //  setModelPresentPrompt(textPresentPrompt);
  }, [selectedTab]);

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };

  const handleChangeFormalChecked = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-reviewTone-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-reviewTone-}}",
          "{{-formalTone-}}"
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-friendlyTone-}}")) {
        let str = modelPresentPrompt.replaceAll(
          "{{-friendlyTone-}}",
          "{{-formalTone-}}"
        );
        setModelPresentPrompt(str);
      }
      setFormalChecked(e.target.checked);
      setReviewToneChecked(false);
      setFriendlyChecked(false);
    }
  };
  const handleChangeReviewToneChecked = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-formalTone-}}")) {
        let str = modelPresentPrompt.replaceAll(
          "{{-formalTone-}}",
          "{{-reviewTone-}}"
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-friendlyTone-}}")) {
        let str = modelPresentPrompt.replaceAll(
          "{{-friendlyTone-}}",
          "{{-reviewTone-}}"
        );
        setModelPresentPrompt(str);
      }
      setReviewToneChecked(e.target.checked);
      setFriendlyChecked(false);
      setFormalChecked(false);
    }
  };
  const handleChangeFriendlyChecked = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-reviewTone-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-reviewTone-}}",
          "{{-friendlyTone-}}"
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-formalTone-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-formalTone-}}",
          "{{-friendlyTone-}}"
        );
        setModelPresentPrompt(str);
      }
      setFriendlyChecked(e.target.checked);
      setReviewToneChecked(false);
      setFormalChecked(false);
    }
  };
  const handleIncludeChecked = async (e) => {
    setTags([]);
    setIncludeChecked(e.target.checked);
    setExcludeChecked(false);

    let str = modelPresentPrompt;

    if (e.target.checked) {
      let splittedStr = str.split(".");
      let findIndex = splittedStr.findIndex((text) =>
        text.includes("{{-excludePhrase-}}")
      );
      try {
        const res = await api.patch(`/template/translateInAllLanguges`, {
          language: "en",
          text: "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.",
          languageArray: [userTranslationLanguage],
        });
        if (res.status === 200) {
          // setAiPromptTranslatedText(res?.data?.data);
          console.log(res?.data?.data, "res?.data?.data");
          if (res?.data?.data?.length > 0) {
            if (findIndex > -1) {
              if (str.includes(res?.data?.data[0]?.text) === false) {
                splittedStr[findIndex] = res?.data?.data[0]?.text;
                let joinedString = splittedStr.join(".");
                setModelPresentPrompt(joinedString);
                setTextToReplaceforPronoun(res?.data?.data[0]?.text);
              }
            } else {
              if (str.includes(res?.data?.data[0]?.text) === false) {
                str = str + " " + res?.data?.data[0]?.text;
                setModelPresentPrompt(str);
                setLimitTextToReplace(res?.data?.data[0]?.text);
              }
            }
          } else {
            str =
              str +
              " " +
              "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.";
            setModelPresentPrompt(str);
            setLimitTextToReplace(
              "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases."
            );
          }
        }
      } catch (error) { }
    } else {
      let splittedStr = str.split(".");
      let filteredStr = splittedStr?.filter(
        (text) => text.includes("{{-includePhrase-}}") === false
      );
      let joinedString = filteredStr.join(".");
      setModelPresentPrompt(joinedString);
    }
  };
  const handleExludeChecked = async (e) => {
    setTags([]);
    setExcludeChecked(e.target.checked);
    setIncludeChecked(false);

    let str = modelPresentPrompt;

    if (e.target.checked) {
      let splittedStr = str.split(".");
      let findIndex = splittedStr.findIndex((text) =>
        text.includes("{{-includePhrase-}}")
      );
      try {
        const res = await api.patch(`/template/translateInAllLanguges`, {
          language: "en",
          text: "For {{-ratingStars-}} reviews, {{-excludePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.",
          languageArray: [userTranslationLanguage],
        });
        if (res.status === 200) {
          // setAiPromptTranslatedText(res?.data?.data);
          if (res?.data?.data?.length > 0) {
            if (findIndex > -1) {
              if (str.includes(res?.data?.data[0]?.text) === false) {
                splittedStr[findIndex] = res?.data?.data[0]?.text;
                let joinedString = splittedStr.join(".");
                setModelPresentPrompt(joinedString);
                setTextToReplaceforPronoun(res?.data?.data[0]?.text);
              }
            } else {
              if (str.includes(res?.data?.data[0]?.text) === false) {
                str = str + " " + res?.data?.data[0]?.text;
                setModelPresentPrompt(str);
                setLimitTextToReplace(res?.data?.data[0]?.text);
              }
            }
          } else {
            str =
              str +
              " " +
              "For {{-ratingStars-}} reviews, {{-excludePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.";
            setModelPresentPrompt(str);
            setLimitTextToReplace(
              "For {{-ratingStars-}} reviews, {{-excludePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases."
            );
          }
        }
      } catch (error) { }
    } else {
      let splittedStr = str.split(".");
      let filteredStr = splittedStr?.filter(
        (text) => text.includes("{{-excludePhrase-}}") === false
      );
      let joinedString = filteredStr.join(".");
      setModelPresentPrompt(joinedString);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    //  setTagsRequired(true);
    if (!value.trim()) return;
    setTags([...tags, value]);
    e.target.value = "";

    // setTagsRequired(false);
  };
  const removeTag = (index) => {
    setTags(tags.filter((el, i) => i !== index));
  };
  const handleChangeYesChecked = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-withOut-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-withOut-}}",
          "{{-includeSignature-}}"
        );
        setModelPresentPrompt(str);
      }
      setYesChecked(e.target.checked);
      setNoChecked(false);
    }
  };

  const handleSingleChecked = async (e) => {
    setSingleChecked(e.target.checked);
    setMultiChecked(false);
    let str = modelPresentPrompt;
    if (e.target.checked) {
      if (userTranslationLanguage === "en") {
        if (
          modelPresentPrompt?.includes(
            "Use the pronoun  {{-SingularPronoun-}} to address customer"
          ) === false &&
          modelPresentPrompt?.includes(
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          ) === false
        ) {
          str =
            str +
            " " +
            "Use the pronoun {{-SingularPronoun-}} to address customer.";
          setModelPresentPrompt(str);
          setTextToReplaceforPronoun(
            "Use the pronoun {{-SingularPronoun-}} to address customer."
          );
        } else if (
          modelPresentPrompt?.includes(
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          )
        ) {
          str = str.replace(
            "Use the pronoun {{-PluralPronoun-}} to address customer.",
            "Use the pronoun {{-SingularPronoun-}} to address customer."
          );
          setModelPresentPrompt(str);
          setTextToReplaceforPronoun(
            "Use the pronoun {{-SingularPronoun-}} to address customer."
          );
        }
      } else {
        let splittedStr = str.split(".");
        let findIndex = splittedStr.findIndex((text) =>
          text.includes("{{-PluralPronoun-}}")
        );

        try {
          const res = await api.post(`/translation/mutipleText`, {
            texts: [
              "Use the pronoun {{-SingularPronoun-}} to address customer",
            ],
            language: [userTranslationLanguage],
          });
          if (res.status === 200) {
            if (res?.data?.data?.length > 0) {
              if (findIndex > -1) {
                if (str.includes(res?.data?.data[0]?.text) === false) {
                  splittedStr[findIndex] = res?.data?.data[0]?.text;
                  let joinedString = splittedStr.join(".");
                  setModelPresentPrompt(joinedString);
                  setTextToReplaceforPronoun(res?.data?.data[0]?.text);
                }
              } else {
                if (str.includes(res?.data?.data[0]?.text) === false) {
                  str = str + " " + res?.data?.data[0]?.text;
                  setModelPresentPrompt(str);
                  setTextToReplaceforPronoun(res?.data?.data[0]?.text);
                }
              }
            }
          }
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    } else {
      let splittedStr = str.split(".");
      let filteredStr = splittedStr?.filter(
        (text) => text.includes("{{-SingularPronoun-}}") === false
      );
      let joinedString = filteredStr.join(".");
      setModelPresentPrompt(joinedString);
    }
  };

  const handleMultiChecked = async (e) => {
    setMultiChecked(e.target.checked);
    setSingleChecked(false);
    let str = modelPresentPrompt;
    if (e.target.checked) {
      if (userTranslationLanguage === "en") {
        if (
          modelPresentPrompt?.includes(
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          ) === false &&
          modelPresentPrompt?.includes(
            "Use the pronoun {{-SingularPronoun-}} to address customer."
          ) === false
        ) {
          str =
            str +
            " " +
            "Use the pronoun {{-PluralPronoun-}} to address customer.";
          setModelPresentPrompt(str);
          setTextToReplaceforPronoun(
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          );
        } else if (
          modelPresentPrompt?.includes(
            "Use the pronoun {{-SingularPronoun-}} to address customer."
          )
        ) {
          str = str.replace(
            "Use the pronoun {{-SingularPronoun-}} to address customer.",
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          );
          setModelPresentPrompt(str);
          setTextToReplaceforPronoun(
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          );
        }
      } else {
        let splittedStr = str.split(".");
        let findIndex = splittedStr.findIndex((text) =>
          text.includes("{{-SingularPronoun-}}")
        );

        try {
          const res = await api.post(`/translation/mutipleText`, {
            texts: ["Use the pronoun {{-PluralPronoun-}} to address customer."],
            language: [userTranslationLanguage],
          });
          if (res.status === 200) {
            if (res?.data?.data?.length > 0) {
              if (findIndex > -1) {
                if (str.includes(res?.data?.data[0]?.text) === false) {
                  splittedStr[findIndex] = res?.data?.data[0]?.text;
                  let joinedString = splittedStr.join(".");
                  setModelPresentPrompt(joinedString);
                  setTextToReplaceforPronoun(res?.data?.data[0]?.text);
                }
              } else {
                if (str.includes(res?.data?.data[0]?.text) === false) {
                  str = str + " " + res?.data?.data[0]?.text;
                  setModelPresentPrompt(str);
                  setTextToReplaceforPronoun(res?.data?.data[0]?.text);
                }
              }
            }
          }
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    } else {
      let splittedStr = str.split(".");
      let filteredStr = splittedStr?.filter(
        (text) => text.includes("{{-PluralPronoun-}}") === false
      );
      let joinedString = filteredStr.join(".");

      setModelPresentPrompt(joinedString);
    }
  };
  const handleNoChecked = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-includeSignature-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-includeSignature-}}",
          "{{-withOut-}}"
        );
        setModelPresentPrompt(str);
      }
      setNoChecked(e.target.checked);
      setYesChecked(false);
    }
  };
  const handleChangeMoreLanguage = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-localLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-localLanguage-}}",
          "{{-selectedLanguage-}}"
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-reviewer'sLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-reviewer'sLanguage-}}",
          "{{-selectedLanguage-}}"
        );
        setModelPresentPrompt(str);
      }
      setMoreLanguageChecked(e.target.checked);
      setReviewLanguageChecked(false);
      setUserLanguageChecked(false);
      setUserLanguage([]);
    }
  };
  const handleChangeReviewLanguage = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-localLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-localLanguage-}}",
          "{{-reviewer'sLanguage-}}"
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-selectedLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-selectedLanguage-}}",
          "{{-reviewer'sLanguage-}}"
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-MultipleLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-MultipleLanguage-}}",
          "{{-reviewer'sLanguage-}}"
        );
        setModelPresentPrompt(str);
      } else {
        languagesData.forEach((language) => {
          if (modelPresentPrompt?.includes(language?.language)) {
            let str = modelPresentPrompt.replace(
              language?.language,
              "{{-reviewer'sLanguage-}}"
            );
            setModelPresentPrompt(str);
          }
        });
      }
      setMoreLanguageChecked(false);
      setReviewLanguageChecked(e.target.checked);
      setUserLanguageChecked(false);
      setUserLanguage([]);
    }
  };
  const handleChangeUserLanguage = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-reviewer'sLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-reviewer'sLanguage-}}",
          // userData?.language
          "{{-localLanguage-}}"
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-selectedLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-selectedLanguage-}}",
          "{{-localLanguage-}}"
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-MultipleLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-MultipleLanguage-}}",
          "{{-localLanguage-}}"
        );
        setModelPresentPrompt(str);
      } else {
        languagesData.forEach((language) => {
          if (modelPresentPrompt?.includes(language?.language)) {
            let str = modelPresentPrompt.replace(
              language?.language,
              "{{-localLanguage-}}"
            );
            setModelPresentPrompt(str);
          }
        });
      }
      setMoreLanguageChecked(false);
      setReviewLanguageChecked(false);
      setUserLanguageChecked(e.target.checked);
    }
  };
  const handleLanguageChange = async (lang) => {
    if (lang?.length > 0) {
      let userSelectedLang = lang?.map((item) => item?.language);
      if (modelPresentPrompt?.includes("{{-selectedLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-selectedLanguage-}}",
          userSelectedLang?.length > 1
            ? "{{-MultipleLanguage-}}"
            : userSelectedLang?.toString()
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-MultipleLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-MultipleLanguage-}}",
          userSelectedLang?.length > 1
            ? "{{-MultipleLanguage-}}"
            : userSelectedLang?.toString()
        );
        setModelPresentPrompt(str);
      } else {
        let langString = [];
        let userSelectedLangs = [];
        languagesData.forEach((language) => {
          userLanguage?.forEach((item) => {
            if (item?.language === language?.language) {
              userSelectedLangs?.push(language);
            }
          });
        });
        langString = userSelectedLangs?.map((item) => item?.language);

        if (modelPresentPrompt?.includes(langString?.toString())) {
          let str = modelPresentPrompt.replace(
            langString?.toString(),
            userSelectedLang?.length > 1
              ? "{{-MultipleLanguage-}}"
              : userSelectedLang?.toString()
          );
          setModelPresentPrompt(str);
        }
      }
    } else {
      let langString = [];
      let userSelectedLangs = [];
      languagesData.forEach((language) => {
        userLanguage?.forEach((item) => {
          if (item?.language === language?.language) {
            userSelectedLangs?.push(language);
          }
        });
      });
      langString = userSelectedLangs?.map((item) => item?.language);

      if (modelPresentPrompt?.includes(langString?.toString())) {
        let str = modelPresentPrompt.replace(
          langString?.toString(),
          "{{-selectedLanguage-}}"
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-MultipleLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-MultipleLanguage-}}",
          "{{-selectedLanguage-}}"
        );
        setModelPresentPrompt(str);
      }
    }

    setUserLanguage(lang);
  };
  const handleShortChecked = async (e) => {
    if (e.target.checked) {
      let str = modelPresentPrompt;
      if (modelPresentPrompt?.includes("{{-detailedResponse-}}")) {
        str = modelPresentPrompt.replace(
          "{{-detailedResponse-}}",
          "{{-shortResponse-}}"
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-selectedLength-}}")) {
        str = modelPresentPrompt.replace(
          "{{-selectedLength-}}",
          "{{-shortResponse-}}"
        );
        setModelPresentPrompt(str);
      }
      setShortChecked(e.target.checked);
      setDetailedChecked(false);

      try {
        const res = await api.patch(`/template/translateInAllLanguges`, {
          language: "en",
          text: "Limit the response to four sentences.",
          languageArray: [userTranslationLanguage],
        });
        if (res.status === 200) {
          // setAiPromptTranslatedText(res?.data?.data);

          if (
            res?.data?.data?.length > 0 &&
            str.includes(res?.data?.data[0]?.text) === false
          ) {
            str = str + " " + res?.data?.data[0]?.text;
            setModelPresentPrompt(str);
            setLimitTextToReplace(res?.data?.data[0]?.text);
          }
        }
      } catch (error) { }
    }
  };
  const handleDetailChecked = async (e) => {
    let str = modelPresentPrompt;
    if (e.target.checked) {
      setDetailedChecked(e.target.checked);
      setShortChecked(false);

      if (modelPresentPrompt?.includes("{{-shortResponse-}}")) {
        str = modelPresentPrompt.replace(
          "{{-shortResponse-}}",
          "{{-detailedResponse-}}"
        );
        // setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-selectedLength-}}")) {
        str = modelPresentPrompt.replace(
          "{{-selectedLength-}}",
          "{{-detailedResponse-}}"
        );
      }

      if (userTranslationLanguage === "en") {
        if (str?.includes("Limit the response to four sentences.")) {
          str = str.replace("Limit the response to four sentences.", "");
        }
      } else {
        if (str.includes(limitTextToReplace)) {
          str = str.replace(limitTextToReplace, "");
        } else {
          try {
            const res = await api.patch(`/template/translateInAllLanguges`, {
              language: "en",
              text: "Limit the response to four sentences.",
              languageArray: [userTranslationLanguage],
            });
            if (res.status === 200) {
              // setAiPromptTranslatedText(res?.data?.data);
            }
          } catch (error) { }
        }
      }
    }

    setModelPresentPrompt(str);
  };
  const handleChangeExtraData = (value) => {
    if (value.length > 0) {
      if (modelPresentPrompt?.includes("{{-selectedExtras-}}")) {
        let str = modelPresentPrompt.replace("{{-selectedExtras-}}", value);
        setModelPresentPrompt(str);
        setTextToReplaced(false);
      } else if (modelPresentPrompt?.includes(textToReplace)) {
        let str = modelPresentPrompt.replace(textToReplace, value);
        setModelPresentPrompt(str);
        setTextToReplaced(false);
      }
    } else {
      if (modelPresentPrompt?.includes(textToReplace)) {
        let str = modelPresentPrompt.replace(
          textToReplace,
          "{{-selectedExtras-}}"
        );
        setModelPresentPrompt(str);
      }
    }

    setTextToReplace(value);
    // setTextToReplaced(true);
  };

  const handleLanguageChangeForTranslationOnOpen = async () => {
    setTranslatePrompLoading(true);
    //  setTranslateAgain(false);
    // setUserTranslationLanguage(lang);
    let splittedTextForLangDetect = modelPresentPrompt;

    const detectdResponse = await api.post(`/languge/detact`, {
      text: splittedTextForLangDetect,
    });

    if (detectdResponse.status === 200) {
      if (
        detectdResponse?.data?.data?.detactLanguge !== userTranslationLanguage
      ) {
        let languageName = handleDisplayLanguageName(userTranslationLanguage);
        splittedTextForLangDetect = `translate this text in ${languageName} \n ${modelPresentPrompt} `;
        try {
          const res = await api.post(`/openAi/generateContent`, {
            prompt: splittedTextForLangDetect,
            /*  language: detectdResponse?.data?.data?.detactLanguge,
            text: splittedTextForLangDetect,
            languageArray: [lang], */
          });
          if (res.status === 200) {
            // setAiPromptTranslatedText(res?.data?.data);
            setModelPresentPrompt(res?.data?.data);

            setTranslatePrompLoading(false);
            toast.success("Translated successfully");
          }
        } catch (error) {
          toast.error("Something went wrong");
          setTranslatePrompLoading(false);
        }
      } else {
        const foundLanguage = languagesData?.find(
          (langs) => langs?.value === userTranslationLanguage
        );
        if (displayToast === true) {
          toast.info(`Already translated in ${foundLanguage?.language}`);
          setDisplayToast(false);
        }

        // setTranslateAgain(true);
        setTranslatePrompLoading(false);
      }
    }
  };
  const handleLanguageChangeForTranslation = async (lang) => {
    setTranslatePrompLoading(true);
    //  setTranslateAgain(false);
    setUserTranslationLanguage(lang);
    let splittedTextForLangDetect = modelPresentPrompt;

    const detectdResponse = await api.post(`/languge/detact`, {
      text: splittedTextForLangDetect,
    });

    if (detectdResponse.status === 200) {
      if (detectdResponse?.data?.data?.detactLanguge !== lang) {
        let languageName = handleDisplayLanguageName(lang);
        splittedTextForLangDetect = `translate this text in ${languageName} \n ${modelPresentPrompt} `;
        try {
          const res = await api.post(`/openAi/generateContent`, {
            prompt: splittedTextForLangDetect,
            /*  language: detectdResponse?.data?.data?.detactLanguge,
            text: splittedTextForLangDetect,
            languageArray: [lang], */
          });
          if (res.status === 200) {
            // setAiPromptTranslatedText(res?.data?.data);
            setModelPresentPrompt(res?.data?.data);

            setTranslatePrompLoading(false);
            toast.success("Translated successfully");
          }
        } catch (error) {
          toast.error("Something went wrong");
          setTranslatePrompLoading(false);
        }
      } else {
        const foundLanguage = languagesData?.find(
          (langs) => langs?.value === lang
        );
        if (displayToast === true) {
          toast.info(`Already translated in ${foundLanguage?.language}`);
          setDisplayToast(false);
        }

        // setTranslateAgain(true);
        setTranslatePrompLoading(false);
      }
    }
  };

  const getDetectedLanguage = async (textValue) => {
    try {
      setDetectedLanguageLoading(true);
      const res = await api.post(`/languge/detact`, {
        text: textValue,
      });
      if (res.status === 200) {
        setDetectedLanguage(res?.data?.data?.detactLanguge);
        if (res?.data?.data?.detactLanguge !== userTranslationLanguage) {
          setDisplayExtraWarning(true);
        } else {
          setDisplayExtraWarning(false);
        }

        setDetectedLanguageLoading(false);
      }
    } catch (error) {
      setDetectedLanguageLoading(false);
    }
  };

  const handleDisplayLanguageName = (langKey) => {
    const selectedLanguage = languagesData?.find(
      (language) => language?.value === langKey
    );
    return selectedLanguage?.language;
  };

  const handleTranslateReviewReplyText = async () => {
    setDetectedLanguageLoading(true);

    try {
      const res = await api.post(`/translation/mutipleText`, {
        texts: [extraData],
        language: [userTranslationLanguage],
      });
      if (res.status === 200) {
        if (res?.data?.data?.length > 0) {
          if (modelPresentPrompt?.includes("{{-selectedExtras-}}")) {
            let str = modelPresentPrompt.replace(
              "{{-selectedExtras-}}",
              res?.data?.data[0]?.text
            );
            setModelPresentPrompt(str);
            setTextToReplaced(false);
          } else if (modelPresentPrompt?.includes(textToReplace)) {
            let str = modelPresentPrompt.replace(
              textToReplace,
              res?.data?.data[0]?.text
            );
            setModelPresentPrompt(str);
            setTextToReplaced(false);
          }
          setTextToReplace(res?.data?.data[0]?.text);
          setDisplayExtraWarning(false);
        }
        setDetectedLanguageLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setDetectedLanguageLoading(false);
    }
  };
  const scrollToGrid = () => {
    // Check if gridRef is not null
    if (tagsGridRef.current) {
      // Scroll to the grid element
      tagsGridRef.current.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        setScrollStateTags(false);
      }, 500);
    }
  };
  const scrollToLangGrid = () => {
    // Check if gridRef is not null
    if (langGridRef.current) {
      // Scroll to the grid element
      langGridRef.current.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        setScrollStateLang(false);
      }, 500);
    }
  };

  return (
    <>
      <Grid container className={classes.mainKeyWordContainer}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={11}
            md={11}
            sm={11}
            lg={11}
          // className={classes.mainKeyWordContainer}
          >
            {" "}
            <Typography className={classes.labelText}>
              {t("select_prompt_language")}
            </Typography>
          </Grid>

          {translatePrompLoading && (
            <Grid
              item
              xs={1}
              md={1}
              sm={1}
              lg={1}
            // className={classes.mainKeyWordContainer}
            >
              <CircularProgress
                style={{
                  width: "17px",
                  height: "17px",
                  color: "#06BDFF",
                  marginTop: "8px",
                  marginLeft: "28px",
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          lg={12}
          sx={{ paddingBottom: "8px" }}
        // className={classes.mainKeyWordContainer}
        >
          {" "}
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={userTranslationLanguage}
              onChange={(event) => {
                setDisplayToast(true);
                handleLanguageChangeForTranslation(event.target.value);
              }}
              disabled={translatePrompLoading}
            >
              {languagesData?.map((item, index) => (
                <MenuItem key={index} value={item?.value}>
                  {item?.language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Typography className={classes.labelText}>
          {t("Generated_prompt")}
        </Typography>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            border: "1px solid #E0E0E0",
            padding: "12px",
            borderRadius: "8px",
            marginBottom: "8px",
            overflow: "hidden", // This property hides any content that overflows the container
            wordWrap: "break-word",
          }}
        >
          <Typography>{modelPresentPrompt}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridStyle}
        >
          <Collapsible
            title={t("responseTone")}
            handleChange={handleExpandPanel}
            expanded={expandedPanels.includes("responseTone")}
            id={"responseTone"}
            fontAlignment={true}
            required={true}
            ComponentToRender={
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={3} md={3} sm={3} lg={3}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={formalChecked}
                        onChange={handleChangeFormalChecked}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("formalText")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={3} sm={3} lg={3}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={friendlyChecked}
                        onChange={handleChangeFriendlyChecked}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("Friendly")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={3} sm={3} lg={3}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={reviewToneChecked}
                        onChange={handleChangeReviewToneChecked}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("reviewTone")}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridStyle}
        >
          <Collapsible
            title={t("pronoun")}
            handleChange={handleExpandPanel}
            expanded={expandedPanels.includes("pronoun")}
            id={"pronoun"}
            fontAlignment={true}
            displayInfoIcon={true}
            ComponentToRender={
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={3} md={3} sm={3} lg={3}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={singleChecked}
                        onChange={handleSingleChecked}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("individual_text")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={3} sm={3} lg={3}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={multiChecked}
                        onChange={handleMultiChecked}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("Company")}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridStyle}
          ref={tagsGridRef}
        >
          <Collapsible
            title={t("style")}
            handleChange={handleExpandPanel}
            expanded={expandedPanels.includes("style")}
            id={"style"}
            fontAlignment={true}
            ComponentToRender={
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={3} md={3} sm={3} lg={3}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={excludeChecked}
                        onChange={handleExludeChecked}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("exclude")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={3} sm={3} lg={3}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={includeChecked}
                        onChange={handleIncludeChecked}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("include")}</Typography>
                    </Box>
                  </Grid>
                  {(includeChecked || excludeChecked) && (
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <Typography className={classes.keywordLabelText}>
                        {t("Keywords")}
                      </Typography>

                      <Grid
                        className={
                          tagsRequired
                            ? "tags-input-container-error"
                            : "tags-input-container"
                        }
                      >
                        {tags.map((tag, index) => (
                          <div className="tag-item" key={index}>
                            <span className="text">
                              {tag}
                              <span
                                className="close"
                                onClick={() => removeTag(index)}
                              >
                                {" "}
                                X
                              </span>
                            </span>
                          </div>
                        ))}

                        <input
                          type="text"
                          className="tags-input"
                          placeholder={
                            includeChecked
                              ? t("styleHelperTextForInclude")
                              : t("styleHelperText")
                          }
                          //   onKeyPress={this.keyPress}
                          onKeyDown={handleKeyDown}
                        // disabled={tagsRequired}
                        //  defaultValue={productData.tags}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridStyle}
        >
          <Collapsible
            title={t("signatures")}
            handleChange={handleExpandPanel}
            expanded={expandedPanels.includes("signatures")}
            id={"signatures"}
            fontAlignment={true}
            required={true}
            ComponentToRender={
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={3} md={3} sm={3} lg={3}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={noChecked}
                        onChange={handleNoChecked}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("No")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={3} sm={3} lg={3}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={yesChecked}
                        onChange={handleChangeYesChecked}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("Yes")}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridStyle}
        >
          <Collapsible
            title={t("length")}
            handleChange={handleExpandPanel}
            expanded={expandedPanels.includes("length")}
            id={"length"}
            fontAlignment={true}
            required={true}
            ComponentToRender={
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={3} md={3} sm={3} lg={3}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={shortChecked}
                        onChange={handleShortChecked}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("short_text")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={3} sm={3} lg={3}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={detailedCheked}
                        onChange={handleDetailChecked}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("detailed_text")}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridStyle}
          ref={langGridRef}
        >
          <Collapsible
            title={t("langs")}
            handleChange={handleExpandPanel}
            expanded={expandedPanels.includes("language")}
            id={"language"}
            fontAlignment={true}
            required={true}
            ComponentToRender={
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={3} md={3} sm={3} lg={4}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={userLanguageChecked}
                        onChange={handleChangeUserLanguage}
                        name="google"
                        color="primary"
                      />

                      <Typography>
                        {`${t("only_text")} {{localLanguage}}`}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                  {present && (
                    <Grid item xs={3} md={3} sm={3} lg={4}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={reviewLanguageChecked}
                          onChange={handleChangeReviewLanguage}
                          name="google"
                          color="primary"
                        />

                        <Typography>{t("reviewer_language")}</Typography>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={3} md={3} sm={3} lg={4}>
                    <Box className={classes.checkboxStyle}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        checked={moreLanguageChecked}
                        onChange={handleChangeMoreLanguage}
                        name="google"
                        color="primary"
                      />

                      <Typography>{t("More_Languages")}</Typography>
                    </Box>
                  </Grid>

                  {moreLanguageChecked && (
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <Typography className={classes.keywordLabelText}>
                        {t("Select_Language")}
                      </Typography>

                      <Autocomplete
                        multiple
                        disablePortal
                        disableCloseOnSelect
                        id="combo-box-demo"
                        options={languagesData}
                        sx={{ width: "100%" }}
                        getOptionLabel={(option) => option?.language}
                        className="Autocomplete-field"
                        // value={locationId}
                        value={userLanguage?.length > 0 ? userLanguage : []}
                        onChange={(event, value) => {
                          handleLanguageChange(value);
                          setLanguageRequired(false);
                          if (value.find((option) => option.id === 0)) {
                            // Check if all options are selected

                            if (
                              languagesData?.length === userLanguage?.length
                            ) {
                              handleLanguageChange([]);
                              setSelectAllPresent(false);
                            } else {
                              setSelectAllPresent(true);
                              handleLanguageChange(languagesData);
                            }
                          } else {
                            handleLanguageChange(value);
                            if (value?.length === languagesData?.length) {
                              setSelectAllPresent(true);
                            } else {
                              setSelectAllPresent(false);
                            }
                          }
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          return [
                            { id: 0, language: "Select All" },
                            ...filtered,
                          ];
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected || selectAllPresent}
                            />
                            {option?.language}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t("Select_Language")}
                            {...params}
                            required
                            error={languageRequired}
                            name="language"
                          />
                        )}
                      ></Autocomplete>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridStyle}
        >
          <Collapsible
            title={t("extras")}
            handleChange={handleExpandPanel}
            expanded={expandedPanels.includes("extras")}
            id={"extras"}
            fontAlignment={true}
            ComponentToRender={
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography className={classes.keywordLabelText}>
                        {t("Additional_Information")}
                      </Typography>

                      {detectLanguageLoading ? (
                        <CircularProgress
                          style={{
                            width: "17px",
                            height: "17px",
                            color: "#06BDFF",
                            marginTop: "8px",
                            marginLeft: "28px",
                          }}
                        />
                      ) : textReplaced === false ? (
                        <BootstrapTooltip title={t("Text_added")}>
                          <CheckCircleOutlineIcon
                            className={classes.tickAddressIconRequested}
                          // onClick={() => handleRequestAddressChange()}
                          />
                        </BootstrapTooltip>
                      ) : (
                        <BootstrapTooltip title={t("press_enter_to_save")}>
                          <CheckCircleOutlineIcon
                            className={classes.tickAddressIcon}
                          //  onClick={() => handleRequestAddressChange()}
                          />
                        </BootstrapTooltip>
                      )}
                    </Box>

                    <TextField
                      required
                      fullWidth
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleChangeExtraData(event.target.value);
                        }
                      }}
                      value={extraData}
                      helperText={t("Enter_extra_text")}
                      placeholder={t("addtional_placeholder")}
                      onChange={(event) => {
                        setExtraData(event.target.value);
                        setDisplayExtraWarning(false);
                        setTextToReplaced(true);
                        // handleChangeExtraData(event.target.value);
                      }}
                      //  fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  {displayExtraWarning && (
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <Alert severity="info">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>{t("translate_extra_text")}</Typography>

                          <BootstrapTooltip
                            title={`${t(
                              "translate_textPrompt"
                            )} ${handleDisplayLanguageName(
                              userTranslationLanguage
                            )}`}
                          >
                            <IconButton
                              onClick={() => handleTranslateReviewReplyText()}
                              sx={{ cursor: "pointer", padding: "0px" }}
                            >
                              <img
                                style={{
                                  width: 26,
                                  // height: 26,
                                  marginLeft: "8px",
                                }}
                                src={TranslateIcon}
                                alt={"Translate Icon"}
                              />
                            </IconButton>
                          </BootstrapTooltip>
                        </Box>
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>

      {openTestModel && (
        <TestPromptModel
          open={openTestModel}
          handleCloseTestModel={handleCloseTestModel}
          textPresentPrompt={modelPresentPrompt}
          setTextPresentGeneratedContent={setTextPresentGeneratedContent}
          textPresentGeneratedContent={textPresentGeneratedContent}
          reviewsData={reviewsData}
          dummyReviewsData={dummyReviewsData}
          allReviewSource={allReviewSource}
          selectedTab={selectedTab}
          selectedRating={selectedRating}
          handleDisplayNames={handleDisplayNames}
          getLocation={getLocation}
          defaultLocation={defaultLocation}
          present={present}
        />
      )}
    </>
  );
};

export default ReviewTextPresent;
