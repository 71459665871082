import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Divider,
  IconButton,
  Modal,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { useStyles } from "./style";
import CommonButton from "../../CustomComponents/Buttons/CommonButton.js";
import { countriesData } from "../../../assets/countriesList/index.js";
import moment from "moment";
import { api } from "../../../contexts/JWTContext.js";
import { toast } from "react-toastify";

const AccountManagementModal = (props) => {
  const initialState = {
    name: "",
    subscriptionDate: "",
    dealLink: "",
    dealValue: "",
    comments: "",
    addressLine1: "",
    companyId: 0,
    countryCode: "",
    city: "",
    industry: "",
    accountOwnerName: "",
    company: null,
  };

  const classes = useStyles();

  const { t } = useTranslation();
  const { open, close, data, getAccountListing } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState(false);
  const [accountOwners, setAccountOwners] = useState([]);
  const [duplicateAccountOwners, setDuplicateAccountOwners] = useState(false);
  const [updateFields, setUpdateFields] = useState({
    dealLink: "",
    dealValue: "",
    comments: "",
    industry: "",
    accountOwnerName: "",
  });

  useEffect(() => {
    if (data) {
      setFormData(data);
      setAccountOwners(
        data?.accountOwnerName === null ? [] : data?.accountOwnerName.split(",")
      );
      setUpdateFields((prev) => ({
        ...prev,
        dealLink: data?.dealLink,
        dealValue: data?.dealValue,
        comments: data?.comments,
        industry: data?.industry,
        accountOwnerName: data?.accountOwnerName,
      }));
    }
  }, [data]);

  const getCountryName = (code, name) => {
    if (code !== null && code !== "") {
      return countriesData.find((item) => item?.code === code).name;
    } else {
      if (name !== null && name !== "") {
        return name;
      } else {
        return "-";
      }
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const update = {
        ...updateFields,
      };
      update.dealLink = update.dealLink === "" ? null : update.dealLink;
      update.dealValue = update.dealValue === "" ? null : update.dealValue;
      update.comments = update.comments === "" ? null : update.comments;
      update.industry = update.industry === "" ? null : update.industry;
      update.accountOwnerName =
        accountOwners.length === 0 ? null : accountOwners.join(",");
      const res = await api.patch(`/location/${formData.id}`, update);
      if (res.status === 200) {
        setIsLoading(false);
        toast.success(
          res.data.message ? res.data.message : "Account Updated successfully"
        );
        getAccountListing();
        close(false);
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      toast.error(message);
    }
  };

  const validateUrl = (value) => {
    const urlPattern = new RegExp(
      "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})"
    );
    return !!value.match(urlPattern);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setError(!validateUrl(value));
    setUpdateFields((prev) => ({
      ...prev,
      dealLink: value,
    }));
    if (value === "") {
      console.log(value);
      setError(false);
    }
  };

  const handleAddBrandsKeywords = (e) => {
    if (e.target.value.includes(",") && e.target.value.includes("\n")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(/[\n,]+/);
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(accountOwners);
      const mergeResult = [...accountOwners, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setAccountOwners(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(",")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(",");
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(accountOwners);
      const mergeResult = [...accountOwners, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setAccountOwners(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(".")) {
      const lastDotRemoved = e?.target?.value.replace(/\.+$/, "");
      const arr = lastDotRemoved.split(".");
      arr.concat(accountOwners);
      const mergeResult = [...accountOwners, ...arr];
      const uniqueKeywords = [...new Set(mergeResult)];
      setAccountOwners(uniqueKeywords);
      e.target.value = "";
    }
    const lowerCaseBrandTags = accountOwners.map((tag) => tag.toLowerCase());
    if (lowerCaseBrandTags.includes(e.target.value.toLowerCase())) {
      setDuplicateAccountOwners(true);
    } else {
      setDuplicateAccountOwners(false);
    }
  };

  const removeBrandsTag = (index) => {
    setAccountOwners(accountOwners.filter((el, i) => i !== index));
  };

  const handleBrandsKeyDown = (e) => {
    if (e.key !== "Enter" || e.target.value.trim() === "") return;

    let value = e.target.value.toLowerCase().trim();
    if (value.includes("\n") && value !== "\n") {
      const lastLineBreakRemoved = value.replace(/\n+$/, "");
      const arr = lastLineBreakRemoved.split("\n");
      const filtered = arr.filter((item) => item?.trim() !== "");
      const mergeResult = [...accountOwners, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setAccountOwners(uniqueKeywords);

      e.target.value = "";
    } else {
      if (!value.trim()) return;
      const mergeResult = [...accountOwners, value];
      const uniqueKeywords = [...new Set(mergeResult)];
      setAccountOwners(uniqueKeywords);
    }
    const lowerCaseBrandTags = accountOwners.map((tag) => tag.toLowerCase());
    const lastLineBreakRemoved = value.replace(/\n+$/, "");
    const arr = lastLineBreakRemoved.split("\n");
    const filtered = arr.filter((item) => item?.trim() !== "");
    if (lowerCaseBrandTags.includes(value.toLowerCase())) {
      setDuplicateAccountOwners(true);
      setTimeout(() => {
        setDuplicateAccountOwners(false);
      }, 2000);
    } else {
      for (let i = 0; i < accountOwners.length; i++) {
        if (filtered.includes(accountOwners[i])) {
          setDuplicateAccountOwners(true);
          setTimeout(() => {
            setDuplicateAccountOwners(false);
          }, 2000);
          break;
        }
      }
    }

    e.target.value = "";
  };

  return (
    <Modal open={open}>
      <Grid container>
        <Grid
          item
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid className={classes.styleMainModelContainer}>
            <Grid
              sx={{
                pb: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.mainContainerHeading}>
                {t("account_settings")}
              </Typography>

              <IconButton
                autoFocus
                onClick={() => close(false)}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider />
            <Grid className={classes.contentContainer}>
              <Grid container spacing={2} sx={{ marginBottom: "10px" }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.nameLabel}>
                    {t("AccountName")}
                  </Typography>
                  <TextField
                    disabled
                    type="text"
                    placeholder={t("AccountName")}
                    autoComplete="none"
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    value={
                      formData?.company === null ? "-" : formData?.company?.name
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.nameLabel}>
                    {t("Location_Name")}
                  </Typography>
                  <TextField
                    disabled
                    type="text"
                    placeholder={t("Location_Name")}
                    defaultValue=""
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    value={formData?.name}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.nameLabel}>
                    {t("ObenanStartDate")}
                  </Typography>
                  <TextField
                    disabled
                    type="date"
                    placeholder={t("ObenanStartDate")}
                    autoComplete="none"
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    value={moment(formData?.subscriptionDate).format(
                      "YYYY-MM-DD"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.nameLabel}>
                    {t("Country")}
                  </Typography>
                  <TextField
                    disabled
                    type="text"
                    placeholder={t("Country")}
                    autoComplete="none"
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    value={getCountryName(
                      formData?.countryCode,
                      formData?.country
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.nameLabel}>
                    {t("ListingPercentage")}
                  </Typography>
                  <TextField
                    disabled
                    type="text"
                    placeholder={t("ListingPercentage")}
                    defaultValue=""
                    autoComplete="none"
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    value={
                      (formData?.profileCompletition === null
                        ? "0"
                        : formData?.profileCompletition) + "%"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.nameLabel}>
                    {t("Review_Response")}
                  </Typography>
                  <TextField
                    disabled
                    type="text"
                    placeholder={t("Review_Response")}
                    autoComplete="none"
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    value={formData?.ruleLocation !== 0 ? "Yes" : "No"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.nameLabel}>
                    {t("Posts")}
                  </Typography>
                  <TextField
                    disabled
                    type="text"
                    placeholder={t("Posts")}
                    autoComplete="none"
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    value={formData?.postLocations !== 0 ? "Yes" : "No"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.nameLabel}>
                    {t("Deal_Link")}
                  </Typography>
                  <TextField
                    type="text"
                    placeholder={t("Deal_Link")}
                    autoComplete="none"
                    fullWidth
                    value={
                      updateFields?.dealLink === null
                        ? ""
                        : updateFields?.dealLink
                    }
                    onChange={handleChange}
                    error={error}
                    helperText={error ? t("Invalid_Url") : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.nameLabel}>
                    {t("Deal_Value")}
                  </Typography>
                  <TextField
                    type="number"
                    placeholder={t("Deal_Value")}
                    autoComplete="none"
                    fullWidth
                    value={
                      updateFields?.dealValue === null
                        ? ""
                        : updateFields?.dealValue
                    }
                    onChange={(e) =>
                      setUpdateFields((prev) => ({
                        ...prev,
                        dealValue: e?.target?.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.nameLabel}>
                    {t("Account_Owner")}
                  </Typography>
                  <Grid className={classes.tagsInputContainer}>
                    <TextField
                      variant="standard"
                      type="text"
                      className={classes.tagsInput}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      placeholder={t("Enter_account_owners")}
                      autoComplete="none"
                      fullWidth
                      onKeyDown={handleBrandsKeyDown}
                      onChange={(e) => {
                        if (
                          e.target.value === "," ||
                          e.target.value === "." ||
                          e.target.value.trim() === ""
                        ) {
                          e.target.value = "";
                        } else if (
                          (e.target.value !== "" &&
                            e.target.value !== "," &&
                            e.target.value.includes(".")) ||
                          (e.target.value !== "." &&
                            e.target.value !== "" &&
                            e.target.value.includes(","))
                        ) {
                          handleAddBrandsKeywords(e);
                        }
                      }}
                    />
                    {accountOwners?.length > 0 &&
                      accountOwners.map((keywords, index) => (
                        <Grid className={classes.tagItem} key={index}>
                          <Box className={classes.keywordBox}>
                            <Typography className={classes.keywordTypo}>
                              {keywords}
                            </Typography>{" "}
                            <CloseIcon
                              className={classes.keywordIcon}
                              onClick={() => removeBrandsTag(index)}
                            />
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                  <Typography
                    sx={{ padding: "4px", color: "gray", fontSize: "10px" }}
                  >
                    {t("Press_Enter")}
                  </Typography>
                  {duplicateAccountOwners && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {t("owners_duplicated_error")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.nameLabel}>
                    {t("Industry")}
                  </Typography>
                  <TextField
                    type="text"
                    placeholder={t("Industry")}
                    autoComplete="none"
                    fullWidth
                    value={
                      updateFields?.industry === null
                        ? ""
                        : updateFields?.industry
                    }
                    onChange={(e) =>
                      setUpdateFields((prev) => ({
                        ...prev,
                        industry: e?.target?.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.nameLabel}>
                    {t("Comment")}
                  </Typography>
                  <TextField
                    rows={4}
                    multiline
                    type="text"
                    placeholder={t("Comment")}
                    autoComplete="none"
                    fullWidth
                    value={
                      updateFields?.comments === null
                        ? ""
                        : updateFields?.comments
                    }
                    onChange={(e) =>
                      setUpdateFields((prev) => ({
                        ...prev,
                        comments: e?.target?.value,
                      }))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                py: "10px",
              }}
            >
              <CommonButton
                type="submit"
                isLoading={false}
                disabled={false}
                onSubmit={() => close(false)}
                label={t("Cancel")}
                leftMargin={true}
                customHeight={true}
                displayWhite={"true"}
              />
              <CommonButton
                type="submit"
                isLoading={isLoading}
                disabled={isLoading || error}
                onSubmit={handleSubmit}
                label={t("Save")}
                leftMargin={true}
                customHeight={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AccountManagementModal;
