import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Box,
  Tooltip,
  tooltipClasses,
  Button,
  Alert,
} from "@mui/material";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../../../contexts/JWTContext";
import Hours from "./hours";
import { toast } from "react-toastify";
import moment from "moment";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import AddIcon from "@mui/icons-material/Add";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const CompanySpeacialhour = ({
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  indexValue,
  setSelectedTab,
  selectedTab,
  setIndexValue,
  setSelectedLocationsBulkUpdate,
  selectedLocationsBulkUpdate,
  onCancel,
  handleCloseFilterModel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedData, setSelectedData] = useState({});
  const [filteredPeriodsArr, setFilteredPeriodsArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [newDate, setNewDate] = useState(null);
  const [todayDate, setTodayDate] = useState(null);
  const [newTimeArr, setNewTimeArr] = useState([]);
  const [cancelState, setCancelState] = useState(false);
  const [duplicateDate, setDuplicateDate] = useState(false);
  const [disableFields, setDisableFields] = useState(false);

  useEffect(() => {
    /*  if (
      locationDetailsData?.result?.google?.openInfo?.status === "OPEN" &&
      locationDetailsData?.result?.google.regularHours === null
    ) {
      setDisableFields(true);
    } else if (
      locationDetailsData?.result?.google?.openInfo?.status !== "OPEN"
    ) {
      setDisableFields(true);
    } else if (
      locationDetailsData?.result?.google?.openInfo?.status === "OPEN" &&
      locationDetailsData?.result?.google.regularHours !== null
    ) {
      setDisableFields(false);
    } else {
      setDisableFields(false);
    } */
    // handleSpecialHoursData();
  }, []);

  useEffect(() => {
    let date = new Date();
    setTodayDate(date);
  }, []);

  useEffect(() => {
    const groupedByStartDate = {};
    if (filteredPeriodsArr?.length > 0) {
      filteredPeriodsArr.forEach((item, index) => {
        const startDateStr = JSON.stringify(item.startDate);
        if (!groupedByStartDate[startDateStr]) {
          groupedByStartDate[startDateStr] = [index];
        } else {
          groupedByStartDate[startDateStr].push(index);
        }
      });

      const indexesWithSameStartDate = Object.values(groupedByStartDate)
        .filter((indices) => indices.length > 1)
        .flat();

      const updatedFilteredPeriodsArr = [...filteredPeriodsArr]; // Create a copy to modify
      let hasChanges = false; // Flag to track changes

      if (indexesWithSameStartDate?.length > 0) {
        indexesWithSameStartDate?.forEach((itemOne) => {
          if (!updatedFilteredPeriodsArr[itemOne].sameDate) {
            updatedFilteredPeriodsArr[itemOne].sameDate = true;
            hasChanges = true; // Set the flag if sameDate was updated
          }
        });
        if (hasChanges) {
          setDuplicateDate(true);
          setFilteredPeriodsArr(updatedFilteredPeriodsArr);
        }
      } else {
        updatedFilteredPeriodsArr?.forEach((item) => {
          if (item.sameDate) {
            item.sameDate = false;
            hasChanges = true; // Set the flag if sameDate was updated
          }
        });
        if (hasChanges) {
          setDuplicateDate(false);
          setFilteredPeriodsArr(updatedFilteredPeriodsArr);
        }
      }
    }
  }, [filteredPeriodsArr]); // Run the effect whenever filteredPeriodsArr changes

  /*  const handleSpecialHoursData = () => {
    let filteredData = [];
    const finalFilteredData = [];
    const arr2 = [];
    if (
      locationDetailsData?.result?.google?.specialHours?.specialHourPeriods
        ?.length > 0
    ) {
      locationDetailsData?.result?.google?.specialHours?.specialHourPeriods?.map(
        (item) => {
          if (item?.closed && item?.closed) {
            const tempObjOne = {
              startDate: {
                year: item?.startDate?.year,
                month: item?.startDate?.month,
                day: item?.startDate?.day,
              },
              openTime: {
                hours: 0,
                minutes: 0,
              },
              endDate: {
                year:
                  item?.endDate?.year > 0
                    ? item?.endDate?.year
                    : item?.startDate?.year,
                month:
                  item?.endDate?.month > 0
                    ? item?.endDate?.month
                    : item?.startDate?.month,
                day:
                  item?.endDate?.day > 0
                    ? item?.endDate?.day
                    : item?.startDate?.day,
              },
              closeTime: {
                hours: 0,
                minutes: 0,
              },
              closed: true,
              sameDate: false,
            };
            filteredData.push(tempObjOne);
            //  filteredPeriodsArr.push(tempObjOne);
          } else {
            const tempObjTwo = {
              startDate: {
                year: item?.startDate?.year,
                month: item?.startDate?.month,
                day: item?.startDate?.day,
              },
              openTime: {
                hours: item?.openTime?.hours,
                minutes: item?.openTime?.minutes ? item?.openTime?.minutes : 0,
              },
              endDate: {
                year:
                  item?.endDate?.year > 0
                    ? item?.endDate?.year
                    : item?.startDate?.year,
                month:
                  item?.endDate?.month > 0
                    ? item?.endDate?.month
                    : item?.startDate?.month,
                day:
                  item?.endDate?.day > 0
                    ? item?.endDate?.day
                    : item?.startDate?.day,
              },
              closeTime: {
                hours: item?.closeTime?.hours,
                minutes: item?.closeTime?.minutes
                  ? item?.closeTime?.minutes
                  : 0,
              },
              closed: false,
              sameDate: false,
            };
            filteredData.push(tempObjTwo);
            // filteredPeriodsArr.push(tempObjTwo);
          }
        }
      );
      //  setFilteredPeriodsArr([...filteredPeriodsArr]);
    }
    filteredData?.forEach((itemOne) => {
      let openTimes = [];
      let closeTimes = [];
      let mergedTimes = [];
      filteredData?.forEach((itemTwo) => {
        if (
          itemOne?.startDate?.day === itemTwo?.startDate?.day &&
          itemOne?.startDate?.year === itemTwo?.startDate?.year &&
          itemOne?.startDate?.month === itemTwo?.startDate?.month
        ) {
          openTimes?.push(itemTwo.openTime);
          closeTimes.push(itemTwo.closeTime);
          const obj = {
            openHours: itemTwo.openTime ? itemTwo.openTime : 0,
            closedHours: itemTwo.closeTime ? itemTwo.closeTime : 0,
          };
          mergedTimes.push(obj);
        }
      });
      itemOne.mergedTimes = [...mergedTimes];
      finalFilteredData.push(itemOne);

      finalFilteredData.forEach((item) => {
        let isFound = false;
        arr2.forEach((item2) => {
          if (
            item?.startDate?.day === item2.startDate?.day &&
            item?.startDate?.month === item2.startDate?.month &&
            item?.startDate?.year === item2.startDate?.year
          ) {
            isFound = true;
          }
        });
        if (!isFound) {
          //  timesArr.push(item);
          arr2.push(item);
        }
      });
    });
    const sortedData = arr2.sort((a, b) => {
      const dateA = new Date(
        a.startDate.year,
        a.startDate.month - 1,
        a.startDate.day
      );
      const dateB = new Date(
        b.startDate.year,
        b.startDate.month - 1,
        b.startDate.day
      );
      return dateA - dateB;
    });

    // setFilteredPeriodsArr([...filteredPeriodsArr]);

    setFilteredPeriodsArr(sortedData);
    setNewTimeArr(sortedData);
  }; */

  const handleChangeOpen = (val, periods, ind) => {
    setTouchedValue(true);
    if (val === null) {
      const tempObjTwo = {
        startDate: {
          year: periods?.startDate?.year,
          month: periods?.startDate?.month,
          day: periods?.startDate?.day,
        },
        openTime: {
          hours: 0,
          minutes: 0,
        },
        endDate: {
          year:
            periods?.endDate?.year > 0
              ? periods?.endDate?.year
              : periods?.startDate?.year,
          month:
            periods?.endDate?.month > 0
              ? periods?.endDate?.month
              : periods?.startDate?.month,
          day:
            periods?.endDate?.day > 0
              ? periods?.endDate?.day
              : periods?.startDate?.day,
        },
        closeTime: {
          hours: 0,
          minutes: 0,
        },
        mergedTimes: [
          {
            openHours: {
              hours: 0,
              minutes: 0,
            },
            closedHours: {
              hours: 0,
              minutes: 0,
            },
          },
        ],
        closed: null,
        sameDate: periods?.sameDate,
      };
      filteredPeriodsArr[ind] = tempObjTwo;
    } else if (val) {
      const tempObjOne = {
        startDate: {
          year: periods?.startDate?.year,
          month: periods?.startDate?.month,
          day: periods?.startDate?.day,
        },
        openTime: {
          hours: 0,
          minutes: 0,
        },
        endDate: {
          year:
            periods?.endDate?.year > 0
              ? periods?.endDate?.year
              : periods?.startDate?.year,
          month:
            periods?.endDate?.month > 0
              ? periods?.endDate?.month
              : periods?.startDate?.month,
          day:
            periods?.endDate?.day > 0
              ? periods?.endDate?.day
              : periods?.startDate?.day,
        },
        closeTime: {
          hours: 0,
          minutes: 0,
        },
        mergedTimes: [
          {
            openHours: {
              hours: 0,
              minutes: 0,
            },
            closedHours: {
              hours: 0,
              minutes: 0,
            },
          },
        ],
        closed: false,
        sameDate: periods?.sameDate,
      };
      filteredPeriodsArr[ind] = tempObjOne;
      // setOpen(true);
    } else {
      const tempObjTwo = {
        startDate: {
          year: periods?.startDate?.year,
          month: periods?.startDate?.month,
          day: periods?.startDate?.day,
        },
        openTime: {
          hours: 0,
          minutes: 0,
        },
        endDate: {
          year:
            periods?.endDate?.year > 0
              ? periods?.endDate?.year
              : periods?.startDate?.year,
          month:
            periods?.endDate?.month > 0
              ? periods?.endDate?.month
              : periods?.startDate?.month,
          day:
            periods?.endDate?.day > 0
              ? periods?.endDate?.day
              : periods?.startDate?.day,
        },
        closeTime: {
          hours: 0,
          minutes: 0,
        },
        mergedTimes: [
          {
            openHours: {
              hours: 0,
              minutes: 0,
            },
            closedHours: {
              hours: 0,
              minutes: 0,
            },
          },
        ],
        closed: true,
        sameDate: periods?.sameDate,
      };
      filteredPeriodsArr[ind] = tempObjTwo;
    }
    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };
  const handleChangeOpenTime = (e, itemIndex, item, periodsIndex) => {
    setTouchedValue(true);

    const formattedDate = dayjs(e).format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
    );
    const obj = {
      openHours: {
        hours: dayjs(e).hour(),
        minutes: dayjs(e).minute(),
      },
      closedHours: {
        hours: item?.closedHours?.hours,
        minutes: item?.closedHours?.minutes,
      },
      openTimesValueMerged: formattedDate,
      closeTimesValueMerged: item?.closeTimesValueMerged,
    };
    filteredPeriodsArr[periodsIndex].mergedTimes[itemIndex] = obj;
    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };

  const handleChangeCloseTime = (e, itemIndex, item, periodsIndex) => {
    setTouchedValue(true);
    const formattedDate = dayjs(e).format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
    );

    const obj = {
      openHours: {
        hours: item?.openHours?.hours,
        minutes: item?.openHours?.minutes,
      },
      closedHours: {
        hours: dayjs(e).hour(),
        minutes: dayjs(e).minute(),
      },
      openTimesValueMerged: item?.openTimesValueMerged,
      closeTimesValueMerged: formattedDate,
    };
    filteredPeriodsArr[periodsIndex].mergedTimes[itemIndex] = obj;
    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };
  const handleRemoveHours = (periodsIndex, itemIndex) => {
    setTouchedValue(true);
    const filteredMergedArr = filteredPeriodsArr[
      periodsIndex
    ].mergedTimes.filter((el, i) => i !== itemIndex);
    filteredPeriodsArr[periodsIndex].mergedTimes = filteredMergedArr;
    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };
  function isValidDateFormat(dateString, format) {
    return dayjs(dateString, format, true).isValid();
  }

  const handleChangeDate = (e, index, data) => {
    setTouchedValue(true);
    const format = "DD/MM/YYYY";
    const isValid = e !== "" && isValidDateFormat(e, format);
    console.log(isValid, e, "isValid");
    const year = dayjs(e).format("YYYY");
    const month = dayjs(e).format("MM");
    const day = dayjs(e).format("D");

    let tempObjOne = "";

    if (isValid === false) {
      tempObjOne = {
        startDate: {
          year: parseInt(year),
          month: parseInt(month),
          day: parseInt(day),
        },
        openTime: {
          hours: data?.openTime?.hours,
          minutes: data?.openTime?.minutes,
        },
        endDate: {
          year: data?.endDate?.year > 0 ? data?.endDate?.year : parseInt(year),
          month:
            data?.endDate?.month > 0 ? data?.endDate?.month : parseInt(month),
          day: data?.endDate?.day > 0 ? data?.endDate?.day : parseInt(day),
        },
        closeTime: {
          hours: data?.closeTime?.hours,
          minutes: data?.closeTime?.minutes,
        },
        mergedTimes: data?.mergedTimes,
        closed: data?.closed,
        dateError: true,
      };
    } else {
      tempObjOne = {
        startDate: {
          year: parseInt(year),
          month: parseInt(month),
          day: parseInt(day),
        },
        openTime: {
          hours: data?.openTime?.hours,
          minutes: data?.openTime?.minutes,
        },
        endDate: {
          year: data?.endDate?.year > 0 ? data?.endDate?.year : parseInt(year),
          month:
            data?.endDate?.month > 0 ? data?.endDate?.month : parseInt(month),
          day: data?.endDate?.day > 0 ? data?.endDate?.day : parseInt(day),
        },
        closeTime: {
          hours: data?.closeTime?.hours,
          minutes: data?.closeTime?.minutes,
        },
        mergedTimes: data?.mergedTimes,
        closed: data?.closed,
        //   dateError: true,
      };
    }

    filteredPeriodsArr[index] = tempObjOne;
    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };

  console.log(filteredPeriodsArr, "filteredPeriodsArr");

  const handleAddNewData = () => {
    setTouchedValue(true);
    var dataTodays = todayDate.setDate(todayDate.getDate() + 1);

    const toDate = new Date(dataTodays);

    const year = moment(toDate).format("YYYY");
    const month = moment(toDate).format("MM");
    const day = moment(toDate).format("D");
    const tempObjOne = {
      startDate: {
        year: parseInt(year),
        month: parseInt(month),
        day: parseInt(day),
      },
      openTime: {
        hours: 0,
        minutes: 0,
      },
      endDate: {
        year: 0,
        month: 0,
        day: 0,
      },
      closeTime: {
        hours: 0,
        minutes: 0,
      },
      mergedTimes: [
        {
          openHours: {
            hours: 0,
            minutes: 0,
          },
          closedHours: {
            hours: 0,
            minutes: 0,
          },
        },
      ],
      closed: true,
      sameDate: false,
    };
    filteredPeriodsArr.push(tempObjOne);
    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };

  const handleAddHours = (periodsIndex, itemIndex) => {
    setTouchedValue(true);
    const date = new Date();
    let obj = {
      openHours: {
        hours: 0,
        minutes: 0,
      },
      closedHours: {
        hours: 0,
        minutes: 0,
      },
      openTimesValueMerged: date,
      closeTimesValueMerged: date,
    };

    filteredPeriodsArr[periodsIndex].mergedTimes?.push(obj);
    setFilteredPeriodsArr([...filteredPeriodsArr]);
  };
  const removeDate = (index) => {
    setTouchedValue(true);
    setFilteredPeriodsArr(filteredPeriodsArr.filter((el, i) => i !== index));
  };
  const handleUpdateSpecialHours = async () => {
    setIsLoading(true);
    if (
      duplicateDate === false &&
      disableFields === false &&
      filteredPeriodsArr?.some(
        (item) => item?.hasOwnProperty("dateError") && item?.dateError === true
      ) === false
    ) {
      const sortedData = filteredPeriodsArr.sort((a, b) => {
        const dateA = new Date(
          a.startDate.year,
          a.startDate.month - 1,
          a.startDate.day
        );
        const dateB = new Date(
          b.startDate.year,
          b.startDate.month - 1,
          b.startDate.day
        );
        return dateA - dateB;
      });

      let filteredOpenArr = [...sortedData];
      let finalArr = [];
      sortedData?.map((item) => {
        if (item?.mergedTimes?.length > 0 && item?.mergedTimes?.length === 1) {
          const tempObjOne = {
            startDate: {
              year: item?.startDate?.year,
              month: item?.startDate?.month,
              day: item?.startDate?.day,
            },
            openTime: {
              hours: item?.mergedTimes[0]?.openHours?.hours,
              minutes: item?.mergedTimes[0]?.openHours?.minutes
                ? item?.mergedTimes[0]?.openHours?.minutes
                : 0,
            },

            endDate: {
              year: item?.startDate?.year,
              month: item?.startDate?.month,
              day: item?.startDate?.day,
            },
            closeTime: {
              hours: item?.mergedTimes[0]?.closedHours?.hours,
              minutes: item?.mergedTimes[0]?.closedHours?.minutes
                ? item?.mergedTimes[0]?.closedHours?.minutes
                : 0,
            },
            closed: item?.closed,
            sameDate: item?.sameDate,
          };
          finalArr.push(tempObjOne);
        } else {
          item?.mergedTimes?.map((itemTwo) => {
            const tempObjTwo = {
              startDate: {
                year: item?.startDate?.year,
                month: item?.startDate?.month,
                day: item?.startDate?.day,
              },
              openTime: {
                hours: itemTwo?.openHours?.hours,
                minutes: itemTwo?.openHours?.minutes
                  ? itemTwo?.openHours?.minutes
                  : 0,
              },
              endDate: {
                year: item?.startDate?.year,
                month: item?.startDate?.month,
                day: item?.startDate?.day,
              },
              closeTime: {
                hours: itemTwo?.closedHours?.hours,
                minutes: itemTwo?.closedHours?.minutes
                  ? itemTwo?.closedHours?.minutes
                  : 0,
              },
              closed: item?.closed,
              sameDate: item?.sameDate,
            };
            finalArr.push(tempObjTwo);
          });
        }
      });
      finalArr.forEach((item) => {
        if (item?.closed === true) {
          delete item["closeTime"];
          delete item["openTime"];
          delete item["sameDate"];
        } else {
          delete item["closed"];
          delete item["sameDate"];
        }
      });
      let locationIds = [];
      if (selectedLocationsBulkUpdate?.length > 0) {
        selectedLocationsBulkUpdate.map((item) => {
          locationIds.push(item.id);
        });
      }
      try {
        setDisplayConfirmationBox(false);
        const res = await api.patch(`/locationListing/bulkSpecialHours`, {
          locationId: locationIds,
          specialHours: {
            specialHourPeriods: finalArr,
          },
        });

        if (res.status === 200) {
          setIsLoading(false);
          toast.success("Updated Successfully");
          // onCancel();
          //   getLocationListingDetails(location);
          // setLocationDetailsData(res?.data?.data);

          if (indexValue !== null) {
            let selectedValue = indexValue;

            setSelectedTab(selectedValue);
          }
          if (cancelState === true) {
            onCancel();
            handleCloseFilterModel();
          }
          setTouchedValue(false);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    } else {
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    }
  };

  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      handleCloseFilterModel();
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      //  getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateSpecialHours}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            /*    getLocationDetails={getLocationDetails}
            location={location} */

            //  loading={setIsLoading}
          />
        </Dialog>
      )}
      <Grid container sx={{ padding: "18px" }}>
        <Grid
          item
          xs={10}
          sm={10}
          md={11.5}
          lg={11.5}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography className={classes.modalHeader}>
            {t("Special_Hours")}
          </Typography>
          <BootstrapTooltip title={t("Speacial_hour_subhead")}>
            <Typography
              sx={{
                marginLeft: "5px",
                marginTop: "4px",
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon />
            </Typography>
          </BootstrapTooltip>
        </Grid>
        <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
          <IconButton
            autoFocus
            onClick={() => {
              handleOpen();
              setCancelState(true);
            }}
            className="delete_button"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />
      <Grid container className={classes.gridContainertwoBulk}>
        {isLoading ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.loaderBox}
          >
            <Loader />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container sx={{ padding: "18px" }}>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                {filteredPeriodsArr?.length > 0 &&
                  filteredPeriodsArr.map((periods, index) => {
                    return (
                      <Grid
                        container
                        key={index}
                        sx={{
                          marginBottom:
                            periods?.closed === true ? "10px" : "0px",
                        }}
                      >
                        <Hours
                          index={index}
                          periods={periods}
                          handleChangeDate={handleChangeDate}
                          removeDate={removeDate}
                          selectedData={selectedData}
                          setSelectedData={setSelectedData}
                          filteredPeriodsArr={filteredPeriodsArr}
                          handleChangeOpen={handleChangeOpen}
                          handleChangeOpenTime={handleChangeOpenTime}
                          handleChangeCloseTime={handleChangeCloseTime}
                          handleAddHours={handleAddHours}
                          handleRemoveHours={handleRemoveHours}
                          setFilteredPeriodsArr={setFilteredPeriodsArr}
                          setDuplicateDate={setDuplicateDate}
                          disableFields={disableFields}
                        />
                      </Grid>
                    );
                  })}

                <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                  <Button
                    autoFocus
                    onClick={handleAddNewData}
                    disabled={disableFields}
                    /*   onClick={() => {
              handleAddNewData();
            }} */
                    className={
                      disableFields
                        ? classes.addButtonDisabled
                        : classes.addButton
                    }
                  >
                    <AddIcon
                      fontSize="medium"
                      className={
                        disableFields
                          ? classes.addIconDisabled
                          : classes.addIcon
                      }
                    />{" "}
                    <Typography
                      className={
                        disableFields
                          ? classes.addButtonDisabled
                          : classes.addButton
                      }
                    >
                      {t("Add_New_Date")}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Divider variant="middle" />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridButtons}
      >
        <CommonButton
          displayWhite="true"
          label={t("Back")}
          onSubmit={handleCancelButton}
        />
        <CommonButton
          onSubmit={handleUpdateSpecialHours}
          label={t("Save")}
          disabled={
            duplicateDate ||
            disableFields ||
            filteredPeriodsArr?.some(
              (item) =>
                item?.hasOwnProperty("dateError") && item?.dateError === true
            )
          }
        />
      </Grid>
    </Grid>
  );
};

export default CompanySpeacialhour;
