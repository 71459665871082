import React from "react";
import Box from "@mui/material/Box";
import { Typography, Grid } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import NoLocation from "../../assets/images/nolocationfull.svg"
import NoLocation from "../../../assets/images/nolocationfull.svg";
import { makeStyles } from "@mui/styles";
import CommonButton from "../Buttons/CommonButton";
import AddLocationButton from "../Buttons/AddLocationButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { api } from "../../../contexts/JWTContext";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  iconCard: {
    border: "1px solid #E0E0E0",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-around",
    width: "40px",
    height: "40px",
    padding: "8px",
  },
  iconContainer: {
    padding: "20px 0px 10px 0px",
  },
  iconStyle: {
    width: "20px",
    height: "18px",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
  },
  locationboxTwo: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "auto",
    margin: "auto",
    //   justifyContent: "space-between",
    paddingTop: "12px",
  },
  locationsubhead: {
    marginTop: "12px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "16px",
    color: "#495059",
  },
  locationhead: {
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "32px",
    color: "#1B2430",
  },
  Imagesection: {
    marginTop: "8px",
    height: "300px",
  },
  Imagesection2: {
    marginTop: "8px",
    height: "250px",
  },
}));
const LocationNotFound = ({ text, subtext }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const baseURL = process.env.REACT_APP_BASE_URL;

  const handleConnectWithGoogle = (fromReconnect) => {
    fetchURL();
  };

  async function fetchURL() {
    try {
      const res = await api.get(`googleConnect/auth-url`);
      if (res.data?.status === true) {
        window.location.href = res?.data.data /* + "&state=" + id */;
      }
    } catch (error) {}
  }
  const handleNavigate = () => {
    navigate("/user/add-location");
  };
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box className={classes.locationbox}>
        <img className={classes.Imagesection} src={NoLocation} />

        <Typography className={classes.locationhead}>{text}</Typography>
        <Typography className={classes.locationsubhead}>{subtext}</Typography>
      </Box>

      <Box className={classes.locationboxTwo}>
        <AddLocationButton
          onSubmit={handleNavigate}
          label={t("Add Location")}
          icon={<AddIcon sx={{ color: "#06BDFF" }} />}
        />

        <CommonButton
          onSubmit={handleConnectWithGoogle}
          icon={
            <img
              style={{ width: 30, height: 30, marginRight: "3px" }}
              src={`${baseURL}/assets/static/review_source/google.png`}
            />
          }
          label={t("Connect_With_Google")}
        />
      </Box>
    </Grid>
  );
};

export default LocationNotFound;
