// ** React Imports ================================================================
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// ** File Imports ================================================================
import TitleHeader from "../../../components/CustomComponents/title";
import NotFound from "../../../components/NotFound/NotFound";
import { api } from "../../../contexts/JWTContext";
import Delete from "../../../components/Models/DeleteModal/Delete";
import CustomizedTableRow from "../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../components/CustomComponents/table/tableCell";
// ** Material ui Imports ================================================================

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  IconButton,
  Dialog,
  Tooltip,
  tooltipClasses,
  Menu,
  MenuItem,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { handleSessionOut } from "../../../contexts/JWTContext";
import { Edit } from "@mui/icons-material";
import useAuth from "../../../hooks/useAuth";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Loader from "../../../components/Loaders/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import { TablePagination } from "@mui/material";

// ** Other Imports ================================================================
import moment from "moment";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});

const Index = () => {
  // ** States ================================================================
  const [location, setLocation] = useState(null);
  const { signOut } = useAuth();

  const [loading, setLoading] = useState(false);
  const [ruleAllLocation, setRuleAllLocation] = useState([]);
  const [count, setCount] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteRule, setDeleteRule] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [allLocation, setAllLocation] = useState([]);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyId, setCompanyId] = useState(null);

  // ** Others ================================================================

  const navigate = useNavigate();
  const { t } = useTranslation();

  // ** Use Effects ================================================================
  useEffect(() => {
    setLoading(true);
    getAllRules();
    getAllCompanies();
  }, [page, rowsPerPage, companyId]);

  const AddRuleNavigateButton = () => {
    navigate(`/admin/addrule`);
  };

  // ** Handler Functions ================================================================

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDeleteRule(id);
  };
  const handleOpenDeleteModel = () => {
    setOpen(deleteRule);
    setAnchorEl(null);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDisplayDate = (item) => {
    let displayDate = "";
    if (companyData.dateFormat === "May 19,2022 3:05PM") {
      displayDate = moment(item).format("MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "Monday,May 19,2022 3:05PM") {
      displayDate = moment(item).format("dddd,MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "May 19,2022") {
      displayDate = moment(item).format("MMMM DD,YYYY");
    }
    if (companyData.dateFormat === "5/19/2022") {
      displayDate = moment(item).format("M/D/YYYY");
    }
    if (companyData.dateFormat === "05/19/2022") {
      displayDate = moment(item).format("MM/D/YYYY");
    }
    if (companyData.dateFormat === "28/12/2022") {
      displayDate = moment(item).format("D/MM/YYYY");
    } else {
      displayDate = moment(item).format("D/MM/YYYY");
    }
    if (displayDate.length > 0) {
      return displayDate;
    } else return null;
  };

  const getAllRules = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        `/rule?limit=${rowsPerPage}&page=${page + 1}&companyId=${
          companyId !== null ? companyId?.id : ""
        }`
      );
      if (res.status === 200) {
        setLocation(res?.data?.data);
        setCount(res?.data?.data?.count);
        let temparray = [];

        res?.data?.data?.results?.map((item) => {
          item?.ruleLocation?.map((item) => {
            temparray.push(item);
          });
        });
        setRuleAllLocation(temparray);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const getAllCompanies = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const DeleteRule = async (id) => {
    try {
      const res = await api.delete(`/rule/${id}`);
      if (res.status === 200) {
        setOpen(false);
        getAllRules();

        toast.success(res?.data?.message);
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const handleChangeCompany = (option) => {
    setCompanyId(option);
    if (option?.locations?.length > 0) {
      const googleConnectedLocations = option?.locations?.filter(
        (item) => item?.socialLink?.length > 0
      );
      setAllLocation(googleConnectedLocations);
    } else {
      setAllLocation([]);
    }
  };

  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Rule_title")}
          description={t("Delete_rule_subhead")}
          onConfirm={() => DeleteRule(deleteRule)}
          onCancel={handleClose}
        />
      </Dialog>

      <TitleHeader
        title={t("Rule_Title")}
        subHeading={t("Rule_subTitle")}
        createButton={true}
        name={t("Add_Rule")}
        onClick={AddRuleNavigateButton}
        count={count}
        addPermission={true}
        viewPermission={true}
      />
      <Grid container spacing={1} sx={{ marginBottom: "16px" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            sx={{
              marginTop: "10px",
              fontSize: "14px",
              fontWeight: "700",
              color: "rgba(0,0,0,0.87)",
            }}
          >
            {t("Select_Company")}
          </Typography>

          <Autocomplete
            // multiple
            disablePortal
            id="combo-box-demo"
            options={
              allCompanies?.results?.length > 0 ? allCompanies?.results : []
            }
            getOptionLabel={(option) =>
              option?.name ? option?.name + "(" + option?.city + ")" : ""
            }
            className="Autocomplete-field"
            value={companyId !== null ? companyId : []}
            onChange={(e, value) => {
              handleChangeCompany(value);
            }}
            /*  renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {`${option?.internalName} (${option.city})`}
              </li>
            )} */
            renderInput={(params) => (
              <TextField
                placeholder={t("Select_Company")}
                {...params}
                required
                name="company"
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <Paper> */}
          {location?.count > 0 ? (
            <>
              <TableContainer borderRadius="12" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <CustomizedTableHead>
                    <CustomizedTableRow>
                      <CustomizedTableCell align="left">
                        {t("AccountTableID")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="left">
                        {t("Rule_Name")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="center">
                        {t("Locations")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="center">
                        {t("Last_updated")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="center">
                        {t("Created_At")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="center">
                        {t("Action")}
                      </CustomizedTableCell>
                    </CustomizedTableRow>
                  </CustomizedTableHead>
                  <TableBody>
                    {location?.results?.map((row) => (
                      <CustomizedTableRow>
                        <CustomizedTableCell sx={{ cursor: "pointer" }}>
                          {row.id}
                        </CustomizedTableCell>
                        <CustomizedTableCell
                          align="left"
                          hover
                          sx={{ cursor: "pointer" }}
                        >
                          {row?.title}
                        </CustomizedTableCell>
                        <CustomizedTableCell align="center">
                          {row?.ruleLocation.length > 0 &&
                          row?.ruleLocation.length === 1
                            ? row?.ruleLocation?.map(
                                (item) =>
                                  item?.location !== null && (
                                    <>
                                      {item?.location?.internalName}
                                      {item?.location?.city &&
                                        `(${item?.location?.city})`}
                                    </>
                                  )
                              )
                            : "Multilocation"}
                        </CustomizedTableCell>
                        <CustomizedTableCell align="center">
                          {companyData && companyData.dateFormat
                            ? handleDisplayDate(row?.updatedAt)
                            : moment(row?.updatedAt).format("MMM DD YYYY")}
                          {/* {handleDisplayDate(row?.updatedAt)} */}
                        </CustomizedTableCell>
                        <CustomizedTableCell align="center">
                          {" "}
                          {companyData && companyData.dateFormat
                            ? handleDisplayDate(row?.createdAt)
                            : moment(row?.createdAt).format("MMM DD YYYY")}
                          {/* {handleDisplayDate(row?.createdAt)} */}
                        </CustomizedTableCell>
                        <CustomizedTableCell align="center">
                          <CustomWidthTooltip title={t("Edit_Rule")}>
                            <IconButton
                              style={{ height: "16px" }}
                              aria-label="edit"
                              // size="large"
                              onClick={() =>
                                navigate(`/admin/addrule/${row?.id}`)
                              }
                            >
                              <Edit />
                            </IconButton>
                          </CustomWidthTooltip>
                          {/* <CustomWidthTooltipOfDelete title="Delete Rule">
                            <IconButton
                              style={{ height: "16px" }}
                              aria-label="delete"
                              // size="large"
                              onClick={(e) => {
                                setOpen(true);

                                setDeleteRule(row?.id);

                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </CustomWidthTooltipOfDelete> */}
                          <IconButton
                            onClick={(e) => handleClick(e, row?.id)}
                            variant="outlined"
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                closeMenu();
                                handleOpenDeleteModel();
                                // handleOpenModal(row?.id);
                              }}
                            >
                              <DeleteIcon
                                sx={{ color: "#545353", paddingRight: "5px" }}
                              />
                              <Typography
                                aria-label="delete"
                                size="large"
                                onClick={(e) => {
                                  setOpen(true);
                                }}
                              >
                                {t("Delete")}
                              </Typography>
                            </MenuItem>
                          </Menu>
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={ruleAllLocation?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <>
              <NotFound text={t("No_Rule_Found")} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Index;
