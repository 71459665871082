import React, { useEffect, useState } from "react";
import data from "./data.json";
import {
  Grid,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  DialogContentText,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import CompanyDetailsModal from "../Components/CompanyDetails/Modal/index";
import CompanyContactmodal from "../Components/CompanyContactDetails/Modal/index";
import CompanyOpeninghours from "../Components/LocationOpeningHours/Modal/index";
import CompanySpeacialhour from "../Components/LocationSpeacialHours/Modal/index";
import CompanyDescription from "../Components/CompanyDescription/Modal/index";
import SocialAccounts from "../Components/SocialAccounts/Modal/index";
import SocialAccountDetails from "../Components/SocialAccountDetails/Modal/index";
import PaymentOptions from "../Components/PaymentOptions/Modal/index";
import CompanyServices from "../Components/LocationServices/Modal/index";
import Additionaldescription from "../Components/AdditionalCategories/Modal/index";
import LocationMap from "../Components/LocationMap/Modal/index";
import Companyattributes from "../Components/CompanyAttributes/Modal/index";
import Categories from "../Components/Categories/Modal/index";
import MoreHours from "../Components/MoreOpeningHours/Modal/index";
import { useStyles } from "../../Styles/style";
import { api } from "../../../../../contexts/JWTContext";
import CompanyLabel from "../Components/CompanyKeywords/Modals/index";
import LocalPages from "../Components/LocalPages/Modals/index";
import CoverImages from "../Components/CoverImages/Modals";
import Images from "../Components/Images/Modals";

const label = { inputProps: { "aria-label": "Switch demo" } };

const EditModal = ({
  onCancel,
  modalIndex,
  locationDetailsData,
  location,
  locationCategory,
  locationFacebookCategory,
  getLocationDetails,
  getLocationDetailsListing,
  allAttributes,
  getLocationById,
  singleLocation,
  setSingleLocationLoading,
  facebookConnect,
  setLocationDetailsData,
  lodgingCategories,
  categoryLoading,
  centerData,
  displayMapError,
  openDefaultModel,
}) => {
  {
    /*************************************** States ***************************************/
  }
  const [tags, setTags] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(modalIndex);
  const [allAttribute, setAllAttributes] = useState([]);
  const [touchedValue, setTouchedValue] = useState(false);
  const [addressTouchedValue, setAddressTouchedValue] = useState(false);
  const [displayConfirmationBox, setDisplayConfirmationBox] = useState(false);
  const [indexValue, setIndexValue] = useState(null);
  const companyData = JSON.parse(localStorage.getItem("company"));

  {
    /*************************************** Use Effects ***************************************/
  }
  useEffect(() => {
    getAllAttributes(location);
    setTags(data.locations[0].categories.primaryCategory.serviceTypes);
  }, []);

  const handleChangeTab = (event, newValue) => {
    //  setSelectedTab(newValue);
    if (touchedValue === false && addressTouchedValue === false) {
      setSelectedTab(newValue);
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      setIndexValue(newValue);
    }
  };

  const getAllAttributes = async (locationData) => {
    try {
      const res = await api.get(
        `/locationListing/getAll-attributes/${locationData?.id}`
      );
      if (res.status === 200) {
        setAllAttributes(res?.data?.data?.attributes);
      }
    } catch (error) {}
  };

  return (
    <>
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/*************************************** Dialog Content Box ***************************************/}
            <DialogContentText
              id="responsive-dialog-title"
              sx={{}}
              className={classes.modalDialogContent}
            >
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={3}
                  md={3}
                  lg={3}
                  sx={{
                    borderRight: 1,
                    borderColor: "divider",
                    backgroundColor: "#F6F6F7",
                    textAlign: "left",
                    height: "670px",
                    overflowY: "scroll",
                  }}
                >
                  {/*************************************** Tabs Section ***************************************/}
                  <Tabs
                    orientation="vertical"
                    value={selectedTab}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    aria-label="Vertical tabs example"
                    classes={{ indicator: classes.indicator }}
                    style={{ alignItems: "left" }}
                    modalIndex={selectedTab}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#8D9298",
                        fontWeight: "700",
                        padding: "10px",
                      }}
                    >
                      {t("Basic_Information")}
                    </Typography>
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Company_Details")}
                    />
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Categories")}
                    />
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Contact_Details")}
                    />

                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Hours_head")}
                    />
                    {/* <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#8D9298",
                        fontWeight: "700",
                        padding: "10px",
                        wordWrap: "break-word",
                        overflow: "hidden",
                        lineBreak: "auto",
                      }}
                    >
                      {t("Additional_Information")}
                    </Typography> */}
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Speacial_Opening_Hours")}
                    />
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("More_Opening_Hours")}
                    />
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Company_Description")}
                    />
                    {/* <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Location_on_Map")}
                    /> */}

                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Social_Accounts")}
                    />
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Attributes")}
                    />

                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("services")}
                    />
                    {/* <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Additional_Categories")}
                    /> */}
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("cover_images_uberAll")}
                    />

                    {/* {companyData !== null &&
                      companyData !== undefined &&
                      companyData?.thirdPartyCoverImages===true && (
                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("cover_images")}
                        />
                      )} */}
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("connect_channel")}
                    />
                    {displayMapError === false && (
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.selectedTab,
                        }}
                        label={t("location_map")}
                      />
                    )}

                    {companyData !== null &&
                      companyData !== undefined &&
                      companyData?.thirdPartyCoverImages === true && (
                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("local_pages")}
                        />
                      )}

                    {/*   <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Map")}
                    /> */}

                    {/* <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Social_Accounts")}
                    />  */}
                    {/* <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Services_&_Brands")}
                    /> */}

                    {/* <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Payment_Options")}
                    /> */}

                    {/* <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("Connect_Channel")}
                    />*/}
                  </Tabs>
                </Grid>
                <Grid item xs={8} sm={9} md={9} lg={9}>
                  {/*************************************** Company Details Tab ***************************************/}
                  {selectedTab === 1 && (
                    <>
                      <CompanyDetailsModal
                        locationDetailsData={locationDetailsData}
                        location={location}
                        onCancel={onCancel}
                        locationCategory={locationCategory}
                        getLocationDetails={getLocationDetails}
                        getLocationDetailsListing={getLocationDetailsListing}
                        touchedValue={touchedValue}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        setLocationDetailsData={setLocationDetailsData}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        addressTouchedValue={addressTouchedValue}
                        setAddressTouchedValue={setAddressTouchedValue}
                      />
                    </>
                  )}
                  {/*************************************** Company Contacts Tab ***************************************/}
                  {selectedTab === 2 && (
                    <Categories
                      locationDetailsData={locationDetailsData}
                      location={location}
                      onCancel={onCancel}
                      locationCategory={locationCategory}
                      locationFacebookCategory={locationFacebookCategory}
                      getLocationDetails={getLocationDetails}
                      facebookConnect={facebookConnect}
                      touchedValue={touchedValue}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      getLocationDetailsListing={getLocationDetailsListing}
                      getLocationById={getLocationById}
                      setIndexValue={setIndexValue}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                      categoryLoading={categoryLoading}
                    />
                  )}
                  {/**************************************** Company Contacts Tab ****************************************/}
                  {selectedTab === 3 && (
                    <CompanyContactmodal
                      locationDetailsData={locationDetailsData}
                      location={location}
                      onCancel={onCancel}
                      locationCategory={locationCategory}
                      getLocationDetails={getLocationDetails}
                      getLocationDetailsListing={getLocationDetailsListing}
                      touchedValue={touchedValue}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      setIndexValue={setIndexValue}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                    />
                  )}
                  {/****************************************  Open Hours Tab ****************************************/}
                  {selectedTab === 4 && (
                    <CompanyOpeninghours
                      locationDetailsData={locationDetailsData}
                      location={location}
                      onCancel={onCancel}
                      locationCategory={locationCategory}
                      getLocationDetails={getLocationDetails}
                      getLocationDetailsListing={getLocationDetailsListing}
                      touchedValue={touchedValue}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      setIndexValue={setIndexValue}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                    />
                  )}
                  {/**************************************** Speacial Hour Tab ****************************************/}
                  {selectedTab === 5 && (
                    <CompanySpeacialhour
                      sx={{ width: "90vh" }}
                      location={location}
                      locationDetailsData={locationDetailsData}
                      onCancel={onCancel}
                      locationCategory={locationCategory}
                      getLocationDetails={getLocationDetails}
                      getLocationDetailsListing={getLocationDetailsListing}
                      touchedValue={touchedValue}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      setIndexValue={setIndexValue}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                    />
                  )}
                  {/*************************************** Location Map Tab ***************************************/}
                  {/*  */}
                  {/**************************************** Company Description Tab ****************************************/}
                  {selectedTab === 6 && (
                    <MoreHours
                      getLocationDetails={getLocationDetails}
                      getLocationDetailsListing={getLocationDetailsListing}
                      location={location}
                      locationDetailsData={locationDetailsData}
                      onCancel={onCancel}
                      locationCategory={locationCategory}
                      touchedValue={touchedValue}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                    />
                  )}
                  {/**************************************** Additional Description Tab ****************************************/}
                  {/* {selectedTab === 11 && (
                    <Additionaldescription
                      location={location}
                      onCancel={onCancel}
                      locationDetailsData={locationDetailsData}
                      locationCategory={locationCategory}
                      getLocationDetails={getLocationDetails}
                      touchedValue={touchedValue}
                      getLocationDetailsListing={getLocationDetailsListing}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      setIndexValue={setIndexValue}
                      categoryLoading={categoryLoading}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                    />
                  )} */}
                  {selectedTab === 9 && (
                    <Companyattributes
                      getLocationDetails={getLocationDetails}
                      getLocationDetailsListing={getLocationDetailsListing}
                      location={location}
                      locationDetailsData={locationDetailsData}
                      onCancel={onCancel}
                      allAttributes={
                        allAttributes !== undefined
                          ? allAttributes
                          : allAttribute
                      }
                      touchedValue={touchedValue}
                      locationCategory={locationCategory}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      lodgingCategories={lodgingCategories}
                      setIndexValue={setIndexValue}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                      openDefaultModel={openDefaultModel}
                    />
                  )}

                  {selectedTab === 7 && (
                    <CompanyDescription
                      locationDetailsData={locationDetailsData}
                      location={location}
                      onCancel={onCancel}
                      getLocationDetails={getLocationDetails}
                      getLocationDetailsListing={getLocationDetailsListing}
                      touchedValue={touchedValue}
                      locationCategory={locationCategory}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      lodgingCategories={lodgingCategories}
                      setIndexValue={setIndexValue}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                    />
                  )}
                  {selectedTab === 10 && (
                    <CompanyLabel
                      getLocationDetails={getLocationDetails}
                      getLocationDetailsListing={getLocationDetailsListing}
                      location={location}
                      locationDetailsData={locationDetailsData}
                      onCancel={onCancel}
                      locationCategory={locationCategory}
                      touchedValue={touchedValue}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      setIndexValue={setIndexValue}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                    />
                  )}
                  {selectedTab === 12 && (
                    <SocialAccounts
                      onCancel={onCancel}
                      data={data}
                      getLocationDetails={getLocationDetails}
                      location={location}
                      locationCategory={locationCategory}
                      setSingleLocationLoading={setSingleLocationLoading}
                      locationDetailsData={locationDetailsData}
                      getLocationById={getLocationById}
                      singleLocation={singleLocation}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                    />
                  )}
                  {selectedTab === 8 && (
                    <SocialAccountDetails
                      locationDetailsData={locationDetailsData}
                      location={location}
                      onCancel={onCancel}
                      locationCategory={locationCategory}
                      getLocationDetails={getLocationDetails}
                      getLocationDetailsListing={getLocationDetailsListing}
                      touchedValue={touchedValue}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      setIndexValue={setIndexValue}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                    />
                  )}
                  {/**************************************** Images Tab ****************************************/}
                  {selectedTab === 11 && (
                    <Images
                      locationDetailsData={locationDetailsData}
                      location={location}
                      onCancel={onCancel}
                      locationCategory={locationCategory}
                      getLocationDetails={getLocationDetails}
                      getLocationDetailsListing={getLocationDetailsListing}
                      touchedValue={touchedValue}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                    />
                  )}

                  {selectedTab === 13 && displayMapError === false && (
                    <LocationMap
                      locationDetailsData={locationDetailsData}
                      getLocationDetails={getLocationDetails}
                      location={location}
                      onCancel={onCancel}
                      centerData={centerData}
                      displayMapError={displayMapError}
                      addressTouchedValue={addressTouchedValue}
                      setAddressTouchedValue={setAddressTouchedValue}
                      touchedValue={touchedValue}
                      setTouchedValue={setTouchedValue}
                      setDisplayConfirmationBox={setDisplayConfirmationBox}
                      displayConfirmationBox={displayConfirmationBox}
                      setLocationDetailsData={setLocationDetailsData}
                      indexValue={indexValue}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      setIndexValue={setIndexValue}
                    />
                  )}

                  {/**************************************** Local Pages Tab ****************************************/}

                  {companyData !== null &&
                    companyData !== undefined &&
                    companyData?.thirdPartyCoverImages === true &&
                    ((displayMapError === false && selectedTab === 14) ||
                      (displayMapError === true && selectedTab === 13)) && (
                      <LocalPages
                        locationDetailsData={locationDetailsData}
                        location={location}
                        onCancel={onCancel}
                        locationCategory={locationCategory}
                        getLocationDetails={getLocationDetails}
                        getLocationDetailsListing={getLocationDetailsListing}
                        touchedValue={touchedValue}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        setLocationDetailsData={setLocationDetailsData}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        addressTouchedValue={addressTouchedValue}
                        setAddressTouchedValue={setAddressTouchedValue}
                      />
                    )}

                  {/**************************************** Cover Images Tab ****************************************/}
                  {/* {selectedTab === 13 &&
                    companyData !== null &&
                    companyData !== undefined &&
                    companyData?.thirdPartyCoverImages===true && (
                      <CoverImages
                        locationDetailsData={locationDetailsData}
                        location={location}
                        onCancel={onCancel}
                        locationCategory={locationCategory}
                        getLocationDetails={getLocationDetails}
                        getLocationDetailsListing={getLocationDetailsListing}
                        touchedValue={touchedValue}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        setLocationDetailsData={setLocationDetailsData}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                      />
                    )} */}

                  {/**************************************** Company Services Tab ****************************************/}
                  {/* {selectedTab === 9 && (
                    <CompanyServices data={data} onCancel={onCancel} />
                  )}

                  {/*************************************** Payment Option Tab ***************************************/}
                  {/* {selectedTab === 11 && (
                    <PaymentOptions onCancel={onCancel} data={data} />
                  )} */}
                  {/********************************* Social Accounts Tab ***************************************/}
                  {/* {selectedTab === 8 && (
                    <SocialAccounts onCancel={onCancel} data={data} />
                  )} */}
                  {/* {selectedTab === 13 && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ padding: "18px", width: "550px" }}
                    >
                      <Grid container>
                        <Grid item xs={10} sm={11} md={11.5} lg={11.5}>
                          <Box>
                            <Typography className={classes.modalHeader}>
                              {t("Connect Channels")}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} sm={1} md={0.5} lg={0.5}>
                          <IconButton
                            autoFocus
                            onClick={onCancel}
                            className="delete_button"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )} */}
                </Grid>
              </Grid>
            </DialogContentText>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default EditModal;
