import React, { useState, useEffect } from "react";
import EditModal from "../../Modal";

import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Stack,
  Dialog,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import NotFound from "../../../../../../components/NotFound/NotFound";
import { LazyLoadImage } from "react-lazy-load-image-component";

import noImageGray from "../../../../../../assets/images/noImageGray.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
  titleName: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#1B2430",
  },
  headName: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
  },
  headNameDesc: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#06BDFF",
    display: "column",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  tagsText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  tagsItem: {
    background: "#EBEBEC",
    minWidth: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "700px",
    flexWrap: "nowrap",
  },
}));

function LocationImages({
  locationDetailsData,
  location,
  getLocationDetails,
  singleLocation,
  googleConnect,
  getLocationById,
  locationCategory,
  setSingleLocationLoading,
  getLocationDetailsListing,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [loadImg, setLoadImg] = useState(false);

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };

  return (
    <>
      {locationDetailsData !== null ? (
        <>
          {openDefaultModel && (
            <Dialog
              maxWidth
              maxHeight
              open={openDefaultModel}
              // onClose={handleCloseTemplateMenu}
            >
              <EditModal
                modalIndex={13}
                location={location}
                locationDetailsData={locationDetailsData}
                onCancel={handleCloseTemplateMenu}
                getLocationDetails={getLocationDetails}
                setSingleLocationLoading={setSingleLocationLoading}
                getLocationById={getLocationById}
                singleLocation={singleLocation}
                locationCategory={locationCategory}
                getLocationDetailsListing={getLocationDetailsListing}
              />
            </Dialog>
          )}
          <Card
            variant="outlined"
            sx={{
              bgcolor: "#ffff",
              borderRadius: "8px",
              height: "auto",
              padding: "10px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={2} sx={{ padding: "16px" }}>
                  <Grid item xs={8} sm={8} md={9} lg={10}>
                    <Typography className={classes.titleName}>
                      {t("cover_images")}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={2}>
                    <Button fullWidth>
                      <Typography
                        onClick={handleOpenTemplateMenu}
                        sx={{ fontWeight: 600 }}
                      >
                        {t("Edit")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ padding: "6px" }}>
                  {locationDetailsData?.result.coverImages?.map(
                    (item, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        style={{
                          // maxHeight: 660,
                          // height: "412px",
                          width: "80px",
                          height: "80px",
                        }}
                      >
                        <LazyLoadImage
                          loading="lazy"
                          src={loadImg ? noImageGray : item}
                          beforeLoad={handleBeforeLOad}
                          afterLoad={handleAfterLOad}
                          style={{
                            // maxHeight: 660,
                            // height: "412px",
                            width: "80px",
                            height: "80px",
                            borderRadius: "8px",
                            padding: "3px",
                          }}
                          PlaceholderSrc={noImageGray}
                          alt="Image Alt"
                          effect="blur"
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </>
      ) : (
        <NotFound text={t("No_Data_Found")} />
      )}
    </>
  );
}
export default LocationImages;
