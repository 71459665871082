import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Checkbox, Paper, Dialog } from "@mui/material";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Delete from "../../../../../components/Models/DeleteModal/Delete";
import { api } from "../../../../../contexts/JWTContext";
import { useStyles } from "../../../../UserPages/UserAccounts/styles/style";
import { useParams } from "react-router-dom";
import Loader from "../../../../../components/Loaders/Loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ProductActivationFeatures = ({ companyName }) => {
  const params = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectAllFeatures, setSelectAllFeatures] = useState(false);
  const [permissionsArr, setPermissionsArr] = useState([]);
  const [companyFeatures, setCompanyFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openSubscribeModel, setOpenSubscribeModel] = useState(false);
  const [permissionsLoading, setPermissionsLoading] = useState(false);

  useEffect(() => {
    getAllEnabledFeatures();
  }, []);

  const handleSelectPermission = (featureData) => {
    let tempArr = [...permissionsArr];
    const findFeature = tempArr?.find(
      (item) => item?.name === featureData?.name
    );

    if (findFeature === undefined) {
      tempArr?.push(featureData);
    } else {
      tempArr = tempArr?.filter((item) => item?.name !== findFeature?.name);
    }

    setPermissionsArr(tempArr);
    if (tempArr?.length === companyFeatures?.length) {
      setSelectAllFeatures(true);
    } else {
      setSelectAllFeatures(false);
    }
  };

  const handleChangeSelectAllFeatures = (e) => {
    setSelectAllFeatures(e.target.checked);
    if (e.target.checked) {
      setPermissionsArr(companyFeatures);
    } else {
      setPermissionsArr([]);
    }
  };

  const getAllEnabledFeatures = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`companyFeatures/${params?.id}/features`);
      if (res.status === 200) {
        setCompanyFeatures(res?.data?.data);
        const filtered = res?.data?.data?.filter(
          (item) =>
            item?.CompanyFeatures?.length > 0 &&
            item?.CompanyFeatures[0]?.subscribed
        );
        setPermissionsArr(filtered);
        if (filtered?.length === res?.data?.data?.length) {
          setSelectAllFeatures(true);
        } else {
          setSelectAllFeatures(false);
        }
        console.log(res, "ressss");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleOpenSubscribeModel = () => {
    setOpenSubscribeModel(true);
  };
  const handleCloseSubscribeModel = () => {
    setOpenSubscribeModel(false);
  };

  const handleSubmitPermissionChanges = async () => {
    let appIds = [];
    if (permissionsArr?.length > 0) {
      permissionsArr?.map((item) => {
        appIds.push(item?.id);
      });
    }
    try {
      setPermissionsLoading(true);
      const res = await api.put(`companyFeatures/${params?.id}/enable`, {
        appId: appIds,
      });

      if (res.status === 200) {
        toast.success(t("toast_subscriptions"));
        handleCloseSubscribeModel();
        setPermissionsLoading(false);
        getAllEnabledFeatures();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setPermissionsLoading(false);
      handleCloseSubscribeModel();
    }
  };
  console.log(permissionsArr, "permissionsArr");

  const handleReset = () => {
    // setPermissionsArr([]);
    getAllEnabledFeatures();
  };

  return (
    <>
      {openSubscribeModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openSubscribeModel}
          onClose={handleCloseSubscribeModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("update_subsciption")}
            description={`${t("update_subsciptiondes")} ${companyName}?`}
            onConfirm={handleSubmitPermissionChanges}
            onCancel={handleCloseSubscribeModel}
            schedule={true}
            loading={permissionsLoading}
          />
        </Dialog>
      )}

      <Paper sx={{ padding: "40px", marginTop: "12px" }}>
        {isLoading ? (
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
            }}
          >
            <Loader />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box className={classes.addUserBox}>
                <Typography className={classes.addUserFieldLable}>
                  {t("features_label")}
                </Typography>

                <Box>
                  <Checkbox
                    name="selectAll"
                    color="primary"
                    checked={selectAllFeatures}
                    onChange={(e) => handleChangeSelectAllFeatures(e)}
                  />
                  {t("Select_All")}
                </Box>
              </Box>
            </Grid>

            <Grid container className={classes.featureGrid}>
              {companyFeatures?.length > 0 &&
                companyFeatures?.map((feature, ind) => {
                  return (
                    <Grid
                      xs={12}
                      sm={12}
                      lg={12}
                      md={12}
                      className={classes.permissionGrid}
                      onClick={() => handleSelectPermission(feature)}
                    >
                      <Box className={classes.permissionBoxStyle}>
                        <Box className={classes.permissionNameBox}>
                          <Typography className={classes.permissionName}>
                            {feature?.name}
                          </Typography>
                          {feature?.CompanyFeatures?.length > 0 &&
                            feature?.CompanyFeatures[0]?.subscribedRequested ==
                              true && (
                              <Item className={classes.subscribeItemStyle}>
                                <Typography
                                  className={classes.subscribeItemTextStyle}
                                >
                                  {t("Subscription_request_received")}
                                </Typography>
                              </Item>
                            )}
                          {/* {feature?.CompanyFeatures?.length > 0 && (
                            <CheckCircleIcon
                              className={classes.activatedPermission}
                            />
                          )} */}
                        </Box>

                        <Checkbox
                          checked={
                            permissionsArr?.length > 0 &&
                            permissionsArr?.find(
                              (item) => item?.name === feature?.name
                            ) !== undefined
                          }
                        />
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </Paper>
      <Grid container className={classes.buttonContainer}>
        <CommonButton
          displayWhite="true"
          onSubmit={handleReset}
          label={t("Reset")}
        />
        <CommonButton
          label={t("Update")}
          onSubmit={handleOpenSubscribeModel}
          disabled={permissionsArr?.length === 0}
        />
      </Grid>
    </>
  );
};

export default ProductActivationFeatures;
