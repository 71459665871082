import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
} from "@mui/material";
import FetchModels from "./FetchModels.css";

import { useTranslation } from "react-i18next";

const FetchModel = ({ title, description }) => {
  const [open, setOpen] = useState(false);
  const [deletePost, setDeletePost] = useState(null);
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Box className="box_delete">
          <DialogContentText
            id="responsive-dialog-title"
            sx={{}}
            className="delete_dialog_content1"
          >
            {title}
          </DialogContentText>
          <DialogContentText
            id="responsive-dialog-title"
            className="delete_dialog_content2"
          >
            {description}
          </DialogContentText>
        </Box>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default FetchModel;
