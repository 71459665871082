import React, { useState, useRef, useEffect } from "react";

import {
  Grid,
  Typography,
  TextField,
  Box,
  Autocomplete,
  Checkbox,
  Button,
  createFilterOptions,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import EditUserFeature from "../EditUserFeature.js";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton.js";

import { useStyles } from "../../../../styles/style.js";
import { api } from "../../../../../../../contexts/JWTContext.js";
import Loader from "../../../../../../../components/Loaders/Loader.js";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Collapsible from "../../../../../../../components/CustomComponents/Collapsible.js";
import Stack from "@mui/material/Stack";
import { WarningAmberOutlined } from "@mui/icons-material";
import Chip from "@mui/material/Chip";

function AddNewUser({
  handleClose,
  selectedUsers,
  getAllUsers,
  setDeleteUsers,
  setSelectedToUpdate,
  open,
}) {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { page } = location.state || {};
  const classes = useStyles();
  const [manageLocation, setManageLocation] = useState(null);
  const [manageRemoveLocation, setManageRemoveLocation] = useState(null);

  const [allLocations, setAllLocations] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [duplicateRole, setDuplicateRole] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [selectedRoleError, setSelectedRoleError] = useState(false);
  const [locationError, setLocationError] = useState(false);

  const [removeLocationError, setRemoveLocationError] = useState(false);
  const [locationValue, setLocationValue] = useState([]);
  const [removeLocationValue, setRemoveLocationValue] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [roleNameError, setRoleNameError] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllForRemoveLocations, setSelectAllForRemoveLocations] =
    useState(false);
  const [showRoleName, setShowRoleName] = useState(false);
  const [sameNameError, setSameNameError] = useState(false);
  const [permissionsArr, setPermissionsArr] = useState([]);
  const [singleRole, setSingleRole] = useState({
    permissions: [],
  });
  const [allRoles, setAllRoles] = useState(null);
  const [companyFeatures, setAllCompanyFeatures] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const filter = createFilterOptions();
  const [disableButton, setDisableButton] = useState(false);
  const [allGroups, setAllGroups] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedRemoveGroups, setSelectedRemoveGroups] = useState([]);
  const [selectAllGroups, setSelectAllGroups] = useState(false);
  const [selectAllRemoveGroups, setSelectAllRemoveGroups] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [oldGroups, setOldGroups] = useState([]);
  const [oldRemoveGroups, setOldRemoveGroups] = useState([]);
  const [duplicateItems, setDuplicateItems] = useState([]);
  const [duplicateItemsToRemove, setDuplicateItemsToRemove] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState([
    "add_locations",
    "remove_locations",
  ]);
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false,
  });
  const [bulkLoading, setBulkLoading] = useState(false);

  const manangeLocationsArray = [
    { label: "All Locations" },
    { label: "Specific Locations" },
    { label: "Groups" },
  ];
  /*  useEffect(() => {
    const permissionFound = permissionsArr?.filter(
      (item) => item?.permissions?.length > 0
    );
    if (permissionFound?.length <= 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [permissionsArr]); */
  useEffect(() => {
    if (locationValue?.length > 0 && removeLocationValue?.length > 0) {
      function getDuplicateItems(array1, array2) {
        // Extract the IDs from the second array for quick lookup
        const ids2 = array2.map((item) => item.id);

        // Find items in the first array that have matching IDs in the second array
        const duplicates = array1.filter((item) => ids2.includes(item.id));

        return duplicates;
      }

      const duplicateItemss = getDuplicateItems(
        locationValue,
        removeLocationValue
      );
      setDuplicateItems(duplicateItemss);
    } else {
      setDuplicateItems([]);
    }
  }, [locationValue, removeLocationValue]);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      setLocationLoading(true);
      await Promise.all([
        getAllLocations(),
        getAllCompanyFeatures(),
        getAllRoles(),
        getAllLocationGroups(),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLocationLoading(false);
    }
  };

  useEffect(() => {
    if (selectedRole !== null) {
      getSingleRole();
    }
  }, [selectedRole]);

  const getAllLocationGroups = async () => {
    // setIsLoading(true);
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.get(`groups?pageNumber=1&limit=1000`);
      if (res.status === 200) {
        setAllGroups(res?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      //  setIsLoading(false);
      setAllGroups(null);
    }
  };

  const getAllLocations = async () => {
    try {
      // setLocationLoading(true);
      const res = await api.get(`/location/search`, {});
      if (res.status === 200) {
        //  setLocationLoading(false);
        if (res?.data?.data?.results?.length > 0) {
          setAllLocations(res?.data?.data?.results);
        } else {
          setAllLocations(null);
        }
      }
    } catch (error) {
      //   setAllLocations(null);
      toast.error(error?.response?.data?.message);
      setLocationLoading(false);
    }
  };

  const getAllRoles = async () => {
    // setLocationLoading(true);
    try {
      const res = await api.get(
        `companyRoles?companyId=${companyData?.id}&pageNumber=1&limit=1000`
      );
      if (res.status === 200) {
        setAllRoles(res?.data?.data);
        //    setLocationLoading(false);
      }
    } catch (error) {
      //   setLocationLoading(false);
      setAllRoles(null);
    }
  };

  useEffect(() => {
    handleChangeGroups(selectedGroups);
    setLocationError(false);
  }, []);

  const getAllCompanyFeatures = async () => {
    try {
      //  setLocationLoading(true);
      const res = await api.get(`/companyFeatures/${companyData?.id}/company`);
      if (res.status === 200) {
        setAllCompanyFeatures(res?.data?.data);
        //  setLocationLoading(false);
      }
    } catch (error) {
      setAllCompanyFeatures([]);
      //  setLocationLoading(false);
    }
  };

  function filterArrayById(inputArray, targetId) {
    return inputArray.filter((item) =>
      item.groups.some((group) => group.locationId === targetId)
    );
  }
  const handleChangeGroups = (value) => {
    setSelectedGroups(value);

    let newLocations = [...locationValue];
    const results = oldGroups.filter(
      ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((group) => {
        group?.groups?.map((groupItem) => {
          allLocations?.map((location) => {
            if (groupItem?.locationId === location?.id) {
              newLocations?.push(location);
            } else {
              setSelectAll(false);
              results?.map((group) => {
                group?.groups?.map((groupItem) => {
                  locationValue?.map((location) => {
                    const filteredArray = filterArrayById(value, location?.id);
                    if (
                      groupItem?.locationId === location?.id &&
                      filteredArray?.length === 0
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== location?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((group) => {
        group?.groups?.map((groupItem) => {
          locationValue?.map((location) => {
            if (groupItem?.locationId === location?.id) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== location?.id
              );
            }
          });
        });
      });
    }
    const uniqueData = removeDuplicates(newLocations);
    setLocationValue(uniqueData);

    if (newLocations?.length > 0) {
      setLocationError(false);
    } else {
      setLocationError(true);
    }

    setOldGroups(value);
  };
  const handleChangeRemoveGroups = (value) => {
    setSelectedRemoveGroups(value);

    let newLocations = [...removeLocationValue];
    const results = oldRemoveGroups.filter(
      ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((group) => {
        group?.groups?.map((groupItem) => {
          allLocations?.map((location) => {
            if (groupItem?.locationId === location?.id) {
              newLocations?.push(location);
            } else {
              setSelectAll(false);
              results?.map((group) => {
                group?.groups?.map((groupItem) => {
                  removeLocationValue?.map((location) => {
                    const filteredArray = filterArrayById(value, location?.id);
                    if (
                      groupItem?.locationId === location?.id &&
                      filteredArray?.length === 0
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== location?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((group) => {
        group?.groups?.map((groupItem) => {
          removeLocationValue?.map((location) => {
            if (groupItem?.locationId === location?.id) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== location?.id
              );
            }
          });
        });
      });
    }
    const uniqueData = removeDuplicates(newLocations);
    setRemoveLocationValue(uniqueData);

    if (newLocations?.length > 0) {
      setRemoveLocationError(false);
    } else {
      setRemoveLocationError(true);
    }

    setOldRemoveGroups(value);
  };
  function checkGroupsExistence(groups, arr) {
    return groups.every((group) =>
      arr.some((elem) => elem.id === group.locationId)
    );
  }

  const handleChangeGroupsForLocations = (locations) => {
    if (locations?.length > 0 || selectedGroups?.length > 0) {
      const filteredArray2 = selectedGroups?.filter((item) =>
        item.groups ? checkGroupsExistence(item.groups, locations) : true
      );

      setSelectAllGroups(false);
      setSelectedGroups(filteredArray2 || []); // Make sure filteredArray2 has a value or assign an empty array if it's undefined/null
      //  setLocationError(false);
    } else {
      setSelectAllGroups(false);
      setSelectedGroups([]);
    }
  };
  const handleChangeGroupsForRemoveLocations = (locations) => {
    if (locations?.length > 0 || selectedGroups?.length > 0) {
      const filteredArray2 = selectedGroups?.filter((item) =>
        item.groups ? checkGroupsExistence(item.groups, locations) : true
      );

      setSelectAllRemoveGroups(false);
      setSelectedRemoveGroups(filteredArray2 || []); // Make sure filteredArray2 has a value or assign an empty array if it's undefined/null
      //  setLocationError(false);
    } else {
      setSelectAllRemoveGroups(false);
      setSelectedRemoveGroups([]);
    }
  };
  function removeDuplicates(arr) {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }

  const getSingleRole = async () => {
    try {
      setLoading(true);
      const res = await api.get(
        `/companyRoles/${selectedRole !== null ? selectedRole?.id : id}`
      );
      if (res.status === 200) {
        setLoading(false);

        setSingleRole({
          permissions: res?.data?.data?.CompanyRolesPermissions,
        });
      }
    } catch (error) {}
  };

  const handleCreateNewRole = () => {
    setShowFeatures(true);
    setSelectedRole(null);
    setCheckboxes({
      view: false,
      add: false,
      edit: false,
      delete: false,
    });
    setSelectAllChecked(false);
    setDuplicateRole(false);
    setShowRoleName(true);
  };

  const handleChangeSelectedRole = (e, value) => {
    if (!value) {
      setSelectedRoleError(true);
      setDisableField(false);
    } else {
      if (value?.name === "company-admin") {
        setManageLocation({
          label: "All Locations",
        });
        let locationIds = [];
        if (allLocations?.length > 0) {
          allLocations?.map((item) => {
            locationIds?.push(item);
          });
        }
        setDisableField(true);
        setLocationValue(locationIds);
        setLocationError(false);
        setSelectedGroups([]);
        setSelectAllGroups(false);
      } else {
        setDisableField(false);
      }
      setSelectedRoleError(false);
      setShowFeatures(true);
      setDuplicateRole(false);
      setShowRoleName(true);
    }
    setShowRoleName(false);
    setSelectedRole(value);
  };

  const onCancelEdit = () => {
    handleClose();
  };

  const handleChangeRoleName = (e) => {
    const value = e.target.value;
    const isSameName = allRoles?.rows?.find((item) => {
      return item.label === value;
    });
    setRoleName(value);
    if (isSameName) {
      setSameNameError(true);
    } else {
      setSameNameError(false);
    }
    if (value === "") {
      setRoleNameError(true);
    } else {
      setSelectedRoleError(false);
      setRoleNameError(false);
    }
  };

  const handleUpdateUser = async () => {
    let locationIds = [];
    if (manageLocation?.label === "All Locations") {
      if (allLocations?.length > 0) {
        allLocations?.map((item) => {
          locationIds?.push(item?.id);
        });
      }
    } else {
      if (locationValue?.length > 0) {
        locationValue?.map((item) => {
          locationIds?.push(item?.id);
        });
      }
    }
    let removeLocationIds = [];
    if (removeLocationValue?.length > 0) {
      removeLocationValue?.map((item) => {
        removeLocationIds?.push(item?.id);
      });
    }

    const allPermissions = permissionsArr?.reduce((accumulator, obj) => {
      return accumulator.concat(obj.permissions);
    }, []);

    setBulkLoading(true);
    let groupIds = [];
    if (manageLocation?.label === "Groups") {
      if (selectedGroups?.length > 0) {
        selectedGroups?.map((item) => {
          groupIds.push(item?.id);
        });
      }
    } else {
      setSelectedGroups([]);
    }
    let removeGroupIds = [];
    if (manageRemoveLocation?.label === "Groups") {
      if (selectedRemoveGroups?.length > 0) {
        selectedRemoveGroups?.map((item) => {
          removeGroupIds.push(item?.id);
        });
      }
    } else {
      setSelectedRemoveGroups([]);
    }

    let UserIds = [];

    if (selectedUsers?.length > 0) {
      selectedUsers?.map((item) => {
        UserIds?.push(item?.User?.id);
      });
    }
    const requestBody = {
      userIds: UserIds,
      companyId: companyData?.id,
      selectAllLocations: manageLocation?.label === "All Locations",
      allLocationAccess: manageLocation?.label === "All Locations",
      locationIds: locationIds,
      groupIds: groupIds,
      removeLocationIds: removeLocationIds,
      removeGroupIds: removeGroupIds,
      newRole: showRoleName ? true : false,
    };

    // Conditionally add properties only if they have valid data
    if (selectedRole !== null && !showRoleName) {
      requestBody.companyRoleId = selectedRole?.id;
    }

    if (showRoleName) {
      requestBody.name = roleName?.replaceAll(" ", "-").toLowerCase();
    } else if (selectedRole !== null) {
      requestBody.name = selectedRole?.name;
    }

    if (allPermissions?.length > 0) {
      requestBody.permissions = allPermissions;
    }
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.put(`companyUserRoles/bulkUsers`, requestBody);
      if (res.status === 200) {
        setBulkLoading(false);
        toast.success(res?.data?.message);
        handleCloseConfirmModel();
        handleClose();
        setSelectedToUpdate([]);
        setDeleteUsers([]);
        getAllUsers();
      }
    } catch (error) {
      setBulkLoading(false);
      toast.error(error?.response?.data?.message);
      handleClose();
      handleCloseConfirmModel();
    }
  };
  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  const handleOpenConfirmModel = () => {
    setOpenConfirmModel(true);
  };
  const handleCloseConfirmModel = () => {
    setOpenConfirmModel(false);
  };

  return (
    <>
      {openConfirmModel && (
        <Dialog
          open={openConfirmModel}
          onClose={handleCloseConfirmModel}
          sx={{
            "& .MuiDialog-paper": {
              width: "550px",
              height: "320px",
            },
          }}
        >
          <DialogTitle>
            <Box className={classes.dialogBoxBulk}>
              <IconButton
                autoFocus
                onClick={handleCloseConfirmModel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogBoxContentBulk}>
            <WarningAmberOutlined className={classes.iconClass} />
            <Typography className={classes.makePrimaryUserTextHeadBulk}>
              {t("Bulk_data_update")}
            </Typography>
            <Typography className={classes.makePrimaryUserTexBulk}>
              {t("Bulk_data_update_des")} {selectedUsers?.length}{" "}
              {selectedUsers?.length > 1
                ? t("Bulk_data_multi_user")
                : t("Bulk_data_single_user")}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: "20px" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CommonButton
                  displayWhite="true"
                  onSubmit={handleCloseConfirmModel}
                  label={t("Cancel")}
                />

                <CommonButton
                  onSubmit={handleUpdateUser}
                  label={t("Yes")}
                  isLoading={bulkLoading}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}

      <Dialog maxWidth maxHeight open={open}>
        <DialogTitle sx={{ padding: "8px 12px" }}>
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
              {t("Bulk_Edit")}
            </Typography>
            <IconButton
              autoFocus
              onClick={handleClose}
              className="delete_button"
            >
              <CloseIcon sx={{ paddingTop: "0px", cursor: "pointer" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container sx={{ width: "800px" }}>
            {locationLoading ? (
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                }}
              >
                <Loader />
              </Grid>
            ) : (
              <>
                {allLocations?.length > 0 && (
                  <>
                    <Grid container>
                      <Box
                        sx={{
                          //  maxHeight: "500px",
                          height: "440px",
                          overflow: "auto",
                          width: "100%",
                        }}
                      >
                        {" "}
                        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                          <Collapsible
                            title={t("Add_Location_Access")}
                            handleChange={handleExpandPanel}
                            expanded={expandedPanels.includes("add_locations")}
                            id={"add_locations"}
                            ComponentToRender={
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  mt={2}
                                >
                                  <Typography
                                    className={classes.emailReportSubLabel}
                                    mb={2}
                                  >
                                    {t("location_access")}
                                  </Typography>

                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    getOptionLabel={(option) => option?.label}
                                    options={manangeLocationsArray}
                                    value={manageLocation}
                                    fullWidth
                                    disabled={disableField}
                                    onChange={(e, value) => {
                                      setManageLocation(value);
                                      if (value?.label === "All Locations") {
                                        let locationIds = [];
                                        if (allLocations?.length > 0) {
                                          allLocations?.map((item) => {
                                            locationIds?.push(item);
                                          });
                                        }
                                        setLocationValue(locationIds);
                                        setLocationError(false);
                                        setSelectedGroups([]);
                                        setSelectAllGroups(false);
                                      } else {
                                        setLocationValue([]);
                                        setLocationError(false);
                                        setSelectedGroups([]);
                                        setSelectAllGroups(false);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={t("Locations")}
                                        disabled={disableField}
                                      />
                                    )}
                                  />
                                </Grid>

                                {manageLocation?.label === "Groups" && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    mt={2}
                                  >
                                    <Typography
                                      className={classes.emailReportSubLabel}
                                      mb={2}
                                    >
                                      {t("group_access")}
                                    </Typography>

                                    <Autocomplete
                                      multiple
                                      disablePortal
                                      disableCloseOnSelect
                                      id="combo-box-demo"
                                      options={
                                        allGroups !== null &&
                                        allGroups?.rows?.length > 0
                                          ? allGroups?.rows
                                          : []
                                      }
                                      getOptionLabel={(option) =>
                                        option?.name ? option?.name : ""
                                      }
                                      className="Autocomplete-field"
                                      value={
                                        selectedGroups ? selectedGroups : []
                                      }
                                      onChange={(e, value) => {
                                        if (
                                          value.find(
                                            (option) => option.id === 0
                                          )
                                        ) {
                                          if (
                                            selectedGroups?.length ==
                                            allGroups?.rows?.length
                                          ) {
                                            setSelectedGroups([]);
                                            setSelectAllGroups(false);
                                          } else {
                                            setSelectAllGroups(true);
                                            handleChangeGroups(allGroups?.rows);
                                          }
                                        } else {
                                          handleChangeGroups(value);
                                          if (
                                            value?.length ===
                                            allGroups?.rows?.length
                                          ) {
                                            setSelectAllGroups(true);
                                          } else {
                                            setSelectAllGroups(false);
                                          }
                                        }
                                      }}
                                      filterOptions={(options, params) => {
                                        const filtered = filter(
                                          options,
                                          params
                                        );
                                        if (allGroups?.rows?.length > 0) {
                                          return [
                                            { id: 0, name: "Select All" },
                                            ...filtered,
                                          ];
                                        } else {
                                          return [];
                                        }
                                      }}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={
                                              selectAllGroups || selected
                                            }
                                            id="select-all-checkbox"
                                          />

                                          <Typography>
                                            {option?.name}
                                          </Typography>
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          placeholder={t("Select_group")}
                                          {...params}
                                          required
                                          name="language"
                                        />
                                      )}
                                    ></Autocomplete>
                                  </Grid>
                                )}

                                {(manageLocation?.label ===
                                  "Specific Locations" ||
                                  manageLocation?.label === "Groups") && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    mt={2}
                                  >
                                    <Typography
                                      className={classes.emailReportSubLabel}
                                      mb={2}
                                    >
                                      {t("Select_Locations")}
                                    </Typography>

                                    {allLocations?.length > 0 && (
                                      <Autocomplete
                                        multiple
                                        error={locationError}
                                        disablePortal
                                        id="combo-box-demo"
                                        disableCloseOnSelect
                                        options={
                                          allLocations?.length > 0
                                            ? allLocations
                                            : []
                                        }
                                        value={locationValue}
                                        onChange={(event, value) => {
                                          // Check if 'All' option is clicked
                                          if (value?.length > 0) {
                                            setLocationError(false);
                                          } else {
                                            setLocationError(true);
                                          }

                                          if (
                                            value.find(
                                              (option) => option.id === 0
                                            )
                                          ) {
                                            // Check if all options are selected
                                            if (
                                              allLocations?.length ===
                                              locationValue?.length
                                            ) {
                                              setLocationValue([]);
                                              setSelectAll(false);
                                            } else {
                                              setSelectAll(true);
                                              setLocationValue(allLocations);
                                              handleChangeGroupsForLocations(
                                                allLocations
                                              );
                                              //  setLocationRequired(false);
                                            }
                                          } else {
                                            setLocationValue(value);
                                            handleChangeGroupsForLocations(
                                              value
                                            );
                                            if (
                                              value?.length ===
                                              allLocations?.length
                                            ) {
                                              setSelectAll(true);
                                            } else {
                                              setSelectAll(false);
                                            }
                                          }
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                          option.id === value.id
                                        }
                                        filterOptions={(options, params) => {
                                          const filtered = filter(
                                            options,
                                            params
                                          );
                                          if (allLocations?.length > 0) {
                                            return [
                                              {
                                                id: 0,
                                                internalName: "Select All",
                                              },
                                              ...filtered,
                                            ];
                                          } else {
                                            return [];
                                          }
                                        }}
                                        renderTags={(value, getTagProps) => {
                                          return (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexWrap: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width: "100%",
                                              }}
                                            >
                                              <Stack
                                                // direction="row"
                                                spacing={1}
                                                sx={{
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  flexWrap: "nowrap",
                                                  display: "inline",
                                                }}
                                              >
                                                {value.map((option, index) => (
                                                  <Chip
                                                    style={{
                                                      background: "#FFF",
                                                      border:
                                                        duplicateItems?.find(
                                                          (item) =>
                                                            item?.id ===
                                                            option?.id
                                                        ) === undefined
                                                          ? "1px solid #06BDFF"
                                                          : "1px solid #FFA2A2",
                                                      borderRadius: "800px",
                                                    }}
                                                    key={index}
                                                    label={option?.internalName}
                                                    {...getTagProps({ index })}
                                                  />
                                                ))}
                                              </Stack>
                                            </Box>
                                          );
                                        }}
                                        defaultValue={locationValue}
                                        getOptionLabel={(option) =>
                                          option?.internalName
                                            ? option?.internalName +
                                              " " +
                                              "(" +
                                              (option?.addressLine1 !== "" &&
                                              option?.addressLine1 !== null &&
                                              option?.addressLine1 !== undefined
                                                ? option?.addressLine1 +
                                                  "," +
                                                  " "
                                                : "") +
                                              (option?.city
                                                ? option?.city
                                                : "") +
                                              ")"
                                            : ""
                                        }
                                        sx={{ minWidth: 300 }}
                                        // value={locationId ? locationId : []}
                                        // onChange={(e, value) => {
                                        //   setLocationRequired(false);

                                        //   setSelectedLocation(value);
                                        // }}
                                        renderOption={(
                                          props,
                                          option,
                                          { selected }
                                        ) => (
                                          <li {...props}>
                                            <Checkbox
                                              icon={icon}
                                              checkedIcon={checkedIcon}
                                              style={{ marginRight: 8 }}
                                              id="select-all-checkbox"
                                              checked={selectAll || selected}
                                              // checked={selected}
                                            />
                                            {`${option?.internalName} ${
                                              option?.addressLine1 !== "" &&
                                              option?.addressLine1 !== null &&
                                              option?.addressLine1 !== undefined
                                                ? "(" +
                                                  option?.addressLine1 +
                                                  "," +
                                                  " "
                                                : ""
                                            } ${
                                              option?.city
                                                ? option?.city + ")"
                                                : ""
                                            }`}
                                          </li>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            name="language"
                                            {...params}
                                            error={locationError}
                                            placeholder={t("Select_Location")}
                                            //  defaultValue={{ selectedLocation }}
                                            helperText={
                                              locationError
                                                ? t("location_req")
                                                : ""
                                            }
                                          />
                                        )}
                                      ></Autocomplete>
                                    )}
                                  </Grid>
                                )}
                              </Grid>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                          <Collapsible
                            title={t("Remove_Locations")}
                            handleChange={handleExpandPanel}
                            expanded={expandedPanels.includes(
                              "remove_locations"
                            )}
                            id={"remove_locations"}
                            ComponentToRender={
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  mt={2}
                                >
                                  <Typography
                                    className={classes.emailReportSubLabel}
                                    mb={2}
                                  >
                                    {t("location_access")}
                                  </Typography>

                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    getOptionLabel={(option) => option?.label}
                                    options={manangeLocationsArray}
                                    value={manageRemoveLocation}
                                    fullWidth
                                    disabled={disableField}
                                    onChange={(e, value) => {
                                      setManageRemoveLocation(value);
                                      if (value?.label === "All Locations") {
                                        let locationIds = [];
                                        if (allLocations?.length > 0) {
                                          allLocations?.map((item) => {
                                            locationIds?.push(item);
                                          });
                                        }
                                        setRemoveLocationValue(locationIds);
                                        setRemoveLocationError(false);
                                        setSelectedRemoveGroups([]);
                                        setSelectAllRemoveGroups(false);
                                      } else {
                                        setRemoveLocationValue([]);
                                        setRemoveLocationError(false);
                                        setSelectedRemoveGroups([]);
                                        setSelectAllRemoveGroups(false);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={t("Locations")}
                                        disabled={disableField}
                                        error={
                                          (manageLocation?.label ===
                                            "All Locations" &&
                                            manageRemoveLocation?.label ===
                                              "All Locations") ||
                                          (manageRemoveLocation?.label ===
                                            "All Locations" &&
                                            locationValue?.length > 0)
                                        }
                                        helperText={
                                          ((manageLocation?.label ===
                                            "All Locations" &&
                                            manageRemoveLocation?.label ===
                                              "All Locations") ||
                                            (manageRemoveLocation?.label ===
                                              "All Locations" &&
                                              locationValue?.length > 0)) &&
                                          t("same_location_error")
                                        }
                                      />
                                    )}
                                  />
                                </Grid>

                                {manageRemoveLocation?.label === "Groups" && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    mt={2}
                                  >
                                    <Typography
                                      className={classes.emailReportSubLabel}
                                      mb={2}
                                    >
                                      {t("group_access")}
                                    </Typography>

                                    <Autocomplete
                                      multiple
                                      disablePortal
                                      disableCloseOnSelect
                                      id="combo-box-demo"
                                      options={
                                        allGroups !== null &&
                                        allGroups?.rows?.length > 0
                                          ? allGroups?.rows
                                          : []
                                      }
                                      getOptionLabel={(option) =>
                                        option?.name ? option?.name : ""
                                      }
                                      className="Autocomplete-field"
                                      value={
                                        selectedRemoveGroups
                                          ? selectedRemoveGroups
                                          : []
                                      }
                                      onChange={(e, value) => {
                                        if (
                                          value.find(
                                            (option) => option.id === 0
                                          )
                                        ) {
                                          if (
                                            selectedRemoveGroups?.length ==
                                            allGroups?.rows?.length
                                          ) {
                                            setSelectedRemoveGroups([]);
                                            setSelectAllRemoveGroups(false);
                                          } else {
                                            setSelectAllRemoveGroups(true);
                                            handleChangeRemoveGroups(
                                              allGroups?.rows
                                            );
                                          }
                                        } else {
                                          handleChangeRemoveGroups(value);
                                          if (
                                            value?.length ===
                                            allGroups?.rows?.length
                                          ) {
                                            setSelectAllRemoveGroups(true);
                                          } else {
                                            setSelectAllRemoveGroups(false);
                                          }
                                        }
                                      }}
                                      filterOptions={(options, params) => {
                                        const filtered = filter(
                                          options,
                                          params
                                        );
                                        if (allGroups?.rows?.length > 0) {
                                          return [
                                            { id: 0, name: "Select All" },
                                            ...filtered,
                                          ];
                                        } else {
                                          return [];
                                        }
                                      }}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={
                                              selectAllRemoveGroups || selected
                                            }
                                            id="select-all-checkbox"
                                          />

                                          <Typography>
                                            {option?.name}
                                          </Typography>
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          placeholder={t("Select_group")}
                                          {...params}
                                          required
                                          name="language"
                                        />
                                      )}
                                    ></Autocomplete>
                                  </Grid>
                                )}

                                {(manageRemoveLocation?.label ===
                                  "Specific Locations" ||
                                  manageRemoveLocation?.label === "Groups") && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    mt={2}
                                  >
                                    <Typography
                                      className={classes.emailReportSubLabel}
                                      mb={2}
                                    >
                                      {t("Select_Locations")}
                                    </Typography>

                                    {allLocations?.length > 0 && (
                                      <Autocomplete
                                        multiple
                                        error={locationError}
                                        disablePortal
                                        id="combo-box-demo"
                                        disableCloseOnSelect
                                        options={
                                          allLocations?.length > 0
                                            ? allLocations
                                            : []
                                        }
                                        value={removeLocationValue}
                                        onChange={(event, value) => {
                                          // Check if 'All' option is clicked
                                          if (value?.length > 0) {
                                            setRemoveLocationError(false);
                                          } else {
                                            setRemoveLocationError(true);
                                          }

                                          if (
                                            value.find(
                                              (option) => option.id === 0
                                            )
                                          ) {
                                            // Check if all options are selected
                                            if (
                                              allLocations?.length ===
                                              locationValue?.length
                                            ) {
                                              setRemoveLocationValue([]);
                                              setSelectAllForRemoveLocations(
                                                false
                                              );
                                            } else {
                                              setSelectAllForRemoveLocations(
                                                true
                                              );
                                              setRemoveLocationValue(
                                                allLocations
                                              );
                                              handleChangeGroupsForRemoveLocations(
                                                allLocations
                                              );
                                              //  setLocationRequired(false);
                                            }
                                          } else {
                                            setRemoveLocationValue(value);
                                            handleChangeGroupsForRemoveLocations(
                                              value
                                            );
                                            if (
                                              value?.length ===
                                              allLocations?.length
                                            ) {
                                              setSelectAllForRemoveLocations(
                                                true
                                              );
                                            } else {
                                              setSelectAllForRemoveLocations(
                                                false
                                              );
                                            }
                                          }
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                          option.id === value.id
                                        }
                                        renderTags={(value, getTagProps) => {
                                          return (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexWrap: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width: "100%",
                                              }}
                                            >
                                              <Stack
                                                // direction="row"
                                                spacing={1}
                                                sx={{
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  flexWrap: "nowrap",
                                                  display: "inline",
                                                }}
                                              >
                                                {value.map((option, index) => (
                                                  <Chip
                                                    style={{
                                                      background: "#FFF",
                                                      border:
                                                        duplicateItems?.find(
                                                          (item) =>
                                                            item?.id ===
                                                            option?.id
                                                        ) === undefined
                                                          ? "1px solid #06BDFF"
                                                          : "1px solid #FFA2A2",
                                                      borderRadius: "800px",
                                                    }}
                                                    key={index}
                                                    label={option?.internalName}
                                                    {...getTagProps({ index })}
                                                  />
                                                ))}
                                              </Stack>
                                            </Box>
                                          );
                                        }}
                                        filterOptions={(options, params) => {
                                          const filtered = filter(
                                            options,
                                            params
                                          );
                                          if (allLocations?.length > 0) {
                                            return [
                                              {
                                                id: 0,
                                                internalName: "Select All",
                                              },
                                              ...filtered,
                                            ];
                                          } else {
                                            return [];
                                          }
                                        }}
                                        defaultValue={locationValue}
                                        getOptionLabel={(option) =>
                                          option?.internalName
                                            ? option?.internalName +
                                              " " +
                                              "(" +
                                              (option?.addressLine1 !== "" &&
                                              option?.addressLine1 !== null &&
                                              option?.addressLine1 !== undefined
                                                ? option?.addressLine1 +
                                                  "," +
                                                  " "
                                                : "") +
                                              (option?.city
                                                ? option?.city
                                                : "") +
                                              ")"
                                            : ""
                                        }
                                        sx={{ minWidth: 300 }}
                                        // value={locationId ? locationId : []}
                                        // onChange={(e, value) => {
                                        //   setLocationRequired(false);

                                        //   setSelectedLocation(value);
                                        // }}
                                        renderOption={(
                                          props,
                                          option,
                                          { selected }
                                        ) => (
                                          <li {...props}>
                                            <Checkbox
                                              icon={icon}
                                              checkedIcon={checkedIcon}
                                              style={{ marginRight: 8 }}
                                              id="select-all-checkbox"
                                              checked={
                                                selectAllForRemoveLocations ||
                                                selected
                                              }
                                              // checked={selected}
                                            />
                                            {`${option?.internalName} ${
                                              option?.addressLine1 !== "" &&
                                              option?.addressLine1 !== null &&
                                              option?.addressLine1 !== undefined
                                                ? "(" +
                                                  option?.addressLine1 +
                                                  "," +
                                                  " "
                                                : ""
                                            } ${
                                              option?.city
                                                ? option?.city + ")"
                                                : ""
                                            }`}
                                          </li>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            name="language"
                                            {...params}
                                            error={
                                              removeLocationError ||
                                              duplicateItems?.length > 0
                                            }
                                            placeholder={t("Select_Location")}
                                            //  defaultValue={{ selectedLocation }}
                                            helperText={
                                              removeLocationError
                                                ? t("location_req")
                                                : duplicateItems?.length > 0
                                                ? t("same_location_error")
                                                : ""
                                            }
                                          />
                                        )}
                                      ></Autocomplete>
                                    )}
                                  </Grid>
                                )}
                              </Grid>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                          <Box className={classes.addUserBox}>
                            <Typography
                              className={classes.emailReportSubLabel}
                              mb={2}
                            >
                              {t("select_role")}
                            </Typography>
                            <Box mb={2}>
                              <Button autoFocus onClick={handleCreateNewRole}>
                                <AddIcon fontSize="medium" />
                                <Typography className={classes.createRole}>
                                  {t("create_new_role")}
                                </Typography>
                              </Button>
                            </Box>
                          </Box>

                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={allRoles !== null ? allRoles?.rows : []}
                            getOptionLabel={(option) =>
                              option?.name
                                ? (
                                    option.name.charAt(0).toUpperCase() +
                                    option.name.slice(1)
                                  ).replaceAll("-", " ")
                                : ""
                            }
                            fullWidth
                            value={selectedRole}
                            onChange={handleChangeSelectedRole}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t("select_role")}
                                error={selectedRoleError}
                                helperText={
                                  selectedRoleError ? t("role_req") : ""
                                }
                              />
                            )}
                          />
                        </Grid>
                        {showRoleName && (
                          <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                            <Typography
                              className={classes.emailReportSubLabel}
                              mb={2}
                            >
                              {t("role_name")}
                            </Typography>
                            <TextField
                              fullWidth
                              placeholder={t("role_placeHolder")}
                              value={roleName}
                              onChange={handleChangeRoleName}
                              error={roleNameError || sameNameError}
                              helperText={
                                roleNameError
                                  ? t("roleName_required")
                                  : sameNameError
                                  ? t("roleName_can't_same")
                                  : ""
                              }
                            />
                          </Grid>
                        )}
                        {showFeatures && (
                          <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                            <EditUserFeature
                              selectedRole={selectedRole}
                              duplicateRole={duplicateRole}
                              setDuplicateRole={setDuplicateRole}
                              setSelectedRole={setSelectedRole}
                              setPermissionsArr={setPermissionsArr}
                              permissionsArr={permissionsArr}
                              companyFeatures={companyFeatures}
                              singleRole={singleRole}
                              showRoleName={showRoleName}
                              setShowRoleName={setShowRoleName}
                            />
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid container sx={{ justifyContent: "flex-end" }}>
            <CommonButton
              displayWhite="true"
              onSubmit={onCancelEdit}
              label={t("Cancel")}
            />

            <CommonButton
              label={t("Submit")}
              disabled={
                disableButton
                  ? true
                  : duplicateItems?.length > 0
                  ? true
                  : /*  : selectedRole === null && roleName === ""
                      ? true */
                  manageLocation == null &&
                    manageRemoveLocation == null &&
                    selectedRole === null &&
                    roleName === ""
                  ? true
                  : manageLocation?.label === "Specific Locations" &&
                    locationError
                  ? true
                  : manageLocation?.label === "Specific Locations" &&
                    locationValue.length === 0
                  ? true
                  : manageLocation?.label === "Groups" &&
                    locationValue.length === 0
                  ? true
                  : false
              }
              onSubmit={handleOpenConfirmModel}
              // loading={isLoading}
            />
          </Grid>
        </DialogActions>
        <Divider />
      </Dialog>
    </>
  );
}

export default AddNewUser;
