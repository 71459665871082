import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Autocomplete,
  TextField,
  Table,
  TableBody,
  TableContainer,
  Button,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import CustomizedTableCell from "../../../../../components/CustomComponents/table/tableCell";
import CustomizedTableHead from "../../../../../components/CustomComponents/table/tableHead";
import CustomizedTableRow from "../../../../../components/CustomComponents/table/tableRow";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useStyles } from "../../Style/style";
import TableSkeletonLoader from "../TableSkeletonLoader";
import NoDataRatingBreakdown from "./NoDataFound/NoDataRatingBreakdown";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const VIEWS = {
  REVIEWS: "reviews",
  REPLIES: "replies",
};

const RatingBreakdown = ({
  ratingBreakDownData,
  ratingLoading,
  handleDisplayName,
  locations,
  filterBreakdownLocation,
  handleOpenReviewModel,
  setRatingBreakdownValue,
  setSelectedLocationId,
  tooltipContent,
  startDate,
  handleDisplayGroupName,
  setSelectedGroupId,
  setDownloadFormat,
  downloadFormat,
  pdfChecked,
  pdfDownloadChecked,
  openDownloadPdfModal,
  openReportEmailModal,
  viewPermission,
  setFeaturesObj,
  featuresObj,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dataCount, setDataCount] = useState(5);
  const [downloadValue, setDownloadValue] = useState(null);

  useEffect(() => {
    if (downloadFormat === "") {
      setDownloadValue(null);
    }
  }, [downloadFormat]);

  useEffect(() => {
    if (ratingBreakDownData?.length > 0) {
      setDataCount(ratingBreakDownData?.length);
    } else {
      setDataCount(5);
    }
  }, [
    pdfChecked,
    pdfDownloadChecked,
    openDownloadPdfModal,
    openReportEmailModal,
  ]);

  const loadMoreData = () => {
    if (ratingBreakDownData?.length > 0) {
      setDataCount(ratingBreakDownData?.length);
    }
  };
  const loadLessData = () => {
    setDataCount(5);
  };
  const headCells = [
    { id: "location_name", alignment: "left", label: t("Name") },
    { id: "one_star", alignment: "left", label: t("one_star") },
    { id: "two_star", alignment: "left", label: t("two_star") },
    { id: "three_star", alignment: "left", label: t("three_star") },
    { id: "four_star", alignment: "left", label: t("four_star") },
    { id: "five_star", alignment: "left", label: t("five_star") },
  ];

  function handleArrows(value, lastValue) {
    let precision = "positive";
    const difference = value - lastValue;
    console.log(value, lastValue, difference, "difference");
    if (difference === 0) {
      precision = "neutral";
    } else if (difference > 0) {
      precision = "positive";
    } else {
      precision = "negative";
    }
    return precision;
  }
  const downloadOptions = [
    { id: 1, value: "download", title: t("downlaod_pdf") },
    { id: 2, value: "email", title: t("email_report") },
  ];
  const handleRatingBreakDownloadChange = (event, newValue) => {
    setDownloadFormat(newValue?.value);
    setDownloadValue(newValue);

    let tempFeatureObj = featuresObj;
    Object.keys(tempFeatureObj)?.map((itemTwo) => {
      if (itemTwo === "ratingBreakdownReviewReplied") {
        tempFeatureObj[itemTwo] = true;
      } else {
        tempFeatureObj[itemTwo] = false;
      }
    });
    setFeaturesObj(tempFeatureObj);
  };

  const handleCalculatePercentage = (
    replyRatingValue,
    ratingValue,
    reviews
  ) => {
    if (isNaN(replyRatingValue) || isNaN(ratingValue)) {
      return 0;
    } else {
      const totalReviews = (ratingValue * reviews) / 100;

      const value = (replyRatingValue / totalReviews?.toFixed()) * 100;

      return value !== Infinity && !isNaN(value) ? value?.toFixed(2) : "0.00";
    }
  };

  return (
    <>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={2} mb={3}>
            <Grid item xs={9.7} sm={9.7} md={9.7} lg={9.7}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography className={classes.subHeadRatingReviewDetail}>
                  {t("replies_rating_breakdown")}
                </Typography>
                <BootstrapTooltip title={t("replies_rating_breakdown_tooltip")}>
                  <Typography
                    sx={{
                      marginLeft: "5px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <InfoOutlinedIcon />
                  </Typography>
                </BootstrapTooltip>
              </Box>
            </Grid>

            {viewPermission && (
              <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3}>
                <Autocomplete
                  id="tags-outlined"
                  options={downloadOptions}
                  value={downloadValue}
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  onChange={handleRatingBreakDownloadChange}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t("actionss")} />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.tableContainer}>
            <TableContainer component={Paper}>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                {ratingLoading ? (
                  <TableSkeletonLoader />
                ) : (
                  <>
                    {ratingBreakDownData.length > 0 && (
                      <CustomizedTableHead>
                        <CustomizedTableRow>
                          {headCells.map((headCell) => (
                            <CustomizedTableCell
                              sx={{
                                fontWeight: 700,
                                fontSize: "14px",
                                paddingRight: "70px",
                              }}
                              key={headCell.id}
                              align={headCell.alignment}
                            >
                              {headCell.label}
                            </CustomizedTableCell>
                          ))}
                        </CustomizedTableRow>
                      </CustomizedTableHead>
                    )}
                    {ratingBreakDownData.length > 0 ? (
                      <>
                        <TableBody>
                          {ratingBreakDownData?.length > 0 &&
                            ratingBreakDownData
                              ?.slice(0, dataCount)
                              .map((item, index) => {
                                return (
                                  <CustomizedTableRow
                                    sx={{
                                      height: "100px",
                                    }}
                                    tabIndex={-1}
                                    key={index}
                                  >
                                    <CustomizedTableCell align="left">
                                      {item.hasOwnProperty("locationId") &&
                                        handleDisplayName(item?.locationId)}

                                      {item.hasOwnProperty("groupId") &&
                                        handleDisplayGroupName(item?.groupId)}
                                    </CustomizedTableCell>

                                    <CustomizedTableCell
                                      sx={{ cursor: "pointer" }}
                                      align="left"
                                      onClick={() => {
                                        if (
                                          item?.reviewReplyOneCount !==
                                            undefined &&
                                          item?.reviewReplyOneCount !== null &&
                                          item?.reviewReplyOneCount > 0
                                        ) {
                                          setRatingBreakdownValue(1);
                                          setSelectedLocationId(
                                            item?.locationId
                                          );
                                          setSelectedGroupId(item?.groupId);

                                          handleOpenReviewModel(
                                            "new",
                                            undefined,
                                            item.hasOwnProperty("locationId")
                                              ? item?.locationId
                                              : item?.groupId,
                                            item.hasOwnProperty("locationId")
                                              ? "locationId"
                                              : "groupId"
                                          );
                                        }
                                      }}
                                    >
                                      <BootstrapTooltip
                                        title={
                                          <h1 style={{ fontSize: "12px" }}>
                                            {`${tooltipContent}`}
                                          </h1>
                                        }
                                      >
                                        <span>
                                          {(item?.reviewReplyOneCount !==
                                            null &&
                                          !isNaN(
                                            parseFloat(
                                              item?.reviewReplyOneCount
                                            )
                                          ) &&
                                          isFinite(item?.reviewReplyOneCount)
                                            ? item?.reviewReplyOneCount
                                            : "0") +
                                            " " +
                                            "(" +
                                            handleCalculatePercentage(
                                              parseInt(
                                                item?.reviewReplyOneCount
                                              ),

                                              parseFloat(item?.oneRatingValue),
                                              parseInt(
                                                item?.totalNumberofReviews
                                              )
                                            ) +
                                            "%" +
                                            ")"}

                                          {handleArrows(
                                            item?.reviewReplyOneCount !==
                                              null &&
                                              !isNaN(
                                                item?.reviewReplyOneCount
                                              ) &&
                                              isFinite(
                                                item?.reviewReplyOneCount
                                              )
                                              ? item?.reviewReplyOneCount
                                              : 0,
                                            item?.lastReviewReplyOneCount !==
                                              null &&
                                              !isNaN(
                                                item?.lastReviewReplyOneCount
                                              ) &&
                                              isFinite(
                                                item?.lastReviewReplyOneCount
                                              )
                                              ? item?.lastReviewReplyOneCount
                                              : 0
                                          ) === "negative" ? (
                                            <ArrowDownwardIcon
                                              className={classes.redIconClass}
                                            />
                                          ) : handleArrows(
                                              item?.reviewReplyOneCount !==
                                                null &&
                                                !isNaN(
                                                  item?.reviewReplyOneCount
                                                ) &&
                                                isFinite(
                                                  item?.reviewReplyOneCount
                                                )
                                                ? item?.reviewReplyOneCount
                                                : 0,
                                              item?.lastReviewReplyOneCount !==
                                                null &&
                                                !isNaN(
                                                  item?.lastReviewReplyOneCount
                                                ) &&
                                                isFinite(
                                                  item?.lastReviewReplyOneCount
                                                )
                                                ? item?.lastReviewReplyOneCount
                                                : 0
                                            ) === "positive" ? (
                                            <ArrowUpwardIcon
                                              className={classes.greenIconClass}
                                            />
                                          ) : (
                                            <ArrowForwardIcon
                                              className={
                                                classes.orangeIconClass
                                              }
                                            />
                                          )}
                                        </span>
                                      </BootstrapTooltip>
                                    </CustomizedTableCell>

                                    <CustomizedTableCell
                                      sx={{ cursor: "pointer" }}
                                      align="left"
                                      onClick={() => {
                                        if (
                                          item?.reviewReplyTwoCount !==
                                            undefined &&
                                          item?.reviewReplyTwoCount !== null &&
                                          item?.reviewReplyTwoCount > 0
                                        ) {
                                          setRatingBreakdownValue(2);
                                          setSelectedLocationId(
                                            item?.locationId
                                          );
                                          setSelectedGroupId(item?.groupId);

                                          handleOpenReviewModel(
                                            "new",
                                            undefined,
                                            item.hasOwnProperty("locationId")
                                              ? item?.locationId
                                              : item?.groupId,
                                            item.hasOwnProperty("locationId")
                                              ? "locationId"
                                              : "groupId"
                                          );
                                        }
                                      }}
                                    >
                                      <BootstrapTooltip
                                        title={
                                          <h1 style={{ fontSize: "12px" }}>
                                            {`${tooltipContent}`}
                                          </h1>
                                        }
                                      >
                                        <span>
                                          {(item?.reviewReplyTwoCount !==
                                            null &&
                                          !isNaN(
                                            parseFloat(
                                              item?.reviewReplyTwoCount
                                            )
                                          ) &&
                                          isFinite(item?.reviewReplyTwoCount)
                                            ? item?.reviewReplyTwoCount
                                            : "0") +
                                            " " +
                                            "(" +
                                            handleCalculatePercentage(
                                              parseInt(
                                                item?.reviewReplyTwoCount
                                              ),
                                              parseFloat(item?.twoRatingValue),
                                              parseInt(
                                                item?.totalNumberofReviews
                                              )
                                            ) +
                                            "%)"}

                                          {handleArrows(
                                            item?.reviewReplyTwoCount !==
                                              null &&
                                              !isNaN(
                                                item?.reviewReplyTwoCount
                                              ) &&
                                              isFinite(
                                                item?.reviewReplyTwoCount
                                              )
                                              ? item?.reviewReplyTwoCount
                                              : 0,

                                            item?.lastReviewReplyTwoCount !==
                                              null &&
                                              !isNaN(
                                                item?.lastReviewReplyTwoCount
                                              ) &&
                                              isFinite(
                                                item?.lastReviewReplyTwoCount
                                              )
                                              ? item?.lastReviewReplyTwoCount
                                              : 0
                                          ) === "negative" ? (
                                            <ArrowDownwardIcon
                                              className={classes.redIconClass}
                                            />
                                          ) : handleArrows(
                                              item?.reviewReplyTwoCount !==
                                                null &&
                                                !isNaN(
                                                  item?.reviewReplyTwoCount
                                                ) &&
                                                isFinite(
                                                  item?.reviewReplyTwoCount
                                                )
                                                ? item?.reviewReplyTwoCount
                                                : 0,
                                              item?.lastReviewReplyTwoCount !==
                                                null &&
                                                !isNaN(
                                                  item?.lastReviewReplyTwoCount
                                                ) &&
                                                isFinite(
                                                  item?.lastReviewReplyTwoCount
                                                )
                                                ? item?.lastReviewReplyTwoCount
                                                : 0
                                            ) === "positive" ? (
                                            <ArrowUpwardIcon
                                              className={classes.greenIconClass}
                                            />
                                          ) : (
                                            <ArrowForwardIcon
                                              className={
                                                classes.orangeIconClass
                                              }
                                            />
                                          )}
                                        </span>
                                      </BootstrapTooltip>
                                    </CustomizedTableCell>
                                    <CustomizedTableCell
                                      sx={{ cursor: "pointer" }}
                                      align="left"
                                      onClick={() => {
                                        if (
                                          item?.reviewReplyThreeCount !==
                                            undefined &&
                                          item?.reviewReplyThreeCount !==
                                            null &&
                                          item?.reviewReplyThreeCount > 0
                                        ) {
                                          setRatingBreakdownValue(3);
                                          setSelectedLocationId(
                                            item?.locationId
                                          );
                                          setSelectedGroupId(item?.groupId);

                                          handleOpenReviewModel(
                                            "new",
                                            undefined,
                                            item.hasOwnProperty("locationId")
                                              ? item?.locationId
                                              : item?.groupId,
                                            item.hasOwnProperty("locationId")
                                              ? "locationId"
                                              : "groupId"
                                          );
                                        }
                                      }}
                                    >
                                      <BootstrapTooltip
                                        title={
                                          <h1 style={{ fontSize: "12px" }}>
                                            {`${tooltipContent}`}
                                          </h1>
                                        }
                                      >
                                        <span>
                                          {(item?.reviewReplyThreeCount !==
                                            null &&
                                          !isNaN(item?.reviewReplyThreeCount) &&
                                          isFinite(item?.reviewReplyThreeCount)
                                            ? item?.reviewReplyThreeCount
                                            : "0") +
                                            " " +
                                            "(" +
                                            handleCalculatePercentage(
                                              parseInt(
                                                item?.reviewReplyThreeCount
                                              ),
                                              parseFloat(
                                                item?.threeRatingValue
                                              ),
                                              parseInt(
                                                item?.totalNumberofReviews
                                              )
                                            ) +
                                            "%)"}
                                          {handleArrows(
                                            item?.reviewReplyThreeCount !==
                                              null &&
                                              !isNaN(
                                                parseFloat(
                                                  item?.reviewReplyThreeCount
                                                )
                                              ) &&
                                              isFinite(
                                                item?.reviewReplyThreeCount
                                              )
                                              ? item?.reviewReplyThreeCount
                                              : 0,

                                            item?.lastReviewReplyThreeCount !==
                                              null &&
                                              !isNaN(
                                                item?.lastReviewReplyThreeCount
                                              ) &&
                                              isFinite(
                                                item?.lastReviewReplyThreeCount
                                              )
                                              ? item?.lastReviewReplyThreeCount
                                              : 0
                                          ) === "negative" ? (
                                            <ArrowDownwardIcon
                                              className={classes.redIconClass}
                                            />
                                          ) : handleArrows(
                                              item?.reviewReplyThreeCount !==
                                                null &&
                                                !isNaN(
                                                  item?.reviewReplyThreeCount
                                                ) &&
                                                isFinite(
                                                  item?.reviewReplyThreeCount
                                                )
                                                ? item?.reviewReplyThreeCount
                                                : 0,

                                              item?.lastReviewReplyThreeCount !==
                                                null &&
                                                !isNaN(
                                                  item?.lastReviewReplyThreeCount
                                                ) &&
                                                isFinite(
                                                  item?.lastReviewReplyThreeCount
                                                )
                                                ? item?.lastReviewReplyThreeCount
                                                : 0
                                            ) === "positive" ? (
                                            <ArrowUpwardIcon
                                              className={classes.greenIconClass}
                                            />
                                          ) : (
                                            <ArrowForwardIcon
                                              className={
                                                classes.orangeIconClass
                                              }
                                            />
                                          )}
                                        </span>
                                      </BootstrapTooltip>
                                    </CustomizedTableCell>
                                    <CustomizedTableCell
                                      sx={{ cursor: "pointer" }}
                                      align="left"
                                      onClick={() => {
                                        if (
                                          item?.reviewReplyFourCount !==
                                            undefined &&
                                          item?.reviewReplyFourCount !== null &&
                                          item?.reviewReplyFourCount > 0
                                        ) {
                                          setRatingBreakdownValue(4);
                                          setSelectedLocationId(
                                            item?.locationId
                                          );
                                          setSelectedGroupId(item?.groupId);

                                          handleOpenReviewModel(
                                            "new",
                                            undefined,
                                            item.hasOwnProperty("locationId")
                                              ? item?.locationId
                                              : item?.groupId,
                                            item.hasOwnProperty("locationId")
                                              ? "locationId"
                                              : "groupId"
                                          );
                                        }
                                      }}
                                    >
                                      <BootstrapTooltip
                                        title={
                                          <h1 style={{ fontSize: "12px" }}>
                                            {`${tooltipContent}`}
                                          </h1>
                                        }
                                      >
                                        <span>
                                          {(item?.reviewReplyFourCount !==
                                            null &&
                                          !isNaN(
                                            parseFloat(
                                              item?.reviewReplyFourCount
                                            )
                                          ) &&
                                          isFinite(item?.reviewReplyFourCount)
                                            ? item?.reviewReplyFourCount
                                            : "0") +
                                            " " +
                                            "(" +
                                            handleCalculatePercentage(
                                              parseInt(
                                                item?.reviewReplyFourCount
                                              ),
                                              parseFloat(item?.fourRatingValue),
                                              parseInt(
                                                item?.totalNumberofReviews
                                              )
                                            ) +
                                            "%)"}
                                          {handleArrows(
                                            item?.reviewReplyFourCount !==
                                              null &&
                                              !isNaN(
                                                item?.reviewReplyFourCount
                                              ) &&
                                              isFinite(
                                                item?.reviewReplyFourCount
                                              )
                                              ? item?.reviewReplyFourCount
                                              : 0,

                                            item?.lastReviewReplyFourCount !==
                                              null &&
                                              !isNaN(
                                                item?.lastReviewReplyFourCount
                                              ) &&
                                              isFinite(
                                                item?.lastReviewReplyFourCount
                                              )
                                              ? item?.lastReviewReplyFourCount
                                              : 0
                                          ) === "positive" ? (
                                            <ArrowUpwardIcon
                                              className={classes.greenIconClass}
                                            />
                                          ) : handleArrows(
                                              item?.reviewReplyFourCount !==
                                                null &&
                                                !isNaN(
                                                  item?.reviewReplyFourCount
                                                ) &&
                                                isFinite(
                                                  item?.reviewReplyFourCount
                                                )
                                                ? item?.reviewReplyFourCount
                                                : 0,

                                              item?.lastReviewReplyFourCount !==
                                                null &&
                                                !isNaN(
                                                  item?.lastReviewReplyFourCount
                                                ) &&
                                                isFinite(
                                                  item?.lastReviewReplyFourCount
                                                )
                                                ? item?.lastReviewReplyFourCount
                                                : 0
                                            ) === "negative" ? (
                                            <ArrowDownwardIcon
                                              className={classes.redIconClass}
                                            />
                                          ) : (
                                            <ArrowForwardIcon
                                              className={
                                                classes.orangeIconClass
                                              }
                                            />
                                          )}
                                        </span>
                                      </BootstrapTooltip>
                                    </CustomizedTableCell>
                                    <CustomizedTableCell
                                      sx={{ cursor: "pointer" }}
                                      align="left"
                                      onClick={() => {
                                        if (
                                          item?.reviewReplyFiveCount !==
                                            undefined &&
                                          item?.reviewReplyFiveCount !== null &&
                                          item?.reviewReplyFiveCount > 0
                                        ) {
                                          setRatingBreakdownValue(5);
                                          setSelectedLocationId(
                                            item?.locationId
                                          );
                                          setSelectedGroupId(item?.groupId);
                                          handleOpenReviewModel(
                                            "new",
                                            undefined,
                                            item.hasOwnProperty("locationId")
                                              ? item?.locationId
                                              : item?.groupId,
                                            item.hasOwnProperty("locationId")
                                              ? "locationId"
                                              : "groupId"
                                          );
                                        }
                                      }}
                                    >
                                      <BootstrapTooltip
                                        title={
                                          <h1 style={{ fontSize: "12px" }}>
                                            {`${tooltipContent}`}
                                          </h1>
                                        }
                                      >
                                        <span>
                                          {(item?.reviewReplyFiveCount !==
                                            null &&
                                          !isNaN(
                                            parseFloat(
                                              item?.reviewReplyFiveCount
                                            )
                                          ) &&
                                          isFinite(item?.reviewReplyFiveCount)
                                            ? item?.reviewReplyFiveCount
                                            : "0") +
                                            " " +
                                            "(" +
                                            handleCalculatePercentage(
                                              parseInt(
                                                item?.reviewReplyFiveCount
                                              ),
                                              parseFloat(item?.fiveRatingValue),
                                              parseInt(
                                                item?.totalNumberofReviews
                                              )
                                            ) +
                                            "%)"}
                                          {handleArrows(
                                            item?.reviewReplyFiveCount !==
                                              null &&
                                              !isNaN(
                                                item?.reviewReplyFiveCount
                                              ) &&
                                              isFinite(
                                                item?.reviewReplyFiveCount
                                              )
                                              ? item?.reviewReplyFiveCount
                                              : 0,

                                            item?.lastReviewReplyFiveCount !==
                                              null &&
                                              !isNaN(
                                                item?.lastReviewReplyFiveCount
                                              ) &&
                                              isFinite(
                                                item?.lastReviewReplyFiveCount
                                              )
                                              ? item?.lastReviewReplyFiveCount
                                              : 0
                                          ) === "positive" ? (
                                            <ArrowUpwardIcon
                                              className={classes.greenIconClass}
                                            />
                                          ) : handleArrows(
                                              item?.reviewReplyFiveCount !==
                                                null &&
                                                !isNaN(
                                                  item?.reviewReplyFiveCount
                                                ) &&
                                                isFinite(
                                                  item?.reviewReplyFiveCount
                                                )
                                                ? item?.reviewReplyFiveCount
                                                : 0,

                                              item?.lastReviewReplyFiveCount !==
                                                null &&
                                                !isNaN(
                                                  item?.lastReviewReplyFiveCount
                                                ) &&
                                                isFinite(
                                                  item?.lastReviewReplyFiveCount
                                                )
                                                ? item?.lastReviewReplyFiveCount
                                                : 0
                                            ) === "negative" ? (
                                            <ArrowDownwardIcon
                                              className={classes.redIconClass}
                                            />
                                          ) : (
                                            <ArrowForwardIcon
                                              className={
                                                classes.orangeIconClass
                                              }
                                            />
                                          )}
                                          <TableBody>
                                            {ratingBreakDownData?.length > 0 &&
                                              filterBreakdownLocation?.map(
                                                (item, index) => (
                                                  <CustomizedTableRow
                                                    sx={{
                                                      height: "100px",
                                                    }}
                                                    tabIndex={-1}
                                                    key={index}
                                                  >
                                                    <CustomizedTableCell
                                                      align="left"
                                                      sx={{
                                                        width: "275px",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20px",
                                                      }}
                                                    >
                                                      {handleDisplayName(
                                                        item?.id
                                                      )}
                                                    </CustomizedTableCell>

                                                    <CustomizedTableCell
                                                      sx={{
                                                        cursor: "pointer",
                                                      }}
                                                      align="left"
                                                    >
                                                      {"0.00"}
                                                      <BootstrapTooltip
                                                        title={
                                                          <h1
                                                            style={{
                                                              fontSize: "12px",
                                                            }}
                                                          >
                                                            {`vs ${tooltipContent} - ${dayjs(
                                                              startDate
                                                            ).format(
                                                              "MMM DD, YYYY"
                                                            )}`}
                                                          </h1>
                                                        }
                                                      >
                                                        <ArrowForwardIcon
                                                          className={
                                                            classes.orangeIconClass
                                                          }
                                                        />
                                                      </BootstrapTooltip>
                                                    </CustomizedTableCell>
                                                    <CustomizedTableCell
                                                      sx={{
                                                        cursor: "pointer",
                                                      }}
                                                      align="left"
                                                    >
                                                      {"0.00"}
                                                      <BootstrapTooltip
                                                        title={
                                                          <h1
                                                            style={{
                                                              fontSize: "12px",
                                                            }}
                                                          >
                                                            {`vs ${tooltipContent} - ${dayjs(
                                                              startDate
                                                            ).format(
                                                              "MMM DD, YYYY"
                                                            )}`}
                                                          </h1>
                                                        }
                                                      >
                                                        <ArrowForwardIcon
                                                          className={
                                                            classes.orangeIconClass
                                                          }
                                                        />
                                                      </BootstrapTooltip>
                                                    </CustomizedTableCell>
                                                    <CustomizedTableCell
                                                      sx={{
                                                        cursor: "pointer",
                                                      }}
                                                      align="left"
                                                    >
                                                      {"0.00"}
                                                      <BootstrapTooltip
                                                        title={
                                                          <h1
                                                            style={{
                                                              fontSize: "12px",
                                                            }}
                                                          >
                                                            {`vs ${tooltipContent} - ${dayjs(
                                                              startDate
                                                            ).format(
                                                              "MMM DD, YYYY"
                                                            )}`}
                                                          </h1>
                                                        }
                                                      >
                                                        <ArrowForwardIcon
                                                          className={
                                                            classes.orangeIconClass
                                                          }
                                                        />
                                                      </BootstrapTooltip>
                                                    </CustomizedTableCell>
                                                    <CustomizedTableCell
                                                      sx={{
                                                        cursor: "pointer",
                                                      }}
                                                      align="left"
                                                    >
                                                      {"0.00"}
                                                      <BootstrapTooltip
                                                        title={
                                                          <h1
                                                            style={{
                                                              fontSize: "12px",
                                                            }}
                                                          >
                                                            {`vs ${tooltipContent} - ${dayjs(
                                                              startDate
                                                            ).format(
                                                              "MMM DD, YYYY"
                                                            )}`}
                                                          </h1>
                                                        }
                                                      >
                                                        <ArrowForwardIcon
                                                          className={
                                                            classes.orangeIconClass
                                                          }
                                                        />
                                                      </BootstrapTooltip>
                                                    </CustomizedTableCell>
                                                    <CustomizedTableCell
                                                      sx={{
                                                        cursor: "pointer",
                                                      }}
                                                      align="left"
                                                    >
                                                      {"0.00"}
                                                      <BootstrapTooltip
                                                        title={
                                                          <h1
                                                            style={{
                                                              fontSize: "12px",
                                                            }}
                                                          >
                                                            {`vs ${tooltipContent} - ${dayjs(
                                                              startDate
                                                            ).format(
                                                              "MMM DD, YYYY"
                                                            )}`}
                                                          </h1>
                                                        }
                                                      >
                                                        <ArrowForwardIcon
                                                          className={
                                                            classes.orangeIconClass
                                                          }
                                                        />
                                                      </BootstrapTooltip>
                                                    </CustomizedTableCell>
                                                  </CustomizedTableRow>
                                                )
                                              )}
                                          </TableBody>
                                        </span>
                                      </BootstrapTooltip>
                                    </CustomizedTableCell>
                                  </CustomizedTableRow>
                                );
                              })}
                        </TableBody>

                        <TableBody>
                          {ratingBreakDownData?.length > 0 &&
                            filterBreakdownLocation?.map((item, index) => (
                              <CustomizedTableRow
                                sx={{
                                  height: "100px",
                                }}
                                tabIndex={-1}
                                key={index}
                              >
                                <CustomizedTableCell
                                  align="left"
                                  sx={{
                                    width: "275px",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                  }}
                                >
                                  {handleDisplayName(item?.id)}
                                </CustomizedTableCell>

                                <CustomizedTableCell
                                  sx={{ cursor: "pointer" }}
                                  align="left"
                                >
                                  {"0.00"}
                                  <BootstrapTooltip
                                    title={
                                      <h1 style={{ fontSize: "12px" }}>
                                        {`vs ${tooltipContent} - ${dayjs(
                                          startDate
                                        ).format("MMM DD, YYYY")}`}
                                      </h1>
                                    }
                                  >
                                    <ArrowForwardIcon
                                      className={classes.orangeIconClass}
                                    />
                                  </BootstrapTooltip>
                                </CustomizedTableCell>
                                <CustomizedTableCell
                                  sx={{ cursor: "pointer" }}
                                  align="left"
                                >
                                  {"0.00"}
                                  <BootstrapTooltip
                                    title={
                                      <h1 style={{ fontSize: "12px" }}>
                                        {`vs ${tooltipContent} - ${dayjs(
                                          startDate
                                        ).format("MMM DD, YYYY")}`}
                                      </h1>
                                    }
                                  >
                                    <ArrowForwardIcon
                                      className={classes.orangeIconClass}
                                    />
                                  </BootstrapTooltip>
                                </CustomizedTableCell>
                                <CustomizedTableCell
                                  sx={{ cursor: "pointer" }}
                                  align="left"
                                >
                                  {"0.00"}
                                  <BootstrapTooltip
                                    title={
                                      <h1 style={{ fontSize: "12px" }}>
                                        {`vs ${tooltipContent} - ${dayjs(
                                          startDate
                                        ).format("MMM DD, YYYY")}`}
                                      </h1>
                                    }
                                  >
                                    <ArrowForwardIcon
                                      className={classes.orangeIconClass}
                                    />
                                  </BootstrapTooltip>
                                </CustomizedTableCell>
                                <CustomizedTableCell
                                  sx={{ cursor: "pointer" }}
                                  align="left"
                                >
                                  {"0.00"}
                                  <BootstrapTooltip
                                    title={
                                      <h1 style={{ fontSize: "12px" }}>
                                        {`vs ${tooltipContent} - ${dayjs(
                                          startDate
                                        ).format("MMM DD, YYYY")}`}
                                      </h1>
                                    }
                                  >
                                    <ArrowForwardIcon
                                      className={classes.orangeIconClass}
                                    />
                                  </BootstrapTooltip>
                                </CustomizedTableCell>
                                <CustomizedTableCell
                                  sx={{ cursor: "pointer" }}
                                  align="left"
                                >
                                  {"0.00"}
                                  <BootstrapTooltip
                                    title={
                                      <h1 style={{ fontSize: "12px" }}>
                                        {`vs ${tooltipContent} - ${dayjs(
                                          startDate
                                        ).format("MMM DD, YYYY")}`}
                                      </h1>
                                    }
                                  >
                                    <ArrowForwardIcon
                                      className={classes.orangeIconClass}
                                    />
                                  </BootstrapTooltip>
                                </CustomizedTableCell>
                              </CustomizedTableRow>
                            ))}
                        </TableBody>
                      </>
                    ) : (
                      <NoDataRatingBreakdown
                        handleDisplayName={handleDisplayName}
                        headCells={headCells}
                        locations={locations}
                        tooltipContent={tooltipContent}
                        startDate={startDate}
                      />
                    )}
                  </>
                )}
              </Table>
            </TableContainer>

            {ratingBreakDownData?.length > 5 && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.viewMoreButtonGrid}
              >
                {dataCount === 5 ? (
                  <Button onClick={loadMoreData}>
                    <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                      {t("View_More")}
                    </Typography>
                  </Button>
                ) : (
                  <Button onClick={loadLessData}>
                    <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                      {t("View_Less")}
                    </Typography>
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RatingBreakdown;
