import React, { useState } from "react";
import Loader from "../../../../components/Loaders/Loader";
import {
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  Divider as MuiDivider,
  Menu,
  MenuItem,
  Checkbox,
  Box,
  Button,
  TablePagination,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import ReadMore from "../../../../components/Models/ReadMore";
import NotFound from "../../../../components/NotFound/NotFound";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import styled from "styled-components/macro";
import { useStyles } from "../Styles/style";
import { spacing } from "@mui/system";
import FaqModel from "./Models/FaqModel";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const Paper = styled(MuiPaper)(spacing);

const FaqTable = ({
  isLoading,
  viewPermission,
  deletePermission,
  editPermission,
  addPermission,
  BootstrapTooltip,
  faqsData,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  handleGetFaqsTableData,
  handleDisplayLocationName,
  modelQuestionText,
  setModelQuestionText,
  handleDisplayNames,
  companyData,
  userData,
  filtersCounterValue,
  setFilterCounterValue,
  isTotalQFilterClicked,
  isNoOwnerAnswerClicked,
  withoutAnswerClicked,
  noQuestions,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openFaqModel, setOpenFaqModel] = useState(false);
  const [deleteTask, setDeleteTask] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [questionText, setQuestionText] = useState("");
  const [showFullQuestionText, setShowFullQuestionText] = useState(false);
  const [openReadQuestionModal, setOpenReadQuestionModal] =
    React.useState(false);
  const [locationName, setLocationName] = useState("");
  const [createdAtQuestion, setCreatedAtQuestion] = useState("");

  const [answerText, setAnswerText] = useState("");
  const [showFullAnswerText, setShowFullAnswerText] = useState(false);
  const [openReadAnswerModal, setOpenReadAnswerModal] = useState(false);
  const [ownerAnswerCount, setOwnerAnswerCount] = useState(null);
  const [questionUserType, setQuestionUserType] = useState(null);
  const [questionAuthName, setQuestionAuthName] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const slicedQuestion = showFullQuestionText ? questionText : "";
  const slicedAnswer = showFullAnswerText ? answerText : "";

  const handleOpenFaqModel = (
    id,
    questionText,
    locationName,
    ownerAnswer,
    createdAtQuestion,
    QuestionUtype,
    QuestionAuthorName
  ) => {
    setOpenFaqModel(true);
    setQuestionId(id);
    setModelQuestionText(questionText);
    setLocationName(locationName);
    setOwnerAnswerCount(ownerAnswer);
    setCreatedAtQuestion(createdAtQuestion);
    setQuestionUserType(QuestionUtype);
    setQuestionAuthName(QuestionAuthorName);
  };
  const handleCloseFaqModel = () => {
    setOpenFaqModel(false);
    setQuestionText("");
    handleGetFaqsTableData();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, id, selectedData) => {
    setAnchorEl(event.currentTarget);
    setDeleteTask(id);
    setSelectedRow(selectedData);
  };

  const handleDeleteTask = async (id) => {
    setDeleteLoading(true);
    try {
      const res = await api.delete(`/questions/${id}`);
      if (res.status === 200) {
        setOpen(false);
        closeMenu(false);
        setDeleteTask(null);
        toast.success("Deleted successfully");
        setDeleteLoading(false);
        handleGetFaqsTableData();
      }
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  const handleOpenModel = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const closeMenu = () => {
    // setAnchorMenu(null);
  };

  const handleClose = () => {
    // setOpenModal(false);
    setOpen(false);
    setAnchorEl(null);
  };

  const handleSeeMoreQuestion = (rowQuestion) => {
    setQuestionText(rowQuestion);
    setShowFullQuestionText(true);
    setOpenReadQuestionModal(true);
  };

  const handleSeeMoreAnswer = (rowAnswer) => {
    setAnswerText(rowAnswer);
    setShowFullAnswerText(true);
    setOpenReadAnswerModal(true);
  };

  const handleCloseReadQuestionMore = () => {
    setOpenReadQuestionModal(false);
  };
  const handleCloseReadAnswerMore = () => {
    setOpenReadAnswerModal(false);
  };

  return (
    <>
      {openFaqModel && (
        <FaqModel
          // title={handleDisplayNames(locationName)}
          title={t("answerAquestion")}
          open={openFaqModel}
          handleCloseNewRequestModel={handleCloseFaqModel}
          questionId={questionId}
          handleDisplayLocationName={handleDisplayLocationName}
          modelQuestionText={modelQuestionText}
          viewPermission={viewPermission}
          deletePermission={deletePermission}
          editPermission={editPermission}
          addPermission={addPermission}
          handleGetFaqsTableData={handleGetFaqsTableData}
          locationName={locationName}
          companyData={companyData}
          userData={userData}
          ownerAnswerCount={ownerAnswerCount}
          handleDisplayNames={handleDisplayNames}
          createdAtQuestion={createdAtQuestion}
          questionUserType={questionUserType}
          questionAuthName={questionAuthName}
        />
      )}
      {openReadQuestionModal && (
        <Dialog
          // fullScreen={fullScreen}
          open={openReadQuestionModal}
          onClose={handleCloseReadQuestionMore}
          aria-labelledby="responsive-dialog-title"
        >
          <ReadMore
            title={t("question")}
            description={slicedQuestion}
            onCancel={handleCloseReadQuestionMore}
          />
        </Dialog>
      )}

      {openReadAnswerModal && (
        <Dialog
          // fullScreen={fullScreen}
          open={openReadAnswerModal}
          onClose={handleCloseReadAnswerMore}
          aria-labelledby="responsive-dialog-title"
        >
          <ReadMore
            title={t("answer")}
            description={slicedAnswer}
            onCancel={handleCloseReadAnswerMore}
          />
        </Dialog>
      )}
      {isLoading ? (
        <Loader />
      ) : faqsData?.rows?.length > 0 && !noQuestions ? (
        <>
          <Grid container spacing={8} sx={{ marginTop: "1rem" }}>
            <TableContainer
              borderRadius="12"
              component={Paper}
              marginLeft="2rem"
            >
              <Table aria-label="simple table">
                <CustomizedTableHead variant="h1">
                  <CustomizedTableRow>
                    {/* <CustomizedTableCell align="start">
                      <Checkbox
                      checked={selectAll}
                      onChange={(e) => handleChangeCheckButton(e)}
                      />
                    </CustomizedTableCell> */}
                    <CustomizedTableCell align="start">
                      {t("IDD")}
                    </CustomizedTableCell>
                    <CustomizedTableCell>
                      {t("Location_Name")}
                    </CustomizedTableCell>
                    <CustomizedTableCell>{t("updated_at")}</CustomizedTableCell>
                    <CustomizedTableCell>{t("questions")}</CustomizedTableCell>
                    <CustomizedTableCell>{t("answers")}</CustomizedTableCell>

                    <CustomizedTableCell>{t("ansCount")}</CustomizedTableCell>
                    <CustomizedTableCell>{t("ownerAns")}</CustomizedTableCell>
                    <CustomizedTableCell align="center">
                      {t("Actionss")}
                    </CustomizedTableCell>
                  </CustomizedTableRow>
                </CustomizedTableHead>
                <TableBody>
                  {faqsData?.rows?.map((row, index) => (
                    <CustomizedTableRow>
                      {/* <CustomizedTableCell hover>
                        <Checkbox
                          checked={deleteTask.includes(row.id)}
                          onChange={(e) => {
                            handleTaskChecked(e, index, row.id);
                          }}
                          label=""
                        />
                      </CustomizedTableCell> */}
                      <CustomizedTableCell
                        hover
                        align="start"
                        sx={{ width: "50px" }}
                      >
                        {row?.id !== null ? row?.id : "--"}
                      </CustomizedTableCell>
                      <CustomizedTableCell sx={{ width: "220px" }}>
                        {row?.location !== null &&
                        row?.location !== "" &&
                        row?.location !== undefined
                          ? row?.location?.name
                          : "--"}
                      </CustomizedTableCell>
                      <CustomizedTableCell sx={{ width: "115px" }}>
                        {row?.googleUpdateTime !== null &&
                        row?.googleUpdateTime !== "" &&
                        row?.googleUpdateTime !== undefined
                          ? moment(row?.googleUpdateTime).format("MMM DD YYYY")
                          : "--"}
                      </CustomizedTableCell>
                      {/* <CustomizedTableCell
                        align="left"
                        sx={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          width: "250px",
                        }}
                      >
                        {row?.questionText !== null &&
                        row?.questionText !== "" &&
                        row?.questionText !== undefined
                          ? row?.questionText
                          : "--"}
                      </CustomizedTableCell> */}

                      <CustomizedTableCell
                        align="left"
                        sx={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          width: "250px",
                        }}
                      >
                        <div>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {row?.userType === "Owner"
                              ? row?.location !== null &&
                                row?.location !== "" &&
                                row?.location !== undefined
                                ? row?.location?.name
                                : handleDisplayLocationName(row?.locationId)
                              : row?.userType === "Local Guide"
                              ? row?.authorName
                              : null}

                            {row?.userType === "Local Guide" ? (
                              <span
                                style={{ color: "#F86F35", marginLeft: "10px" }}
                                className={classes.typoOwner}
                              >
                                {t("local_guide")}
                              </span>
                            ) : row?.userType === "Owner" ? (
                              <span
                                style={{ color: "#06BDFF", marginLeft: "10px" }}
                                className={classes.typoOwner}
                              >
                                {t("owner")}
                              </span>
                            ) : null}
                          </Typography>
                        </div>

                        {row?.questionText !== null &&
                        row?.questionText !== "" &&
                        row?.questionText !== undefined ? (
                          row?.questionText.length <= 70 ? (
                            row?.questionText
                          ) : (
                            <>
                              {row?.questionText.slice(0, 70)}
                              <span
                                style={{
                                  color: "#06BDFF",
                                  cursor: "pointer",
                                  fontSize: "12px",
                                }}
                                // onClick={() =>
                                // handleSeeMoreQuestion(row?.questionText)

                                // }
                                onClick={() => {
                                  handleOpenFaqModel(
                                    row?.id,
                                    row?.questionText,
                                    row?.location?.id,
                                    row?.ownerAnswer,
                                    row?.googleUpdateTime,
                                    row?.userType,
                                    row?.authorName
                                  );
                                }}
                              >
                                {` ${t("Read_more")}...`}
                              </span>
                            </>
                          )
                        ) : (
                          "--"
                        )}
                      </CustomizedTableCell>
                      <CustomizedTableCell
                        align="left"
                        sx={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          width: "250px",
                        }}
                      >
                        {/* <div>
                          <Typography sx={{ color: "rgb(117, 117, 117)" }}>
                            {row?.userType === "Owner"
                              ? handleDisplayLocationName(row?.locationId)
                              : row?.userType === "Local Guide"
                              ? row?.authorName
                              : null}

                            {row?.userType === "Local Guide" ? (
                              <span
                                style={{ color: "#F86F35", marginLeft: "10px" }}
                                className={classes.typoOwner}
                              >
                                {t("local_guide")}
                              </span>
                            ) : row?.userType === "Owner" ? (
                              <span
                                style={{ color: "#06BDFF", marginLeft: "10px" }}
                                className={classes.typoOwner}
                              >
                                {t("owner")}
                              </span>
                            ) : null}
                          </Typography>
                        </div> */}
                        {row?.topAnswer !== null &&
                        row?.topAnswer !== "" &&
                        row?.topAnswer !== undefined ? (
                          row?.topAnswer.length <= 70 ? (
                            row?.topAnswer
                          ) : (
                            <>
                              {row?.topAnswer.slice(0, 70)}
                              <span
                                style={{
                                  color: "#06BDFF",
                                  cursor: "pointer",
                                  fontSize: "12px",
                                }}
                                // onClick={() =>
                                //   handleSeeMoreAnswer(row?.topAnswer)
                                // }

                                onClick={() => {
                                  handleOpenFaqModel(
                                    row?.id,
                                    row?.questionText,
                                    row?.location?.id,
                                    row?.ownerAnswer,
                                    row?.googleUpdateTime,
                                    row?.userType,
                                    row?.authorName
                                  );
                                }}
                              >
                                {` ${t("Read_more")}...`}
                              </span>
                            </>
                          )
                        ) : (
                          "--"
                        )}
                      </CustomizedTableCell>
                      <CustomizedTableCell sx={{ width: "110px" }}>
                        {row?.totalAnswerCount}
                      </CustomizedTableCell>
                      <CustomizedTableCell sx={{ width: "115px" }}>
                        {row?.ownerAnswer === 0 ? (
                          <CloseIcon sx={{ color: "#FF1616" }} />
                        ) : row?.ownerAnswer > 0 ? (
                          <CheckIcon sx={{ color: "#13CF8F" }} />
                        ) : (
                          row?.ownerAnswer
                        )}
                      </CustomizedTableCell>

                      <CustomizedTableCell align="center">
                        <Button
                          onClick={() => {
                            handleOpenFaqModel(
                              row?.id,
                              row?.questionText,
                              row?.location?.id,
                              row?.ownerAnswer,
                              row?.googleUpdateTime,
                              row?.userType,
                              row?.authorName
                            );
                          }}
                          // className={classes.compareFilterButton}
                        >
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            {t("answers")}
                          </Typography>
                        </Button>
                        <IconButton
                          onClick={(e) => handleClick(e, row?.id, row)}
                          variant="outlined"
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          {viewPermission && deletePermission ? (
                            selectedRow?.userType === "Local Guide" ? (
                              <BootstrapTooltip
                                title={t("local_guide_delete_tooltip")}
                              >
                                <span>
                                  <MenuItem
                                    onClick={() => {
                                      closeMenu();

                                      handleOpenModel(row?.id);
                                    }}
                                    disabled={
                                      selectedRow?.userType === "Local Guide"
                                        ? true
                                        : false
                                    }
                                  >
                                    <DeleteIcon
                                      sx={{
                                        color: "#545353",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    <Typography
                                      aria-label="delete"
                                      size="large"
                                    >
                                      {t("Delete")}
                                    </Typography>
                                  </MenuItem>
                                </span>
                              </BootstrapTooltip>
                            ) : (
                              <MenuItem
                                onClick={() => {
                                  closeMenu();

                                  handleOpenModel(row?.id);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: "#545353",
                                    paddingRight: "5px",
                                  }}
                                />
                                <Typography aria-label="delete" size="large">
                                  {t("Delete")}
                                </Typography>
                              </MenuItem>
                            )
                          ) : (
                            <BootstrapTooltip title={t("authorized_access")}>
                              <span>
                                <MenuItem disabled>
                                  <DeleteIcon
                                    sx={{
                                      color: "#545353",
                                      paddingRight: "5px",
                                    }}
                                  />
                                  <Typography aria-label="delete" size="large">
                                    {t("Delete")}
                                  </Typography>
                                </MenuItem>
                              </span>
                            </BootstrapTooltip>
                          )}
                        </Menu>
                      </CustomizedTableCell>
                    </CustomizedTableRow>
                  ))}
                </TableBody>
                {open && (
                  <Dialog
                    //fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <Delete
                      title={t("Delete_QA")}
                      description={t("confirm_delete_qa")}
                      onConfirm={() => handleDeleteTask(deleteTask)}
                      onCancel={handleClose}
                      loading={deleteLoading}
                    />
                  </Dialog>
                )}
              </Table>
            </TableContainer>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={
              isTotalQFilterClicked
                ? faqsData?.count
                : isNoOwnerAnswerClicked
                ? faqsData?.withoutOwnerAnswerCount
                : withoutAnswerClicked
                ? faqsData?.withNoAnswerCount
                : faqsData?.count
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Box sx={{ marginTop: "3rem", marginInline: "auto" }}>
          <NotFound text={t("No_faqs_found")} />
        </Box>
      )}
    </>
  );
};

export default FaqTable;
