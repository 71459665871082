import React, { useState, useEffect } from "react";
import EditModal from "../../Modal";

import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Stack,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import NotFound from "../../../../../../components/NotFound/NotFound";
import { LazyLoadImage } from "react-lazy-load-image-component";

import noImageGray from "../../../../../../assets/images/noImageGray.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
  titleName: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#1B2430",
  },
  headName: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
  },
  headNameDesc: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#06BDFF",
    display: "column",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  tagsText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  tagsItem: {
    background: "#EBEBEC",
    minWidth: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "700px",
    flexWrap: "nowrap",
  },
  viewMoreButton: {
    fontWeight: 700,
    fontSize: "14px",
    textAlign: "start",
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
function LocationImages({
  locationDetailsData,
  location,
  getLocationDetails,
  singleLocation,
  googleConnect,
  getLocationById,
  locationCategory,
  setSingleLocationLoading,
  getLocationDetailsListing,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [images, setImages] = useState([]);
  const [logoImages, setLogoImages] = useState([]);
  const [sqaureImages, setSqaureImages] = useState([]);
  const [coverImages, setCoverImages] = useState([]);
  const [loadImg, setLoadImg] = useState(false);
  const [dataCount, setDataCount] = useState(5);

  const loadMoreData = () => {
    setDataCount(images?.length);
  };
  const loadLessData = () => {
    setDataCount(5);
  };

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };
  useEffect(() => {
    if (locationDetailsData !== null) {
      const filtered = locationDetailsData?.result.google?.media?.filter(
        (item) =>
          item?.isDeleted === false &&
          (item?.category === "TEAMS" ||
            item?.category === "ADDITIONAL" ||
            item?.category === "INTERIOR" ||
            item?.category === "EXTERIOR")
      );
      setImages(filtered);
      const filteredTwo = locationDetailsData?.result.google?.media?.map(
        (item) => {
          if (item?.isDeleted === false) {
            if (item?.category === "LOGO") {
              setLogoImages([item]);
            } else if (item?.category === "SQUARED_LOGO") {
              setSqaureImages([item]);
            } else if (item?.category === "COVER") {
              setCoverImages([item]);
            }
          }
        }
      );
    }
    return () => {
      setLogoImages([]);
      setSqaureImages([]);
      setCoverImages([]);
      setImages([]);
    };
  }, [locationDetailsData]);

  return (
    <>
      {locationDetailsData !== null && (
        <>
          {openDefaultModel && (
            <Dialog
              maxWidth
              maxHeight
              open={openDefaultModel}
              // onClose={handleCloseTemplateMenu}
            >
              <EditModal
                modalIndex={11}
                location={location}
                locationDetailsData={locationDetailsData}
                onCancel={handleCloseTemplateMenu}
                getLocationDetails={getLocationDetails}
                setSingleLocationLoading={setSingleLocationLoading}
                getLocationById={getLocationById}
                singleLocation={singleLocation}
                locationCategory={locationCategory}
                getLocationDetailsListing={getLocationDetailsListing}
                lodgingCategories={lodgingCategories}
                categoryLoading={categoryLoading}
                centerData={centerData}
                displayMapError={displayMapError}
              />
            </Dialog>
          )}
          <Card
            variant="outlined"
            sx={{
              bgcolor: "#ffff",
              borderRadius: "8px",
              height: "auto",
              padding: "10px",
            }}
          >
            <Grid
              container
              spacing={1}
              // sx={{ maxHeight: "250px", height: "250px", overflow: "auto" }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={2} sx={{ padding: "16px" }}>
                  <Grid item xs={8} sm={8} md={9} lg={9}>
                    <Typography className={classes.titleName}>
                      {t("cover_images_uberAll")}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sm={4} md={3} lg={3}>
                    {editPermission && viewPermission ? (
                      <Button fullWidth onClick={handleOpenTemplateMenu}>
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("Edit")}
                        </Typography>
                      </Button>
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <Button
                            disabled
                            fullWidth
                            sx={{ cursor: "pointer" }}
                            onClick={handleOpenTemplateMenu}
                          >
                            <Typography sx={{ fontWeight: 600 }}>
                              {t("Edit")}
                            </Typography>
                          </Button>
                        </span>
                      </BootstrapTooltip>
                    )}
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    maxHeight:
                      logoImages?.length === 0 &&
                      sqaureImages?.length === 0 &&
                      coverImages?.length === 0 &&
                      images?.length === 0
                        ? "0px"
                        : dataCount > 5
                        ? "200px"
                        : "175px",
                    height: "auto",
                    overflowY: "auto",
                  }}
                >
                  <Grid container spacing={2} sx={{ padding: "6px" }}>
                    {logoImages?.length > 0 && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        style={{
                          // maxHeight: 660,
                          // height: "412px",
                          width: "80px",
                          height: "80px",
                        }}
                      >
                        <LazyLoadImage
                          loading="lazy"
                          src={loadImg ? noImageGray : logoImages[0]?.sourceUrl}
                          beforeLoad={handleBeforeLOad}
                          afterLoad={handleAfterLOad}
                          style={{
                            // maxHeight: 660,
                            // height: "412px",
                            width: "80px",
                            height: "80px",
                            borderRadius: "8px",
                            padding: "3px",
                          }}
                          PlaceholderSrc={noImageGray}
                          alt="Image Alt"
                          effect="blur"
                        />
                      </Grid>
                    )}
                    {sqaureImages?.length > 0 && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        style={{
                          // maxHeight: 660,
                          // height: "412px",
                          width: "80px",
                          height: "80px",
                        }}
                      >
                        <LazyLoadImage
                          loading="lazy"
                          src={
                            loadImg ? noImageGray : sqaureImages[0]?.sourceUrl
                          }
                          beforeLoad={handleBeforeLOad}
                          afterLoad={handleAfterLOad}
                          style={{
                            // maxHeight: 660,
                            // height: "412px",
                            width: "80px",
                            height: "80px",
                            borderRadius: "8px",
                            padding: "3px",
                          }}
                          PlaceholderSrc={noImageGray}
                          alt="Image Alt"
                          effect="blur"
                        />
                      </Grid>
                    )}
                    {coverImages?.length > 0 && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        style={{
                          // maxHeight: 660,
                          // height: "412px",
                          width: "80px",
                          height: "80px",
                        }}
                      >
                        <LazyLoadImage
                          loading="lazy"
                          src={
                            loadImg ? noImageGray : coverImages[0]?.sourceUrl
                          }
                          beforeLoad={handleBeforeLOad}
                          afterLoad={handleAfterLOad}
                          style={{
                            // maxHeight: 660,
                            // height: "412px",
                            width: "80px",
                            height: "80px",
                            borderRadius: "8px",
                            padding: "3px",
                          }}
                          PlaceholderSrc={noImageGray}
                          alt="Image Alt"
                          effect="blur"
                        />
                      </Grid>
                    )}
                    {images?.slice(0, dataCount).map((item, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        style={{
                          // maxHeight: 660,
                          // height: "412px",
                          width: "80px",
                          height: "80px",
                        }}
                      >
                        <LazyLoadImage
                          loading="lazy"
                          src={loadImg ? noImageGray : item?.sourceUrl}
                          beforeLoad={handleBeforeLOad}
                          afterLoad={handleAfterLOad}
                          style={{
                            // maxHeight: 660,
                            // height: "412px",
                            width: "80px",
                            height: "80px",
                            borderRadius: "8px",
                            padding: "3px",
                          }}
                          PlaceholderSrc={noImageGray}
                          alt="Image Alt"
                          effect="blur"
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            {images?.length > 5 ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ alignItems: "start", padding: "12px" }}
                >
                  {dataCount <= 5 ? (
                    <Button onClick={loadMoreData}>
                      <Typography className={classes.viewMoreButton}>
                        {t("View_More")}
                      </Typography>
                    </Button>
                  ) : (
                    <Button onClick={loadLessData}>
                      <Typography className={classes.viewMoreButton}>
                        {t("View_Less")}
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Card>
        </>
      )}
    </>
  );
}
export default LocationImages;
