import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { api } from "../../../../../contexts/JWTContext";
import { useParams } from "react-router-dom";
import Loader from "../../../../../components/Loaders/Loader";
import { Helmet } from "react-helmet";
const QRCodeCampaign = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCampaignByQRID();
  }, []);

  const getCampaignByQRID = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`campaign/${id}`);
      if (res.status === 200) {
        const data = res?.data?.data;
        i18n.changeLanguage(data?.language);
        //   setCampaignDataQR(data);
        const devQueryParam =
          process.env.REACT_APP_NODE_ENV === "development" ||
          process.env.REACT_APP_NODE_ENV === "beta"
            ? "?dev=true"
            : "";
        window.location.replace(
          `${process.env.REACT_APP_LANDING_BASE_URL}/${data?.language}/campaign-qr/${id}${devQueryParam}`
        );
        setIsLoading(false);

        // Update document title
        document.title = data?.campaignLocations[0]?.location?.internalName;
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /*   const handleUpdateReachPositive = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `/campaign/updateCampaignReach/${null}?campaignId=${id}&state=1&isScannedByQRCode=true`
      );
      if (res.status === 200) {
        window.location.replace(res?.data?.data?.url);
        // setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleUpdateReachNegative = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `/campaign/updateCampaignReach/${null}?campaignId=${id}&state=0&isScannedByQRCode=true`
      );
      if (res.status === 200) {
        window.location.replace(res?.data?.data?.url);
        // setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }; */

  /*   const handleNavigation = () => {
    const devQueryParam =
      process.env.REACT_APP_NODE_ENV === "development" ||
      process.env.REACT_APP_NODE_ENV === "beta"
        ? "?dev=true"
        : "";
    window.open(
      `${process.env.REACT_APP_LANDING_BASE_URL}/${campaignDataQR?.language}/private-survey/${id}${devQueryParam}`,
      {
        state: {
          isScannedByQRCode: true,
        },
      }
    );
  }; */

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          type="image/x-icon"
          href={`${process.env.REACT_APP_BASE_URL}/empty-favicon.ico`}
        />
        <script src="..." id="id_1" />
      </Helmet>

      {!isLoading && (
        <Box sx={{ marginTop: { xs: "75%", sm: "50%", md: "22%" } }}>
          <Loader />
        </Box>
        /*  <Grid
          container
          sx={{
            width: "50%",
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <img style={{ width: "800px", height: "500px" }} src={Logo} />

          <Typography sx={{ fontWeight: 700, fontSize: "30px" }}>
            Redirecting, please wait...
          </Typography>
        </Grid> */
      )}

      {/*  {isLoading ? (
        <Box sx={{ marginTop: { xs: "75%", sm: "50%", md: "22%" } }}>
          <Loader />
        </Box>
      ) : (
        <Grid
          sx={{
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            marginTop: "80px",

            display: "flex",
          }}
        >
          <Grid item xs={9} sm={9} md={9} lg={9}>
          
            <Box
              // className={classes.mainStep2Box}
              sx={{ margin: "auto", marginTop: "10px" }}
            >
              <Divider variant="middle" />
              <LazyLoadImage
                loading="lazy"
                src={
                  loadImg
                    ? noImageGray
                    : campaignDataQR?.campaignEmail?.image_url
                }
                beforeLoad={handleBeforeLoad}
                afterLoad={handleAfterLOad}
                style={{
                  width: "100%",
                  height: { xs: "150px", sm: "250px", md: "400px" },
                  marginTop: "16px",
                  borderRadius: "8px",
                }}
                PlaceholderSrc={noImageGray}
                alt="Image Alt"
                effect="blur"
              />
              <Typography className={classes.stepLocation1}>
                {campaignDataQR?.campaignEmail?.heading}
              </Typography>
              <Typography
                className={classes.stepLocationSubhead1}
                sx={{ paddingY: "21px" }}
              >
                {campaignDataQR?.campaignEmail?.description}
              </Typography>
              <Grid
                container
                item
                gap={1}
                sx={{
                  // paddingY: "21px",
                  // padding: { xs: "10px", md: "0px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                  lg={1.5}
                  sx={{
                    // height: "122px",
                    cursor: "pointer",
                  }}
                  onClick={handleUpdateReachPositive}
                >
                  <Box className={classes.emojiBox1}>
                    <div style={{ height: "79px" }}>
                      <LazyLoadImage
                        loading="lazy"
                        src={Face1}
                        className={classes.socialBoxImage}
                        PlaceholderSrc={blurImage}
                        alt="Image Alt"
                        effect="blur"
                      />
                    </div>
                    <Typography className={classes.emojiBoxText}>
                      {t("Excellent")}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                  lg={1.5}
                  sx={{ height: "122px", cursor: "pointer" }}
                  onClick={handleUpdateReachPositive}
                >
                  <Box className={classes.emojiBox2}>
                  
                    <div style={{ height: "79px" }}>
                      <LazyLoadImage
                        loading="lazy"
                        src={Face2}
                        className={classes.socialBoxImage}
                        PlaceholderSrc={blurImage}
                        alt="Image Alt"
                        effect="blur"
                      />
                    </div>

                    <Typography className={classes.emojiBoxText}>
                      {t("Good")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                  lg={1.5}
                  sx={{
                    // height: "122px",
                    // marginTop: { xs: "7px", md: "0px" },
                    cursor: "pointer",
                  }}
                  onClick={
                    campaignDataQR?.negative_feedback_type === "private-survey"
                      ? handleNavigation
                      : handleUpdateReachNegative
                  }
                >
                  <Box className={classes.emojiBox3}>
                    <div style={{ height: "79px" }}>
                      <LazyLoadImage
                        loading="lazy"
                        src={Face4}
                        className={classes.socialBoxImage}
                        PlaceholderSrc={blurImage}
                        alt="Image Alt"
                        effect="blur"
                      />
                    </div>

                    <Typography className={classes.emojiBoxText}>
                      {t("Okay")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                  lg={1.5}
                  sx={{
                    // height: "122px",
                    // marginTop: { xs: "7px", md: "0px" },
                    cursor: "pointer",
                  }}
                  onClick={
                    campaignDataQR?.negative_feedback_type === "private-survey"
                      ? handleNavigation
                      : handleUpdateReachNegative
                  }
                >
                  <Box className={classes.emojiBox4}>
                   
                    <div style={{ height: "79px" }}>
                      <LazyLoadImage
                        loading="lazy"
                        src={Face3}
                        className={classes.socialBoxImage}
                        PlaceholderSrc={blurImage}
                        alt="Image Alt"
                        effect="blur"
                      />
                    </div>

                    <Typography className={classes.emojiBoxText}>
                      {t("Bad")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Typography
                className={classes.stepLocation1}
                sx={{ paddingY: "21px" }}
                mb={5}
              >
                {t("Thanks_for_your_feedback")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )} */}
    </>
  );
};

export default QRCodeCampaign;
