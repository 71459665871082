import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import { ReactComponent as GoogleIcon } from "../../../../assets/google.png";
import { api } from "../../../../../contexts/JWTContext";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import Delete from "../../../../../components/Models/DeleteModal/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const useStyles = makeStyles((theme) => ({
  Mainbox: {
    padding: "24px",
  },
  connectBox: {
    borderRadius: "8px",
    backgroundColor: "#F6F6F7",
    padding: "24px",
    marginBottom: "8px",
  },
  subscriptionContainer: {
    marginTop: "20px",
  },
  UnsubscribeButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "114px",
    height: "48px",
    background: "#FC3652",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    "&:hover ": {
      background: "#b02639",
      borderRadius: "8px",
      color: "#FFFFFF",
    },
  },
  subscribeButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "94px",
    height: "40px",
    background: "#06BDFF",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    "&:hover ": {
      background: "#06BDFF",
      borderRadius: "8px",
      color: "#FFFFFF",
    },
  },
  reconnectButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "114px",
    height: "48px",
    background: "#06BDFF",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    marginRight: "10px",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    "&:hover ": {
      background: "#06BDFF",
      borderRadius: "8px",
      color: "#FFFFFF",
    },
  },
}));
const AccountsCard = ({
  socialLink,
  id,
  placeId,
  getLocationById,
  setIsLoading,
  location,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    socialLink?.map((link) => {
      if (link?.isConnected === true) {
        setSelected(true);
        setSelectedName(link?.type);
      }
    });
  }, []);
  async function fetchURL(id, locationId) {
    try {
      const res = await api.get(`google/auth-url`);
      if (res.data?.status === true) {
        window.location.href = res?.data.data + "&state=" + id;
      }
    } catch (error) {}

    // if (response?.data?.url) {
    //   window.location.href = response.data.url;
    // }
  }
  async function disconnectSocialLink() {
    setIsLoading(true);
    try {
      const res = await api.delete(`/socialLink/${socialLink[0]?.id}`);

      setOpen(false);
      if (res.status === 200) {
        setIsLoading(false);
        getLocationById(id);
        toast.success(t("Disconnected_Successfully"));
      }
    } catch (error) {
      setOpen(false);
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    }
  }

  const handleOpenConfirmBox = () => {
    setOpen(true);
  };
  const handleCloseConfirmBox = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item sm={12} md={12} lg={12}>
        <Box className={classes.connectBox}>
          <Grid container>
            <Grid item sm={1} md={1} lg={1}>
              <img
                style={{ width: 50, height: 50 }}
                src={`${baseURL}/assets/static/review_source/google.png`}
              />
            </Grid>
            <Grid item sm={7} md={7} lg={7}>
              <Typography
                sx={{
                  color: "#1B2430",
                  fontSize: "18px",
                  fontWeight: 700,
                }}
              >
                {selected
                  ? t("Connected_with_Google")
                  : t("Connect_With_Google_Text")}
              </Typography>
              <Typography
                sx={{
                  color: "#495059",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {t("Google_access_dec")}
              </Typography>
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {selected ? (
                  <>
                    <Button
                      onClick={() => fetchURL(`${id}/${placeId}`, id)}
                      className={classes.reconnectButton}
                    >
                      {/*   {t("Confirm")} */}
                      {t("Reconnect")}
                    </Button>
                    <Button
                      onClick={() => handleOpenConfirmBox()}
                      className={classes.UnsubscribeButton}
                    >
                      {/*   {t("Confirm")} */}
                      {t("Disconnect")}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => fetchURL(`${id}/${placeId}`, id)}
                    className={classes.subscribeButton}
                  >
                    {/*   {t("Confirm")} */}
                    {t("Connect")}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/*    <Grid sx={{ pr: 2 }}>
          <img
            style={{ width: 40, height: 40 }}
            src={`${baseURL}/assets/static/review_source/google.png`}
          />
        </Grid>
        <Grid>
          {selected ? (
            <Typo name="connected" variant="h6" />
          ) : (
            <Typo name="connect" variant="h6" />
          )}
        </Grid> */}

        {/* <Grid>
          <Box sx={{ float: "right" }}>
            <KeyboardArrowRightIcon sx={{ color: "#8D9298" }} />
          </Box>
        </Grid> */}
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseConfirmBox}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Disconnect_title")}
          description={t("Disconnect_sub")}
          onConfirm={() => disconnectSocialLink()}
          onCancel={handleCloseConfirmBox}
        />
      </Dialog>
    </>
  );
};

export default AccountsCard;
