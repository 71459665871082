import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, DialogContentText } from "@mui/material";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import { Card } from "@mui/material";
const baseURL = process.env.REACT_APP_BASE_URL;

const SubscriptionCard = ({
  item,
  result,
  handleSubscriptionClose,
  confirmSubscription,
  subscriptionArr,
  setSubscriptionArr,
  updateSubscriptionHandler,
   addPermission,
                      editPermission,
                      deletePermission,
                      viewPermission,
  /*  confirmSubscription,
  setConfirmSubscription, */
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (location?.state !==null) {
      if (
        location?.state?.showProduct===true &&
        location?.state?.displayOn===true &&
        result===undefined
      ) {
        setChecked(true);
      } else {
        if (result !==undefined && result?.status === "active") {
          setChecked(true);
        } else {
          setChecked(false);
        }
      }
    } else {
      if (result===undefined && location?.search.length > 0) {
        setChecked(true);
      } else if (result !==undefined && result?.status === "active") {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, []);
  useEffect(() => {
    if (location?.state !==null) {
      if (
        location?.state?.showProduct===true &&
        location?.state?.displayOn===true &&
        result===undefined
      ) {
        setChecked(true);
      } else {
        if (result !==undefined && result?.status === "active") {
          setChecked(true);
        } else {
          setChecked(false);
        }
      }
    } else {
      if (result===undefined && location?.search.length > 0) {
        setChecked(true);
      } else if (result !==undefined && result?.status === "active") {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [item, result]);

  const handleSubscription = (e, id) => {
    setChecked(e.target.checked);
    let arr = [];
    if (e.target.checked) {
      subscriptionArr.push(id);
    } else {
      var ind = subscriptionArr.indexOf(id);
      subscriptionArr.splice(ind, 1);
    }
    setSubscriptionArr([...subscriptionArr]);
  };

  return (
    <>
      <Box className="box_delete">
        <Dialog
          open={confirmSubscription}
          onClose={handleSubscriptionClose}
          aria-labelledby="responsive-dialog-title"
        >
          <div>
            <Box className="box_delete">
              <DialogContentText
                id="responsive-dialog-title"
                className="delete_dialog_content1"
              >
                {"Update Subscription"}
              </DialogContentText>

              <DialogContentText
                id="responsive-dialog-title"
                className="delete_dialog_content2"
              >
                {` ${t("Are_you_sure_you_want_to")}  update subscription ?`}

                {/* {result !==undefined ? "Unsubscribe?" : "Subscribe?"} */}
              </DialogContentText>

              <DialogActions>
                <Button
                  className="delete_button"
                  autoFocus
                  onClick={handleSubscriptionClose}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  onClick={updateSubscriptionHandler}
                  className="delete_button"
                  autoFocus
                >
                  {t("Confirm")}
                </Button>
              </DialogActions>
            </Box>
          </div>
        </Dialog>
      </Box>

      <Card
        variant="outlined"
        sx={{
          minHeight: "68px",
          width: "auto",
          marginBottom: "8px",
          padding: "10px",
          borderRadius: "8px",
          cursor: "pointer",
        }}
        inputProps={{ "aria-label": "controlled" }}
      >

        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <img src={`${baseURL}/${item?.logoUrl}`} alt="icon" />
          </Grid>
          <Grid item xs={9.5} sm={9.5} md={9.5} lg={9.5}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ padding: "4px" }}
              >
                <Typography sx={{ color: "#1B2430", fontSize: "14px" }}>
                  {item?.displayName}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ padding: "4px" }}
              >
                <Typography
                  sx={{ color: "#495059", fontSize: "14px", fontWeight: "400" }}
                >
                  {item?.discription}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Switch
              checked={checked}
              onChange={(e) => handleSubscription(e, item?.id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default SubscriptionCard;
