import React, { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Box,
  TextField,
  Card,
  Checkbox,
  Avatar,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Dialog } from "@material-ui/core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import CoverImageModal from "./CoverImageModal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
// import noImageGray from "../../../../../../assets/images/noImageGray.png";
import noImageGray from "../../../../../../../assets/images/noImageGray.png";
import CoverImageModal from "../../CoverImages/Modals/CoverImageModal";

const LocalPages = ({
  location,
  setTouchedValue,
  isLoading,
  locationDetailsData,
  touchedValue,
  displayConfirmationBox,
  setDisplayConfirmationBox,
  getLocationDetails,
  handleUpdateCoverImages,
  onCancel,
  indexValue,
  setSelectedTab,
  handleAdd,
  handleDelete,
  handleChangeLanguage,
  handleChangeDescription,
  handleImportDescription,
  handleOpenDeleteModel,
  languagesDataArray,
  disableAddDescription,
  alreadyAddedImages,
  setAlreadyAddedImages,
  imagesShowData,
  setImagesShowData,
  handleDeleteImage,
  handleImagesShow,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openImagesModal, setOpenImagesModal] = useState(false);
  const [imagesUploadData, setImagesUploadData] = useState([]);
  const [loadImg, setLoadImg] = useState(false);
  // const [alreadyAddedImages, setAlreadyAddedImages] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;

  const [cancelState, setCancelState] = useState(false);

  const classes = useStyles();

  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      //   onCancel();
      //   getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      //   onCancel();
      //   getLocationDetails(location);
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpenModal = () => {
    setOpenImagesModal(true);
  };

  const handleClosePictureModal = () => {
    setOpenImagesModal(false);
  };

  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };

  return (
    <>
      <Grid container className={classes.customFieldMain}>
        {isLoading ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.loaderBox}
            >
              <Loader />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box className={classes.coverImagesBox}>
                <Typography className={classes.modalHeader}>
                  {t("upload_images")}
                </Typography>
                <Typography className={classes.modalSubheader}>
                  {t("sub_head_local_pages")}
                </Typography>
              </Box>
              {/* </Grid> */}
              {(imagesShowData?.length === 0 || imagesShowData?.length < 5) && (
                <Box className={classes.boxBtnCoverImg}>
                  <CommonButton
                    label={t("Browse_File")}
                    onSubmit={handleOpenModal}
                  />
                </Box>
              )}

              <Grid container className={classes.GridBox}>
                {imagesShowData?.length > 0 &&
                  imagesShowData?.map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        className={classes.gridImgContainer}
                      >
                        {/* <img src={item} width={"150px"} /> */}

                        {/* <LazyLoadImage
                          loading="lazy"
                          src={loadImg ? noImageGray : item}
                          beforeLoad={handleBeforeLOad}
                          afterLoad={handleAfterLOad}
                          style={{
                            borderRadius: "8px",
                            // maxHeight: 660,
                            // height: "412px",
                            width: "150px",
                          }}
                          PlaceholderSrc={noImageGray}
                          alt="Image Alt"
                          effect="blur"
                        /> */}

                        {/* <Avatar
                          variant="square"
                          sx={{
                            width: "150px",
                            height: "150px",
                            marginY: "20px",
                            borderRadius: "8px",
                          }}
                          alt="Remy Sharp"
                          src={item}
                        /> */}
                        <IconButton
                          className={classes.imgIconsBtn}
                          onClick={() => handleDeleteImage(index)}
                        >
                          <CloseOutlinedIcon
                            fontSize="small"
                            className={classes.closeBtn}
                          />
                        </IconButton>
                        <Avatar variant="square" className={classes.imgAvatar}>
                          <LazyLoadImage
                            loading="lazy"
                            src={loadImg ? noImageGray : item}
                            beforeLoad={handleBeforeLOad}
                            afterLoad={handleAfterLOad}
                            style={{
                              // maxHeight: 660,
                              // height: "412px",
                              height: "150px",
                              width: "150px",
                            }}
                            PlaceholderSrc={noImageGray}
                            alt="Image Alt"
                            effect="blur"
                          />
                        </Avatar>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <CoverImageModal
        locationId={location.id}
        handleClose={handleClosePictureModal}
        open={openImagesModal}
        loading={isLoading}
        setImagesUploadData={setImagesUploadData}
        imagesUploadData={imagesUploadData}
        handleImagesShow={handleImagesShow}
        setTouchedValue={setTouchedValue}
        alreadyAddedImages={alreadyAddedImages}
        setAlreadyAddedImages={setAlreadyAddedImages}
      />
    </>
  );
};

export default LocalPages;
