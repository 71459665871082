import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import NoLocation from "../../assets/images/nolocationfull.svg"
import NoLocation from "../../../assets/images/nolocationfull.svg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  iconCard: {
    border: "1px solid #E0E0E0",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-around",
    width: "40px",
    height: "40px",
    padding: "8px",
  },
  iconContainer: {
    padding: "20px 0px 10px 0px",
  },
  iconStyle: {
    width: "20px",
    height: "18px",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
  },
  locationsubhead: {
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "16px",
    color: "#495059",
  },
  locationhead: {
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "32px",
    color: "#1B2430",
  },
  Imagesection: {
    marginTop: "8px",
    height: "300px",
  },
  Imagesection2: {
    marginTop: "8px",
    height: "250px",
  },
}));
const LocationNotFound = ({ text, subtext }) => {
  const classes = useStyles();
  return (
    <Box className={classes.locationbox}>
      <img className={classes.Imagesection} src={NoLocation} />

      <Typography className={classes.locationhead}>{text}</Typography>
      <Typography className={classes.locationsubhead}>{subtext}</Typography>
    </Box>
  );
};

export default LocationNotFound;
