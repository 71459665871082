import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography,
  Stack,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../Styles/style";
import CropImgModalStep2 from "../../Modal/CropImgModalStep2";
import Face1 from "../../../../../../assets/images/excellent.svg";
import Face2 from "../../../../../../assets/images/good.svg";
import Face3 from "../../../../../../assets/images/ok.svg";
import Face4 from "../../../../../../assets/images/bad.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import noImageGray from "../../../../../../assets/images/noImageGray.png";
import blurImage from "../../../../../../assets/placeholder.png";
// import ProfilePictureModal from "../../Modal/PictureModal";
import ProfilePictureModalStep2 from "../../Modal/PictureModalStep2";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const Item = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  paddingY: theme.spacing(1),
  // textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Step2 = ({
  stepperData,
  setStepperData,
  imageUpload,
  setImageUpload,

  id,
  surveyImageDisplayStepThree,
  setSurveyImageDisplayStepThree,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const inputFileRef = React.useRef();
  const [openPictureModal, setOpenPictureModal] = useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loadImg, setLoadImg] = useState(false);
  const [imGUploaded, setImgUploadded] = useState();
  const [openCropModal, setOpenCropModal] = useState(false);
  const [cropModalImage, setCropModalImage] = useState();
  const [uploadImgData, setUploadImgData] = useState({
    image_url: stepperData?.emailTemplateData?.image_url
      ? stepperData?.emailTemplateData?.image_url
      : "",
    heading: stepperData?.emailTemplateData?.heading,
    description: stepperData?.emailTemplateData?.description,
  });

  const CommonButtons = ({ onClick, children }) => {
    return (
      <button
        style={{
          height: "52px",
          padding: "14px 20px",
          fontSize: "14px",
          marginLeft: "10px",
          borderRadius: "8px",
          color: "#FFF",
          background: "#06BDFF",
          width: "auto",
          border: "none",
          cursor: "pointer",
        }}
        type="button"
        onClick={onClick}
      >
        {children}
      </button>
    );
  };
  useEffect(() => {
    if (id !== undefined) {
      setImageUpload(1);
      /*   setStepperData({
        ...stepperData,
        survey_email: stepperData?.survey_email,
        custom_positive_url: stepperData?.custom_positive_url,
        platform: stepperData?.platform,
        negative_feedback_type: stepperData?.negative_feedback_type,
        language: stepperData?.language,
        campaign_type: stepperData?.campaign_type,
        locationIds: stepperData?.locationIds,
        custom_negative_url: stepperData?.custom_negative_url,
        emailTemplateData: {
          image_url: emailUrl?.image_url,
        },

        status: stepperData?.status,
        campaignSurveyData: {
          image_url: stepperData?.campaignSurveyData?.image_url,
          heading: stepperData?.campaignSurveyData?.heading,
          description: stepperData?.campaignSurveyData?.description,
        },
      }); */
    }
  }, []);

  /*  useEffect(() => {
    setStepperData({
      ...stepperData,

      emailTemplateData: {
        heading: "How was your experience at",
        description: "Your opinion matters to us, thanks!",
      },
    });
  }, []); */
  const onFileChangeCapture = (e) => {
    if (e.target.files.length < 1 || e.target.files === "") {
      //  setPictureRequired(true);
    } else {
      // setPictureRequired(false);
    }
    setImageUpload(e.target.files.length);
    let tempImages = [];
    for (const [key, value] of Object.entries(e.target.files)) {
      if (key !== "length") {
        tempImages.push({
          picture: value,
          picturePreview: URL.createObjectURL(value),
        });
      }
    }

    setSelectedImages(tempImages);

    if (e.target.files[0]) {
      const objectURL = URL.createObjectURL(e.target.files[0]);

      setImgUploadded(e.target.files[0]);
      setCropModalImage(objectURL);
    }
    setOpenCropModal(true);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files) {
      let tempImages = [];
      for (const [key, value] of Object.entries(e.dataTransfer.files)) {
        if (key !== "length") {
          tempImages.push({
            picture: value,
            picturePreview: URL.createObjectURL(value),
          });
        }
      }

      setSelectedImages(tempImages);
      setImageUpload(1);

      if (e.dataTransfer.files[0]) {
        // setPictureRequired(false);
        const objectURL = URL.createObjectURL(e.dataTransfer.files[0]);

        setUploadImgData({
          ...uploadImgData,
          image_url: objectURL,
        });
        setImgUploadded(e.dataTransfer.files[0]);
        setCropModalImage(objectURL);
      }
    }
    setOpenCropModal(true);
  };

  const handleCloseEdit = () => {
    setStepperData({
      ...stepperData,
      emailTemplateData: {
        image_url: "",
        heading: stepperData?.emailTemplateData?.heading,
        description: stepperData?.emailTemplateData?.description,
      },
    });
  };

  const closeCropModal = () => {
    setOpenCropModal(false);
  };

  const handleOpenPictureModal = () => {
    setOpenPictureModal(true);
  };
  const handleCloseNavigate = () => {
    setOpenPictureModal(false);
  };
  const handleClosePictureModal = () => {
    setOpenPictureModal(false);
  };
  const handleImageData = (data) => {
    // setImageUploadData(data);
  };

  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };
  return (
    <>
      {openPictureModal && (
        <ProfilePictureModalStep2
          handleClose={handleClosePictureModal}
          open={openPictureModal}
          handleCloseNavigate={handleCloseNavigate}
          stepperData={stepperData}
          setStepperData={setStepperData}
          handleImageData={handleImageData}
          setOpenPictureModal={setOpenPictureModal}
          setDragActive={setDragActive}
          dragActive={dragActive}
          handleDrop={handleDrop}
          handleDrag={handleDrag}
          onFileChangeCapture={onFileChangeCapture}
          setSelectedImages={setSelectedImages}
          selectedImages={selectedImages}
          setImageUpload={setImageUpload}
          surveyImageDisplayStepThree={surveyImageDisplayStepThree}
          setSurveyImageDisplayStepThree={setSurveyImageDisplayStepThree}
        />
      )}
      <Grid container component={Paper} sx={{ padding: "16px" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box sx={{ paddingY: "26px" }}>
            <Typography className={classes.stepLocation}>
              {t("Preview_Your_Email")}
            </Typography>
            <Typography className={classes.stepLocationSubhead}>
              {t("Preview_Your_Email_subhead")}
            </Typography>
          </Box>

          <Box className={classes.mainStep2Box}>
            <Grid
              container
              sx={{
                paddingY: "21px",
                padding: { xs: "10px", md: "0px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ padding: "24px", position: "relative", height: "450px" }}
              >
                {stepperData?.emailTemplateData?.image_url === undefined ||
                stepperData?.emailTemplateData?.image_url === "" ? (
                  <>
                    <Stack direction="column">
                      <Item>
                        <Box
                          sx={{
                            p: 2,
                            // /    border: "2px dashed #E0E0E0", /
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "450px",
                            borderWidth: "2px",
                            borderRadius: "1rem",
                            borderStyle: "dashed",
                            borderColor: "#cbd5e1",

                            // height: "70vh",
                          }}
                        >
                          <form
                            className={classes.formUpload}
                            onDragEnter={handleDrag}
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <label className={classes.lableFileUpload}>
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <AddAPhotoOutlinedIcon
                                    sx={{
                                      height: "79.38px",
                                      width: "91.67px",
                                      cursor: "pointer",
                                      color: "#8D9298",
                                    }}
                                  />

                                  <Typography
                                    sx={{
                                      fontSize: "1rem",
                                      marginTop: "8px",
                                      fontWeight: "400",
                                      color: "#495059",
                                    }}
                                  >
                                    {t("Uploading_Images_Text")}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      marginTop: "15px",
                                    }}
                                  >
                                    <CommonButtons
                                      sx={{
                                        height: "15px",
                                      }}
                                      onClick={() =>
                                        inputFileRef.current.click()
                                      }
                                    >
                                      {t("Browse_Image")}
                                    </CommonButtons>
                                  </Typography>
                                </Box>
                              </>
                            </label>
                            <input
                              id="file"
                              type="file"
                              accept="image/*"
                              multiple={true}
                              ref={inputFileRef}
                              onChange={onFileChangeCapture}
                              style={{ display: "none" }}
                            />

                            {dragActive && (
                              <div
                                className={classes.dragFile}
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              ></div>
                            )}
                          </form>
                        </Box>
                      </Item>
                    </Stack>
                  </>
                ) : (
                  <>
                    <>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <LazyLoadImage
                          loading="lazy"
                          src={
                            loadImg ? noImageGray : surveyImageDisplayStepThree
                          }
                          beforeLoad={handleBeforeLOad}
                          afterLoad={handleAfterLOad}
                          style={{
                            width: "100%",
                            height: "450px",
                            borderRadius: "8px",
                          }}
                          PlaceholderSrc={noImageGray}
                          alt="Image Alt"
                          effect="blur"
                        />
                        {/* IconButton with absolute positioning */}
                        {loadImg === false ? (
                          <IconButton
                            className={classes.imageCrossIcon2}
                            onClick={() => handleCloseEdit()}
                          >
                            <CloseOutlinedIcon
                              fontSize="small"
                              sx={{ color: "#FFFF" }}
                            />
                          </IconButton>
                        ) : null}
                      </div>
                    </>
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={10.5}
                md={10.5}
                lg={10.5}
                sx={{ marginTop: "40px" }}
              >
                <Typography className={classes.stepLocation1}>
                  {stepperData?.emailTemplateData?.heading}
                  <EditIcon
                    style={{
                      color: "#06BDFF",
                      fontSize: "25px",
                      cursor: "pointer",
                      margin: "0px 15px",
                    }}
                    onClick={handleOpenPictureModal}
                  />
                </Typography>
                <Typography
                  className={classes.stepLocationSubhead1}
                  sx={{ paddingY: "21px" }}
                >
                  {`${stepperData?.emailTemplateData?.description}`}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                paddingY: "21px",
                padding: { xs: "10px", md: "0px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={6} sm={6} md={2} lg={1.5} sx={{ height: "122px" }}>
                <Box className={classes.emojiBox1}>
                  {/*   <img src={Face1} className={classes.socialBoxImage} /> */}
                  <div style={{ height: "79px" }}>
                    <LazyLoadImage
                      loading="lazy"
                      src={Face1}
                      className={classes.socialBoxImage}
                      PlaceholderSrc={blurImage}
                      alt="Image Alt"
                      effect="blur"
                    />
                  </div>

                  <Typography className={classes.emojiBoxText}>
                    {t("Excellent")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={2} lg={1.5} sx={{ height: "122px" }}>
                <Box className={classes.emojiBox2}>
                  {/*   <img src={Face4} className={classes.socialBoxImage} /> */}
                  <div style={{ height: "79px" }}>
                    <LazyLoadImage
                      loading="lazy"
                      src={Face2}
                      className={classes.socialBoxImage}
                      PlaceholderSrc={blurImage}
                      alt="Image Alt"
                      effect="blur"
                    />
                  </div>

                  <Typography className={classes.emojiBoxText}>
                    {t("Good")}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={2}
                lg={1.5}
                sx={{ height: "122px", marginTop: { xs: "7px", md: "0px" } }}
              >
                <Box className={classes.emojiBox3}>
                  {/*   <img src={Face2} className={classes.socialBoxImage} /> */}
                  <div style={{ height: "79px" }}>
                    <LazyLoadImage
                      loading="lazy"
                      src={Face4}
                      className={classes.socialBoxImage}
                      PlaceholderSrc={blurImage}
                      alt="Image Alt"
                      effect="blur"
                    />
                  </div>

                  <Typography className={classes.emojiBoxText}>
                    {t("Okay")}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={2}
                lg={1.5}
                sx={{ height: "122px", marginTop: { xs: "7px", md: "0px" } }}
              >
                <Box className={classes.emojiBox4}>
                  {/*   <img src={Face3} className={classes.socialBoxImage} /> */}
                  <div style={{ height: "79px" }}>
                    <LazyLoadImage
                      loading="lazy"
                      src={Face3}
                      className={classes.socialBoxImage}
                      PlaceholderSrc={blurImage}
                      alt="Image Alt"
                      effect="blur"
                    />
                  </div>

                  <Typography className={classes.emojiBoxText}>
                    {t("Bad")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {/* <Box className={classes.emojiRating}>
              <img src={ExcellentImg} className={classes.emojiSize} />

              <img src={GoodImg} className={classes.emojiSize} />

              <img src={OkImg} className={classes.emojiSize} />

              <img src={BadImg} className={classes.emojiSize} />
            </Box> */}
            <Typography
              className={classes.stepLocation1}
              sx={{ paddingY: "21px" }}
            >
              {t("Thanks_for_your_feedback")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <CropImgModalStep2
        open={openCropModal}
        onClose={closeCropModal}
        uploadImgData={uploadImgData}
        setUploadImgData={setUploadImgData}
        setOpenCropModal={setOpenCropModal}
        cropModalImage={cropModalImage}
        imGUploaded={imGUploaded}
        setStepperData={setStepperData}
        stepperData={stepperData}
        setOpenPictureModal={setOpenPictureModal}
        setSurveyImageDisplayStepThree={setSurveyImageDisplayStepThree}
      />
    </>
  );
};

export default Step2;
