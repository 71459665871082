import React from "react";
import { Typography, Grid, TextField, Modal, IconButton } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Autocomplete, Box } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerComponent from "../../../../components/CustomComponents/DateRangePicker/RangePicker";

const styleEditUser = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "95vh",
  width: "800px",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainerHeader: {
    width: "100%",
    justifyContent: "space-between",
  },
  mainContainerHeading: {
    fontWeight: 700,
    fontSize: "20px",
  },
  fieldLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    marginLeft: "10px",
    marginTop: "6px",
  },
  buttonContainer: {
    justifyContent: "flex-end",
    alignItems: "end",
    textAlign: "end",
    paddingTop: "8px",
  },
  whiteBackground: {
    background: "white",
    width: "98%",
  },
}));

const EmotionAiFilterModal = ({
  handleCloseModalFilter,
  openCompareModel,
  resetHandler,
  handleChangeStartDate,
  handleChangeEndDate,
  startDate,
  endDate,
  dateRequired,
  handleChangeRange,
  range,
  dateValues,
  compareDateValues,
  handleChangeNextStartDate,
  handleChangeNextEndDate,
  compareRange,
  handleChangeCompareRange,
  nextStartDate,
  nextEndDate,
  setCallApi,
  dateRange,
  setDateRange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Modal
      open={openCompareModel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container>
        <Grid item>
          <Box sx={styleEditUser}>
            <Grid container lg={12} className={classes.mainContainer}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.mainContainerHeader}
              ></Grid>
            </Grid>

            <Box sx={{ display: "flex" }}>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <Typography className={classes.mainContainerHeading}>
                  {t("Comparison_Range")}
                </Typography>
              </Grid>

              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                sx={{ justifyContent: "flex-end", textAlign: "end" }}
              >
                <IconButton
                  autoFocus
                  onClick={handleCloseModalFilter}
                  className="delete_button"
                  sx={{ paddingTop: "0px" }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Box>

            <Divider my={6} />
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.fieldLabel}>
                  {t("Datee")}
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={dateValues}
                  getOptionLabel={(option) => option?.label}
                  sx={{ width: "100%", padding: "8px" }}
                  //  value={locationId}
                  value={range}
                  onChange={(e, value) => {
                    setCallApi(false);
                    handleChangeRange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select Range")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>

              {range !== null &&
                (range?.value === "gt" || range?.value === "lt") && (
                  <Grid
                    container
                    spacing={2}
                    sx={{ paddingTop: "8px", marginLeft: "0px" }}
                  >
                    {range !== null && range?.value === "gt" && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className={classes.fieldLabel}>
                          {t("Fromm")}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            minDate={dayjs("2005-01-01")}
                            maxDate={dayjs().endOf("day")}
                            className={classes.whiteBackground}
                            disableFuture
                            placeholder={t("From")}
                            value={startDate !== null ? dayjs(startDate) : null}
                            onChange={(e) => {
                              setCallApi(false);
                              if (range?.value === "between") {
                                handleChangeStartDate(e);
                              } else {
                                handleChangeStartDate(e);
                                const today = new Date();
                                handleChangeEndDate(today);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                autoComplete="off"
                                fullWidth
                                {...params}
                                sx={{ padding: "8px", background: "#FFFF" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    )}

                    {range !== null && range?.value === "lt" && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className={classes.fieldLabel}>
                          {t("Too")}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            minDate={dayjs("2005-01-01")}
                            maxDate={dayjs().endOf("day")}
                            className={classes.whiteBackground}
                            disableFuture
                            value={endDate !== null ? dayjs(endDate) : null}
                            // maxDate={endDate}
                            // minDate={new Date("2005-01-01")}
                            onChange={(e) => {
                              setCallApi(false);
                              if (range?.value === "between") {
                                handleChangeEndDate(e.endOf("day"));
                              } else {
                                handleChangeEndDate(e.endOf("day"));
                                const today = new Date();
                                const previousYear = new Date(
                                  today.getFullYear() - 1,
                                  today.getMonth(),
                                  today.getDate()
                                );
                                handleChangeStartDate(previousYear);
                              }

                              //   setEndDate(e);
                            }}
                            placeholder={t("To")}
                            renderInput={(params) => (
                              <TextField
                                autoComplete="off"
                                fullWidth
                                {...params}
                                //  label={t("To")}
                                sx={{ padding: "8px", background: "#FFFF" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    )}
                  </Grid>
                )}
            </Grid>
            {range !== null && range?.value === "between" && (
              <DatePickerComponent
                handleChangeStartDate={handleChangeStartDate}
                handleChangeEndDate={handleChangeEndDate}
                startDate={startDate}
                endDate={endDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                fromFilters={true}
                rangeValue={"between"}
                removePadding={false}
              />
            )}

            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.fieldLabel}>
                  {t("Compare_to")}
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={compareDateValues}
                  getOptionLabel={(option) => option?.label}
                  sx={{ width: "100%", padding: "8px" }}
                  //  value={locationId}
                  value={compareRange}
                  onChange={(e, value) => {
                    setCallApi(false);
                    handleChangeCompareRange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select Range")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>

              {compareRange !== null &&
                (compareRange?.value === "gt" ||
                  compareRange?.value === "lt") && (
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginLeft: "0px", paddingTop: "8px" }}
                  >
                    {compareRange !== null && compareRange?.value === "gt" && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className={classes.fieldLabel}>
                          {t("Fromm")}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            disableFuture
                            //  disabled={range !== null && range?.value === "gt"}
                            placeholder={t("From")}
                            value={
                              nextStartDate !== null
                                ? dayjs(nextStartDate)
                                : null
                            }
                            format="DD/MM/YYYY"
                            // maxDate={endDate}
                            minDate={dayjs("2005-01-01")}
                            maxDate={dayjs().endOf("day")}
                            className={classes.whiteBackground}
                            onChange={(e) => {
                              setCallApi(false);
                              if (compareRange?.value === "between") {
                                handleChangeNextStartDate(e);
                              } else {
                                handleChangeNextStartDate(e);
                                const today = new Date();
                                //  handleChangeEndDate(today);
                              }
                              if (compareRange?.value === "gt") {
                                var milliseconds = moment.duration(
                                  moment(endDate).diff(startDate),
                                  "milliseconds"
                                );
                                const newEndDate =
                                  moment(nextEndDate).add(milliseconds);
                                const newDate = new Date(newEndDate);

                                handleChangeNextEndDate(newDate);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                autoComplete="off"
                                fullWidth
                                {...params}
                                //  label={t("From")}
                                sx={{ padding: "8px", background: "#FFFF" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    )}

                    {compareRange !== null && compareRange?.value === "lt" && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className={classes.fieldLabel}>
                          {t("Too")}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            disableFuture
                            value={
                              nextEndDate !== null ? dayjs(nextEndDate) : null
                            }
                            // maxDate={endDate}
                            minDate={dayjs("2005-01-01")}
                            maxDate={dayjs().endOf("day")}
                            className={classes.whiteBackground}
                            format="DD/MM/YYYY"
                            onChange={(e) => {
                              setCallApi(false);
                              if (compareRange?.value === "between") {
                                handleChangeNextEndDate(e);
                              } else if (compareRange?.value === "lt") {
                                var milliseconds = moment.duration(
                                  moment(endDate).diff(startDate),
                                  "milliseconds"
                                );
                                const newStartDate =
                                  moment(startDate).subtract(milliseconds);
                                const newDate = new Date(newStartDate);
                                handleChangeNextStartDate(newDate);
                              }
                              handleChangeNextEndDate(e);
                              const today = new Date();
                              const previousYear = new Date(
                                today.getFullYear() - 1,
                                today.getMonth(),
                                today.getDate()
                              );
                              //  handleChnage(previousYear);
                            }}
                            placeholder={t("To")}
                            renderInput={(params) => (
                              <TextField
                                autoComplete="off"
                                fullWidth
                                {...params}
                                //label={t("To")}
                                sx={{ padding: "8px", background: "#FFFF" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    )}
                  </Grid>
                )}
              {compareRange !== null && compareRange?.value === "between" && (
                <DatePickerComponent
                  handleChangeStartDate={handleChangeNextStartDate}
                  handleChangeEndDate={handleChangeNextEndDate}
                  startDate={nextStartDate}
                  endDate={nextEndDate}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  fromFilters={true}
                  rangeValue={"between"}
                  removePadding={false}
                />
              )}

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "8px" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.buttonContainer}
                >
                  <CommonButton
                    onSubmit={resetHandler}
                    label={t("Clear_Filters")}
                    leftMargin={true}
                    customHeight={true}
                    displayWhite={"true"}
                  />
                  &nbsp;&nbsp;
                  <CommonButton
                    type="submit"
                    //  isLoading={loading}
                    disabled={dateRequired}
                    onSubmit={() => {
                      handleCloseModalFilter();
                      setCallApi(true);
                    }}
                    label={t("Apply_filters")}
                    leftMargin={true}
                    customHeight={true}
                  />
                  {/*   <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      handleCloseModalFilter();
                      setCallApi(true);
                    }}
                    disabled={dateRequired}
                    sx={{
                      height: "52px",
                      width: "88px",
                      fontSize: "14px",
                      marginLeft: "4px",
                      borderRadius: "8px",
                      color: "#FFFFFF",
                      border: "none",
                    }}
                  >
                    {t("apply_label")}
                  </Button> */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default EmotionAiFilterModal;
