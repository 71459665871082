import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Card,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import { api } from "../../../../../../../contexts/JWTContext";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Facebook from "../../../../../../../assets/images/facebookround.svg";
import Google from "../../../../../../../assets/images/googleround.svg";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/Check";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const CompanyDetailsModal = ({
  onCancel,
  data,
  locationDetailsData,
  location,
  locationCategory,
  locationFacebookCategory,
  getLocationDetails,
  getLocationDetailsListing,
  facebookConnect,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setLocationDetailsData,
  indexValue,
  setSelectedTab,
  selectedTab,
  getLocationById,
  setIndexValue,
  categoryLoading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
  const [fieldValue, setFieldValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFacebookCategory, setSelectedFacebookCategory] =
    useState(null);
  const [additionalCategories, setAddtionalCategories] = useState([]);
  const [categoryRequired, setCategoryRequired] = useState(false);
  const [facebookCategoryRequired, setFacebookCategoryRequired] =
    useState(false);
  const [faceBookConnected, setFacebookConnected] = useState(null);
  const [cancelState, setCancelState] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [limitError, setLimitError] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const classes = useStyles();
  const handleCategoryChange = (option) => {
    setTouchedValue(true);
    setSelectedCategory(option);
  };
  const handleFacebookCategoryChange = (option) => {
    setTouchedValue(true);
    setSelectedFacebookCategory(option);
  };

  useEffect(() => {
    if (selectedCategory !== null) {
    }
    const selectedOptions = [selectedCategory, ...additionalCategories];
    const updatedOptions =
      locationCategory?.length > 0 &&
      locationCategory[0]?.google.filter(
        (option) =>
          !selectedOptions.some(
            (selectedOption) => selectedOption?.name === option?.name
          )
      );
    setFilteredOptions(updatedOptions);
  }, [selectedCategory, additionalCategories, locationCategory]);

  useEffect(() => {
    if (location?.categoryChangeAt !== null) {
      const date1 = new Date(location?.categoryChangeAt);
      const date2 = new Date();
      const differenceInMilliseconds = Math.abs(date2 - date1);

      // Convert milliseconds to minutes
      const differenceInMinutes = (
        differenceInMilliseconds /
        (1000 * 60)
      ).toFixed(2);

      if (parseInt(differenceInMinutes) >= 15) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }, []);
  useEffect(() => {
    if (location?.categoryChangeAt !== null) {
      const date1 = new Date(location?.categoryChangeAt);
      const date2 = new Date();
      const differenceInMilliseconds = Math.abs(date2 - date1);

      // Convert milliseconds to minutes
      const differenceInMinutes = (
        differenceInMilliseconds /
        (1000 * 60)
      ).toFixed(2);

      if (parseInt(differenceInMinutes) >= 15) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }, [location, locationCategory]);
  useEffect(
    (e) => {
      if (selectedCategory?.length === 10) {
        setLimitError(true);
      } else {
        setLimitError(false);
      }
    },
    [additionalCategories]
  );

  useEffect(() => {
    if (locationCategory !== null) {
      let arr = [];
      locationDetailsData?.result?.google?.categories?.additionalCategories &&
        locationDetailsData?.result?.google?.categories?.additionalCategories?.map(
          (item) => {
            locationCategory[0]?.google?.map((itemTwo) => {
              if (item?.name === itemTwo?.name) {
                arr.push(itemTwo);
              }
            });
          }
        );
      setAddtionalCategories(arr);
      if (
        locationCategory?.length > 0 &&
        locationCategory[0]?.google?.length > 0
      ) {
        const selectedCategory =
          locationCategory?.length > 0 &&
          locationCategory[0]?.google?.find(
            (item) =>
              item?.name ===
              locationDetailsData?.result?.google?.categories?.primaryCategory
                ?.name
          );
        setSelectedCategory(selectedCategory);
      }
    }

    if (locationDetailsData?.result?.facebook?.category_list?.length > 0) {
      setSelectedFacebookCategory(
        locationDetailsData?.result?.facebook?.category_list[0]
      );
    }
    const filtered = location?.socialLink?.find(
      (item) => item?.type === "facebook"
    );
    if (filtered !== undefined) {
      setFacebookConnected(filtered);
    }
  }, []);
  useEffect(() => {
    if (locationCategory !== null) {
      let arr = [];
      locationDetailsData?.result?.google?.categories?.additionalCategories &&
        locationDetailsData?.result?.google?.categories?.additionalCategories?.map(
          (item) => {
            locationCategory[0]?.google?.map((itemTwo) => {
              if (item?.name === itemTwo?.name) {
                arr.push(itemTwo);
              }
            });
          }
        );
      setAddtionalCategories(arr);
      if (
        locationCategory?.length > 0 &&
        locationCategory[0]?.google?.length > 0
      ) {
        const selectedCategory =
          locationCategory?.length > 0 &&
          locationCategory[0]?.google?.find(
            (item) =>
              item?.name ===
              locationDetailsData?.result?.google?.categories?.primaryCategory
                ?.name
          );
        setSelectedCategory(selectedCategory);
      }
    }

    if (locationDetailsData?.result?.facebook?.category_list?.length > 0) {
      for (
        let i = 0;
        i < locationDetailsData.result.facebook.category_list.length;
        i++
      ) {
        if (locationDetailsData.result.facebook.category_list[i] !== null) {
          setSelectedFacebookCategory(
            locationDetailsData.result.facebook.category_list[i]
          );
          // return locationDetailsData.result.facebook.category_list[i].name;
        }
      }
    }
    const filtered = location?.socialLink?.find(
      (item) => item?.type === "facebook"
    );
    if (filtered !== undefined) {
      setFacebookConnected(filtered);
    }
  }, [locationCategory]);

  const handleUpdateCategories = async () => {
    setTouchedValue(false);

    if (location?.enableFacebook === true && faceBookConnected !== null) {
      if (selectedCategory === null) {
        setCategoryRequired(true);
      }
      if (selectedFacebookCategory === null) {
        setFacebookCategoryRequired(true);
      }
      if (
        disableButton ||
        selectedCategory == null ||
        selectedFacebookCategory == null ||
        additionalCategories?.length >= 10
      ) {
        setDisplayConfirmationBox(false);
        setIndexValue(null);
      } else {
        if (selectedCategory !== null && selectedFacebookCategory !== null) {
          try {
            const body = {
              google: {
                categories: {
                  primaryCategory: {
                    name: selectedCategory?.name,
                    displayName: selectedCategory?.displayName,
                  },
                  additionalCategories,
                },
              },
              facebook: {
                category_list: [
                  {
                    id:
                      selectedFacebookCategory !== null
                        ? selectedFacebookCategory?.id
                        : "",
                    name:
                      selectedFacebookCategory !== null
                        ? selectedFacebookCategory?.name
                        : "",
                  },
                ],
              },
            };

            setDisplayConfirmationBox(false);
            setIsLoading(true);
            setDisplayConfirmationBox(false);
            const res = await api.patch(
              `locationListing/update-all-categories/${location?.id}`,
              body
            );
            if (indexValue === null) {
            } else {
              let selectedValue = indexValue;

              setSelectedTab(selectedValue);
            }

            if (cancelState === true) {
              onCancel();
              getLocationDetails(location);
            }
            if (res.status === 200) {
              setIsLoading(false);
              toast.success("Updated Successfully");
              // onCancel();
              setTouchedValue(false);
              setIsLoading(false);
              getLocationDetailsListing(location);
              getLocationById(location);
            }
          } catch (error) {
            toast.error(error?.response?.data?.message);
            setIsLoading(false);
          }
        }
      }
    } else {
      if (selectedCategory === null) {
        setCategoryRequired(true);
      }
      if (
        disableButton ||
        selectedCategory == null ||
        additionalCategories?.length >= 10
      ) {
        setDisplayConfirmationBox(false);
        setIndexValue(null);
      } else {
        if (selectedCategory !== null) {
          try {
            const body = {
              google: {
                categories: {
                  primaryCategory: {
                    name: selectedCategory?.name,
                    displayName: selectedCategory?.displayName,
                  },
                  additionalCategories,
                },
              },
            };
            setDisplayConfirmationBox(false);
            setIsLoading(true);
            setDisplayConfirmationBox(false);
            const res = await api.patch(
              `locationListing/update-all-categories/${location?.id}`,
              body
            );
            if (indexValue === null) {
            } else {
              let selectedValue = indexValue;

              setSelectedTab(selectedValue);
            }
            if (cancelState === true) {
              onCancel();
              // setTouchedValue(false);
              getLocationDetails(location);
            }
            if (res.status === 200) {
              setTouchedValue(false);
              setIsLoading(false);
              toast.success("Updated Successfully");
              //    onCancel();
              getLocationDetailsListing(location);
              getLocationById(location?.id);
            }
          } catch (error) {
            toast.error(error?.response?.data?.message);
            setIsLoading(false);
          }
        }
      }
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      getLocationDetails(location);
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };

  const handleAdditionalCategoryChange = (option) => {
    setAddtionalCategories(option);
    setTouchedValue(true);
  };

  const customFilterToSearchCategory = (options, { inputValue }) => {
    const startsWithInput = options.filter((option) =>
      option?.displayName?.toLowerCase().startsWith(inputValue?.toLowerCase())
    );

    const containsInput = options.filter(
      (option) =>
        option?.displayName
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) &&
        !option?.displayName
          ?.toLowerCase()
          .startsWith(inputValue?.toLowerCase())
    );

    return [...startsWithInput, ...containsInput];
  };

  return (
    <>
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateCategories}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            getLocationDetails={getLocationDetails}
            location={location}

            //  loading={setIsLoading}
          />
        </Dialog>
      )}
      <Grid container direction="column" className={classes.gridContainerone}>
        <Grid container sx={{ padding: "18px" }}>
          <Grid item xs={10} sm={10} md={11.5} lg={11.5}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.modalHeader}>
                {t("Categories")}
              </Typography>
              <BootstrapTooltip title={t("Primary_Category_subHeading")}>
                <Typography
                  sx={{
                    marginLeft: "5px",
                    marginTop: "4px",
                    cursor: "pointer",
                  }}
                >
                  <InfoOutlinedIcon />
                </Typography>
              </BootstrapTooltip>
            </Box>
          </Grid>
          <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
            <IconButton
              autoFocus
              onClick={() => {
                handleOpen();
                setCancelState(true);
              }}
              className="delete_button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid container className={classes.gridContainertwo}>
          {isLoading ? (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.loaderBox}
              >
                <Loader />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container sx={{ padding: "18px" }}>
                  <Grid item md={12} sm={12} xs={12} lg={12}>
                    <Box className={classes.categoriesSocialBox}>
                      <img src={Google} />
                      <Typography className={classes.fieldTextSocial}>
                        {t("Google")}{" "}
                        <span style={{ color: "#FC3652" }}>*</span>
                      </Typography>
                      <BootstrapTooltip
                        title={
                          <h1 style={{ fontSize: "12px" }}>
                            {t("disabled_tooltip_category")}
                          </h1>
                        }
                      >
                        <ErrorOutlineIcon
                          sx={{
                            color: "#1B2430",
                          }}
                        />
                      </BootstrapTooltip>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        padding: "10px",
                        borderRadius: "8px",
                        marginTop: "12px",
                      }}
                    >
                      <Grid container sx={{ padding: "0px" }}>
                        {" "}
                        <Grid
                          item
                          md={categoryLoading ? 11 : 12}
                          sm={categoryLoading ? 11 : 12}
                          xs={categoryLoading ? 11.2 : 12}
                          lg={categoryLoading ? 11.2 : 12}
                        >
                          <Box className={classes.categoriesSocialBox}>
                            <Typography className={classes.fieldTextCategories}>
                              {t("Primary_Category")}
                            </Typography>
                          </Box>
                          <Autocomplete
                            disablePortal
                            error={categoryRequired}
                            id="combo-box-demo"
                            options={
                              filteredOptions?.length > 0 ? filteredOptions : []
                            }
                            getOptionLabel={(option) =>
                              option?.displayName ? option?.displayName : ""
                            }
                            filterOptions={customFilterToSearchCategory}
                            sx={{ width: "100%" }}
                            value={selectedCategory}
                            onChange={(e, value) => {
                              handleCategoryChange(value);
                              if (value === null) {
                                setCategoryRequired(true);
                              } else {
                                setCategoryRequired(false);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                // required
                                name="locationPrimarycategory"
                                placeholder={t("Select_primary_category")}
                                error={categoryRequired}
                                helperText={
                                  categoryRequired &&
                                  t("Primary_Category_Error")
                                }
                              />
                            )}
                          ></Autocomplete>
                        </Grid>
                        {categoryLoading && (
                          <Grid
                            item
                            xs={1}
                            sm={1}
                            md={0.8}
                            lg={0.8}
                            sx={{ textAlign: "center", paddingTop: "20px" }}
                          >
                            <Box sx={{ marginTop: "15px" }}>
                              <CircularProgress
                                color="secondary"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#06BDFF",
                                }}
                              />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container sx={{ padding: "0px" }}>
                        {" "}
                        <Grid
                          item
                          md={categoryLoading ? 11 : 12}
                          sm={categoryLoading ? 11 : 12}
                          xs={categoryLoading ? 11.2 : 12}
                          lg={categoryLoading ? 11.2 : 12}
                          sx={{ marginTop: "12px" }}
                        >
                          <Box className={classes.categoriesSocialBox}>
                            <Typography className={classes.fieldTextCategories}>
                              {t("Additional_Categories")}
                            </Typography>
                          </Box>

                          <Autocomplete
                            multiple
                            disablePortal
                            id="combo-box-demo"
                            disableCloseOnSelect
                            options={
                              filteredOptions?.length > 0 ? filteredOptions : []
                            }
                            getOptionLabel={(option) =>
                              option?.displayName ? option?.displayName : ""
                            }
                            sx={{ width: "100%" }}
                            //                 value={locationDetailsData?.result
                            //    ? locationDetailsData?.result?.google?.categories
                            //  : ""}
                            value={additionalCategories}
                            filterOptions={customFilterToSearchCategory}
                            onChange={(e, value) => {
                              if (additionalCategories.length >= 10) {
                                setLimitError(true);
                              } else {
                                setLimitError(false);
                              }
                              handleAdditionalCategoryChange(value);
                            }}
                            renderOption={(props, option, { selected }) => {
                              return selected &&
                                additionalCategories?.length > 0 &&
                                additionalCategories?.find(
                                  (item) => item?.name === option?.name
                                ) ? (
                                <li {...props}>
                                  <div
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      // border: "2px solid gray",
                                      backgroundColor: "#06BDFF",
                                      cursor: "pointer",
                                      display: "inlineBlock",
                                      marginRight: "10px",
                                      textAlign: "center",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    <CheckIcon
                                      sx={{
                                        color: "#FFFF",
                                        marginBottom: "3px",
                                        width: "14px",
                                      }}
                                      fontSize="small"
                                    />
                                  </div>
                                  {option?.displayName
                                    ? option.displayName
                                    : ""}
                                  {/* {option?.internalName + ", " + option?.city} */}
                                </li>
                              ) : (
                                <li {...props}>
                                  <div
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      border: "2px solid #aaaaaa",
                                      // backgroundColor: "#06BDFF",
                                      cursor: "pointer",
                                      display: "inlineBlock",
                                      marginRight: "10px",
                                      textAlign: "center",
                                      borderRadius: "3px",
                                    }}
                                  ></div>
                                  {option?.displayName
                                    ? option.displayName
                                    : ""}
                                  {/* {option?.internalName + ", " + option?.city} */}
                                </li>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // required
                                name="selectedCategory"
                                placeholder={t("Select_Category")}
                                error={selectedCategory?.length >= 10}
                              />
                            )}
                          ></Autocomplete>
                          {additionalCategories.length >= 10 && (
                            <Typography className={classes.errorText}>
                              {t("Categories_limit_text")}
                            </Typography>
                          )}
                        </Grid>
                        {categoryLoading && (
                          <Grid
                            item
                            xs={1}
                            sm={1}
                            md={0.8}
                            lg={0.8}
                            sx={{ textAlign: "center", paddingTop: "20px" }}
                          >
                            <Box sx={{ marginTop: "28px" }}>
                              <CircularProgress
                                color="secondary"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#06BDFF",
                                }}
                              />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Box>

                    {location?.enableFacebook === true && (
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        lg={12}
                        sx={{ marginTop: "12px", padding: "10px" }}
                      >
                        <Box className={classes.categoriesSocialBox}>
                          <img src={Facebook} />
                          <Typography className={classes.fieldTextSocial}>
                            {t("Facebookk")}{" "}
                            <span style={{ color: "#FC3652" }}>*</span>
                          </Typography>
                        </Box>

                        <Autocomplete
                          // multiple
                          disablePortal
                          error={facebookCategoryRequired}
                          id="combo-box-demo"
                          disabled={faceBookConnected === null ? true : false}
                          options={
                            locationCategory?.length > 0 &&
                            locationCategory[1]?.facebook?.length > 0
                              ? locationCategory[1]?.facebook
                              : []
                          }
                          getOptionLabel={(option) =>
                            option?.name ? option?.name : ""
                          }
                          sx={{ width: "100%" }}
                          value={
                            selectedFacebookCategory !== null
                              ? selectedFacebookCategory
                              : {}
                          }
                          onChange={(e, value) => {
                            handleFacebookCategoryChange(value);
                            if (value === null) {
                              setFacebookCategoryRequired(true);
                            } else {
                              setFacebookCategoryRequired(false);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              // required
                              name="locationPrimarycategory"
                              placeholder={t("Select_primary_category")}
                              error={facebookCategoryRequired}
                              disabled={
                                faceBookConnected === null ? true : false
                              }
                              helperText={
                                facebookCategoryRequired &&
                                t("Primary_Category_Error")
                              }
                            />
                          )}
                        ></Autocomplete>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Divider />
        <Grid container className={classes.gridContainerthree}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridButtons}
          >
            <CommonButton
              displayWhite="true"
              label={t("Close")}
              onSubmit={handleCancelButton}
            />

            {disableButton ? (
              <BootstrapTooltip title={t("update_category")}>
                <span>
                  <CommonButton
                    disabled={
                      categoryRequired ||
                      isLoading ||
                      facebookCategoryRequired ||
                      additionalCategories?.length >= 10 ||
                      disableButton
                    }
                    onSubmit={handleUpdateCategories}
                    label={t("Save")}
                  />
                </span>
              </BootstrapTooltip>
            ) : (
              <CommonButton
                disabled={
                  categoryRequired ||
                  isLoading ||
                  facebookCategoryRequired ||
                  additionalCategories?.length >= 10
                }
                onSubmit={handleUpdateCategories}
                label={t("Save")}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyDetailsModal;
