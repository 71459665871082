import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";

const CommonButton = ({
  label,
  isLoading,
  onSubmit,
  onChange,
  type,
  width,
  cancelButton,
  disabled,
  displayWhite,
  displayBlack,
  icon,
  alignment,
  customHeight,
  leftMargin,
  iconLocation,
}) => {
  return (
    <>
      <LoadingButton
        sx={{
          height: customHeight ? "42px" : "52px",
          // padding: "14px 20px",
          fontSize: "14px",
          marginLeft: leftMargin ? "0px" : "10px",
          borderRadius: "8px",
          color:
            displayWhite === "true"
              ? "#212529"
              : "" || displayBlack === "true"
              ? "#FFFFFF"
              : "",
          background:
            displayWhite === "true"
              ? "#f9f9f9 linear-gradient(180deg,#fafafa,#f9f9f9) repeat-x"
              : "" || displayBlack === "true"
              ? "#1B2430"
              : "",
          width:
            alignment === true
              ? "100%"
              : typeof alignment === "string"
              ? alignment
              : "auto",
          border: displayWhite === "true" ? "1px solid #E0E0E0" : "none",
        }}
        disabled={disabled ? disabled : false}
        variant="contained"
        color="primary"
        loading={isLoading}
        onChange={onChange}
        type={type}
        onClick={onSubmit}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: iconLocation ? "row-reverse" : "row",
          }}
        >
          {icon}
          {label}
        </Box>
      </LoadingButton>
      {/* {cancelButton && (
        <>
          <Button
            onClick={() => {
              onSubmit();
            }}
            variant="outlined"
            sx={{
              paddingX: "20px",
              paddingY: "14px",
              fontSize: "14px",
              borderRadius: "8px",
              minWidth: "20px",
            }}
          >
            {label}
          </Button>
        </>
      )} */}
    </>
  );
};

export default CommonButton;
