import React, { useState, useEffect } from "react";
import EditModal from "../../Modal";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";

import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import data from "./SocialAccounts.json";
import { useTranslation } from "react-i18next";
import Instagram from "../../../../../../assets/images/Instagram.svg";
import Twitter from "../../../../../../assets/images/Twitter.svg";
import Youtube from "../../../../../../assets/images/Youtube.svg";
import Pinterest from "../../../../../../assets/images/Pinterest.svg";
import Fb from "../../../../../../assets/images/fbicon.svg";
import { useStyles } from "../../../Styles/style";
import Facebook from "../../../../../../assets/images/facebookround.svg";
import Google from "../../../../../../assets/images/googleround.svg";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import NotFound from "../../../../../../components/NotFound/NotFound";
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Socialaccounts({
  locationDetailsData,
  location,
  getLocationDetails,
  singleLocation,
  googleConnect,
  getLocationById,
  locationCategory,
  setSingleLocationLoading,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [facebookConnect, setFacebookConnected] = useState(false);
  const [displayFacebookIcon, setDisplayFacebookIcon] = useState(false);
  // const [googleConnect, setGoogleConnected] = useState(false);

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  useEffect(() => {
    if (location?.enableFacebook === true) {
      setDisplayFacebookIcon(true);
    }
    location?.socialLink?.map((item) => {
      /*  if (item?.type==="google" && item?.isConnected===true) {
        setGoogleConnected(true);
      } */
      if (item?.type === "facebook") {
        setFacebookConnected(true);
      }
    });
  }, []);
  return (
    <>
      {locationDetailsData !== null ? (
        <>
          {openDefaultModel && (
            <Dialog
              maxWidth
              maxHeight
              open={openDefaultModel}
              //  onClose={handleCloseTemplateMenu}
            >
              <EditModal
                modalIndex={12}
                location={location}
                locationDetailsData={locationDetailsData}
                onCancel={handleCloseTemplateMenu}
                getLocationDetails={getLocationDetails}
                setSingleLocationLoading={setSingleLocationLoading}
                getLocationById={getLocationById}
                singleLocation={singleLocation}
                locationCategory={locationCategory}
                lodgingCategories={lodgingCategories}
                centerData={centerData}
                displayMapError={displayMapError}
              />
            </Dialog>
          )}
          <Card
            variant="outlined"
            sx={{
              bgcolor: "#ffff",
              borderRadius: "8px",
              height: "auto",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container sx={{ padding: "16px" }}>
                  <Grid item xs={8} sm={8} md={9} lg={9}>
                    <Typography className={classes.titleName}>
                      {t("connect_channel")}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3}>
                    {editPermission && viewPermission ? (
                      <Button fullWidth onClick={handleOpenTemplateMenu}>
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("Edit")}
                        </Typography>
                      </Button>
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <Button
                            disabled
                            fullWidth
                            sx={{ cursor: "pointer" }}
                            onClick={handleOpenTemplateMenu}
                          >
                            <Typography sx={{ fontWeight: 600 }}>
                              {t("Edit")}
                            </Typography>
                          </Button>
                        </span>
                      </BootstrapTooltip>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container sx={{ padding: "16px" }}>
                    <Grid item xs={9} sm={9} md={10} lg={10}>
                      <Typography className={classes.headName}>
                        {googleConnect !== null &&
                        googleConnect.validToken === true
                          ? t("Connected_with_google")
                          : googleConnect !== null &&
                            googleConnect.validToken === false
                          ? t("token_expired")
                          : t("Connect_With_Google_Text")}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={2} lg={2} textAlign="end">
                      {/* <img src={Google} /> */}
                      <CustomWidthTooltip
                        title={
                          googleConnect !== null &&
                          googleConnect.validToken === true
                            ? t("Connected_with_google")
                            : googleConnect !== null &&
                              googleConnect.validToken === false
                            ? t("token_expired")
                            : t("Connect_With_Google_Text")
                        }
                      >
                        <GoogleIcon
                          sx={{
                            color:
                              googleConnect !== null &&
                              googleConnect?.validToken === true
                                ? "#34A853"
                                : googleConnect !== null &&
                                  googleConnect?.validToken === false
                                ? "#FF1616"
                                : "#8D9298",
                          }}
                        />
                      </CustomWidthTooltip>
                    </Grid>
                  </Grid>

                  <Divider />
                  {displayFacebookIcon === true && (
                    <Grid container sx={{ padding: "16px" }}>
                      <Grid item xs={9} sm={9} md={10} lg={10}>
                        <Typography className={classes.headName}>
                          {facebookConnect === true
                            ? t("Connected_with_facebook")
                            : t("Connect_with_facebook")}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={2} lg={2} textAlign="end">
                        <CustomWidthTooltip
                          title={
                            facebookConnect === true
                              ? t("Connected_with_facebook")
                              : t("Connect_with_facebook")
                          }
                        >
                          <FacebookOutlinedIcon
                            sx={{
                              color:
                                facebookConnect === true ? "#0639c2" : "gray",
                            }}
                          />
                        </CustomWidthTooltip>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </>
      ) : (
        <NotFound text={t("No_Data_Found")} />
      )}
    </>
  );
}
export default Socialaccounts;
