import React, { useState, useEffect } from "react";
import EditModal from "../../Modal";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import Instagram from "../../../../../../assets/images/Instagram.svg";
import Twitter from "../../../../../../assets/images/Twitter.svg";
import Youtube from "../../../../../../assets/images/Youtube.svg";
import Pinterest from "../../../../../../assets/images/Pinterest.svg";
import Fb from "../../../../../../assets/images/fbicon.svg";
import { useStyles } from "../../../Styles/style";
import Facebook from "../../../../../../assets/images/facebookround.svg";
import Google from "../../../../../../assets/images/googleround.svg";
import GoogleIcon from "@mui/icons-material/Google";
import Truncate from "react-truncate";

import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import NotFound from "../../../../../../components/NotFound/NotFound";
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

function Socialaccounts({
  locationDetailsData,
  location,
  getLocationDetails,
  singleLocation,
  googleConnect,
  getLocationById,
  locationCategory,
  setSingleLocationLoading,
  getLocationDetailsListing,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [facebookConnect, setFacebookConnected] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  // const [googleConnect, setGoogleConnected] = useState(false);

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  useEffect(() => {
    location?.socialLink?.map((item) => {
      /*   if (item?.type==="google" && item?.isConnected===true) {
        setGoogleConnected(true)
      } */
      /*   if (item?.type==="facebook" && item?.isConnected===true) {
        setFacebookConnected(true);
      } */
    });
  }, []);
  return (
    <>
      {locationDetailsData !== null ? (
        <>
          {openDefaultModel && (
            <Dialog
              maxWidth
              maxHeight
              open={openDefaultModel}
              // onClose={handleCloseTemplateMenu}
            >
              <EditModal
                modalIndex={displayMapError === false ? 14 : 13}
                location={location}
                locationDetailsData={locationDetailsData}
                onCancel={handleCloseTemplateMenu}
                getLocationDetails={getLocationDetails}
                setSingleLocationLoading={setSingleLocationLoading}
                getLocationById={getLocationById}
                singleLocation={singleLocation}
                locationCategory={locationCategory}
                getLocationDetailsListing={getLocationDetailsListing}
                lodgingCategories={lodgingCategories}
                categoryLoading={categoryLoading}
                centerData={centerData}
                displayMapError={displayMapError}
              />
            </Dialog>
          )}
          <Card variant="outlined" className={classes.containerCard}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container className={classes.gridContainers}>
                  <Grid item xs={8} sm={8} md={9} lg={9}>
                    <Typography className={classes.titleName}>
                      {t("local_pages")}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3}>
                    {editPermission && viewPermission ? (
                      <Button fullWidth onClick={handleOpenTemplateMenu}>
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("Edit")}
                        </Typography>
                      </Button>
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <Button
                            disabled
                            fullWidth
                            sx={{ cursor: "pointer" }}
                            onClick={handleOpenTemplateMenu}
                          >
                            <Typography sx={{ fontWeight: 600 }}>
                              {t("Edit")}
                            </Typography>
                          </Button>
                        </span>
                      </BootstrapTooltip>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container className={classes.gridContainers}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("Slug_url")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7} textAlign="end">
                      <Truncate
                        lines={1}
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#06BDFF",
                        }}
                        ellipsis={<span>...</span>}
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#06BDFF",
                          }}
                          // className={classes.headNameDescAttr}
                        >
                          {locationDetailsData?.result?.slug}
                        </Typography>
                      </Truncate>
                    </Grid>
                  </Grid>

                  <Divider />
                  <Grid container className={classes.gridContainers}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("display_name")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7} textAlign="end">
                      <Truncate
                        lines={1}
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#06BDFF",
                        }}
                        ellipsis={<span>...</span>}
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#06BDFF",
                          }}
                          // className={classes.headNameDescAttr}
                        >
                          {locationDetailsData?.result?.displayName}
                        </Typography>
                      </Truncate>
                    </Grid>
                  </Grid>

                  <Divider />
                  <Grid container className={classes.gridContainers}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("menu_link")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7} textAlign="end">
                      <Truncate
                        lines={1}
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#06BDFF",
                        }}
                        ellipsis={<span>...</span>}
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#06BDFF",
                          }}
                          // className={classes.headNameDescAttr}
                        >
                          {locationDetailsData?.result?.menuLink}
                        </Typography>
                      </Truncate>
                    </Grid>
                  </Grid>

                  <Divider />
                  <Grid container className={classes.gridContainers}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        Deliveroo
                      </Typography>
                    </Grid>
                    <Grid xs={7} sm={7} md={7} lg={7} textAlign="end">
                      <Truncate
                        lines={1}
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#06BDFF",
                        }}
                        ellipsis={<span>...</span>}
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#06BDFF",
                          }}
                          // className={classes.headNameDescAttr}
                        >
                          {
                            locationDetailsData?.result?.deliveryOptions
                              ?.deliveroo
                          }
                        </Typography>
                      </Truncate>
                    </Grid>
                  </Grid>

                  <Divider />
                  <Grid container className={classes.gridContainers}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        JustEat
                      </Typography>
                    </Grid>
                    <Grid xs={7} sm={7} md={7} lg={7} textAlign="end">
                      <Truncate
                        lines={1}
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#06BDFF",
                        }}
                        ellipsis={<span>...</span>}
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#06BDFF",
                          }}
                          // className={classes.headNameDescAttr}
                        >
                          {
                            locationDetailsData?.result?.deliveryOptions
                              ?.justEat
                          }
                        </Typography>
                      </Truncate>
                    </Grid>
                  </Grid>

                  <Divider />
                  <Grid container className={classes.gridContainers}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        UberEats
                      </Typography>
                    </Grid>
                    <Grid xs={7} sm={7} md={7} lg={7} textAlign="end">
                      <Truncate
                        lines={1}
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#06BDFF",
                        }}
                        ellipsis={<span>...</span>}
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#06BDFF",
                          }}
                          // className={classes.headNameDescAttr}
                        >
                          {
                            locationDetailsData?.result?.deliveryOptions
                              ?.uberEats
                          }
                        </Typography>
                      </Truncate>
                    </Grid>
                  </Grid>

                  <Divider />
                  <Grid container className={classes.gridContainers}>
                    <Grid item xs={6} sm={6} md={6.5} lg={6.5}>
                      <Typography className={classes.headName}>
                        ClickAndConnect
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={5.5} lg={5.5} textAlign="end">
                      <Truncate
                        lines={1}
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#06BDFF",
                        }}
                        ellipsis={<span>...</span>}
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#06BDFF",
                          }}
                          // className={classes.headNameDescAttr}
                        >
                          {
                            locationDetailsData?.result?.deliveryOptions
                              ?.clickAndConnect
                          }
                        </Typography>
                      </Truncate>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container className={classes.gridContainers}>
                    <Grid item xs={6} sm={6} md={6.5} lg={6.5}>
                      <Typography className={classes.headName}>
                        Pedidosya
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={5.5} lg={5.5} textAlign="end">
                      <Truncate
                        lines={1}
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#06BDFF",
                        }}
                        ellipsis={<span>...</span>}
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#06BDFF",
                          }}
                          // className={classes.headNameDescAttr}
                        >
                          {locationDetailsData?.result?.deliveryOptions
                            ?.pedidosYa
                            ? locationDetailsData?.result?.deliveryOptions
                                ?.pedidosYa
                            : ""}
                        </Typography>
                      </Truncate>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container className={classes.gridContainers}>
                    <Grid item xs={6} sm={6} md={6.5} lg={6.5}>
                      <Typography className={classes.headName}>
                        Rappi
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={5.5} lg={5.5} textAlign="end">
                      <Truncate
                        lines={1}
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#06BDFF",
                        }}
                        ellipsis={<span>...</span>}
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#06BDFF",
                          }}
                          // className={classes.headNameDescAttr}
                        >
                          {locationDetailsData?.result?.deliveryOptions?.rappi
                            ? locationDetailsData?.result?.deliveryOptions
                                ?.rappi
                            : ""}
                        </Typography>
                      </Truncate>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider variant="middle" />
              </Grid>
            </Grid>
          </Card>
        </>
      ) : (
        <NotFound text={t("No_Data_Found")} />
      )}
    </>
  );
}
export default Socialaccounts;
