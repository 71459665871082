import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TextField,
  Menu,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { toast } from "react-toastify";
import moment from "moment";
import { Paper } from "@mui/material";
import ShortcutSharpIcon from "@mui/icons-material/ShortcutSharp";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import ReplyFaqIcon from "../../../../../assets/Icons/replyFaq.svg";
import InputAdornment from "@mui/material/InputAdornment";
import Loader from "../../../../../components/Loaders/Loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../contexts/JWTContext";
import { useStyles } from "../../Styles/style";
import NotFound from "../../../../../components/NotFound/NotFound";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import Delete from "../../../../../components/Models/DeleteModal/Delete";
import EditIcon from "@mui/icons-material/Edit";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  title,
  subTitle,
  open,
  handleCloseNewRequestModel,
  totalModalEmailsArr,
  selectedItems,
  setSelectedItems,
  selectAllChecked,
  setSelectAllChecked,
  handleSubmitOk,
  handleCancelEmailsModal,
  isModalEmailsSent,
  setIsModalEmailsSent,
  isLoading,
  questionId,
  handleDisplayLocationName,
  modelQuestionText,
  viewPermission,
  addPermission,
  deletePermission,
  editPermission,
  handleGetFaqsTableData,
  locationName,
  companyData,
  userData,
  ownerAnswerCount,
  handleDisplayNames,
  createdAtQuestion,
  questionUserType,
  questionAuthName,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [allAnswersLoading, setAllAnswersLoading] = useState(false);
  const [allAnswers, setAllAnswers] = useState(false);
  const [openAns, setOpenAns] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteTask, setDeleteTask] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showAnswerFiled, setShowAnswerFiled] = useState();
  const [AnswerValue, setAnswerValue] = useState("");
  const [createAnswerLoading, setCreateAnswerLoading] = useState(false);
  const [editAnswer, setEditAnswer] = useState({});
  const [editAnswerText, setEditAnswerText] = useState("");
  const [editAnswerId, setEditAnswerId] = useState(null);
  const [editAnswerLoading, setEditAnswerLoading] = useState(false);
  const [isEditAnswer, setIsEditAnswer] = useState(false);
  const [showEditActions, setShowEditActions] = useState(true);
  const [addAnswer, setAddAnswer] = useState(true);
  const [ownerAnswerCnt, setOwnerAnswerCnt] = useState(null);
  const [ownerAnswer, setOwnerAnswer] = useState({});
  const [userAnswers, setUserAnswers] = useState([]);

  useEffect(() => {
    if (open) handleGetAllAnswersById();
  }, [open]);

  useEffect(() => {
    setEditAnswerText(ownerAnswer?.answerText);
  }, [ownerAnswer]);

  useEffect(() => {
    if (allAnswers && allAnswers.rows.length > 0) {
      const ownerAnswers = allAnswers.rows.filter(
        (row) => row && row.userType === "Owner"
      );
      const ownerAnswersCnt = ownerAnswers.length;

      setOwnerAnswerCnt(ownerAnswersCnt);
    }
  }, [allAnswers]);

  const handleGetAllAnswersById = async () => {
    setAllAnswersLoading(true);
    try {
      const res = await api.get(`answers/question/${questionId}`);
      let ownerAnswer = {};
      let userAnswers = [];
      if (res.status === 200) {
        for (let row of res?.data?.data?.rows) {
          if (row?.userType === "Owner") {
            ownerAnswer = { ...row };
          } else {
            userAnswers.push(row);
          }
        }
        setAllAnswers(res?.data?.data);
        setOwnerAnswer(ownerAnswer);
        setUserAnswers(userAnswers);
        setAllAnswersLoading(false);
        setAddAnswer(false);
      }

      if (res.status === 204) {
        // setAllAnswers(res?.data?.data);
        setAddAnswer(true);
        setAllAnswersLoading(false);
      }
    } catch (error) {
      setAllAnswers([]);
      setAllAnswersLoading(false);
      setAddAnswer(false);
    }
  };

  const handleDeleteAnswer = async (id) => {
    setDeleteLoading(true);
    try {
      const res = await api.delete(`answers/${id}`);
      if (res.status === 200) {
        setOpenAns(false);
        //  closeMenu(false);
        setDeleteTask(null);
        toast.success("Deleted successfully");
        setDeleteLoading(false);
        handleGetAllAnswersById();
        handleGetFaqsTableData();
        handleCloseNewRequestModel();
      }
    } catch (error) {
      setDeleteLoading(false);
      handleCloseNewRequestModel();
    }
  };

  const handleCreateAnswer = async () => {
    setCreateAnswerLoading(true);

    try {
      const res = await api.post(`/answers`, {
        googleAnswerId: "",
        authorName: `${userData?.firstName} ${userData?.lastName}`,
        answerText: AnswerValue,
        locationId: locationName,
        companyId: companyData.id,
        userId: userData.id,
        questionId,
      });

      if (res?.data?.status === true) {
        toast.success(res?.data?.message);
        setCreateAnswerLoading(false);
        // handleResetAskQuestionModel();
        setEditAnswerText("");
        setAnswerValue("");
        handleGetAllAnswersById();
        // handleCloseNewRequestModel();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setCreateAnswerLoading(false);
      setEditAnswerText("");
      setAnswerValue("");
      handleCloseNewRequestModel();
    }
  };

  const handleOpenModel = (id) => {
    setDeleteTask(id);
    setAnchorEl(null);
    setOpenAns(true);
  };

  const handleClose = () => {
    // setOpenModal(false);
    setOpenAns(false);
    setAnchorEl(null);
  };

  const handleAnswerClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDeleteTask(id);
  };

  const handleClickAnsQuestion = () => {
    setShowAnswerFiled(true);
  };

  const handleChangeAnswer = (e) => {
    const inputValue = e.target.value;
    setAnswerValue(inputValue);
  };

  // const handleClickEditAnswer = (event, id, index) => {
  //   setEditAnswerId(id);
  //   // setEditAnswer(true);
  //   setEditAnswer((prevState) => ({
  //     ...prevState,
  //     [index]: true,
  //   }));
  //   setAnchorEl(null);
  // };

  const handleUpdateAnswer = async () => {
    try {
      setEditAnswerLoading(true);
      const res = await api.patch(`answers/${editAnswerId}`, {
        googleAnswerId: "",
        authorName: `${userData?.firstName} ${userData?.lastName}`,
        answerText: editAnswerText,
        locationId: locationName,
        companyId: companyData.id,
        userId: userData.id,
        questionId: questionId,
      });
      if (res.status === 200) {
        setEditAnswerLoading(false);
        setIsEditAnswer(false);
        toast.success(res?.data?.message);
        handleGetAllAnswersById();
        setEditAnswer(false);
        setShowEditActions(true);
        // handleCloseNewRequestModel();
      }
    } catch (error) {
      setEditAnswerLoading(false);
      setEditAnswer(false);
      setIsEditAnswer(false);
      toast.error(error?.response?.data?.message);
      setShowEditActions(true);
      // handleCloseNewRequestModel();
    }
  };

  const handleChangeAnswersValue = (event) => {
    setEditAnswerText(event.target.value);
  };

  const handleClickEditAnswer = (event, id, index) => {
    setIsEditAnswer(true);
    setShowEditActions(false);
    setEditAnswerId(id);
  };

  const handleCancelUpdateAnswer = () => {
    setIsEditAnswer(false);
    setShowEditActions(true);
    setEditAnswerText(ownerAnswer?.answerText);
  };

  const handleCancelCreateAnswer = () => {
    setAnswerValue("");
  };

  return (
    <>
      <div>
        {openAns && (
          <Dialog
            //fullScreen={fullScreen}
            open={openAns}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <Delete
              title={t("Delete_Answer")}
              description={t("confirm_delete_answer")}
              onConfirm={() => handleDeleteAnswer(deleteTask)}
              onCancel={handleClose}
              loading={deleteLoading}
            />
          </Dialog>
        )}
        {open && (
          <Dialog
            open={open}
            sx={{
              "& .MuiDialog-paper": {
                maxWidth: "100%",
                width: "850px",
                height: "550px",
              },
            }}
          >
            {/* <DialogTitle>  */}
            <Box className={classes.faqModelTitleHead}>
              <Typography className={classes.faqModelTitle}>{title}</Typography>

              {/* <CommonButton
              label={t("Ans_a_Question")}
              disabled={showAnswerFiled === true ? true : false}
              onSubmit={handleClickAnsQuestion}
            /> */}
            </Box>
            {/* </DialogTitle>  */}

            <DialogContent dividers>
              {allAnswersLoading ? (
                <Loader />
              ) : allAnswers?.rows?.length > 0 ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                      <Box>
                        <Typography className={classes.faqModelTitle}>
                          {questionUserType === "Local Guide"
                            ? questionAuthName
                            : handleDisplayNames(locationName)}
                        </Typography>
                      </Box>

                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            // marginTop: "-7px",
                          }}
                        >
                          <Typography className={classes.typoDate}>
                            {moment(createdAtQuestion).format(
                              "DD MMM YYYY • hh:mm A"
                            )}
                          </Typography>
                          <Typography
                            style={{
                              color:
                                questionUserType === "Local Guide"
                                  ? "#F86F35"
                                  : "#06BDFF",
                              marginLeft: "10px",
                            }}
                            className={classes.typoOwner}
                          >
                            {questionUserType}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={3} mb={3}>
                    <Typography>{modelQuestionText}</Typography>
                  </Grid>
                  {ownerAnswerCnt === 0 && (
                    <>
                      <Grid container>
                        <Grid item xs={10.5} md={10.5} lg={10.5}></Grid>
                        <Grid item xs={1.5} md={1.5} lg={1.5}>
                          <Typography className={classes.limitFieldTextAskQ}>
                            {AnswerValue === "" ? 0 : AnswerValue?.length}
                            /1000{" "}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                          {AnswerValue?.length >= 1000 && (
                            <Typography className={classes.textLimitTypo}>
                              {t("Text_Limit_Answer")}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                          <TextField
                            id="standard-basic"
                            // style={{ background: "#F6F6F7" }}
                            // InputProps={{
                            //   startAdornment: (
                            //     <InputAdornment position="top">
                            //       <img src={ReplyFaqIcon} />
                            //     </InputAdornment>
                            //   ),
                            // }}
                            rows={5}
                            maxRows={20}
                            multiline
                            type="text"
                            name="createAnswer"
                            sx={{ width: "98%" }}
                            value={AnswerValue}
                            onChange={handleChangeAnswer}
                            placeholder={t("write_your_answer_here")}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          // sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Box className={classes.actionsClassSubmit}>
                            {/* <Button onClick={handleCancelCreateAnswer}>
                              {t("Cancel")}
                            </Button> */}
                            {viewPermission && addPermission ? (
                              <LoadingButton
                                variant="contained"
                                onClick={handleCreateAnswer}
                                loading={createAnswerLoading}
                                disabled={
                                  AnswerValue === ""
                                    ? true
                                    : AnswerValue?.length >= 1000
                                    ? true
                                    : false
                                }
                              >
                                {t("submit_edit")}
                              </LoadingButton>
                            ) : (
                              <BootstrapTooltip title={t("authorized_access")}>
                                <Box>
                                  <LoadingButton
                                    variant="contained"
                                    disabled={true}
                                  >
                                    {t("submit_edit")}
                                  </LoadingButton>
                                </Box>
                              </BootstrapTooltip>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {ownerAnswer?.answerText && (
                    <Grid
                      container
                      sx={{
                        padding: "7px",
                        borderRadius: "8px",
                        background: "#F1F7FF",
                        marginTop: "18px",
                        paddingBottom: "18px",
                      }}
                    >
                      <Grid item xs={12} md={12} lg={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <Typography className={classes.typoOwner}>
                            {handleDisplayLocationName(locationName)}
                          </Typography>
                          <Typography className={classes.typoDate}>
                            {moment(ownerAnswer?.googleUpdateTime).format(
                              "DD MMM YYYY • hh:mm A"
                            )}
                          </Typography>
                          <Typography
                            style={{ color: "#06BDFF", marginLeft: "10px" }}
                            className={classes.typoOwner}
                          >
                            {t("owner")}
                          </Typography>
                        </Box>
                      </Grid>

                      {isEditAnswer ? (
                        <Grid item xs={12} md={12} lg={12}>
                          <Grid container>
                            <Grid item xs={10.5} md={10.5} lg={10.5}></Grid>
                            <Grid item xs={1.5} md={1.5} lg={1.5}>
                              <Typography
                                className={classes.limitFieldTextAskQ}
                              >
                                {editAnswerText?.length}
                                /1000
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                              {editAnswerText?.length >= 1000 && (
                                <Typography className={classes.textLimitTypo}>
                                  {t("Text_Limit_Answer")}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          <TextField
                            id="standard-basic"
                            style={{ background: "white" }}
                            rows={5}
                            maxRows={20}
                            multiline
                            type="text"
                            sx={{ width: "98%" }}
                            value={editAnswerText}
                            onChange={(e) => handleChangeAnswersValue(e)}
                            placeholder="Enter Answer"
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography>{ownerAnswer?.answerText}</Typography>
                          {showEditActions && (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {viewPermission && editPermission ? (
                                <Typography
                                  className={classes.typoOwnerActions}
                                  onClick={(e) =>
                                    handleClickEditAnswer(e, ownerAnswer?.id)
                                  }
                                >
                                  {t("Edit")}
                                </Typography>
                              ) : (
                                <BootstrapTooltip
                                  title={t("authorized_access")}
                                >
                                  <Box>
                                    <Typography
                                      className={
                                        classes.typoOwnerActionsDisabled
                                      }
                                    >
                                      {t("Edit")}
                                    </Typography>
                                  </Box>
                                </BootstrapTooltip>
                              )}
                              {viewPermission && deletePermission ? (
                                <Typography
                                  className={classes.typoOwnerActions}
                                  onClick={() =>
                                    handleOpenModel(ownerAnswer?.id)
                                  }
                                  ml={3}
                                >
                                  {t("Delete")}
                                </Typography>
                              ) : (
                                <BootstrapTooltip
                                  title={t("authorized_access")}
                                >
                                  <Box>
                                    <Typography
                                      className={
                                        classes.typoOwnerActionsDisabled
                                      }
                                      ml={3}
                                    >
                                      {t("Delete")}
                                    </Typography>
                                  </Box>
                                </BootstrapTooltip>
                              )}
                            </Box>
                          )}
                        </Grid>
                      )}

                      {isEditAnswer && (
                        <Grid item xs={12} md={12} lg={12}>
                          <Box className={classes.actionsClassSubmit}>
                            <Button onClick={handleCancelUpdateAnswer}>
                              {t("Cancel")}
                            </Button>
                            {viewPermission && editPermission ? (
                              <LoadingButton
                                variant="contained"
                                onClick={handleUpdateAnswer}
                                loading={editAnswerLoading}
                              >
                                {t("submit_edit")}
                              </LoadingButton>
                            ) : (
                              <BootstrapTooltip title={t("authorized_access")}>
                                <Box>
                                  <LoadingButton
                                    variant="contained"
                                    disabled={true}
                                  >
                                    {t("submit_edit")}
                                  </LoadingButton>
                                </Box>
                              </BootstrapTooltip>
                            )}
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {userAnswers?.length > 0 && (
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={7}>
                      <Typography className={classes.faqModelTitle}>
                        {`${t("users_answer")} (${userAnswers.length})`}
                      </Typography>
                    </Grid>
                  )}

                  {userAnswers?.map((item, index) => {
                    return (
                      <Grid
                        container
                        // mb={5}
                        sx={{
                          padding: "7px",
                          borderRadius: "8px",
                        }}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              // marginTop: "10px",
                            }}
                          >
                            <Typography className={classes.typoOwner}>
                              {t(item?.authorName)}
                            </Typography>

                            <Typography className={classes.typoDate}>
                              {moment(item?.googleUpdateTime).format(
                                "DD MMM YYYY • hh:mm A"
                              )}
                            </Typography>

                            <Typography
                              style={{ color: "#F86F35", marginLeft: "10px" }}
                              className={classes.typoOwner}
                            >
                              {t("local_guide")}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography>{item?.answerText}</Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              ) : addAnswer ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                      <Box>
                        <Typography className={classes.faqModelTitle}>
                          {questionUserType === "Local Guide"
                            ? questionAuthName
                            : handleDisplayNames(locationName)}
                        </Typography>
                      </Box>

                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            // marginTop: "-7px",
                          }}
                        >
                          <Typography className={classes.typoDate}>
                            {moment(createdAtQuestion).format(
                              "DD MMM YYYY • hh:mm A"
                            )}
                          </Typography>
                          <Typography
                            style={{
                              color:
                                questionUserType === "Local Guide"
                                  ? "#F86F35"
                                  : "#06BDFF",
                              marginLeft: "10px",
                            }}
                            className={classes.typoOwner}
                          >
                            {questionUserType}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={3}>
                    <Typography>{modelQuestionText}</Typography>
                  </Grid>

                  <Grid container>
                    <Grid item xs={10.5} md={10.5} lg={10.5}></Grid>
                    <Grid item xs={1.5} md={1.5} lg={1.5}>
                      <Typography className={classes.limitFieldTextAskQ}>
                        {AnswerValue === "" ? 0 : AnswerValue?.length}
                        /1000{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      {AnswerValue?.length >= 1000 && (
                        <Typography className={classes.textLimitTypo}>
                          {t("Text_Limit_Answer")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        id="standard-basic"
                        // style={{ background: "#F6F6F7" }}
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="top">
                        //       <img src={ReplyFaqIcon} />
                        //     </InputAdornment>
                        //   ),
                        // }}
                        rows={5}
                        maxRows={20}
                        multiline
                        type="text"
                        name="askQuestions"
                        sx={{ width: "98%" }}
                        value={AnswerValue}
                        onChange={handleChangeAnswer}
                        placeholder={t("write_your_answer_here")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box className={classes.actionsClassSubmit}>
                        {viewPermission && addPermission ? (
                          <LoadingButton
                            variant="contained"
                            onClick={handleCreateAnswer}
                            loading={createAnswerLoading}
                            disabled={
                              AnswerValue === ""
                                ? true
                                : AnswerValue?.length >= 1000
                                ? true
                                : false
                            }
                          >
                            {t("submit_edit")}
                          </LoadingButton>
                        ) : (
                          <BootstrapTooltip title={t("authorized_access")}>
                            <Box>
                              <LoadingButton
                                variant="contained"
                                disabled={true}
                              >
                                {t("submit_edit")}
                              </LoadingButton>
                            </Box>
                          </BootstrapTooltip>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <NotFound text={"No answers found"} />
              )}
            </DialogContent>

            <Box className={classes.actionsClass}>
              <CommonButton
                displayWhite="true"
                label={t("Cancel")}
                onSubmit={handleCloseNewRequestModel}
              />
              <CommonButton
                onSubmit={handleCloseNewRequestModel}
                // disabled={AnswerValue === "" ? true : false}
                variant="contained"
                label={t("Ok")}
                // isLoading={createAnswerLoading}
              />
            </Box>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default FetchModel;
