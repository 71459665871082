import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TitleHeader from "../../../components/CustomComponents/title";
import { useStyles } from "./Styles/style";
import Overview from "./Components/Overview";
import QuestionMark from "../../../assets/Icons/Question.svg";
import AddIcon from "@mui/icons-material/Add";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import Loader from "../../../components/Loaders/Loader";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";

import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Checkbox,
  Tooltip,
  Box,
  tooltipClasses,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Iframe from "../../../components/Models/IframeModal/Iframe";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useAuth from "../../../hooks/useAuth";
import LocationNotFound from "../../../components/CustomComponents/Errors/NoLocationFound";
import NotFound from "../../../components/NotFound/NotFound";
import styled from "styled-components/macro";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="bottom"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Index = () => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { signOut, permissionsAcess } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [locationId, setLocationId] = useState([]);
  const [locationIdValues, setLocationIdValues] = useState([]);

  const [campaigns, setCampaigns] = useState([]);
  const [openIframeModel, setOpenIframeModel] = useState(false);
  const i18nextLng = localStorage.getItem("i18nextLng");
  const [formitableLocations, setFormitableLocations] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/review"
      );
      // setUserManagementPermissions(filteredPermissions);

      filteredPermissions?.forEach((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    getAllLocations();
    getAllFormitableLocations();
  }, []);

  useEffect(() => {
    getAllCampaigns();
  }, [page, rowsPerPage, locationIdValues]);

  useEffect(() => {
    getAllCampaigns();
  }, [locationIdValues]);

  const handleNavigationToLocations = () => {
    navigate(`/user/review_request_campaign`);
  };
  const getAllLocations = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
        },
      });
      if (res.status === 200) {
        setIsLoading(true);
        if (res.data.data?.results?.length > 0) {
          setAllLocations(res.data.data);
        } else {
          setAllLocations(null);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setAllLocations(null);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const getAllCampaigns = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `/campaign?locationIds=${
          locationIdValues?.length > 0 ? locationIdValues?.toString() : ""
        }&limit=${rowsPerPage}&page=${page + 1}&sortBy=createdAt`
      );
      if (res.status === 200) {
        setIsLoading(true);
        if (res?.data?.data?.results?.length > 0) {
          //  setDisplayTabs(true);
          setCampaigns(res.data.data);
        } else {
          //  setDisplayTabs(false);
          setCampaigns(null);
        }

        setIsLoading(false);
      }
    } catch (error) {
      setCampaigns(null);
      setIsLoading(false);
    }
  };
  const handleClickCloseWarning = () => {
    setDisplayWarning(false);
  };
  const getAllFormitableLocations = async () => {
    // setIsLoading(true);
    try {
      const res = await api.get(`campaign/formitableCampaign`);
      if (res.status === 200) {
        //  setIsLoading(true);

        if (res.data.data?.length > 0) {
          const locationNames = res.data.data?.map(
            (item) => item?.internalName
          );
          setFormitableLocations(locationNames);
          setDisplayWarning(true);
        } else {
          setDisplayWarning(false);
        }

        //  setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
    }
  };
  const handleOpenTemplateMenu = () => {
    setOpenIframeModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenIframeModel(false);
  };

  const handleLocationChange = (value) => {
    setLocationId(value);
    setLocationIdValues(value?.map((item) => item?.id));
    /*  if (value?.length > 0) {
      value?.map((item) => {
        locationIdValues.push(item?.id);
      });
    } */
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };

  return (
    <>
      {openIframeModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openIframeModel}
          onClose={handleCloseTemplateMenu}
        >
          {i18nextLng === "es" ? (
            <Iframe
              title={t("Review_Req_Tutorial")}
              onCancel={handleCloseTemplateMenu}
              iframe={
                <iframe
                  src="https://ask.obenan.com/fnsxugdcu"
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                  width="1200px"
                  height="500px"
                  onLoad={() => {}}
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
              }
            />
          ) : (
            <Iframe
              title={t("Review_Req_Tutorial")}
              onCancel={handleCloseTemplateMenu}
              iframe={
                <iframe
                  title="iframe"
                  loading="lazy"
                  src="https://ask.obenan.com/fnsxugdcu"
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                  width="1200px"
                  height="500px"
                  onLoad={() => {}}
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                />
              }
            />
          )}
        </Dialog>
      )}

      <TitleHeader
        title={t("campaigns")}
        IframeButton={true}
        IframeButtonName={
          <BootstrapTooltip title={t("Help")}>
            <img src={QuestionMark} />
          </BootstrapTooltip>
        }
        extraButton={allLocations?.results?.length > 0 ? true : false}
        extraButtonName={
          <>
            <AddIcon /> {t("Create_new_campaign")}
          </>
        }
        handleDefaultIframe={handleOpenTemplateMenu}
        handleDefaultTemplate={handleNavigationToLocations}
        viewPermission={viewPermission}
        addPermission={addPermission}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {" "}
          {allLocations?.results?.length > 0 ? (
            <>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    multiple
                    disablePortal
                    id="combo-box-demo"
                    options={
                      allLocations?.results?.length > 0
                        ? allLocations?.results
                        : []
                    }
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? " (" + option.addressLine1
                            : "") +
                          (option?.city !== "" &&
                          option?.city !== null &&
                          option?.city !== undefined
                            ? (option?.addressLine1 ? ", " : " (") + option.city
                            : "") +
                          ")"
                        : ""
                    }
                    className="Autocomplete-field"
                    value={locationId ? locationId : []}
                    onChange={(e, value) => {
                      handleLocationChange(value);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {`${option?.internalName} (${
                          option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1
                            : ""
                        }${
                          option?.city !== "" &&
                          option?.city !== null &&
                          option?.city !== undefined
                            ? ", " + option.city
                            : ""
                        })`}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("Select_Location")}
                        {...params}
                        required
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                </Grid>
              </Grid>
              {/*   {displayTabs && (
        <Tabs
          className={classes.Tabs}
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
        >
          <Tab label="Overview" />
         <Tab label="Archived" />
        </Tabs>
      )} */}
              {/*  {selectedTab === 0 && ( */}
              {displayWarning && (
                <Grid container className={classes.mainContainer}>
                  <Grid
                    item
                    xs={0.5}
                    sm={0.5}
                    md={0.5}
                    lg={0.5}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <ErrorOutlineIcon className={classes.errorIcon} />
                  </Grid>
                  <Grid item xs={11} sm={11} md={11} lg={11}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {formitableLocations?.length > 0 &&
                        formitableLocations?.length === 1 && (
                          <Typography className={classes.errorText}>
                            {`${t("formitable_warningText_single")} ${
                              formitableLocations?.length > 0
                                ? formitableLocations?.toString()
                                : ""
                            }${t("formitable_warningText2")}`}
                          </Typography>
                        )}
                      {formitableLocations?.length > 0 &&
                        formitableLocations?.length !== 1 && (
                          <Typography className={classes.errorText}>
                            {`${t("formitable_warningText_multiple")} ${
                              formitableLocations?.length > 0
                                ? formitableLocations?.toString()
                                : ""
                            }${t("formitable_warningText2")}`}
                          </Typography>
                        )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={0.5}
                    sm={0.5}
                    md={0.5}
                    lg={0.5}
                    sx={{
                      textAlign: "end",
                    }}
                  >
                    <CloseIcon
                      onClick={() => handleClickCloseWarning()}
                      className={classes.closeIcon}
                    />
                  </Grid>
                </Grid>
              )}

              {campaigns?.results?.length > 0 ? (
                <>
                  <Overview
                    locationIdValues={locationIdValues}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    campaigns={campaigns}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    page={page}
                    getAllFormitableLocations={getAllFormitableLocations}
                    setPage={setPage}
                    getAllCampaigns={getAllCampaigns}
                    editPermission={editPermission}
                    addPermission={addPermission}
                    deletePermission={deletePermission}
                    viewPermission={viewPermission}
                  />
                </>
              ) : (
                campaigns === null && (
                  <Box sx={{ marginTop: "3rem", marginInline: "auto" }}>
                    <NotFound text={t("No_Campaign_Found")} />
                  </Box>
                )
              )}

              {/*  )} */}
              {/*   {selectedTab === 1 && <Archived />} */}
            </>
          ) : (
            allLocations === null && (
              <Box className={classes.locationbox}>
                <LocationNotFound
                  text={t("No_Location_Added")}
                  subtext={t("add_location_text")}
                />
              </Box>
            )
          )}
        </>
      )}
    </>
  );
};

export default Index;
