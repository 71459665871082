import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoOnlineReputationFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        conatiner
        sx={{
          height: "100%",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "30%",
            height: "100%",
            textAlign: "center",
            margin: "auto",
          }}
        >
          <Typography
            sx={{
              marginTop: "50px",
              marginBottom: "50px",
              fontWeight: 400,
            }}
          >
            {t("No_Data_Found")}
          </Typography>
        </div>
      </Grid>
    </>
  );
};

export default NoOnlineReputationFound;
