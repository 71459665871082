import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// import EditIcon from "@mui/icons-material/Edit";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CustomizedTableCell from "../../../../../../components/CustomComponents/table/tableCell";
import CustomizedTableRow from "../../../../../../components/CustomComponents/table/tableRow";
import { useStyles } from "../../../Styles/style";

import ProfilePictureModal from "../../Modal/PictureModal";
import Face1 from "../../../../../../assets/images/excellent.svg";
import Face2 from "../../../../../../assets/images/good.svg";
import Face3 from "../../../../../../assets/images/ok.svg";
import Face4 from "../../../../../../assets/images/bad.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import blurImage from "../../../../../../assets/placeholder.png";
import noImageGray from "../../../../../../assets/images/noImageGray.png";

const Step4 = ({
  stepperData,
  EditStep1,
  EditStep2,
  EditStep3,
  surveyImageDisplayStepThree,
}) => {
  const { t } = useTranslation();
  const [openPictureModal, setOpenPictureModal] = useState(false);
  const [loadImg, setLoadImg] = useState(false);

  const handleCloseNavigate = () => {
    setOpenPictureModal(false);
  };
  const handleClosePictureModal = () => {
    setOpenPictureModal(false);
  };
  const classes = useStyles();

  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };
  return (
    <>
      {openPictureModal && (
        <ProfilePictureModal
          handleClose={handleClosePictureModal}
          open={openPictureModal}
          handleCloseNavigate={handleCloseNavigate}
        />
      )}
      <Grid container component={Paper} sx={{ padding: "16px" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box sx={{ paddingY: "16px" }}>
            <Typography className={classes.stepLocation}>
              {t("Overvieww")}
            </Typography>
            <Typography className={classes.stepLocationSubhead}>
              {t("Overview_subhead")}
            </Typography>
          </Box>
          <TableContainer
            sx={{ border: "1px solid #E0E0E0" }}
            component={Paper}
          >
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableBody>
                {stepperData?.locationIds?.map((item, i) => {
                  return (
                    <CustomizedTableRow tabIndex={-1}>
                      <CustomizedTableCell
                        sx={{ cursor: "pointer" }}
                        align="left"
                      >
                        {t("Location")}
                      </CustomizedTableCell>

                      <CustomizedTableCell
                        sx={{ cursor: "pointer" }}
                        align="left"
                      >
                        {item?.name}
                      </CustomizedTableCell>

                      <CustomizedTableCell align="right">
                        <BorderColorOutlinedIcon
                          sx={{ color: "#1B2430" }}
                          cursor={"pointer"}
                          onClick={EditStep1}
                        />
                        {/* <MoreHorizIcon sx={{marginLeft:"8px", color:"#1B2430"}} cursor={"pointer"} /> */}
                      </CustomizedTableCell>
                    </CustomizedTableRow>
                  );
                })}

                <CustomizedTableRow tabIndex={-1}>
                  <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                    {t("Review_Platform")}
                  </CustomizedTableCell>
                  <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                    {stepperData?.platform === "google"
                      ? t("Google")
                      : "facebook"
                      ? t("Facebook")
                      : stepperData?.platform}
                  </CustomizedTableCell>
                  <CustomizedTableCell align="right">
                    <BorderColorOutlinedIcon
                      cursor={"pointer"}
                      onClick={EditStep1}
                    />
                    {/* <MoreHorizIcon sx={{marginLeft:"8px", color:"#1B2430"}} cursor={"pointer"} /> */}
                  </CustomizedTableCell>
                </CustomizedTableRow>

                <CustomizedTableRow tabIndex={-1}>
                  <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                    {t("What_negative_feedback?")}
                  </CustomizedTableCell>
                  <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                    {stepperData?.negative_feedback_type === "private-survey"
                      ? t("Send_private_surveyy")
                      : "review-platform"
                      ? t("review_platform")
                      : "custom-url"
                      ? t("Send_to_custom_url")
                      : ""}
                  </CustomizedTableCell>
                  <CustomizedTableCell align="right">
                    <BorderColorOutlinedIcon
                      cursor={"pointer"}
                      onClick={EditStep1}
                    />
                    {/* <MoreHorizIcon sx={{marginLeft:"8px", color:"#1B2430"}} cursor={"pointer"} /> */}
                  </CustomizedTableCell>
                </CustomizedTableRow>

                <CustomizedTableRow>
                  <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                    {t("Email")}
                  </CustomizedTableCell>
                  <CustomizedTableCell
                    sx={{
                      cursor: "pointer",
                      width: "500px !important",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                    align="left"
                  >
                    {stepperData?.survey_email?.length > 0 &&
                      (stepperData?.survey_email?.length === 1 ? (
                        stepperData?.survey_email.toString()
                      ) : (
                        <Typography
                          sx={{
                            "&:hover ": {
                              //  background: "#c2cbcf",

                              color: "#06BDFF",
                            },
                          }}
                          onClick={EditStep1}
                        >
                          {t("Multiple_Emails")}
                        </Typography>
                      ))}
                  </CustomizedTableCell>
                  <CustomizedTableCell align="right">
                    <BorderColorOutlinedIcon
                      cursor={"pointer"}
                      onClick={EditStep1}
                    />
                    {/* <MoreHorizIcon sx={{marginLeft:"8px", color:"#1B2430"}} cursor={"pointer"} /> */}
                  </CustomizedTableCell>
                </CustomizedTableRow>

                <CustomizedTableRow tabIndex={-1}>
                  <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                    {t("Email_language")}
                  </CustomizedTableCell>
                  <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                    {stepperData?.language}
                  </CustomizedTableCell>
                  <CustomizedTableCell align="right">
                    <BorderColorOutlinedIcon
                      cursor={"pointer"}
                      onClick={EditStep3}
                    />{" "}
                    {/* <MoreHorizIcon sx={{marginLeft:"8px", color:"#1B2430"}} cursor={"pointer"} /> */}
                  </CustomizedTableCell>
                </CustomizedTableRow>

                <CustomizedTableRow tabIndex={-1}>
                  <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                    {t("Preview_your_email")}
                  </CustomizedTableCell>
                  <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                    {""}
                  </CustomizedTableCell>
                  <CustomizedTableCell align="right">
                    <BorderColorOutlinedIcon
                      cursor={"pointer"}
                      onClick={EditStep2}
                    />{" "}
                    {/* <MoreHorizIcon sx={{marginLeft:"8px", color:"#1B2430"}} cursor={"pointer"} /> */}
                  </CustomizedTableCell>
                </CustomizedTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box className={classes.stepMainbox}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ padding: "24px", position: "relative" }}
            >
              <LazyLoadImage
                loading="lazy"
                src={
                  loadImg
                    ? noImageGray
                    : surveyImageDisplayStepThree
                    ? surveyImageDisplayStepThree
                    : stepperData?.emailTemplateData?.image_url
                }
                beforeLoad={handleBeforeLOad}
                afterLoad={handleAfterLOad}
                style={{
                  width: "100%",
                  height: "560px",
                  borderRadius: "8px",
                }}
                PlaceholderSrc={noImageGray}
                alt="Image Alt"
                effect="blur"
              />
              {/* <img style={{ width: "100%", maxHeight: 660 }} src={Email} /> */}
              {/* <Button
                variant="text"
                className={classes.pictureModalButton}
                onClick={handleOpenPictureModal}
              >
                Edit
              </Button> */}
            </Grid>
            {stepperData?.locationIds?.length > 0 && (
              <Typography
                className={classes.stepLocation1}
                sx={{ paddingLeft: "10px", paddingRight: "10px" }}
              >
                {/* {t("Amsterdam_review")}{" "} */}
                {stepperData?.emailTemplateData?.heading}
              </Typography>
            )}
            <Typography
              className={classes.stepLocationSubhead1}
              sx={{ paddingTop: "21px" }}
            >
              {stepperData?.emailTemplateData?.description}
            </Typography>
            {/* <Container sx={{ paddingY: "12px" }}>
              <Grid container spacing={2}>
                <Grid item xs={2} sm={2} md={2} lg={3}></Grid>

                <Grid item xs={2} sm={2} md={2} lg={1.5}>
                  <Box className={classes.emojiBox1}>
                    <img src={Face1} className={classes.socialBoxImage} />
                    <Typography className={classes.emojiBoxText}>
                      {t("Excellent")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={1.5}>
                  <Box className={classes.emojiBox2}>
                    <img src={Face4} className={classes.socialBoxImage} />
                    <Typography className={classes.emojiBoxText}>
                      {t("Good")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={1.5}>
                  <Box className={classes.emojiBox3}>
                    <img src={Face2} className={classes.socialBoxImage} />
                    <Typography className={classes.emojiBoxText}>
                      {t("Okk")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={1.5}>
                  <Box className={classes.emojiBox4}>
                    <img src={Face3} className={classes.socialBoxImage} />
                    <Typography className={classes.emojiBoxText}>
                      {t("Bad")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={3}></Grid>
              </Grid>
            </Container> */}
            {/* <Box className={classes.emojiRating}>
              <img src={ExcellentImg} className={classes.emojiSize} />

              <img src={GoodImg} className={classes.emojiSize} />

              <img src={OkImg} className={classes.emojiSize} />

              <img src={BadImg} className={classes.emojiSize} />
            </Box> */}

            <Grid container spacing={2} sx={{ paddingY: "21px" }}>
              <Grid item xs={2} sm={2} md={2} lg={3}></Grid>

              <Grid item xs={2} sm={2} md={2} lg={1.5} sx={{ height: "122px" }}>
                <Box className={classes.emojiBox1}>
                  {/*   <img src={Face1} className={classes.socialBoxImage} /> */}
                  <div style={{ height: "79px" }}>
                    <LazyLoadImage
                      loading="lazy"
                      src={Face1}
                      className={classes.socialBoxImage}
                      PlaceholderSrc={blurImage}
                      alt="Image Alt"
                      effect="blur"
                    />
                  </div>

                  <Typography className={classes.emojiBoxText}>
                    {t("Excellent")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={1.5} sx={{ height: "122px" }}>
                <Box className={classes.emojiBox2}>
                  {/*   <img src={Face4} className={classes.socialBoxImage} /> */}
                  <div style={{ height: "79px" }}>
                    <LazyLoadImage
                      loading="lazy"
                      src={Face2}
                      className={classes.socialBoxImage}
                      PlaceholderSrc={blurImage}
                      alt="Image Alt"
                      effect="blur"
                    />
                  </div>

                  <Typography className={classes.emojiBoxText}>
                    {t("Good")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={1.5} sx={{ height: "122px" }}>
                <Box className={classes.emojiBox3}>
                  {/*   <img src={Face2} className={classes.socialBoxImage} /> */}
                  <div style={{ height: "79px" }}>
                    <LazyLoadImage
                      loading="lazy"
                      src={Face4}
                      className={classes.socialBoxImage}
                      PlaceholderSrc={blurImage}
                      alt="Image Alt"
                      effect="blur"
                    />
                  </div>

                  <Typography className={classes.emojiBoxText}>
                    {t("Okay")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={1.5} sx={{ height: "122px" }}>
                <Box className={classes.emojiBox4}>
                  {/*   <img src={Face3} className={classes.socialBoxImage} /> */}
                  <div style={{ height: "79px" }}>
                    {" "}
                    <LazyLoadImage
                      loading="lazy"
                      src={Face3}
                      className={classes.socialBoxImage}
                      PlaceholderSrc={blurImage}
                      alt="Image Alt"
                      effect="blur"
                    />
                  </div>

                  <Typography className={classes.emojiBoxText}>
                    {t("Bad")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={3}></Grid>
            </Grid>
            <Typography
              className={classes.stepLocation1}
              sx={{ paddingY: "21px" }}
            >
              {t("Thanks_for_feedback")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Step4;
