import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  TextField,
  Alert as MuiAlert,
  Dialog,
} from "@mui/material";
import "./model.css";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { api } from "../../../../contexts/JWTContext";
import ReviewModel from "./ReviewModel";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  mainHeadingContainer: {
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  mainKeyWordContainer: {
    paddingTop: "15px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    paddingBottom: "8px",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
}));

const PostAIModal = ({
  open,
  handleCloseTestModel,
  textPresentPrompt,
  setTextPresentGeneratedContent,
  textPresentGeneratedContent,
  reviewsData,
  dummyReviewsData,
  allReviewSource,
  selectedTab,
  selectedRating,
  handleDisplayNames,
  defaultLocation,
  getLocation,
  present,
}) => {
  const classes = useStyles();
  const gridRef = useRef();
  const { t } = useTranslation();

  const [ratingValue, setRatingValue] = useState(selectedRating);

  const [reviewsValue, setReviewsValue] = useState(null);
  const [reviewToReplace, setReviewToReplace] = useState("");
  const [promptToTest, setPromptToTest] = useState(textPresentPrompt);
  const [addReview, setAddReview] = useState(false);
  const [reviewValue, setReviewValue] = useState("");
  const [testLoading, setTestLoading] = useState(false);
  const [openReviewModel, setOpenReviewModel] = useState(false);
  const [selectedReviewData, setSelectedReviewData] = useState(null);
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (reviewValue !== "") {
      const delayDebounceFn = setTimeout(() => {
        handleChangeReviewsInPrompt(reviewValue, selectedReviewData);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [reviewValue]);

  useEffect(() => {
    if (open) {
      handlePromptSetOnOpen();
    }
  }, [open]);

  const handlePromptSetOnOpen = () => {
    let newStr = textPresentPrompt;
    if (newStr?.includes("{{-SingularPronoun-}}")) {
      newStr = newStr.replace("{{-SingularPronoun-}}", "I");
    } else if (newStr?.includes("{{-PluralPronoun-}}")) {
      newStr = newStr.replace("{{-PluralPronoun-}}", "We");
    }
    if (present === false) {
      if (newStr?.includes("{{-client-}}")) {
        newStr = newStr.replace(
          "{{-client-}}",
          userData?.firstName + " " + userData?.lastName
        );
      }
    }

    if (defaultLocation !== null) {
      if (newStr?.includes("{{-locationName-}}")) {
        newStr = newStr.replace("{{-locationName-}}", defaultLocation?.name);
        newStr = newStr.replace(
          "{{-cityName-}}",
          defaultLocation?.city !== null
            ? defaultLocation?.city
            : "{{-cityName-}}"
        );
        if (defaultLocation?.businessEmail !== null) {
          newStr = newStr.replace(
            "{{businessEmail}}",
            defaultLocation?.businessEmail !== null
              ? defaultLocation?.businessEmail
              : "{{businessEmail}}"
          );
        }
      }
    }

    setPromptToTest(newStr);

    if (selectedRating === "one") {
      setRatingValue(1);
      handleChangeRatingOnOpen(1, newStr);
    } else if (selectedRating === "two") {
      setRatingValue(2);
      handleChangeRatingOnOpen(2, newStr);
    } else if (selectedRating === "three") {
      setRatingValue(3);
      handleChangeRatingOnOpen(3, newStr);
    } else if (selectedRating === "four") {
      setRatingValue(4);
      handleChangeRatingOnOpen(4, newStr);
    } else if (selectedRating === "five") {
      setRatingValue(5);
      handleChangeRatingOnOpen(5, newStr);
    }
  };

  const starRatings = [
    { id: 1, name: t("1_star"), value: 1 },
    { id: 2, name: t("2_star"), value: 2 },
    { id: 3, name: t("3_star"), value: 3 },
    { id: 4, name: t("4_star"), value: 4 },
    { id: 5, name: t("5_star"), value: 5 },
  ];
  const handleReset = () => {
    setTextPresentGeneratedContent("");
    // setRatingValue(selectedRating);
    setReviewValue("");
    setReviewToReplace("");
    setAddReview(false);
    setReviewsValue("");
    setPromptToTest(textPresentPrompt);
    handlePromptSetOnOpen();
  };

  const handleChangeRatingOnOpen = (event, stringValue) => {
    let value = "";

    if (event === 1) {
      value = 1;
    } else if (event === 2) {
      value = 2;
    } else if (event === 3) {
      value = 3;
    } else if (event === 4) {
      value = 4;
    } else if (event === 5) {
      value = 5;
    }
    const matchedItem = starRatings?.find((item) => item?.value === value);

    let newStr = stringValue;

    if (newStr?.includes("{{-ratingStars-}}")) {
      newStr = newStr?.replaceAll("{{-ratingStars-}}", matchedItem?.name);
      //  setPromptToTest(str);
    } else {
      starRatings?.forEach((item) => {
        if (newStr?.includes(item?.name)) {
          newStr = newStr?.replaceAll(item?.name, value);
          /*     setPromptToTest((prevPrompt) =>
            prevPrompt.replaceAll(item?.name, value)
          ); */
        }
      });
    }

    if (defaultLocation !== null) {
      if (newStr?.includes("{{-locationName-}}")) {
        newStr = newStr.replace("{{-locationName-}}", defaultLocation?.name);
        newStr = newStr.replace(
          "{{-cityName-}}",
          defaultLocation?.city !== null
            ? defaultLocation?.city
            : "{{-cityName-}}"
        );
        if (defaultLocation?.businessEmail !== null) {
          newStr = newStr.replace(
            "{{businessEmail}}",
            defaultLocation?.businessEmail !== null
              ? defaultLocation?.businessEmail
              : "{{businessEmail}}"
          );
        }
      }
    }
    setPromptToTest(newStr);

    //  setRatingValue(value);
  };
  const handleChangeRating = (event) => {
    const value = event.target.value;

    const matchedItem = starRatings.find((item) => item?.value === value);

    if (promptToTest?.includes("{{-ratingStars-}}")) {
      let str = promptToTest.replaceAll("{{-ratingStars-}}", matchedItem?.name);
      setPromptToTest(str);
    } else {
      starRatings?.forEach((item) => {
        if (promptToTest?.includes(item?.name)) {
          let str = promptToTest.replaceAll(item?.value, value);
          setPromptToTest(str);
        }
      });
    }

    setRatingValue(value);
  };
  const handleSelectReview = (event) => {
    setReviewsValue(event.target.value);
    if (event.target.length > 0) {
      if (promptToTest?.includes("{{-reviewText-}}")) {
        let str = promptToTest.replace("{{-reviewText-}}", event.target.value);
        setPromptToTest(str);
      } else if (promptToTest?.includes(reviewToReplace)) {
        let str = promptToTest.replace(reviewToReplace, event.target.value);
        setPromptToTest(str);
      }
    } else {
      if (promptToTest?.includes("{{-reviewText-}}")) {
        let str = promptToTest.replace("{{-reviewText-}}", event.target.value);
        setPromptToTest(str);
      } else if (promptToTest?.includes(reviewToReplace)) {
        let str = promptToTest.replace(reviewToReplace, "{{-reviewText-}}");
        setPromptToTest(str);
      }
    }

    setReviewToReplace(event.target.value);
  };
  const handleChangeReviewsInPrompt = (value, item) => {
    let str = promptToTest;
    if (str?.includes("{{-reviewText-}}")) {
      str = str.replace("{{-reviewText-}}", value);

      setPromptToTest(str);
    } else if (str?.includes(reviewToReplace)) {
      str = str.replace(reviewToReplace, value);
      setPromptToTest(str);
    }

    if (str?.includes("{{-client-}}")) {
      str = str.replace(
        "{{-client-}}",
        item !== null
          ? item?.reviewerTitle
          : userData?.firstName + " " + userData?.lastName
      );
      setPromptToTest(str);
    } else if (str?.includes(userData?.firstName + " " + userData?.lastName)) {
      str = str.replace(
        userData?.firstName + " " + userData?.lastName,
        item !== null
          ? item?.reviewerTitle
          : userData?.firstName + " " + userData?.lastName
      );
      setPromptToTest(str);
    } else if (str?.includes(selectedReviewData?.reviewerTitle)) {
      str = str.replace(selectedReviewData?.reviewerTitle, item?.reviewerTitle);
      setPromptToTest(str);
    }

    if (item !== null && selectedReviewData !== null) {
      if (str?.includes(defaultLocation?.name)) {
        str = str.replace(
          defaultLocation?.name,
          handleDisplayNames(item?.locationId)
        );
        setPromptToTest(str);
      } else if (
        str?.includes(getLocation(selectedReviewData?.locationId).name)
      ) {
        str = str.replace(
          getLocation(selectedReviewData?.locationId).name,
          handleDisplayNames(item?.locationId)
        );
        setPromptToTest(str);
      }
    } else {
      if (str?.includes(defaultLocation?.name)) {
        str = str.replace(
          defaultLocation?.name,
          handleDisplayNames(defaultLocation?.id)
        );
        setPromptToTest(str);
      } else if (str?.includes(getLocation(defaultLocation?.locationId).name)) {
        str = str.replace(
          getLocation(defaultLocation?.locationId).name,
          handleDisplayNames(item?.locationId)
        );
        setPromptToTest(str);
      }
    }

    if (item !== null && selectedReviewData !== null) {
      if (str?.includes(defaultLocation?.city)) {
        str = str.replace(
          defaultLocation?.city,
          getLocation(item?.locationId).city
        );
        setPromptToTest(str);
      } else if (
        str?.includes(getLocation(selectedReviewData?.locationId).city)
      ) {
        str = str.replace(
          getLocation(selectedReviewData?.locationId).city,
          getLocation(item?.locationId).city
        );
        setPromptToTest(str);
      }
    } /* else {
      if (str?.includes(defaultLocation?.city)) {
        console.log(
          getLocation(item?.locationId).city,
          "getLocation(item?.locationId).city"
        );
        str = str.replace(
          defaultLocation?.city,
          getLocation(defaultLocation?.locationId).city
        );
        setPromptToTest(str);
      } else if (str?.includes(getLocation(defaultLocation?.locationId).city)) {
        console.log(
          getLocation(defaultLocation?.locationId).city,
          handleDisplayNames(defaultLocation?.locationId),
          "getLocation(selectedReviewData?.locationId).name"
        );
        str = str.replace(
          getLocation(defaultLocation?.locationId).city,
          getLocation(item?.locationId).city
        );
        setPromptToTest(str);
      }
    } */

    setSelectedReviewData(item);
    setReviewToReplace(value);
  };
  const handleClickAddReview = () => {
    setAddReview(true);
    if (promptToTest?.includes(reviewToReplace)) {
      let str = promptToTest.replace(reviewToReplace, "{{review}}");
      setPromptToTest(str);
    }
    setReviewsValue("");
  };
  const handleClickUseDefaultReview = () => {
    handleOpenReviewModel();
  };

  const handleTestPrompt = async () => {
    setTestLoading(true);
    try {
      const res = await api.post(`openAi/generateContent`, {
        prompt: promptToTest,
        requestedFor: "review"
      });
      if (res.status === 200) {
        setTextPresentGeneratedContent(res?.data?.data);
        scrollDown();
        setTestLoading(false);
      }
    } catch (error) {
      setTestLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  const scrollDown = () => {
    if (gridRef.current) {
      const grid = gridRef.current;
      grid.scrollTop = grid.scrollHeight - grid.clientHeight;
    }
  };

  const handleCloseReviewModel = async () => {
    setOpenReviewModel(false);
  };
  const handleOpenReviewModel = async () => {
    setOpenReviewModel(true);
  };

  return (
    <>
      <Dialog
        maxWidth
        maxHeight
        open={open}
        // onClose={onCancel}
        sx={{ "& .MuiDialog-paper": { width: "850px", height: "550px" } }}
      >
        <DialogTitle sx={{ padding: "12px" }}>
          <Box sx={{ display: "flex" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography className={classes.titleText}>
                {t("test_prompt")}
              </Typography>
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              sx={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              <IconButton
                autoFocus
                onClick={() => {
                  handleCloseTestModel();

                  handleReset();
                }}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
          <Grid className={classes.headingTextGrid}>
            <Typography className={classes.headingText}>
              {t("test_prompt_subheading")}
            </Typography>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent ref={gridRef}>
          {" "}
          <Grid container>
            <Divider variant="middle" />
            <Grid container>
              {" "}
              <Grid
                sx={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.mainKeyWordContainer}
              >
                <Typography className={classes.labelText}>
                  {t("Select_Rating")}
                </Typography>
                <FormControl fullWidth>
                  <Select value={ratingValue} onChange={handleChangeRating}>
                    {starRatings?.map((item) => (
                      <MenuItem key={item.id} value={item?.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {selectedTab !== 1 && (
                <Grid
                  sx={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.mainKeyWordContainer}
                >
                  <Typography className={classes.labelText}>
                    {t("Review")}
                  </Typography>

                  <TextField
                    id="standard-basic"
                    type="text"
                    multiline
                    fullWidth
                    rows={5.8}
                    maxRows={10}
                    placeholder={t("Enter_Review")}
                    //  helperText={t("Enter_extra_text")}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleChangeReviewsInPrompt(event.target.value);
                      }
                    }}
                    value={reviewValue}
                    onChange={(e) => {
                      setReviewValue(e.target.value);
                      if (e.target.value.length === 0) {
                        if (promptToTest?.includes(reviewToReplace)) {
                          let str = promptToTest.replace(
                            reviewToReplace,
                            "{{-reviewText-}}"
                          );
                          setPromptToTest(str);
                        }
                      }
                    }}
                    InputProps={{
                      disableunderline: true,
                    }}
                  />

                  {/*   <FormControl fullWidth>
                    <Select value={reviewsValue} onChange={handleSelectReview}>
                      {reviewsData?.length > 0
                        ? reviewsData?.map((item) => (
                            <MenuItem
                              style={{
                                maxWidth: "850px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                              value={item?.ratingText}
                            >
                              {item?.ratingText}
                            </MenuItem>
                          ))
                        : dummyReviewsData?.map((item) => (
                            <MenuItem value={item?.ratingText}>
                              {item?.ratingText}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl> */}

                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#06BDFF",
                      cursor: "pointer",
                      paddingTop: "5px",
                      textAlign: "right",
                    }}
                    onClick={handleOpenReviewModel}
                  >
                    {t("useDefault")}
                  </Typography>
                </Grid>
              )}
              <Grid
                sx={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.mainKeyWordContainer}
              >
                <Typography className={classes.labelText}>
                  {t("Generated_prompt")}
                </Typography>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    border: "1px solid #E0E0E0",
                    padding: "12px",
                    borderRadius: "8px",
                    marginBottom: "8px",
                    overflow: "hidden", // This property hides any content that overflows the container
                    wordWrap: "break-word",
                  }}
                >
                  <Typography>{promptToTest}</Typography>
                </Grid>
              </Grid>
              {textPresentGeneratedContent?.length > 0 && (
                <Grid
                  sx={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.mainKeyWordContainer}
                >
                  <Typography className={classes.labelText}>
                    {t("Generated_review_response")}
                  </Typography>

                  <TextField
                    multiline
                    fullWidth
                    placeholder={t("What_Would_Like_Placeholder")}
                    value={textPresentGeneratedContent}
                    name="Description"
                  />
                </Grid>
              )}
            </Grid>

            {/* </DialogActions> */}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ padding: "20px", boxShadow: "0px -1px 0px #E0E0E0" }}
        >
          <Grid container>
            <Grid item xs={8} sm={10} md={10} lg={10}></Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              sx={{ justifyContent: "flex-end" }}
            >
              {textPresentGeneratedContent?.length === 0 && (
                <CommonButton
                  onSubmit={handleTestPrompt}
                  label={t("test_prompt")}
                  alignment={true}
                  disabled={testLoading || (present && reviewValue === "")}
                  isLoading={testLoading}
                />
              )}
              {textPresentGeneratedContent?.length !== 0 && (
                <CommonButton
                  onSubmit={handleReset}
                  label={t("test_prompt_again")}
                  alignment={true}
                  disabled={testLoading || (present && reviewValue === "")}
                  isLoading={testLoading}
                />
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {openReviewModel && (
        <ReviewModel
          open={openReviewModel}
          title={`${t("Reviewss")}`}
          handleCloseReviewModel={handleCloseReviewModel}
          reviewsData={reviewsData?.length > 0 ? reviewsData : dummyReviewsData}
          allReviewSource={allReviewSource}
          setReviewsValue={setReviewValue}
          reviewValue={reviewValue}
          handleChangeReviewsInPrompt={handleChangeReviewsInPrompt}
          handleDisplayNames={handleDisplayNames}
          setSelectedReviewData={setSelectedReviewData}
        />
      )}
    </>
  );
};

export default PostAIModal;
