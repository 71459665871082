import React, { useEffect, useState, useRef } from "react";
import { api } from "../../../../../../contexts/JWTContext";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Autocomplete,
  TextField,
  createFilterOptions,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import LogoImageModal from "./UploadModels/CoverImageModal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { languagesData } from "../../../../../../assets/defaultLanguage";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CommonButton from "../../../../../../components/CustomComponents/Buttons/CommonButton";
import Loader from "../../../../../../components/Loaders/Loader";
import Collapsible from "../../../../../../components/CustomComponents/Collapsible.js";
import { useLocation, useNavigate } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
//import EmotionAiFilterModal from "./../../Scheduler/FilterModel";
import EmotionAiFilterModal from "./../../Scheduler/FilterModelNew";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../Style/style";
import DatePickerComponent from "../../../../../../components/CustomComponents/DateRangePicker/RangePicker";

const FetchModel = ({
  title,
  open,
  handleCloseNewRequestModel,
  companyUser,
  setCompanyUser,
  others,
  setOthers,
  handleEmailEmotionAiReport,
  pdfChecked,
  setPdfChecked,
  xlsChecked,
  setXlsChecked,
  setSelectedCompanyUsers,
  selectedCompanyUsers,
  totalEmailsArr,
  handleAddNewUserEmailsChange,
  addNewUserEmials,
  handleResetOthers,
  handleButtonClick,
  handleFileUpload,
  selectedFile,
  fileInputRef,
  handleReopenModal,
  handleRemoveFile,
  exportReportLoading,
  setEmailSubject,
  emailSubject,
  setEmailBody,
  emailBody,
  handleReviewsChecked,
  reviewsChecked,
  setReviewsChecked,
  handleChangeLanguageForScheduler,
  languageSchedular,
  setDateRange,
  dateRange,
  setLanguageSchedular,
  handleChangeRangeEndDateSchedule,
  endDateRangeSchedule,
  handleChangeRangeStartDateSchedule,
  startDateRangeSchedule,
  setStartDateRangeSchedule,
  setEndDateRangeSchedule,
  scheduleLocations,
  setScheduleLocations,
  scheduleGroups,
  setScheduleGroups,
  setScheduleLanguage,
  scheduleLanguage,
  setScheduleSentiments,
  scheduleSentiments,
  allLocations,
  allGroups,
  startDate,
  endDate,
  handleResetScheduleFiltersModel,
  setEmailTitle,
  featuresArray,
  setSelectedFeatures,
  selectedFeatures,
  handleChangeFeature,
  fromHeader,
  handleChangeManageSections,
  sectionFormat,
  manageSectionOptions,
  sectionOption,
  uploadedImages,
  setUploadedImages,
  images,
  setImages,
  countries,
  compareRange,
  defaultLocation,
  locationAddPermission,
  locationViewPermission,
  autoSelectHeader,
  isSentimentalAnalysisDone,
  selectAllGroups,
  setSelectAllGroupsHeader,
  setSelectAllLocations,
  selectAllLocations,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const textArea = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const i18nextLng = localStorage.getItem("i18nextLng");
  const currentPath = location.pathname.replace(/\/+$/, "");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [expandedPanels, setExpandedPanels] = useState(["configureReport"]);
  const [dateRangeError, setDateRangeError] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [openModalFilter, setOpenFilterModel] = useState(false);
  const [selectAllFeatures, setSelectAllFeatures] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const filter = createFilterOptions();
  const [openLogoUploadModal, setOpenLogoUploadModel] = useState(false);
  const [selectedFileForImage, setSelectedFileForImage] = useState(null);
  const [autoSelect, setAutoSelect] = useState(autoSelectHeader);
  const [selectAllFiltersLocations, setSelectAllFiltersLocations] =
    useState(selectAllLocations);
  const [selectAllFiltersGroups, setSelectAllFiltersGroups] =
    useState(selectAllGroups);

  useEffect(() => {
    getAllUsers();
    const userLang = languagesData?.find((item) => item?.value === i18nextLng);
    setLanguageSchedular(userLang);
  }, [open]);

  const handleChangePdfChecked = (e) => {
    setPdfChecked(e.target.checked);
    if (xlsChecked === false) {
      setReviewsChecked(false);
    }
  };
  const handleChangeXlsChecked = (e) => {
    setXlsChecked(e.target.checked);
    if (pdfChecked === false) {
      setReviewsChecked(false);
    }
    //  setReviewsChecked(false);
  };
  const handleChangeCompanyUser = (e) => {
    setCompanyUser(e.target.checked);
    if (e.target.checked === false) {
      setSelectedCompanyUsers([]);
    }
  };
  const handleChangeOthers = (e) => {
    setOthers(e.target.checked);
    if (e.target.checked === false) {
      handleResetOthers();
    }
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `companyUserRoles?companyId=${companyData?.id}&pageNumber=1&limit=500`
      );
      if (res.status === 200) {
        const activeUsers = res?.data?.data?.rows?.filter(
          (user) =>
            user?.User?.inActive === false &&
            (user?.User?.status === "active" ||
              user?.User?.status === "pending")
        );

        setUsers(activeUsers);
        setIsLoading(false);
      }
    } catch (error) {
      setUsers([]);
      setIsLoading(false);
    }
  };
  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  const handleOpenFilterModel = () => {
    setOpenFilterModel(true);
  };
  const handleCloseFilterModel = (resetValue) => {
    setOpenFilterModel(false);
    if (resetValue !== true) {
      setSelectAllFiltersLocations(selectAllLocations);
      setSelectAllFiltersGroups(selectAllGroups);
    }
  };

  const handleDateRangChange = (e, fromModel, startDateValue, endDateValue) => {
    const value = fromModel ? e : e.target.value;
    setDateRange(value);
    setDateRangeError(value === "");
    const today = new Date();
    let date = dayjs(today);
    let oneDaysBefore = date.subtract(1, "day").endOf("day");
    let oneDaysBeforeDate = oneDaysBefore.toDate();
    if (today !== null && today !== "") {
      if (value === "Yesterday") {
        let oneDaysBefore = date.subtract(1, "day");
        let oneDaysBeforeDateStart = oneDaysBefore.startOf("day").toDate();
        let oneDaysBeforeDateEnd = oneDaysBefore.endOf("day").toDate();

        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(oneDaysBeforeDateStart);

        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneDaysBeforeDateStart
          )}`
        );
      } else if (value === "Today") {
        let todayDayEnd = date.endOf("day");
        let oneDaysBefore = date.startOf("day");
        let oneDaysBeforeDate = oneDaysBefore.startOf("day").toDate();
        setEndDateRangeSchedule(todayDayEnd);
        setStartDateRangeSchedule(oneDaysBeforeDate);

        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneDaysBeforeDate
          ).format("MMM DD, YYYY")}`
        );
      } else if (value === "2days") {
        let twoDaysBefore = date.subtract(2, "day");
        let twoDaysBeforeDate = twoDaysBefore.startOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(twoDaysBeforeDate);

        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            twoDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(oneDaysBeforeDate).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (value === "7days") {
        let sevenDaysBefore = date.subtract(7, "day");
        let sevenDaysBeforeDate = sevenDaysBefore.startOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(sevenDaysBeforeDate);

        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            sevenDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(oneDaysBeforeDate).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (value === "week") {
        let weekBeforeSelectedDate = date.subtract(7, "day");
        let previousMonday = weekBeforeSelectedDate.subtract(
          weekBeforeSelectedDate.day() - 1,
          "day"
        );
        let followingSunday = previousMonday.add(6, "day");
        setEndDateRangeSchedule(followingSunday.endOf("day").toDate());
        setStartDateRangeSchedule(previousMonday.startOf("day").toDate());

        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            previousMonday.toDate()
          ).format("MMM DD, YYYY")} to ${dayjs(followingSunday.toDate()).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (value === "month") {
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore.toDate());
        setStartDateRangeSchedule(startOfMonthBefore.startOf("day").toDate());

        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            startOfMonthBefore.toDate()
          ).format("MMM DD, YYYY")} to ${dayjs(
            endOfMonthBefore.toDate()
          ).format("MMM DD, YYYY")}`
        );
      } else if (value === "14days") {
        let fourteenDaysBefore = date.subtract(14, "day");
        let fourteenDaysBeforeDate = fourteenDaysBefore.startOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(fourteenDaysBeforeDate);

        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            fourteenDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(oneDaysBeforeDate).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (value === "30days") {
        let oneMonthBefore = date.subtract(30, "day");
        let oneMonthBeforeDate = oneMonthBefore.startOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(oneMonthBeforeDate);

        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneMonthBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(oneDaysBeforeDate).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (value === "60days") {
        let sixtyDaysBefore = date.subtract(60, "day");
        let sixtyDaysBeforeDate = sixtyDaysBefore.startOf("day").toDate();

        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(sixtyDaysBeforeDate);

        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            sixtyDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(oneDaysBeforeDate).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (value === "90days") {
        let ninetyDaysBefore = date.subtract(90, "day");
        let ninetyDaysBeforeDate = ninetyDaysBefore.startOf("day").toDate();

        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(ninetyDaysBeforeDate);

        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            ninetyDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(oneDaysBeforeDate).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (value === "6months") {
        let date = dayjs(today);

        let sixMonthsBefore = date.subtract(6, "month");
        let sixMonthsBeforeDate = sixMonthsBefore.startOf("month").toDate();

        setStartDateRangeSchedule(sixMonthsBeforeDate);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore.toDate());
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            sixMonthsBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(endOfMonthBefore).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (value === "12months") {
        let oneYearBefore = date.subtract(12, "month");
        let oneYearBeforeDate = oneYearBefore.startOf("month").toDate();

        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore.toDate());
        setStartDateRangeSchedule(oneYearBeforeDate);

        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneYearBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(endOfMonthBefore).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (value === "custom") {
        if (fromModel === false) {
          setEndDateRangeSchedule(endDate);
          setStartDateRangeSchedule(startDate);
          setEmailBody(
            `Dear customer Please find attached emotion AI report for the ${dayjs(
              startDate
            ).format("MMM DD, YYYY")} to ${dayjs(endDate).format(
              "MMM DD, YYYY"
            )}`
          );
        } else {
          setEndDateRangeSchedule(endDateValue);
          setStartDateRangeSchedule(startDateValue);
          setEmailBody(
            `Dear customer Please find attached emotion AI report for the ${dayjs(
              startDateValue
            ).format("MMM DD, YYYY")} to ${dayjs(endDateValue).format(
              "MMM DD, YYYY"
            )}`
          );
        }
      }
    }
  };

  const handleOpenLogoUploadModal = () => {
    setOpenLogoUploadModel(true);
  };
  const handleCloseLogoUploadModal = () => {
    setOpenLogoUploadModel(false);
  };

  const handleRemoveImageFile = () => {
    setSelectedFileForImage(null);
    setUploadedImages([]);
    setImages([]);
  };

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              width: "50%",
              height: "550px",
            },
          }}
          open={open}
        >
          <DialogTitle className={classes.emailReportTitle}>
            {title}
          </DialogTitle>

          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid container>
                <Box sx={{ marginTop: "12px", width: "100%" }}>
                  <Collapsible
                    title={t("configure_report")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("configureReport")}
                    id={"configureReport"}
                    ComponentToRender={
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ paddingTop: "0px" }}
                          >
                            {currentPath !== "/user/reviews" && (
                              <>
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("report_format")}
                                </Typography>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      sx={{ marginLeft: "-10px" }}
                                    >
                                      <Grid item xs={3}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Checkbox
                                            onChange={handleChangePdfChecked}
                                            checked={pdfChecked}
                                          />
                                          <Typography>{"PDF"}</Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Checkbox
                                            onChange={handleChangeXlsChecked}
                                            checked={xlsChecked}
                                          />
                                          <Typography>{"XLS"}</Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={6}></Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={8} md={8} lg={8}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkbox
                                        onChange={handleReviewsChecked}
                                        checked={reviewsChecked}
                                        sx={{
                                          paddingLeft: "0px",
                                        }}
                                      />
                                      <Typography
                                        className={
                                          classes.scheduleReviewTypoEmail
                                        }
                                      >
                                        {t("add_reviews")}
                                      </Typography>
                                    </Box>

                                    {reviewsChecked && (
                                      <Box className={classes.reviewsubHeadBox}>
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={1}
                                            sm={1}
                                            md={0.8}
                                            lg={0.8}
                                          >
                                            <InfoOutlinedIcon
                                              className={
                                                classes.scheduleReviewsIcon
                                              }
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={11}
                                            sm={11}
                                            md={11.2}
                                            lg={11.2}
                                            className={
                                              classes.scheduleReviewInfoGrid
                                            }
                                          >
                                            <Typography
                                              className={
                                                classes.scheduleReviewInfoTypoEmail
                                              }
                                            >
                                              {t("add_reviews_info")}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Typography
                                    className={classes.emailReportLabel}
                                  >
                                    {t("upload_logo")}
                                  </Typography>

                                  <Box mt={3} ml={-3}>
                                    <CommonButton
                                      onSubmit={handleOpenLogoUploadModal}
                                      label={t("Select_logo")}
                                    />
                                  </Box>
                                  <Box>
                                    {selectedFileForImage ? (
                                      <Box className={classes.fileBoxStyle}>
                                        <Typography
                                          className={classes.selectedFileTypo}
                                        >
                                          {selectedFileForImage?.name}
                                        </Typography>
                                        <IconButton
                                          className={classes.removeFileButton}
                                          onClick={() =>
                                            handleRemoveImageFile()
                                          }
                                        >
                                          <DeleteIcon
                                            fontSize="small"
                                            className={classes.deleteIcon}
                                          />
                                        </IconButton>
                                      </Box>
                                    ) : (
                                      <p>{t("")}</p>
                                    )}
                                  </Box>
                                </Grid>
                              </>
                            )}
                          </Grid>

                          {fromHeader && (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("manage_sections")}
                              </Typography>
                              <Autocomplete
                                id="tags-outlined"
                                options={manageSectionOptions}
                                fullWidth
                                value={sectionOption}
                                getOptionLabel={(option) => option?.title}
                                filterSelectedOptions
                                onChange={handleChangeManageSections}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={t("select_section")}
                                  />
                                )}
                              />
                            </Grid>
                          )}

                          {fromHeader && sectionFormat === "specificReport" && (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("select_features")}
                              </Typography>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="tags-outlined"
                                options={featuresArray}
                                getOptionLabel={(option) => option?.name}
                                onChange={(event, value) => {
                                  if (value.find((option) => option.id === 0)) {
                                    if (
                                      featuresArray?.length ===
                                      selectedFeatures?.length
                                    ) {
                                      setSelectedFeatures([]);
                                      setSelectAllFeatures(false);
                                    } else {
                                      setSelectAllFeatures(true);
                                      setSelectedFeatures(featuresArray);
                                    }
                                    handleChangeFeature(featuresArray);
                                  } else {
                                    setSelectedFeatures(value);
                                    handleChangeFeature(value);
                                    if (
                                      value?.length === featuresArray?.length
                                    ) {
                                      setSelectAllFeatures(true);
                                    } else {
                                      setSelectAllFeatures(false);
                                    }
                                  }
                                }}
                                value={selectedFeatures}
                                sx={{ minWidth: 300 }}
                                renderOption={(props, option, { selected }) => {
                                  if (option.id === 0) {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selectAllFeatures}
                                        />
                                        {option?.name}
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={
                                            selected || selectAllFeatures
                                          }
                                        />
                                        {option?.name}
                                      </li>
                                    );
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  if (featuresArray?.length > 0) {
                                    return [
                                      { id: 0, name: t("Select_All") },
                                      ...filtered,
                                    ];
                                  } else {
                                    return [];
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={t("select_features_tooltip")}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={12}
                            sm={4.5}
                            md={4.5}
                            lg={4.5}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("date_range")}
                            </Typography>

                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={dateRange}
                              onChange={(e) => {
                                handleDateRangChange(e, false);
                              }}
                              fullWidth
                              error={dateRangeError}
                              helperText={dateRangeError ? t("Field_req") : ""}
                            >
                              {currentPath === "/user/reviews" && (
                                <MenuItem value="All">{t("All")}</MenuItem>
                              )}

                              <MenuItem value="Today">{t("today")}</MenuItem>
                              <MenuItem value="Yesterday">
                                {t("yesterday")}
                              </MenuItem>
                              <MenuItem value="2days">{t("2days")}</MenuItem>
                              <MenuItem value="7days">{t("7days")}</MenuItem>
                              <MenuItem value="week">{t("week")}</MenuItem>

                              <MenuItem value="14days">{t("14days")}</MenuItem>
                              <MenuItem value="30days">{t("30days")}</MenuItem>
                              <MenuItem value="month">
                                {t("monthValue")}
                              </MenuItem>
                              <MenuItem value="60days">{t("60days")}</MenuItem>
                              <MenuItem value="90days">{t("90days")}</MenuItem>
                              <MenuItem value="6months">
                                {t("6months")}
                              </MenuItem>
                              <MenuItem value="12months">
                                {t("12months")}
                              </MenuItem>
                              <MenuItem value="custom">
                                {t("custom_text")}
                              </MenuItem>
                            </Select>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={4.5}
                            md={4.5}
                            lg={4.5}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("report_language")}
                            </Typography>
                            <Select
                              required
                              style={{ width: "100%" }}
                              key={languageSchedular?.value}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={languageSchedular?.value}
                              onChange={(e, value) => {
                                const userLang = languagesData?.find(
                                  (item) => item?.value === e.target.value
                                );
                                handleChangeLanguageForScheduler(userLang);
                              }}
                            >
                              {languagesData.map((item) => (
                                <MenuItem value={item.value}>
                                  {item.language}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3}
                            lg={3}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Box sx={{ marginTop: "25px" }}>
                              <CommonButton
                                icon={<FilterAltIcon sx={{ color: "#FFFF" }} />}
                                onSubmit={handleOpenFilterModel}
                                label={t("Filters")}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        {/* {dateRange === "custom" &&
                          currentPath !== "/user/reviews" && (
                            <Grid
                              container
                              spacing={2}
                              sx={{ marginTop: "8px" }}
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                sx={{ paddingTop: "0px" }}
                              >
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("Fromm")}
                                </Typography>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    value={
                                      startDateRangeSchedule !== null
                                        ? dayjs(startDateRangeSchedule)
                                        : null
                                    }
                                    format="DD/MM/YYYY"
                                    disableFuture
                                    onChange={(e) => {
                                      const newDate = e
                                        ? dayjs(e).startOf("day").toDate()
                                        : null;
                                      handleChangeRangeStartDateSchedule(
                                        newDate
                                      );
                                    }}
                                    className={classes.whiteBackground}
                                    minDate={dayjs("2005-01-01")}
                                    maxDate={dayjs().endOf("day")}
                                    renderInput={(params) => (
                                      <TextField
                                        autoComplete="off"
                                        fullWidth
                                        {...params}
                                        sx={{ background: "#FFFF" }}
                                        error={startDateError}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                sx={{ paddingTop: "0px" }}
                              >
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("Too")}
                                </Typography>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    disableFuture
                                    value={
                                      endDateRangeSchedule !== null
                                        ? dayjs(endDateRangeSchedule)
                                        : null
                                    }
                                    format="DD/MM/YYYY"
                                    onChange={(e) => {
                                      handleChangeRangeEndDateSchedule(e);
                                    }}
                                    className={classes.whiteBackground}
                                    minDate={dayjs("2005-01-01")}
                                    renderInput={(params) => (
                                      <TextField
                                        error
                                        autoComplete="off"
                                        fullWidth
                                        {...params}
                                        sx={{ background: "#FFFF" }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          )} */}

                        {dateRange === "custom" &&
                          currentPath !== "/user/reviews" && (
                            <DatePickerComponent
                              handleChangeStartDate={
                                handleChangeRangeStartDateSchedule
                              }
                              handleChangeEndDate={
                                handleChangeRangeEndDateSchedule
                              }
                              startDate={
                                startDateRangeSchedule !== null
                                  ? startDateRangeSchedule
                                  : null
                              }
                              endDate={
                                endDateRangeSchedule !== null
                                  ? endDateRangeSchedule
                                  : null
                              }
                              dateRange={dateRange}
                              setDateRange={setDateRange}
                              fromFilters={true}
                              rangeValue={"between"}
                              removePadding={true}
                            />
                          )}
                      </Grid>
                    }
                  />
                </Box>
                <Box sx={{ marginTop: "12px" }}>
                  <Collapsible
                    title={t("configure_emaill")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("configureEmail")}
                    id={"configureEmail"}
                    ComponentToRender={
                      <Grid container>
                        <Grid item xs={12} mt={1}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("select_user_type")}
                          </Typography>
                          <Grid container sx={{ marginLeft: "-10px" }}>
                            <Grid item xs={3}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  onChange={handleChangeCompanyUser}
                                  checked={companyUser}
                                />
                                <Typography>{t("company_users")}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  onChange={handleChangeOthers}
                                  checked={others}
                                />
                                <Typography>{t("others")}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}></Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} mt={1}>
                          {companyUser && users?.length > 0 && (
                            <>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("recipients")}
                              </Typography>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="tags-outlined"
                                options={users?.length > 0 ? users : []}
                                getOptionLabel={(option) =>
                                  option?.User?.firstName !== null &&
                                  option?.User?.lastName !== null
                                    ? option?.User?.firstName
                                        .charAt(0)
                                        .toUpperCase() +
                                      option?.User?.firstName.slice(1) +
                                      " " +
                                      (option?.User?.lastName !== null &&
                                      option?.User?.lastName !== ""
                                        ? option?.User?.lastName
                                            .charAt(0)
                                            .toUpperCase() +
                                          option?.User?.lastName.slice(1) +
                                          " " +
                                          "(" +
                                          option?.User?.email +
                                          ")"
                                        : "")
                                    : option?.User?.email
                                }
                                onChange={(event, value) => {
                                  if (value.find((option) => option.id === 0)) {
                                    if (
                                      users?.length ===
                                      selectedCompanyUsers?.length
                                    ) {
                                      setSelectedCompanyUsers([]);
                                      setSelectAll(false);
                                    } else {
                                      setSelectAll(true);
                                      setSelectedCompanyUsers(users);
                                    }
                                  } else {
                                    setSelectedCompanyUsers(value);
                                    if (value?.length === users?.length) {
                                      setSelectAll(true);
                                    } else {
                                      setSelectAll(false);
                                    }
                                  }
                                }}
                                value={selectedCompanyUsers}
                                sx={{ minWidth: 300 }}
                                renderOption={(props, option, { selected }) => {
                                  if (option.id === 0) {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selectAll}
                                        />
                                        {option?.firstName}
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected || selectAll}
                                        />
                                        {option?.User?.firstName !== null &&
                                        option?.User?.lastName !== null
                                          ? option?.User?.firstName
                                              .charAt(0)
                                              .toUpperCase() +
                                            option?.User?.firstName.slice(1) +
                                            " " +
                                            (option?.User?.lastName !== null &&
                                            option?.User?.lastName !== ""
                                              ? option?.User?.lastName
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                option?.User?.lastName.slice(
                                                  1
                                                ) +
                                                " " +
                                                "(" +
                                                option?.User?.email +
                                                ")"
                                              : "")
                                          : option?.User?.email}
                                      </li>
                                    );
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  if (users?.length > 0) {
                                    return [
                                      { id: 0, firstName: t("Select_All") },
                                      ...filtered,
                                    ];
                                  } else {
                                    return [];
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={t("select_user")}
                                  />
                                )}
                              />
                            </>
                          )}
                          {others && (
                            <>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("Email_Address")}
                              </Typography>
                              <TextField
                                id="outlined-basic"
                                multiline
                                rows={5}
                                maxRows={10}
                                ref={textArea}
                                variant="outlined"
                                placeholder={t("Enter_recipients_email_here")}
                                sx={{ marginBottom: "12px" }}
                                value={addNewUserEmials}
                                fullWidth
                                onKeyPress={(event) => {
                                  if (event.key === "Enter") {
                                    handleAddNewUserEmailsChange(
                                      event.target.value + ","
                                    );
                                  }
                                }}
                                onChange={(e) =>
                                  handleAddNewUserEmailsChange(e.target.value)
                                }
                              />
                              <Box className={classes.reviewsubHeadBox}>
                                {totalEmailsArr?.length > 5000 ? (
                                  <Typography
                                    className={classes.reviewsubHeadTextError}
                                  >
                                    {t("max_upload_text")}
                                  </Typography>
                                ) : (
                                  <Typography
                                    className={classes.reviewsubHeadText}
                                  >
                                    {t("send_email_text_emotionAi") +
                                      " " +
                                      totalEmailsArr?.length +
                                      " " +
                                      t("email_addresses")}
                                  </Typography>
                                )}
                              </Box>

                              <Box>
                                <input
                                  type="file"
                                  id="file-email"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  onChange={handleFileUpload}
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                />

                                <Box mt={3} ml={-3}>
                                  <CommonButton
                                    onSubmit={handleButtonClick}
                                    label={t("Browse_File")}
                                  />
                                </Box>

                                <Box>
                                  {selectedFile ? (
                                    <Box className={classes.fileBoxStyle}>
                                      <Typography
                                        onClick={handleReopenModal}
                                        className={classes.selectedFileTypo}
                                      >
                                        {selectedFile}
                                      </Typography>
                                      <IconButton
                                        className={classes.removeFileButton}
                                        onClick={() => handleRemoveFile()}
                                      >
                                        <DeleteIcon
                                          fontSize="small"
                                          className={classes.deleteIcon}
                                        />
                                      </IconButton>
                                    </Box>
                                  ) : (
                                    <p>{t("")}</p>
                                  )}
                                </Box>
                              </Box>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("subjectss")}
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("enter_subject")}
                            defaultValue={emailSubject}
                            fullWidth
                            onChange={(e) => {
                              setEmailSubject(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("body")}
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("enter_body_text")}
                            value={emailBody}
                            fullWidth
                            onChange={(e) => {
                              setEmailBody(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </Box>
              </Grid>
            )}
          </DialogContent>

          <DialogActions sx={{ marginRight: "12px" }}>
            <CommonButton
              label={t("Cancel")}
              displayWhite="true"
              onSubmit={handleCloseNewRequestModel}
            />

            <CommonButton
              label={t("send")}
              onSubmit={handleEmailEmotionAiReport}
              disabled={
                pdfChecked === false && xlsChecked === false
                  ? true
                  : companyUser === false && others === false
                  ? true
                  : selectedCompanyUsers?.length === 0 &&
                    totalEmailsArr?.length === 0
                  ? true
                  : fromHeader &&
                    sectionFormat === "specificReport" &&
                    selectedFeatures?.length === 0
                  ? true
                  : exportReportLoading === true
                  ? true
                  : emailSubject === ""
                  ? true
                  : false
              }
              isLoading={exportReportLoading}
            />
          </DialogActions>
          {openModalFilter && (
            <EmotionAiFilterModal
              locations={scheduleLocations}
              setLocations={setScheduleLocations}
              allLocations={allLocations}
              setSlectedGroups={setScheduleGroups}
              selectedGroups={scheduleGroups}
              allGroups={allGroups}
              sentimentValue={scheduleSentiments}
              setScheduleSentiments={setScheduleSentiments}
              startDateRangeSchedule={startDateRangeSchedule}
              setStartDateRangeSchedule={setStartDateRangeSchedule}
              setEndDateRangeSchedule={setEndDateRangeSchedule}
              endDateRangeSchedule={endDateRangeSchedule}
              handleChangeStartDate={handleChangeRangeStartDateSchedule}
              handleChangeEndDate={handleChangeRangeEndDateSchedule}
              language={scheduleLanguage}
              setLanguage={setScheduleLanguage}
              dateRangeTimePeriod={dateRange}
              setDateRangeTimePeriod={setDateRange}
              handleDateRangChange={handleDateRangChange}
              openModalFilter={openModalFilter}
              handleCloseModalFilter={handleCloseFilterModel}
              handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
              setLanguageSchedular={setLanguageSchedular}
              countries={countries}
              compareRange={compareRange}
              startDate={startDate}
              endDate={endDate}
              defaultLocation={defaultLocation}
              locationAddPermission={locationAddPermission}
              locationViewPermission={locationViewPermission}
              autoSelectHeader={autoSelectHeader}
              isSentimentalAnalysisDone={isSentimentalAnalysisDone}
              selectAll={selectAllGroups}
              setSelectAllGroupsHeader={setSelectAllGroupsHeader}
              selectAllLocations={selectAllLocations}
              setSelectAllLocations={setSelectAllLocations}
              setAutoSelect={setAutoSelect}
              autoSelect={autoSelect}
              selectAllGroups={selectAllFiltersGroups}
              setSelectAllGroups={setSelectAllFiltersGroups}
              selectAllFeatures={selectAllFiltersLocations}
              setSelectAllFeatures={setSelectAllFiltersLocations}
            />
          )}
        </Dialog>

        {openLogoUploadModal && (
          <LogoImageModal
            title={t("upload_logo")}
            handleClose={handleCloseLogoUploadModal}
            open={openLogoUploadModal}
            selectedImages={images}
            setSelectedImages={setImages}
            setSelectedFile={setSelectedFileForImage}
            setUploadedImages={setUploadedImages}
            uploadedImages={uploadedImages}
          />
        )}
      </div>
    </>
  );
};

export default FetchModel;
