import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import NoGoogle from "../../../assets/images/nogoogle.svg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
  },
  locationsubhead: {
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "16px",
    color: "#495059",
  },
  locationhead: {
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "32px",
    color: "#1B2430",
  },
  Imagesection: {
    marginTop: "8px",
    height: "250px",
  },
  Imagesection2: {
    marginTop: "8px",
    height: "250px",
  },
}));

const NotConnected = ({ text, subtext }) => {
  const classes = useStyles();
  return (
    <Box className={classes.locationbox}>
      <img className={classes.Imagesection} src={NoGoogle} />

      <Typography className={classes.locationhead}>{text}</Typography>
      <Typography className={classes.locationsubhead}>{subtext}</Typography>
    </Box>
  );
};

export default NotConnected;
