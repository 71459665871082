import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  DialogContent,
  Dialog,
} from "@mui/material";
import { useStyles } from "./Styles/style";
import { Formik, Form, ErrorMessage } from "formik";
import Rating from "@mui/material/Rating";
import { useTranslation } from "react-i18next";
import { api } from "../../../contexts/JWTContext";

import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";

import one from "../../../assets/images/one.jpg";
import two from "../../../assets/images/two.jpg";
import three from "../../../assets/images/three.jpg";
import four from "../../../assets/images/four.jpg";
import five from "../../../assets/images/five.jpg";
import six from "../../../assets/images/six.jpg";
import tick from "../../../assets/images/tick.jpg";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import noImageGray from "../../../assets/images/noImageGray.png";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const PrivateSurvey = ({}) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const classes = useStyles();
  const params = useParams();
  const [value, setValue] = React.useState(3);
  const textArea = useRef();
  const [open, setOpen] = useState(false);
  const [surveyData, setSurveyData] = useState(null);

  const [campaignUserData, setCampaignUserData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    review: "",
    rating: 3,
  });
  const [surveyEmailError, setSurveyEmailError] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [surveyNameError, setSurveyNameError] = useState(false);
  const [imageUploadData, setImageUploadData] = useState({});
  //  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const [locationsName, setLocationName] = useState(null);
  useEffect(() => {
    if (params?.id) {
      getSurveyData(params?.id);

      // getCampaignUserData(params?.id);
    }
  }, []);
  useEffect(() => {
    getCampaignByQRID();
  }, []);
  const getCampaignByQRID = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`campaign/${params?.id}`);
      if (res.status === 200) {
        const data = res?.data?.data;
        i18n.changeLanguage(data?.language);
        setLocationName(data?.campaignLocations[0]?.location?.internalName);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleSubmitSurvey = async () => {
    setIsLoading(true);

    if (campaignUserData?.name === "") {
      setSurveyNameError(true);
    }
    if (campaignUserData?.email === "") {
      setSurveyEmailError(true);
    } else if (
      !/^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/.test(campaignUserData.email)
    ) {
      setValidEmail(true);
    }
    /*   if (campaignUserData?.phoneNumber==="") {
      setPhoneError(true);
    } */
    if (campaignUserData?.name !== "" && campaignUserData?.email !== "") {
      try {
        const res = await api.patch(
          `campaign/campaignDataUser/${params?.id}?isScannedByQRCode=${
            location.state !== null ? true : false
          }`,
          campaignUserData
        );
        if (res.status === 200) {
          setIsLoading(false);
          // toast.success(res?.data?.message);
          setOpen(true);
          // getAllUsers();
        }
      } catch (error) {
        setIsLoading(false);

        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getSurveyData = async (campaignId) => {
    try {
      //  setLoading(true);
      const res = await api.get(
        `campaign/campaignSurvey?campaignId=${campaignId}`
      );
      if (res.status === 200) {
        //  setLoading(false);
        i18n.changeLanguage(res?.data?.data?.language);
        setSurveyData(res?.data?.data);

        // toast.success(res?.data?.message, {});
      }
    } catch (error) {
      //  setLoading(false);

      toast.error(error?.response?.data?.message);
    }
  };

  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          type="image/x-icon"
          href={`${process.env.REACT_APP_BASE_URL}/empty-favicon.ico`}
        />
      </Helmet>
      <Grid
        container
        sx={{
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          marginTop: "80px",
        }}
      >
        <Grid item xs={9} sm={9} md={9} lg={9}>
          {/* <Grid container justifyContent="center">
            <img
              alt="img"
              src="https://obenan-files-upload.s3.amazonaws.com/obenan+logo/obenan.png"
              width="150px"
            />
          </Grid> */}
          {/* <Divider variant="middle" /> */}

          {surveyData !== null && (
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  paddingBottom: "0px",
                  paddingTop: "10px",
                }}
              >
                {/* <img
                style={{ width: "100%", height: "400px" }}
                src={surveyData !== null ? surveyData?.image_url : ""}
              /> */}

                <LazyLoadImage
                  loading="lazy"
                  src={
                    loadImg
                      ? noImageGray
                      : surveyData !== null
                      ? surveyData?.image_url
                      : ""
                  }
                  beforeLoad={handleBeforeLOad}
                  afterLoad={handleAfterLOad}
                  style={{
                    width: "100%",
                    height: { xs: "150px", sm: "250px", md: "400px" },
                    borderRadius: "8px",
                  }}
                  PlaceholderSrc={noImageGray}
                  alt="Image Alt"
                  effect="blur"
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    // paddingLeft: "24px",
                    // paddingRight: "24px",
                    paddingBottom: "24px",
                    paddingTop: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "28px", md: "32px" },
                      fontWeight: "700",
                      color: "#1B2430",
                      marginTop: "30px",
                      marginBottom: "8px",
                      lineHeight: "40px",
                      // paddingLeft: "10px",
                      // paddingRight: "10px",
                    }}
                  >
                    {surveyData !== null ? surveyData?.heading : ""}
                  </Typography>
                  <Typography className={classes.stepLocationSubhead1}>
                    {surveyData !== null ? surveyData?.description : ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  // paddingLeft: "24px",
                  // paddingRight: "24px",
                  paddingBottom: "24px",
                  paddingTop: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "28px", md: "32px" },
                    fontWeight: "700",
                    color: "#1B2430",
                    marginTop: "30px",
                    marginBottom: "8px",
                    lineHeight: "40px",
                    // paddingLeft: "10px",
                    // paddingRight: "10px",
                  }}
                >
                  {imageUploadData?.heading}
                </Typography>
                <Typography className={classes.stepLocationSubhead1}>
                  {imageUploadData?.description}
                </Typography>

                <Typography
                  // className={classes.stepLocation1}
                  // sx={{ paddingBottom: "30px" }}
                  sx={{
                    fontSize: { xs: "28px", md: "32px" },
                    fontWeight: "700",
                    color: "#1B2430",
                    marginTop: "30px",
                    marginBottom: "20px",
                    lineHeight: "40px",
                    // paddingLeft: "10px",
                    // paddingRight: "10px",
                  }}
                >
                  {t("Rate_your_visit")}
                </Typography>
                <Rating
                  name="hover-feedback"
                  value={value}
                  precision={1}
                  size="large"
                  sx={{
                    fontSize: { xs: "2.5rem", md: "6.5rem" },
                  }}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                    setCampaignUserData({
                      ...campaignUserData,
                      rating: newValue,
                    });
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  paddingBottom: "24px",
                  paddingTop: "20px",
                  textAlign: "left",
                }}
              >
                <Formik
                  initialValues={{
                    name: campaignUserData?.name,
                    email: campaignUserData?.email,
                    phoneNumber: campaignUserData?.phoneNumber,
                    review: campaignUserData?.review,
                  }}
                  // validationSchema={SubmitSchema}
                  onSubmit={handleSubmitSurvey}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form>
                      <Box>
                        <Typography className={classes.stepLocationFormText}>
                          {t("Name")}
                          <span style={{ color: "#FC3652" }}>*</span>
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          type="text"
                          placeholder={t("Name_subhead")}
                          sx={{ marginBottom: "12px" }}
                          fullWidth
                          value={campaignUserData?.name}
                          name="name"
                          onChange={(e) => {
                            setCampaignUserData({
                              ...campaignUserData,
                              name: e?.target?.value,
                            });
                            if (e?.target?.value?.length > 0) {
                              setSurveyNameError(false);
                            } else {
                              setSurveyNameError(true);
                            }
                          }}
                          // onBlur={handleBlur}
                          error={surveyNameError}
                          helperText={surveyNameError && t("Name_is_required")}
                        />
                        <Box
                          sx={{
                            color: "red",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                          mb={2}
                        >
                          <ErrorMessage component="span" name="name" />
                        </Box>
                      </Box>

                      <Box>
                        <Typography className={classes.stepLocationFormText}>
                          {t("Email")}
                          <span style={{ color: "#FC3652" }}>*</span>
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder={t("Email_here")}
                          fullWidth
                          sx={{ marginBottom: "12px" }}
                          name="email"
                          value={campaignUserData?.email}
                          onChange={(e) => {
                            setCampaignUserData({
                              ...campaignUserData,
                              email: e?.target?.value,
                            });
                            if (e?.target?.value?.length > 0) {
                              setSurveyEmailError(false);
                              if (
                                /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/.test(
                                  e.target.value
                                )
                              ) {
                                setValidEmail(false);
                              } else {
                                setValidEmail(true);
                              }
                            } else {
                              setSurveyEmailError(true);
                            }
                          }}
                          // onBlur={handleBlur}
                          error={surveyEmailError || validEmail}
                          helperText={
                            surveyEmailError
                              ? t("Email_is_required")
                              : validEmail
                              ? t("valid_email_t")
                              : ""
                          }
                        />
                        {/*   <Box
                          sx={{
                            color: "red",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                          mb={2}
                        >
                          <ErrorMessage component="span" name="email" />
                        </Box> */}
                      </Box>
                      <Box>
                        <Typography className={classes.stepLocationFormText}>
                          {t("phone1")}
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder={t("Phone_here")}
                          sx={{ marginBottom: "12px" }}
                          name="phoneNumber"
                          fullWidth
                          value={campaignUserData?.phoneNumber}
                          onChange={(e) => {
                            setCampaignUserData({
                              ...campaignUserData,
                              phoneNumber: e?.target?.value,
                            });
                          }}
                          // onBlur={handleBlur}
                          /*  error={phoneError}
                        helperText={phoneError && "Phone number is required"} */
                        />
                        <Box
                          sx={{
                            color: "red",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                          mb={2}
                        >
                          <ErrorMessage component="span" name="phoneNumber" />
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          className={classes.stepLocationFormTextReview}
                        >
                          {t("Write_Review")}
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          multiline
                          rows={5.8}
                          maxRows={10}
                          ref={textArea}
                          variant="outlined"
                          placeholder={t("review_here_subhead")}
                          sx={{ marginBottom: "12px" }}
                          name="review"
                          fullWidth
                          value={campaignUserData?.review}
                          onChange={(e) => {
                            setCampaignUserData({
                              ...campaignUserData,
                              review: e?.target?.value,
                            });
                          }}
                          // onBlur={handleBlur}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          paddingY: "12px",
                        }}
                      >
                        <CommonButton
                          type="submit"
                          isLoading={isLoading}
                          label={t("Submit_review")}
                          // disabled={surveyNameError || surveyEmailError}
                          // disabled={
                          //   Object.keys(errors).length > 0
                          //     ? true
                          //     : !values?.email.length
                          //     ? true
                          //     : false
                          // }
                          disabled={
                            campaignUserData?.name.length === 0
                              ? true
                              : campaignUserData?.email.length === 0
                              ? true
                              : surveyEmailError
                              ? true
                              : validEmail
                              ? true
                              : false
                          }
                        />
                      </Box>
                    </Form>
                  )}
                </Formik>
                <Divider variant="middle" style={{ marginTop: "15px" }} />
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <Grid
                    xs={8}
                    sm={8}
                    lg={8}
                    md={8}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <a href="https://obenan.com/" target="_blank" rel="noreferrer">
                      <img src={one} />
                    </a>
                    <a href="https://www.facebook.com/obenann" target="_blank" rel="noreferrer">
                      <img src={two} style={{ marginLeft: "8px" }} />
                    </a>

                    <a href="https://twitter.com/obenann" target="_blank" rel="noreferrer">
                      <img src={three} style={{ marginLeft: "8px" }} />
                    </a>

                    <a
                      href="https://www.instagram.com/obenan.software/"
                      target="_blank" rel="noreferrer"
                    >
                      <img src={four} style={{ marginLeft: "8px" }} />
                    </a>

                    <a href="https://obenan.tumblr.com/" target="_blank" rel="noreferrer">
                      <img src={five} style={{ marginLeft: "8px" }} />
                    </a>

                    <a
                      href="https://www.linkedin.com/company/obenan"
                      target="_blank" rel="noreferrer"
                    >
                      <img src={six} style={{ marginLeft: "8px" }} />
                    </a>
                  </Grid>

                  {locationsName !== null && (
                    <>
                      <Grid
                        xs={8}
                        sm={8}
                        lg={8}
                        md={8}
                        sx={{ marginTop: "15px", textAlign: "center" }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                            textAlign: "center",
                            color: "#495059",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t(
                                `${t("Copy_right")} <b>${locationsName}</b>`
                              ),
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid
                        xs={8}
                        sm={8}
                        lg={8}
                        md={8}
                        sx={{ marginTop: "8px", textAlign: "center" }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                            textAlign: "center",
                            color: "#495059",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t(
                                `${t(
                                  "email_Content"
                                )}  <b>${locationsName}</b>. ${t(
                                  "email_Contents"
                                )} <b>${locationsName}</b>`
                              ),
                            }}
                          />
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Grid
                    xs={8}
                    sm={8}
                    lg={8}
                    md={8}
                    sx={{ marginTop: "8px", textAlign: "center" }}
                  >
                    {/*
                      <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                        textAlign: "center",
                        color: "#495059",
                      }}
                    >
                      {t("email_address_line")}
                    </Typography>
                      */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {open && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "80%",
              height: "auto",
              padding: "20px",
              borderRadius: "8px",
            },
          }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <img src={tick} width="80px" height="80px" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "48px",
                    lineHeight: "60px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("Thankss")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "24px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("thankyou_form_subhead")}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default PrivateSurvey;
