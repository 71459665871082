import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  TextareaAutosize,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Link from "@mui/material/Link";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../Styles/style";
import FacebookAccountsCard from "./FacebookCard";
import GoogleAccountsCard from "./AccountsCard";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const SocialAccounts = ({
  onCancel,
  locationDetailsData,
  location,
  getLocationDetails,
  googleConnect,
  setIsLoading,
  data,
  facebookConnect,
  singleLocation,
  setSingleLocationLoading,
  getLocationById,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Grid container direction="column" className={classes.gridContainerone}>
      <Grid container sx={{ padding: "18px" }}>
        <Grid item xs={10} sm={10} md={11.5} lg={11.5}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.modalHeader}>
              {t("connect_channel")}
            </Typography>
            <BootstrapTooltip title={t("Social_desc")}>
              <Typography
                sx={{
                  marginLeft: "5px",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
              >
                <InfoOutlinedIcon />
              </Typography>
            </BootstrapTooltip>
          </Box>
        </Grid>
        <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
          <IconButton autoFocus onClick={onCancel} className="delete_button">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <Grid container className={classes.gridContainertwo}>
        <Grid item md={12} sm={12} xs={12} lg={12} sx={{ padding: "16px" }}>
          <FacebookAccountsCard
            socialLink={location?.socialLink}
            id={location?.id}
            placeId={location?.placeId}
            getLocationById={getLocationById}
            location={location}
            setIsLoading={setSingleLocationLoading}
            onCancel={onCancel}
          />

          {/* getLocationById={getLocationById} */}
          <GoogleAccountsCard
            socialLink={location?.socialLink}
            id={location?.id}
            placeId={location?.placeId}
            getLocationById={getLocationById}
            location={location}
            setIsLoading={setSingleLocationLoading}
            onCancel={onCancel}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container className={classes.gridContainerthree}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtonsSocial}
        >
          {/*   <Typography
            sx={{ cursor: "pointer", fontWeight: "400", color: "#495059" }}
          >
            {t("Link_Text")}
            <Link
              style={{ cursor: "pointer" }}
              // onSubmit={handleUpdateRegularHour}
              onClick={() => {
                navigate(`/user/edit_location?state=${location?.id}/`, {
                  state: {
                    showProduct: true,
                    //  displayOn: false,
                  },
                });
              }}
            >
              {t("Click_Here")}
            </Link>
          </Typography> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SocialAccounts;
