import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  TextField as MuiTextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components/macro";
import { useStyles } from "../styles/style";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import { api } from "../../../../contexts/JWTContext";
import Editors from "../../../../components/CustomComponents/Editor/Editors";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);

const AddTask = ({ alignment, onCancel, task }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [isLoading, setIsLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [locationId, setLocationId] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("open");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [statusRequired, setStatusRequired] = useState(false);
  const [titleRequired, setTitleRequired] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [descriptionRequired, setDescriptionRequired] = useState(false);
  const [userRequired, setUserRequired] = useState(false);
  const [selectedDueDate, setSelectedDueDate] = useState("7");
  const companyData = JSON.parse(localStorage.getItem("company"));

  useEffect(async () => {
    getAllCompanies();
    getAllUsers();
    //  getTasks()
  }, []);

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    setStatusRequired(false);
  };
  const handleLocationChange = (option) => {
    setLocationId(option);
  };
  const handleUserChange = (option) => {
    setSelectedUser(option);
  };

  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/company/` + companyData?.id);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
      }
    } catch (error) {}
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`user/search`);
      if (res.status === 200) {
        setUsers(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const createTask = async () => {
    const data = {
      title: title,
      status: status,
      locationId: locationId,
      companyId: companyId,
      dueDate: selectedDueDate,
    };
    if (title === undefined) {
      setTitleRequired(true);
    }

    if (description === "") {
      setDescriptionRequired(true);
    }

    if (locationId === undefined) {
      setLocationRequired(true);
    }
    if (selectedUser?.length < 1) {
      setUserRequired(true);
    }
    let userIds = [];
    selectedUser?.map((item) => {
      userIds.push(item?.id);
    });

    if (
      description !== "" &&
      title !== undefined &&
      locationId !== undefined &&
      selectedUser.length > 0
    ) {
      try {
        setIsLoading(true);

        const res = await api.post(`/tasks`, {
          title: title,
          description: description,
          companyId: companyData?.id,
          locationId: locationId?.id,
          status: status,
          dueDate: selectedDueDate,
          userIds: userIds?.length > 0 ? userIds.toString() : "",
          // getStatus: task.status,
        });
        if (res.status === 201) {
          toast.success("Task Added Successfully", {
            onClose: () => {
              setIsLoading(false);
              onCancel(true);
            },
          });
          // setAllCompanies(res.data.data);
        }
      } catch (error) {
        setIsLoading(false);

        toast.error(t("Error"));
      }
    }
  };

  return (
    <>
      <Box className={classes.addTaskMainBox}>
        <Typography className={classes.title}>{t("AddTask")}</Typography>
      </Box>
      <Divider my={4} />

      <Box className={classes.addTaskField}>
        <Box sx={{ overflowY: "scroll", maxHeight: "70vh" }}>
          <Box>
            <Typography className={classes.addTaskTitle}>
              {t("Task_Tile")}
            </Typography>
            <TextField
              className={classes.addTaskTitleField}
              error={titleRequired}
              value={title}
              placeholder={t("Enter_Title")}
              onChange={(e) => {
                setTitleRequired(false);
                setTitle(e.target.value);
              }}
              InputProps={{
                inputProps: {
                  style: {
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "100%",
                  },
                },
              }}
              margin="normal"
              // label={t("TitleForm")}
            />
            {titleRequired && (
              <Typography sx={{ color: "#d32f2f" }}>
                {t("Field_req_Title")}
              </Typography>
            )}
          </Box>

          <Box>
            <Typography className={classes.addTaskTypo}>
              {t("Status")}
            </Typography>
            <FormControl fullWidth>
              <Select
                error={statusRequired}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                defaultValue={status}
                // label={"Title"}

                onChange={handleChangeStatus}
              >
                <MenuItem value={"open"}>{t("Open")}</MenuItem>
                <MenuItem value={"inprogress"}>{t("InProgress")}</MenuItem>
                <MenuItem value={"completed"}>{t("Completed")}</MenuItem>
              </Select>
              {statusRequired && (
                <Typography sx={{ color: "#d32f2f" }}>
                  {t("Field_req_status")}
                </Typography>
              )}
            </FormControl>
          </Box>

          <Typography className={classes.addTaskTypo}>
            {t("Select_Locations")}
          </Typography>

          <Autocomplete
            error={locationRequired}
            disablePortal
            id="combo-box-demo"
            options={
              allCompanies?.locations?.length > 0 ? allCompanies?.locations : []
            }
            getOptionLabel={(option) =>
              option?.internalName
                ? option?.internalName +
                  " " +
                  "(" +
                  (option?.addressLine1 !== "" &&
                  option?.addressLine1 !== null &&
                  option?.addressLine1 !== undefined
                    ? option?.addressLine1 + "," + " "
                    : "") +
                  (option?.city ? option?.city : "") +
                  ")"
                : ""
            }
            sx={{ width: "100%" }}
            value={locationId}
            onChange={(e, value) => {
              handleLocationChange(value);
              setLocationRequired(false);
            }}
            renderInput={(params) => (
              <TextField
                placeholder={t("Select_Location")}
                {...params}
                required
                error={locationRequired}
                name="language"
              />
            )}
          ></Autocomplete>
          {locationRequired && (
            <Typography sx={{ color: "#d32f2f" }}>
              {t("Field_req_loc")}
            </Typography>
          )}

          <Typography className={classes.addTaskTypo}>
            {t("Select_User")}
          </Typography>
          {users?.results?.length > 0 && (
            <Autocomplete
              error={userRequired}
              multiple
              disablePortal
              id="combo-box-demo"
              options={users?.results ? users?.results : []}
              getOptionLabel={(option) =>
                option?.firstName ? option.firstName : ""
              }
              sx={{ width: "100%" }}
              value={selectedUser}
              onChange={(e, value) => {
                handleUserChange(value);
                setUserRequired(false);
              }}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.firstName + " " + option?.lastName}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  placeholder={t("Select_User")}
                  {...params}
                  required
                  error={userRequired}
                  name="language"
                />
              )}
            ></Autocomplete>
          )}
          {userRequired && (
            <Typography sx={{ color: "#d32f2f" }}>
              {t("Field_req_user")}
            </Typography>
          )}
          <Grid container>
            <Typography className={classes.addTaskTypo}>
              {t("Duee_Date")}
            </Typography>
            <Select
              required
              style={{ width: "100%" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedDueDate}
              onChange={(e, value) => {
                setSelectedDueDate(e.target.value);
              }}
            >
              <MenuItem value={"3"}>{t("3-Days")}</MenuItem>
              <MenuItem value={"7"}>{t("7-Days")}</MenuItem>
            </Select>

            <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
          </Grid>
          <Grid container>
            <Typography className={classes.descText}>
              {t("Description")}
            </Typography>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Editors
                error={descriptionRequired}
                value={description}
                onChange={(e) => {
                  setDescription(e);
                  setDescriptionRequired(false);
                }}
              />
              {descriptionRequired && (
                <Typography sx={{ color: "#d32f2f" }}>
                  {t("Field_req_dec")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ alignItems: "flex-end" }}>
          <Grid
            item
            md={12}
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Box className={classes.buttonBox}>
              <CommonButton
                displayWhite="true"
                onSubmit={onCancel}
                label={t("Cancel")}
              />

              <CommonButton
                onSubmit={() => {
                  createTask();
                }}
                variant="contained"
                loading={isLoading}
                label={t("Add_Task")}
                disabled={isLoading}
              />
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AddTask;
