import React, { useState, useEffect } from "react";
import CreateRoleDetails from "./CreateRoleDetails";
import { Helmet } from "react-helmet-async";
import { api } from "../../../../../../contexts/JWTContext";
import { Grid, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../UserReviewRequest/Styles/style.js";
import TitleHeader from "../../../../../../components/CustomComponents/title";
import Loader from "../../../../../../components/Loaders/Loader";
import NoLocationFound from "../../../../../../components/CustomComponents/Errors/NoLocationFound";
import { toast } from "react-toastify";

function CreateRole() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [allLocations, setAllLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [singleRole, setSingleRole] = useState({
    permissions: [],
  });
  const [displayRoleError, setDisplayRoleError] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [permissionsArr, setPermissionsArr] = useState([]);
  const [companyFeatures, setAllCompanyFeatures] = useState([]);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [defaultRole, setDefaultRole] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [checkboxes, setCheckboxes] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false,
  });

  useEffect(() => {
    if (params?.id) {
      getSingleRole();
    }
    getAllCompanyFeatures();
    getAllLocations();
  }, []);

  const getAllLocations = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/location/search`, {});
      if (res.status === 200) {
        setLoading(false);
        if (res?.data?.data?.results?.length > 0) {
          setAllLocations(res?.data?.data?.results);
        } else {
          setAllLocations(null);
        }
      }
    } catch (error) {
      setLoading(false);
      setAllLocations(null);
    }
  };

  const getSingleRole = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/companyRoles/${params?.id}`);
      if (res.status === 200) {
        setLoading(false);

        setRoleName(
          (
            res?.data?.data?.name.charAt(0).toUpperCase() +
            res?.data?.data?.name.slice(1)
          ).replaceAll("-", " ")
        );
        setDefaultRole(res?.data?.data?.default);
        setSingleRole({
          permissions: res?.data?.data?.CompanyRolesPermissions,
        });
      }
    } catch (error) {}
  };

  const getAllCompanyFeatures = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/companyFeatures/${companyData?.id}/company`);
      if (res.status === 200) {
        setAllCompanyFeatures(res?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setAllCompanyFeatures([]);
      setLoading(false);
    }
  };

  const updateRole = async () => {
    const allPermissions = permissionsArr?.reduce((accumulator, obj) => {
      return accumulator.concat(obj.permissions);
    }, []);

    setLoading(true);
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.put(`companyRoles/${params?.id}`, {
        name: roleName?.replaceAll(" ", "-").toLowerCase(),
        companyId: companyData?.id,
        permissions: allPermissions?.length > 0 ? allPermissions : [],
      });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        navigate("/user/accounts-overview", {
          state: { tabIndex: 2, page: 0 },
        });
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message === "Role Already Exists") {
        setDisplayRoleError(true);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const createRole = async () => {
    const allPermissions = permissionsArr?.reduce((accumulator, obj) => {
      return accumulator.concat(obj.permissions);
    }, []);

    setLoading(true);
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.post(`companyRoles`, {
        name: roleName?.replaceAll(" ", "-").toLowerCase(),
        companyId: companyData?.id,
        permissions: allPermissions?.length > 0 ? allPermissions : [],
      });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        navigate("/user/accounts-overview", {
          state: { tabIndex: 2, page: 0 },
        });
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message === "Role Already Exists") {
        setDisplayRoleError(true);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <TitleHeader
        title={params?.id ? t("edit_role") : t("create_role")}
        // subHeading={t("create_role_subHead")}
        showButtonRole={true}
        navigateToAccountOverViewRole={true}
        showDivider={true}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {allLocations?.length && allLocations !== null > 0 ? (
            <Grid container mt={2}>
              <Grid item xs={12} lg={12}>
                <CreateRoleDetails
                  allLocations={allLocations}
                  setAllLocations={setAllLocations}
                  checkboxes={checkboxes}
                  setCheckboxes={setCheckboxes}
                  selectAllChecked={selectAllChecked}
                  setSelectAllChecked={setSelectAllChecked}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  singleRole={singleRole}
                  setSingleRole={setSingleRole}
                  setRoleName={setRoleName}
                  roleName={roleName}
                  companyFeatures={companyFeatures}
                  setPermissionsArr={setPermissionsArr}
                  permissionsArr={permissionsArr}
                  updateRole={updateRole}
                  createRole={createRole}
                  displayRoleError={displayRoleError}
                  setDisplayRoleError={setDisplayRoleError}
                  defaultRole={defaultRole}
                />
              </Grid>
            </Grid>
          ) : allLocations === null ? (
            <Box className={classes.locationbox}>
              <NoLocationFound
                text={t("No_Location_Added")}
                subtext={t("add_location_text")}
              />
            </Box>
          ) : null}
        </>
      )}
    </>
  );
}

export default CreateRole;
