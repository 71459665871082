import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { api } from "../../../../contexts/JWTContext";
// import Loader from "../../components/Loaders/Loader";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import Divider from "@mui/material/Divider";
import TitleHeader from "../../../../components/CustomComponents/title";
import { ToastContainer, toast } from "react-toastify";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import GoogleMapInpuField from "../../../../components/GoogleMapField/index";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

const Alert = styled(MuiAlert)(spacing);
const AddUsers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signUp } = useAuth();
  const [message, setMessage] = useState("");
  const [googlePlaceData, setGooglePlaceData] = useState({});
  const [placeId, setPlaceId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isSuccess, setIsSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const setPlaceIdFromGoogle = (place) => {
    setPlaceId(place.value.place_id);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_LOCATION_API_KEY}&libraries=places`;
    script.onload = () => {
      setScriptLoaded(true);
    };
    script.onerror = (error) => {
      console.error("Error loading Google Maps Places script:", error);
      // You might want to handle the error here, such as setting a flag to indicate that loading failed
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(async () => {
    if (placeId) {
      try {
        const res = await api.get(`/location/place-details/${placeId}`);
        if (res.status === 200) {
          setGooglePlaceData(res?.data?.data?.googleData?.result);
        }
      } catch (error) {}
    }
  }, [placeId]);
  useEffect(() => {
    getLoginUserData();
  }, []);

  const getLoginUserData = async () => {
    try {
      const res = await api.get(`/user/me`);
      if (res.data?.status === true) {
        setUserData(res?.data?.data);
      }
    } catch (error) {}
  };

  return (
    <Grid>
      <TitleHeader title={t("Add_Account")} />
      <Grid sx={{ padding: "10px", bgcolor: "white" }}>
        <Formik
          autoComplete="off"
          enableReinitialize={true}
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            name:
              googlePlaceData && googlePlaceData?.name
                ? googlePlaceData?.name
                : "",
            // addressLine: "",
            // addressLineExtra: "",
            addressLine:
              googlePlaceData &&
              googlePlaceData?.vicinity &&
              googlePlaceData?.vicinity
                ? googlePlaceData?.vicinity
                : "",
            addressLineExtra: "",
            postalCode:
              googlePlaceData &&
              googlePlaceData?.address_components &&
              googlePlaceData?.address_components.filter(
                (address) => address.types[0] === "postal_code"
              )?.length > 0 &&
              googlePlaceData?.address_components.filter(
                (address) => address.types[0] === "postal_code"
              )[0]["long_name"]
                ? googlePlaceData?.address_components.filter(
                    (address) => address.types[0] === "postal_code"
                  )[0]["long_name"]
                : "",
            postalCode:
              googlePlaceData &&
              googlePlaceData?.address_components &&
              googlePlaceData?.address_components.filter(
                (address) => address.types[0] === "postal_code"
              )?.length > 0 &&
              googlePlaceData?.address_components.filter(
                (address) => address.types[0] === "postal_code"
              )[0]["long_name"]
                ? googlePlaceData?.address_components.filter(
                    (address) => address.types[0] === "postal_code"
                  )[0]["long_name"]
                : "",
            city:
              googlePlaceData &&
              googlePlaceData?.address_components &&
              googlePlaceData?.address_components.filter(
                (address) => address.types[0] === "administrative_area_level_2"
              )?.length > 0 &&
              googlePlaceData?.address_components.filter(
                (address) => address.types[0] === "administrative_area_level_2"
              )[0]["long_name"]
                ? googlePlaceData?.address_components.filter(
                    (address) =>
                      address.types[0] === "administrative_area_level_2"
                  )[0]["long_name"]
                : "",
            country:
              googlePlaceData &&
              googlePlaceData?.address_components &&
              googlePlaceData?.address_components.filter(
                (address) => address.types[0] === "country"
              )?.length > 0 &&
              googlePlaceData?.address_components.filter(
                (address) => address.types[0] === "country"
              )[0]["long_name"]
                ? googlePlaceData?.address_components.filter(
                    (address) => address.types[0] === "country"
                  )[0]["long_name"]
                : "",
            telephone: "",
            password: "",
            confirmPassword: "",
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .max(255)
              .required(`${t("First_name_req")}`),
            lastName: Yup.string()
              .max(255)
              .required(`${t("Last_name_req")}`),
            addressLine: Yup.string()
              .max(255)
              .required(`${t("Addre_line_req")}`),
            // addressLineExtra: Yup.string()
            //   .max(255)
            //   .required(`${t("Add_line_ex")}`),
            // postalCode: Yup.number()
            //   .min(9999, `${t("invalid_postal")}`)
            //   .max(99999, `${t("invalid_postal")}`)
            //   .required(`${t("postal_req")}`),
            city: Yup.string()
              .max(255)
              .required(`${t("city_req")}`),
            country: Yup.string()
              .max(255)
              .required(`${t("country_reqq")}`),
            postalCode: Yup.string().required(`${t("postal_req")}`),
            // .matches(/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/, `${t("invalid_postal")}`),
            telephone: Yup.string()
              //  .required(`${t("telephone_req")}`)
              .matches(
                /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
                "Invalid phone number"
              ),
            email: Yup.string()
              .email(`${t("valid_email")}`)
              .max(255)
              .required(`${t("email_req")}`)
              .matches(/\S+@\S+\.\S+/, `${t("valid_email")}`),

            name: Yup.string()
              .max(255)
              .required(`${t("company_req")}`),

            password: Yup.string()
              .min(5, `${t("valid_pass")}`)
              .max(255)
              .required(`${t("Pass_req")}`),
            confirmPassword: Yup.string()
              .required(`${t("confirm_pass")}`)
              .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref("password")],
                  "Both password need to be the same"
                ),
              }),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const role = userData ? userData?.role : "";
              const isPrimary = true;
              setLoading(true);
              const company = {
                name: values.name,
                addressLine: values.addressLine,
                addressLineExtra: values.addressLineExtra,
                postalCode: values.postalCode,
                city: values.city,
                country: values.country,
                telephone: values.telephone,
                placeId,
              };
              const response = await signUp(
                values.email,
                values.password,
                values.firstName,
                values.lastName,
                company,
                role,
                isPrimary
              );

              const { message, status } = response.data;
              setMessage(message);
              setIsSuccess(status);

              if (status) {
                toast.success(response?.data?.message, {});
                navigate("/admin/accounts");
                //  toast.success(message, {
                //    onClose: () => {
                //      navigate("/admin/accounts");
                //      setLoading(false);
                //    },
                //  });
                // ;
              } else {
                toast.error(message, {
                  //  onClose: () => {
                  //    navigate("/admin/accounts");
                  //    setLoading(false);
                  //  },
                });
              }
            } catch (error) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <>
              <form noValidate onSubmit={handleSubmit}>
                {message && (
                  <Alert mt={2} mb={1} severity="error">
                    {message}
                  </Alert>
                )}
                {scriptLoaded && (
                  <Grid>
                    <Grid sx={{ bgcolor: "white" }}>
                      <Grid>
                        {/* <Typography variant="subtitle1">
                {t("CreateYourAccount")}
              </Typography> */}

                        <Grid
                          container
                          sx={{
                            marginTop: "20px",
                            marginLeft: "2px",
                            marginRight: "2px",
                            marginBottom: "20px",
                          }}
                        >
                          <Grid item xs={12} sm={3}>
                            <Typography variant="h3">
                              {t("SetupCompanyLocation")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <GoogleMapInpuField
                              placeholder="Select google location"
                              setPlaceIdFromGoogle={setPlaceIdFromGoogle}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Divider variant="middle" />
                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("Company_Details")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("CompanyyName")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="text"
                      name="name"
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Address_Line")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="text"
                      name="addressLine"
                      value={values.addressLine}
                      error={Boolean(touched.addressLine && errors.addressLine)}
                      fullWidth
                      helperText={touched.addressLine && errors.addressLine}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Address_Line_Extra")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="text"
                      name="addressLineExtra"
                      value={values.addressLineExtra}
                      // error={Boolean(touched.addressLineExtra && errors.addressLineExtra)}
                      fullWidth
                      // helperText={touched.addressLineExtra && errors.addressLineExtra}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Postal_Code")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      name="postalCode"
                      inputProps={{
                        maxLength: 12,
                      }}
                      value={values.postalCode}
                      error={Boolean(touched.postalCode && errors.postalCode)}
                      fullWidth
                      helperText={touched.postalCode && errors.postalCode}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Cityy")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="text"
                      name="city"
                      value={values.city}
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      helperText={touched.city && errors.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Countryy")}
                    </Typography>
                    <TextField
                      disabled={loading}
                      type="text"
                      name="country"
                      value={values.country}
                      error={Boolean(touched.country && errors.country)}
                      fullWidth
                      helperText={touched.country && errors.country}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Telephonee")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="number"
                      name="telephone"
                      value={values.telephone}
                      error={Boolean(touched.telephone && errors.telephone)}
                      fullWidth
                      helperText={touched.telephone && errors.telephone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                  </Grid>
                </Grid>
                <Divider variant="middle" />
                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("User_Det")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("First_name")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="text"
                      autoComplete="off"
                      name="firstName"
                      value={values.firstName}
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Last_name")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="text"
                      name="lastName"
                      autoComplete="off"
                      value={values.lastName}
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Email")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="email"
                      name="email"
                      autoComplete="off"
                      defaultValue=""
                      value={values?.email}
                      // inputProps={{
                      //   autoComplete: "none",
                      // }}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Password")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="password"
                      name="password"
                      defaultValue=""
                      autoComplete="new-password"
                      // inputProps={{
                      //   autoComplete: "none",
                      // }}
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Confirm_Password")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      autoComplete="new-password"
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      fullWidth
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                  </Grid>
                </Grid>
                <Divider variant="middle" />
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  my={3}
                >
                  <CommonButton
                    onSubmit={() => navigate("/admin/accounts")}
                    // type="submit"
                    displayWhite="true"
                    variant="contained"
                    // disabled={isSubmitting}

                    label={t("Cancel")}
                  />

                  <CommonButton
                    type="submit"
                    disabled={isSubmitting}
                    loading={isLoading}
                    label={t("Add_Account")}
                  />
                </Grid>
              </form>
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default AddUsers;
