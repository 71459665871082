import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import {
  Grid,
  Typography,
  Card,
  Paper,
  TextField,
  Autocomplete,
  Button,
  Box,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import styled from "styled-components/macro";

import { useTranslation } from "react-i18next";
import moment from "moment";
import BouncingDotsLoader from "../../../../components/Loaders/smallBounceLoader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useStyles } from "../Style/style";
import { useEffect } from "react";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Review = ({
  summaryLoading,
  googleReviewData,
  downloadFormat,
  setDownloadFormat,
  viewPermission,
  handleDisplayNames,
  groupsReviewsFromatedData,
  handleDisplayGroupNames,
  pdfChecked,
  pdfDownloadChecked,
  openDownloadPdfModal,
  openReportEmailModal,
  setFeaturesObj,
  featuresObj,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [downloadValue, setDownloadValue] = useState(null);
  const [dataCountGroups, setDataCountGroups] = useState(5);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    if (downloadFormat === "") {
      setDownloadValue(null);
    }
  }, [downloadFormat]);

  useEffect(() => {
    if (
      (pdfChecked === true && openReportEmailModal === true) ||
      (pdfDownloadChecked === true && openDownloadPdfModal === true)
    ) {
      if (
        groupsReviewsFromatedData?.length > 0 ||
        googleReviewData?.length > 0
      ) {
        setDataCount(googleReviewData?.length);
        setDataCountGroups(groupsReviewsFromatedData?.length);
      } else {
        loadLessData();
      }
    } else {
      if (googleReviewData?.length >= 5) {
        setDataCount(5);
        setDataCountGroups(0);
      } else {
        setDataCount(googleReviewData?.length);
        setDataCountGroups(5 - googleReviewData?.length);
      }
    }
  }, [
    pdfChecked,
    pdfDownloadChecked,
    openDownloadPdfModal,
    openReportEmailModal,
  ]);

  useEffect(() => {
    if (groupsReviewsFromatedData?.length > 0 || googleReviewData?.length > 0) {
      if (googleReviewData?.length >= 5) {
        setDataCount(5);
        setDataCountGroups(0);
      } else {
        setDataCount(googleReviewData?.length);
        setDataCountGroups(5 - googleReviewData?.length);
      }
    }
  }, [googleReviewData, groupsReviewsFromatedData]);

  const handleSummaryDownload = (event, newValue) => {
    setDownloadFormat(newValue?.value);
    setDownloadValue(newValue);
    let tempFeatureObj = featuresObj;
    Object.keys(tempFeatureObj)?.map((itemTwo) => {
      if (itemTwo === "latestImpression") {
        tempFeatureObj[itemTwo] = true;
      } else {
        tempFeatureObj[itemTwo] = false;
      }
    });
    setFeaturesObj(tempFeatureObj);
  };

  const loadMoreData = () => {
    if (googleReviewData?.length > 0) {
      setDataCount(googleReviewData?.length);
      setDataCountGroups(groupsReviewsFromatedData?.length);
    }
  };
  const loadLessData = () => {
    if (googleReviewData?.length >= 5) {
      setDataCount(5);
      setDataCountGroups(0);
    } else {
      setDataCount(googleReviewData?.length);
      setDataCountGroups(5 - googleReviewData?.length);
    }
  };

  const downloadOptions = [
    { id: 1, value: "download", title: t("downlaod_pdf") },
    { id: 2, value: "email", title: t("email_report") },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card className={classes.cardbox}>
            <>
              <Paper variant="outlined">
                <Grid container>
                  <Grid
                    item
                    xs={9.3}
                    sm={9.3}
                    md={9.3}
                    lg={9.3}
                    className={classes.mainGridCont}
                  >
                    <Box className={classes.latestImpressingHeadingBox}>
                      <Typography className={classes.subHeadRatingNameBox}>
                        {t("latest_impression")}
                      </Typography>
                      <BootstrapTooltip title={t("summarizedText")}>
                        <Typography className={classes.summarizedTextStyle}>
                          <InfoOutlinedIcon />
                        </Typography>
                      </BootstrapTooltip>
                    </Box>
                  </Grid>
                  {viewPermission && (
                    <Grid
                      item
                      xs={2.7}
                      sm={2.7}
                      md={2.7}
                      lg={2.7}
                      className={classes.mainGridCont}
                    >
                      <Autocomplete
                        id="tags-outlined"
                        options={downloadOptions}
                        value={downloadValue}
                        getOptionLabel={(option) => option.title}
                        filterSelectedOptions
                        onChange={handleSummaryDownload}
                        renderInput={(params) => (
                          <TextField {...params} placeholder={t("actionss")} />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider />
                {googleReviewData?.length > 0 &&
                  googleReviewData?.slice(0, dataCount).map((item) => {
                    return (
                      <>
                        <Grid container>
                          <Grid
                            item
                            xs={9.3}
                            sm={9.3}
                            md={9.3}
                            lg={9.3}
                            className={classes.mainGridCont}
                          >
                            <Typography
                              className={classes.subHeadRatingNameBox}
                            >
                              {handleDisplayNames(item?.locationId, true)}
                            </Typography>
                          </Grid>
                        </Grid>
                        {item?.response !== null &&
                        item?.response?.lastsummary?.summary ? (
                          <>
                            <Grid>
                              <Grid className={classes.summaryGrid}>
                                <Grid className={classes.summaryGridTwo}>
                                  <Typography
                                    className={classes.summaryTextStyle}
                                  >
                                    "{item?.response?.lastsummary?.summary}"
                                  </Typography>
                                  <br></br>
                                  <Typography
                                    className={classes.summarizedDateStyle}
                                  >
                                    {t("last_updated")} &nbsp;
                                    {moment(
                                      item?.response?.lastsummary?.createdAt
                                    ).format("DD/MM/YYYY")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <Grid
                            className={classes.noReviewGrid}
                            variant="outlined"
                          >
                            <Typography className={classes.summaryGridTwo}>
                              {t("No_review_summary")}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    );
                  })}
                {groupsReviewsFromatedData?.length > 0 &&
                  groupsReviewsFromatedData
                    ?.slice(0, dataCountGroups)
                    .map((item) => {
                      return (
                        <>
                          <Grid
                            item
                            xs={9.3}
                            sm={9.3}
                            md={9.3}
                            lg={9.3}
                            className={classes.mainGridContGroups}
                          >
                            <Typography
                              className={classes.subHeadRatingNameBox}
                            >
                              {`${t("group_name")}` + " "}
                            </Typography>{" "}
                            <Typography
                              className={classes.subHeadRatingNameBox}
                            >
                              {handleDisplayGroupNames(item?.groupId, true)}
                            </Typography>
                          </Grid>
                          <Divider />

                          {item?.groups?.length > 0 &&
                            item?.groups?.map((location) => {
                              return (
                                <>
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={9.3}
                                      sm={9.3}
                                      md={9.3}
                                      lg={9.3}
                                      className={classes.mainGridCont}
                                    >
                                      <Typography
                                        className={classes.subHeadRatingNameBox}
                                      >
                                        {handleDisplayNames(
                                          location?.locationId,
                                          true
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  {location?.response !== null &&
                                  location?.response?.lastsummary?.summary ? (
                                    <>
                                      <Grid sx={{}}>
                                        <Grid className={classes.summaryGrid}>
                                          <Grid
                                            className={classes.summaryGridTwo}
                                          >
                                            <Typography
                                              className={
                                                classes.summaryTextStyle
                                              }
                                            >
                                              "
                                              {
                                                location?.response?.lastsummary
                                                  ?.summary
                                              }
                                              "
                                            </Typography>
                                            <br></br>
                                            <Typography
                                              className={
                                                classes.summarizedDateStyle
                                              }
                                            >
                                              {t("last_updated")} &nbsp;
                                              {moment(
                                                location?.response?.lastsummary
                                                  ?.createdAt
                                              ).format("DD/MM/YYYY")}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </>
                                  ) : (
                                    <Grid
                                      className={classes.noReviewGrid}
                                      variant="outlined"
                                    >
                                      <Typography sx={{ padding: "16px" }}>
                                        {t("No_review_summary")}
                                      </Typography>
                                    </Grid>
                                  )}
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                {summaryLoading && (
                  <Grid container className={classes.summaryLoadingStyle}>
                    <Grid item xs={4} sm={4} md={5} lg={5.5}></Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={1}
                      className={classes.summaryLoadingGridStyle}
                    >
                      <BouncingDotsLoader />
                    </Grid>
                    <Grid item xs={4} sm={4} md={5} lg={5.5}></Grid>
                  </Grid>
                )}
              </Paper>
            </>

            {googleReviewData?.length + groupsReviewsFromatedData?.length >
              5 && (
              <Grid container className={classes.viewMoreGridStyle}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.viewMoreButtonGrid}
                >
                  {dataCount + dataCountGroups === 5 ? (
                    <Button onClick={loadMoreData}>
                      <Typography className={classes.viewMoreText}>
                        {t("View_More")}
                      </Typography>
                    </Button>
                  ) : (
                    <Button onClick={loadLessData}>
                      <Typography className={classes.viewMoreText}>
                        {t("View_Less")}
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Review;
