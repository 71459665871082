import React from "react";
import { Grid, Card, Typography, Tooltip, tooltipClasses } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import ErrorIcon from "../../../assets/images/ErrorIcon.svg";
import CommonButton from "../Buttons/CommonButton";
import Googleicon from "../../../assets/Icons/Group.svg";
import { useNavigate } from "react-router-dom";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const ReconnectCard = ({
  location,

  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  setOpeningHoursModel,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigation = () => {
    setOpeningHoursModel(true);
  };
  const handleNavigationToLocations = () => {
    navigate(`/user/locations`);
  };
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card
          sx={{
            bgcolor: "#FFD0D0",
            borderRadius: "8px",
            height: "100%",
            position: "relative",
          }}
        >
          <Grid
            container
            sx={{
              alignItems: "center",
              paddingY: "8px",
            }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={2}
              lg={1.5}
              sx={{
                textAlign: "center",
                marginTop: "4px",
                paddingY: "4px",
              }}
            >
              <img src={ErrorIcon} alt="err" />
            </Grid>
            <Grid item xs={6} sm={6} md={7} lg={8}>
              <Typography
                sx={{ fontSize: "18px", fontWeight: "700", color: "#FF1616" }}
              >
                {t("Permanently_Closed")}
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "400", color: "#330404" }}
              >
                {location?.internalName + t("Permanently_Closed_Subtext")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={3}
              lg={2.5}
              sx={{
                justifyContent: "center",
                textAlign: "center",
                paddingRight: "5px",
              }}
            >
              {editPermission && viewPermission ? (
                <CommonButton
                  variant="contained"
                  onSubmit={handleNavigation}
                  label={t("re-open")}
                  //  disabled={editPermission}
                />
              ) : (
                <BootstrapTooltip title={t("authorized_access")}>
                  <span>
                    <CommonButton
                      variant="contained"
                      onSubmit={handleNavigation}
                      label={t("re-open")}
                      disabled={true}
                    />
                  </span>
                </BootstrapTooltip>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default ReconnectCard;
