import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Autocomplete, Stack, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import "react-toastify/dist/ReactToastify.css";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import TableComponent from "./Components/Table";
import GoogleIcon from "@mui/icons-material/Google";
import { useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddressChangeRequests from "../Locations/AddressChangeRequests";
import {
  Box,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  Checkbox,
  Chip,
  Select,
  MenuItem,
  Menu,
} from "@mui/material";
import Loader from "../../../../components/Loaders/Loader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TitleHeader from "../../../../components/CustomComponents/title";
import { Edit } from "@mui/icons-material";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";

// import GoogleIcon from "@mui/icons-material/Google";

// import ReviewsIcon from "@mui/icons-material/Reviews";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { spacing } from "@mui/system";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import NotFound from "../../../../components/NotFound/NotFound";
import SubscriptionCard from "./Components/SubscriptionCard";

const Divider = styled(MuiDivider)(spacing);

const WhiteTextTypography = withStyles({
  root: {
    color: "#495059",
  },
})(Typography);
const HeadTextTypography = withStyles({
  root: {
    color: "#1B2430",
    fontWeight: "700",
    fontSize: "20px",
  },
})(Typography);

const Paper = styled(MuiPaper)(spacing);

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});

// const accessToken = window.localStorage.getItem("accessToken");

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  buttonAccept: {
    color: "white",
    backgroundColor: "#06BDFF",
    fontSize: "14px",
    borderRadius: "8px",
    padding: "14px",
    "&:hover": { backgroundColor: "#0638C1", color: "white" },
  },
  buttonReject: {
    color: "white",
    backgroundColor: "#FC3652",
    fontSize: "14px",
    borderRadius: "8px",
    padding: "14px",
    marginLeft: "8px",
    "&:hover": { backgroundColor: "#e37685", color: "white" },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

async function fetchURL(id) {
  try {
    const res = await api.get(`google/auth-url`);
    if (res.data?.status === true) {
      window.location.href = res?.data.data + "&state=" + id;
    }
  } catch (error) {}
}
//  const handleNavigation = (data) => {
//     navigate(`/user/token-expire/location/${data?.id}`);
//   };

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    getAllLocation,
    t,
    selectedLocationData,

    handleChangeCheckButton,
    selectAll,
    deleteTable,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    getAllLocation();
  };
  // const handleConnectWithGoogle = () => {
  //   fetchURL(
  //     `${defaultValueLocation?.id}/${defaultValueLocation?.placeId}`,
  //     defaultValueLocation?.id
  //   );
  // };
  const headCells = [
    {
      alignment: "left",
      label: (
        <Checkbox
          checked={selectAll}
          onChange={(e) => handleChangeCheckButton(e, deleteTable)} //   inputProps={{ "aria-label": "controlled" }}
        />
      ),
    },
    { id: "id", alignment: "left", label: t("TableID") },
    { id: "name", alignment: "left", label: t("LocationTableName") },
    { id: "customer", alignment: "left", label: t("Company_Name") },
    { id: "address", alignment: "left", label: t("address") },
    { id: "actions", alignment: "center", label: t("TableAction") },
  ];
  const headCellsDelete = [
    { id: "id", alignment: "left", label: t("TableID") },
    { id: "name", alignment: "left", label: t("LocationTableName") },
    { id: "customer", alignment: "left", label: t("Company_Name") },
    { id: "address", alignment: "left", label: t("address") },
    { id: "actions", alignment: "center", label: t("TableAction") },
  ];

  return (
    <CustomizedTableHead>
      <CustomizedTableRow>
        {deleteTable === false
          ? headCells.map((headCell) => (
              <CustomizedTableCell
                key={headCell.id}
                align={headCell.alignment}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {/*    <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
                {headCell.label}
                {/*  </TableSortLabel> */}
              </CustomizedTableCell>
            ))
          : headCellsDelete.map((headCell) => (
              <CustomizedTableCell
                key={headCell.id}
                align={headCell.alignment}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {/*    <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
                {headCell.label}
                {/*  </TableSortLabel> */}
              </CustomizedTableCell>
            ))}
      </CustomizedTableRow>
    </CustomizedTableHead>
  );
};

function MyLocations() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { search } = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);

  const [allCompanies, setAllCompanies] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedLocationData, setselectedLocationData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [bulkButton, setBulkButton] = useState(false);
  const [openAcceptModel, setOpenAcceptModel] = useState(false);
  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [openMultiAcceptModel, setOpenMultiAcceptModel] = useState(false);
  const [openMultiRejectModel, setOpenMultiRejectModel] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [pageLoad, setPageLoad] = useState(0);

  const [openFromDeleteSingle, setOpenFromDeleteSingle] = useState(false);
  const [openFromDeleteMultiple, setOpenFromDeleteMultiple] = useState(false);

  useEffect(async () => {
    if (selectedTab === 0) getLocationRequests();
    else if (selectedTab === 1) getAllLocationForDelete();
    setPageLoad(1);
  }, [rowsPerPage, page, selectedTab]);

  useEffect(() => {
    if (selectedLocationData?.length !== allCompanies?.results?.length)
      setSelectAll(false);
  }, [selectedLocationData]);

  useEffect(() => {
    if (pageLoad > 0) {
      const delayDebounceFn = setTimeout(() => {
        if (selectedTab === 0) getLocationRequests();
        else if (selectedTab === 1) getAllLocationForDelete();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [filterWithName]);

  const getLocationRequests = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `location/location-requests?limit=${rowsPerPage}&page=${page + 1}${
          filterWithName !== "" ? "&search=" + filterWithName : ""
        }`
      );
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const getAllLocationForDelete = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`location/location-requests`, {
        params: {
          limit: rowsPerPage,
          page: page + 1,
          status: "deleted",
          // Append filterWithName parameter if it's not empty
          ...(filterWithName !== "" && { search: filterWithName }),
        },
      });
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const handleChangeCheckButton = (e, fromDelete) => {
    // setSelectAll(e.target.checked);
    setOpenFromDeleteMultiple(fromDelete);
    setBulkButton(true);
    let dummyArr = [];
    if (e.target.checked) {
      setSelectAll(true);
      for (var i = 0; i < allCompanies?.results?.length; i++) {
        selectedLocationData?.push(allCompanies?.results[i]?.id);
        dummyArr?.push(allCompanies?.results[i]?.id);
      }

      const uniqueLocationIds = new Set(selectedLocationData);
      {
      }
      setselectedLocationData([...uniqueLocationIds]);
      // dummyArr([...uniqueTasks]);
    } else {
      setSelectAll(false);
      setselectedLocationData([]);
      // dummyArr([]);
    }

    console.log();

    /*  if (allCompanies?.results?.length===dummyArr?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    } */
  };
  const handleTaskChecked = (e, index, locationId, fromDelete) => {
    setOpenFromDeleteMultiple(fromDelete);
    setBulkButton(true);
    if (allCompanies?.results?.length === selectedLocationData?.length) {
      setselectedLocationData([]);
      setSelectAll(false);
    } else {
      setSelectAll(true);
      setselectedLocationData(allCompanies?.results);
    }
    if (e.target.checked) {
      selectedLocationData.push(locationId);
      {
      }
    } else {
      var locationIndex = selectedLocationData.indexOf(locationId);
      selectedLocationData.splice(locationIndex, 1);
    }

    setselectedLocationData([...selectedLocationData]);
  };

  console.log(selectedLocationData, "selectedLocationData");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
    setPageLoad(0);
    setRowsPerPage(10);
    setFilterWithName("");
    setselectedLocationData([]);

    // setPage(0);
    // setselectedLocationData([]);
    setBulkButton(false);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const handleOpenAcceptModel = (id, fromDelete) => {
    setOpenFromDeleteSingle(fromDelete);
    let array = [...selectedLocationData];
    array.push(id);
    const uniqueLocationIds = new Set(array);
    setselectedLocationData(array);
    setOpenAcceptModel(true);
    setAnchorEl(null);
  };
  const handleCloseAcceptModel = () => {
    setOpenFromDeleteSingle(false);
    setselectedLocationData([]);
    setOpenAcceptModel(false);
    setAnchorEl(null);
  };

  const handleOpenMultiAcceptModel = () => {
    // selectedLocationData.push(id);
    setOpenMultiAcceptModel(true);
    setAnchorEl(null);
  };
  const handleCloseMultiAcceptModel = () => {
    setselectedLocationData([]);
    setOpenMultiAcceptModel(false);
    setAnchorEl(null);
  };

  const handleDeleteLocation = async () => {
    setRequestLoading(true);
    try {
      const res = await api.delete(
        `/location/${selectedRow?.id}?finalDelete=true`
      );
      if (res.status === 200) {
        setRequestLoading(false);
        toast.success(t("Location_deleted_successfully"));
        handleCloseAcceptModel([]);
        getAllLocationForDelete();
      }
    } catch (error) {
      handleCloseAcceptModel([]);
      setRequestLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleRejectDeleteLocation = async () => {
    setRequestLoading(true);
    try {
      const res = await api.delete(
        `/location/${selectedRow?.id}?finalDelete=reject`
      );
      if (res.status === 200) {
        setRequestLoading(false);
        toast.success("Request rejected successfully");
        handleCloseRejectModel([]);
        getAllLocationForDelete();
      }
    } catch (error) {
      handleCloseRejectModel([]);
      setRequestLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleOpenRejectModel = (id) => {
    let array = [...selectedLocationData];
    array.push(id);
    const uniqueLocationIds = new Set(array);
    setselectedLocationData(array);
    setOpenRejectModel(true);
    setAnchorEl(null);
  };
  const handleCloseRejectModel = () => {
    setselectedLocationData([]);
    setOpenRejectModel(false);
    setAnchorEl(null);
  };

  const handleOpenMultiRejectModel = () => {
    //  selectedLocationData.push(id);
    setOpenMultiRejectModel(true);
    setAnchorEl(null);
  };
  const handleCloseMultiRejectModel = () => {
    setselectedLocationData([]);
    setOpenMultiRejectModel(false);
    setAnchorEl(null);
  };
  const handleClick = (event, selectedData) => {
    setselectedLocationData([]);
    setSelectedRow(selectedData);

    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleAcceptRequest = async () => {
    setRequestLoading(true);
    try {
      const uniqueLocationIds = [...new Set(selectedLocationData)];
      const res = await api.patch(`/location`, {
        locationIds: uniqueLocationIds,
        status: "approved",
      });
      if (res.status === 200) {
        setRequestLoading(false);
        setOpenAcceptModel(false);
        setOpenMultiAcceptModel(false);
        setselectedLocationData([]);
        toast.success(res?.data?.message);
        getLocationRequests();
      }
    } catch (error) {
      setRequestLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleRejectRequest = async () => {
    setRequestLoading(true);
    try {
      const uniqueLocationIds = [...new Set(selectedLocationData)];
      const res = await api.patch(`/location`, {
        locationIds: uniqueLocationIds,
        status: "rejected",
      });
      if (res.status === 200) {
        setOpenRejectModel(false);
        setOpenMultiRejectModel(false);
        setselectedLocationData([]);
        toast.success(res?.data?.message);
        getLocationRequests();
        setRequestLoading(false);
      }
    } catch (error) {
      setRequestLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allCompanies.length - page * rowsPerPage);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openAcceptModel}
        onClose={handleCloseAcceptModel}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("location_Requests")}
          description={
            selectedTab === 1
              ? t("location_reqSingleAccept_desc_Delete")
              : t("location_reqAccept_desc")
          }
          onConfirm={
            selectedTab === 1 ? handleDeleteLocation : handleAcceptRequest
          }
          loading={requestLoading}
          onCancel={handleCloseAcceptModel}
        />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openRejectModel}
        onClose={handleCloseRejectModel}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("location_Requests")}
          description={
            selectedTab === 1
              ? t("location_reqSingleReject_desc_Delete")
              : t("location_reqReject_desc")
          }
          onConfirm={
            selectedTab === 0 ? handleRejectRequest : handleRejectDeleteLocation
          }
          loading={requestLoading}
          onCancel={handleCloseRejectModel}
        />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openMultiAcceptModel}
        onClose={handleCloseMultiAcceptModel}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("location_Requests")}
          description={
            selectedTab === 1
              ? t("location_reqMultiAccept_desc_Delete")
              : t("location_reqMultiAccept_desc")
          }
          onConfirm={() => {
            handleAcceptRequest();
          }}
          loading={requestLoading}
          onCancel={handleCloseMultiAcceptModel}
        />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openMultiRejectModel}
        onClose={handleCloseMultiRejectModel}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("location_Requests")}
          description={
            selectedTab === 1
              ? t("location_reqMultiReject_desc_Delete")
              : t("location_reqMultiReject_desc")
          }
          onConfirm={() => {
            handleRejectRequest();
          }}
          loading={requestLoading}
          onCancel={handleCloseMultiRejectModel}
        />
      </Dialog>
      <Helmet title="Locations" />
      <TitleHeader
        title={t("location_Requests")}
        // subHeading={t("LocationList")}
      />

      {bulkButton === true && (
        <>
          {selectedLocationData?.length > 0 && (
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                }}
              >
                <Button
                  className={classes.buttonAccept}
                  onClick={handleOpenMultiAcceptModel}
                >
                  {t("approve")}
                </Button>
                <Button
                  className={classes.buttonReject}
                  onClick={handleOpenMultiRejectModel}
                >
                  {t("Reject")}
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}

      <Grid>
        <Grid>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            variant="scrollable"
          >
            <Tab label={t("Add_Location_req")} />

            <Tab label={t("delete_location_req")} />

            <Tab label={t("location_address_Requests")} />
          </Tabs>
        </Grid>
        {selectedTab === 0 &&
          (isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "50vh",
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <Grid container spacing={2} mt={5} mb={5}>
                <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5}>
                  <SearchField
                    filterByName={filterByName}
                    onSubmit={getLocationRequests}
                    placeholder={t("Filter_Location_Name")}
                    filterWithName={filterWithName}
                  />
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5}>
                  <CommonButton
                    alignment={true}
                    onSubmit={getLocationRequests}
                    label={t("Search")}
                    leftMargin={true}
                  />
                </Grid>
              </Grid>
              {allCompanies && allCompanies?.count > 0 ? (
                <Grid container marginTop="16px">
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                      >
                        <EnhancedTableHead
                          t={t}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          rowCount={allCompanies?.count}
                          getAllLocation={getLocationRequests}
                          handleChangeCheckButton={handleChangeCheckButton}
                          selectAll={selectAll}
                          selectedLocationData={selectedLocationData}
                          deleteTable={false}
                        />
                        <TableBody>
                          {allCompanies?.results?.length > 0 ? (
                            /*   stableSort(
                        allCompanies?.results,
                        getComparator(order, orderBy)
                      )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
                            allCompanies?.results.map((row, index) => {
                              const googleData = row?.socialLink?.find(
                                (item) => item.type === "google"
                              );

                              return (
                                <>
                                  <TableComponent
                                    row={row}
                                    index={index}
                                    // handleConnectWithGoogle={handleConnectWithGoogle}
                                    handleClose={handleClose}
                                    // handleNavigation={handleNavigation}
                                    setOpen={setOpen}
                                    setDeleteId={setDeleteId}
                                    closeMenu={closeMenu}
                                    selectedRow={selectedRow}
                                    handleOpenAcceptModel={
                                      handleOpenAcceptModel
                                    }
                                    handleOpenRejectModel={
                                      handleOpenRejectModel
                                    }
                                    handleClick={handleClick}
                                    anchorEl={anchorEl}
                                    handleTaskChecked={handleTaskChecked}
                                    selectedLocationData={selectedLocationData}
                                    deleteTable={false}
                                  />
                                </>
                              );
                            })
                          ) : (
                            <Typography variant="h3">no data found</Typography>
                          )}
                          {emptyRows > 0 && (
                            <CustomizedTableRow
                              style={{ height: 53 * emptyRows }}
                            >
                              <CustomizedTableCell colSpan={8} />
                            </CustomizedTableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={allCompanies?.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                </Grid>
              ) : (
                <NotFound text={t("Location_found")} />
              )}
            </>
          ))}

        {selectedTab === 1 &&
          (isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "50vh",
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <Grid container spacing={2} mt={5} mb={5}>
                <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5}>
                  <SearchField
                    filterByName={filterByName}
                    onSubmit={getAllLocationForDelete}
                    placeholder={t("Filter_Location_Name")}
                    filterWithName={filterWithName}
                  />
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5}>
                  <CommonButton
                    alignment={true}
                    onSubmit={getAllLocationForDelete}
                    label={t("Search")}
                    leftMargin={true}
                  />
                </Grid>
              </Grid>
              {allCompanies && allCompanies?.count > 0 ? (
                <Grid container marginTop="16px">
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <>
                        <Table
                          aria-labelledby="tableTitle"
                          size={"medium"}
                          aria-label="enhanced table"
                        >
                          <EnhancedTableHead
                            t={t}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={allCompanies?.count}
                            getAllLocation={getLocationRequests}
                            handleChangeCheckButton={handleChangeCheckButton}
                            selectAll={selectAll}
                            selectedLocationData={selectedLocationData}
                            deleteTable={true}
                          />
                          <TableBody>
                            {allCompanies?.results?.length > 0 ? (
                              /*   stableSort(
                        allCompanies?.results,
                        getComparator(order, orderBy)
                      )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
                              allCompanies?.results?.map((row, index) => {
                                const googleData = row?.socialLink?.find(
                                  (item) => item.type === "google"
                                );

                                return (
                                  <>
                                    <TableComponent
                                      row={row}
                                      index={index}
                                      // handleConnectWithGoogle={handleConnectWithGoogle}
                                      handleClose={handleClose}
                                      // handleNavigation={handleNavigation}
                                      setOpen={setOpen}
                                      setDeleteId={setDeleteId}
                                      closeMenu={closeMenu}
                                      selectedRow={selectedRow}
                                      handleOpenAcceptModel={
                                        handleOpenAcceptModel
                                      }
                                      handleOpenRejectModel={
                                        handleOpenRejectModel
                                      }
                                      handleClick={handleClick}
                                      anchorEl={anchorEl}
                                      handleTaskChecked={handleTaskChecked}
                                      selectedLocationData={
                                        selectedLocationData
                                      }
                                      deleteTable={true}
                                    />
                                  </>
                                );
                              })
                            ) : (
                              <Typography variant="h3">
                                no data found
                              </Typography>
                            )}
                            {emptyRows > 0 && (
                              <CustomizedTableRow
                                style={{ height: 53 * emptyRows }}
                              >
                                <CustomizedTableCell colSpan={8} />
                              </CustomizedTableRow>
                            )}
                          </TableBody>
                        </Table>
                      </>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={allCompanies?.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                </Grid>
              ) : (
                <NotFound text={t("Location_found")} />
              )}
            </>
          ))}

        {selectedTab === 2 && <AddressChangeRequests />}
      </Grid>
    </>
  );
}

export default MyLocations;
