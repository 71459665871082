import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";

const color = {
  pink: "#f68897",
  lightyellow: "#fdf2df",
  orange: "orange",
  gray: "#757575",
};

export const useStyles = makeStyles((theme) => ({
  subHeadFetch: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1B2430",
    marginTop: "8px",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },

  filterBox: {
    marginY: "10px",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "8px",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3%",
    marginTop: "8px",
  },
  locationText: {
    color: "#8D9298",
    marginY: "16px",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    marginLeft: "3px",
    marginTop: "12px",
  },
  locationTypo: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    marginBottom: "8px",
  },
  locationReviewBox: {
    background: "#FFF1D7",
    padding: "10px",
    borderRadius: "8px",
    marginTop: "15px",
    marginLeft: "0px",
    marginRight: "0px",
    width: "99.9%",
  },
  warningBoxGrid: {
    background: "#FFF1D7",
    padding: "10px",
    borderRadius: "8px",
    marginTop: "15px",
    marginLeft: "0px",
    marginRight: "0px",
    width: "99.9%",
  },
  viewMoreFilter: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
  },
  textAssignedto: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#1B2430",
    lineHeight: "20px",
    marginBottom: "8px",
  },
  input: {
    color: "white",
    height: "48px",
  },
  emailReportTitle: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "20px",
    color: "#1b2430",
  },
  emailReportLabel: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "17px",
    color: "#1b2430",
  },
  emailReportSubLabel: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "14px",
    color: "#1b2430",
  },
  reviewsubHeadBox: {
    paddingY: "16px",
    backgroundColor: "#E0F7FF",
    borderRadius: "8px",
    // width: "60%",
  },
  reviewsubHeadTextError: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "4px",
    marginLeft: "6px",
    color: "#FF0000",
  },
  reviewsubHeadText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "4px",
    marginLeft: "6px",
  },
  whiteBackground: {
    background: "white",
    width: "100%",
  },
  removeFileButton: {
    cursor: "pointer",
    width: "9px",
    height: "9px",
    marginLeft: "8px",
    marginTop: "2px",
  },
  deleteIcon: {
    color: "#FF1616",
  },
  fileBoxStyle: {
    display: "flex",
    paddingTop: "8px",
  },
  selectedFileTypo: {
    cursor: "pointer",
    "&:hover": {
      color: "#06BDFF",
    },
  },
  scheduleReviewTypoEmail: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#1B2430",
  },
  scheduleReviewsIcon: {
    marginLeft: "5px",
    marginTop: "5px",
    color: "#06BDFF",
  },
  scheduleReviewInfoGrid: {
    paddingTop: "5px",
    paddingBottom: "0px",
  },
  scheduleReviewInfoTypo: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#330404",
  },
  scheduleReviewInfoTypoEmail: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "18px",
    color: "#330404",
    paddingLeft: "3px",
    paddingTop: "2px",
  },
}));
