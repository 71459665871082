import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  TextareaAutosize,
  Card,
  Tooltip,
  tooltipClasses,
  Dialog,
  Alert,
} from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Marker } from "@react-google-maps/api";
import { useStyles } from "../../../../Styles/style";
import { toast } from "react-toastify";
import LocationMapComponent from "../map";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { api } from "../../../../../../../contexts/JWTContext";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const containerStyle = {
  width: "100%",
  height: "350px",
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const LocationMap = ({
  onCancel,
  locationDetailsData,
  getLocationDetails,
  location,
  centerData,
  displayMapError,
  setIndexValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setSelectedTab,
  touchedValue,
  indexValue,
  setAddressTouchedValue,
  setTouchedValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const containerRef = useRef(null);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [isLoading, setIsLoading] = useState(false);
  const [openEditUI, setOpenEditUI] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [addressLineRequired, setAddressLineRequired] = useState(false);
  const [administrativeAreaRequired, setAdministrativeArea] = useState(false);
  const [postalCodeRequired, setPostalCodeRequired] = useState(false);
  const [regionCodeRequired, setRegionCodeRequired] = useState(false);
  const [localityRequired, setLocalityRequired] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [addressInfo, setAddressInfo] = useState({
    addressLine: "",
    administrativeArea: "",
    postalCode: "",
    regionCode: "",
    locality: "",
  });
  useEffect(() => {
    getLogsDetail();
  }, []);

  const handleScroll = () => {
    if (containerRef.current !== null) {
      containerRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (openEditUI === true) {
      setAddressInfo({
        addressLine:
          locationDetailsData?.result?.google?.storefrontAddress?.addressLines
            ?.length > 0
            ? locationDetailsData?.result?.google?.storefrontAddress?.addressLines.join(
                " "
              )
            : "",
        administrativeArea: locationDetailsData?.result?.google
          ?.storefrontAddress?.administrativeArea
          ? locationDetailsData?.result?.google?.storefrontAddress
              ?.administrativeArea
          : "",
        postalCode: locationDetailsData?.result?.google?.storefrontAddress
          ?.postalCode
          ? locationDetailsData?.result?.google?.storefrontAddress?.postalCode
          : "",
        regionCode: locationDetailsData?.result?.google?.storefrontAddress
          ?.regionCode
          ? locationDetailsData?.result?.google?.storefrontAddress?.regionCode
          : "",
        locality: locationDetailsData?.result?.google?.storefrontAddress
          ?.locality
          ? locationDetailsData?.result?.google?.storefrontAddress?.locality
          : "",
      });
    }
  }, [openEditUI]);

  const getLogsDetail = async () => {
    setIsLoading(true);

    try {
      const res = await api.get(`/locationLogs/changeAddress/${location?.id}`);

      if (res.status === 200) {
        if (res?.data?.data?.status === "pending") {
          setRequestSent(true);
        } else {
          setRequestSent(false);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const handleOpenAddressChangeModel = () => {
    setOpenEditUI(true);
  };
  const handleCloseAddressChangeModel = () => {
    setOpenEditUI(false);
  };
  const handleRequestAddressChange = async () => {
    if (addressInfo?.addressLine === "") {
      setAddressLineRequired(true);
    }

    if (addressInfo?.regionCode === "") {
      setRegionCodeRequired(true);
    }
    if (addressInfo?.locality === "") {
      setLocalityRequired(true);
    }

    if (
      addressInfo?.addressLine !== "" &&
      addressInfo?.regionCode !== "" &&
      addressInfo?.locality !== ""
    ) {
      try {
        setDisplayConfirmationBox(false);
        setTouchedValue(false);
        setAddressLoading(true);

        const res = await api.post(
          `/locationLogs/changeAddress/${location?.id}`,
          {
            companyId: companyData?.id,
            currentAddress: {
              addressLine:
                locationDetailsData?.result?.google?.storefrontAddress
                  ?.addressLines,
              administrativeArea: locationDetailsData?.result?.google
                ?.storefrontAddress?.administrativeArea
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.administrativeArea
                : "",
              postalCode: locationDetailsData?.result?.google?.storefrontAddress
                ?.postalCode
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.postalCode
                : "",
              regionCode: locationDetailsData?.result?.google?.storefrontAddress
                ?.regionCode
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.regionCode
                : "",
              locality: locationDetailsData?.result?.google?.storefrontAddress
                ?.locality
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.locality
                : "",
            },
            newAddress: {
              addressLine: [addressInfo?.addressLine],
              administrativeArea: addressInfo?.administrativeArea,
              postalCode: addressInfo?.postalCode,
              regionCode: addressInfo?.regionCode,
              locality: addressInfo?.locality,
            },
          }
        );
        if (res.status === 200) {
          toast.success(t("request_sent_for_address"));
          handleCloseAddressChangeModel();
          setAddressLoading(false);
          // setRequestSent(true);
          getLogsDetail();

          if (indexValue === null) {
          } else {
            let selectedValue = indexValue;

            setSelectedTab(selectedValue);
          }

          if (cancelState === true) {
            onCancel();
            // setTouchedValue(false);
          }
        }
      } catch (error) {
        setAddressLoading(false);
        handleCloseAddressChangeModel();
        toast.error(error?.response?.data?.message);
      }
    } else {
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      getLocationDetails(location);
      onCancel();
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };

  return (
    <>
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleRequestAddressChange}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            // setAddressTouchedValue={setAddressTouchedValue}
            getLocationDetails={getLocationDetails}
            location={location}

            //  loading={setIsLoading}
          />
        </Dialog>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container sx={{ padding: "18px" }}>
          <Grid item xs={10} sm={10} md={11.5} lg={11.5}>
            <Box>
              <Typography className={classes.modalHeader}>
                {t("Location_on_Map")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
            <IconButton autoFocus onClick={onCancel} className="delete_button">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          ref={containerRef}
          className={classes.actionContent}
        >
          <Grid container sx={{ padding: "12px 18px 18px 18px" }}>
            {displayErrorMessage > 0 && (
              <Grid container className={classes.mainContainer}>
                <Grid
                  item
                  xs={0.5}
                  sm={0.5}
                  md={0.5}
                  lg={0.5}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <ErrorOutlineIcon className={classes.errorIcon} />
                </Grid>
                <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.errorText1}>
                      {t("map_error_on_move")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <LocationMapComponent
              centerData={centerData}
              containerStyle={containerStyle}
              displayMapError={displayMapError}
              locationDetailsData={locationDetailsData}
              setAddressInfo={setAddressInfo}
              addressInfo={addressInfo}
              requestSent={requestSent}
              setTouchedValue={setTouchedValue}
              setDisplayErrorMessage={setDisplayErrorMessage}
              handleScroll={handleScroll}
            />
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              lg={12}
              sx={{ marginTop: "12px" }}
            >
              <Card
                variant="outlined"
                className={
                  requestSent
                    ? classes.locationAddressCardRequested
                    : classes.locationAddressCard
                }
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ textAlign: "end" }}
                  >
                    {openEditUI ? (
                      addressLoading ? (
                        <CircularProgress
                          color="primary"
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            textAlign: "end",
                          }}
                        >
                          {/*  <BootstrapTooltip title={t("save_and_send_request")}>
                            <CheckCircleOutlineIcon
                              className={classes.tickAddressIcon}
                              onClick={() => handleRequestAddressChange()}
                            />
                          </BootstrapTooltip> */}
                          <CloseIcon
                            onClick={() => handleCloseAddressChangeModel()}
                            sx={{ cursor: "pointer" }}
                          />
                        </Box>
                      )
                    ) : requestSent ? (
                      <BootstrapTooltip title={t("request_sent")}>
                        <CheckCircleOutlineIcon
                          className={classes.tickAddressIconRequested}
                        />
                      </BootstrapTooltip>
                    ) : (
                      <BootstrapTooltip title={t("edit_address")}>
                        <EditIcon
                          className={classes.editIconAddress}
                          onClick={() => handleOpenAddressChangeModel()}
                        />
                      </BootstrapTooltip>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.locationAddressCardLabels}>
                      {t("Addres_Line")}
                    </Typography>
                    {openEditUI ? (
                      <TextField
                        type="text"
                        name="firstName"
                        variant="outlined"
                        fullWidth
                        placeholder={t("Addres_Line")}
                        value={addressInfo?.addressLine}
                        error={addressLineRequired}
                        helperText={addressLineRequired && t("Addre_line_req")}
                        onChange={(e) => {
                          setTouchedValue(true);
                          setAddressInfo({
                            ...addressInfo,
                            addressLine: e.target.value,
                          });
                          if (e.target.value?.length > 0) {
                            setAddressLineRequired(false);
                          } else {
                            setAddressLineRequired(true);
                          }
                        }}
                      />
                    ) : (
                      <Typography className={classes.adressField}>
                        {addressInfo?.addressLine
                          ? addressInfo?.addressLine
                          : locationDetailsData?.result?.google
                              ?.storefrontAddress?.addressLines?.length > 0
                          ? locationDetailsData?.result?.google
                              ?.storefrontAddress?.addressLines[0]
                          : "--"}
                      </Typography>
                    )}
                  </Grid>

                  {/*  */}
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: "8px" }}>
                  <Grid item xs={4} sm={4} md={12} lg={12}>
                    <Typography className={classes.locationAddressCardLabels}>
                      {t("Administrative_Area")}
                    </Typography>
                    {openEditUI ? (
                      <TextField
                        // disabled={loading}

                        type="text"
                        name="lastName"
                        autoComplete="off"
                        fullWidth
                        placeholder={t("Administrative_Area")}
                        inputProps={{ autoComplete: "off" }}
                        value={addressInfo?.administrativeArea}
                        variant="outlined"
                        error={administrativeAreaRequired}
                        helperText={
                          administrativeAreaRequired &&
                          t("Administrative_Area_req")
                        }
                        onChange={(e) => {
                          setTouchedValue(true);
                          setAddressInfo({
                            ...addressInfo,
                            administrativeArea: e.target.value,
                          });
                          /*   if (e.target.value?.length > 0) {
                                              setAdministrativeArea(false);
                                            } else {
                                              setAdministrativeArea(true);
                                            } */
                        }}
                      />
                    ) : (
                      <Typography className={classes.adressField}>
                        {addressInfo?.administrativeArea
                          ? addressInfo?.administrativeArea
                          : locationDetailsData?.result?.google
                              ?.storefrontAddress?.administrativeArea
                          ? locationDetailsData?.result?.google
                              ?.storefrontAddress?.administrativeArea
                          : "--"}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    sx={{ marginTop: "8px" }}
                  >
                    <Typography className={classes.locationAddressCardLabels}>
                      {t("Postal_Code")}
                    </Typography>

                    {openEditUI ? (
                      <TextField
                        type="text"
                        placeholder={t("Postal_Code")}
                        defaultValue=""
                        autoComplete="none"
                        variant="outlined"
                        fullWidth
                        value={addressInfo?.postalCode}
                        error={postalCodeRequired}
                        helperText={postalCodeRequired && t("postal_req")}
                        onChange={(e) => {
                          setTouchedValue(true);
                          setAddressInfo({
                            ...addressInfo,
                            postalCode: e.target.value,
                          });
                          /*  if (e.target.value?.length > 0) {
                                              setPostalCodeRequired(false);
                                            } else {
                                              setPostalCodeRequired(true);
                                            } */
                        }}
                      />
                    ) : (
                      <Typography className={classes.adressField}>
                        {addressInfo?.postalCode
                          ? addressInfo?.postalCode
                          : locationDetailsData?.result?.google
                              ?.storefrontAddress?.postalCode
                          ? locationDetailsData?.result?.google
                              ?.storefrontAddress?.postalCode
                          : "--"}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    sx={{ marginTop: "8px" }}
                  >
                    <Typography className={classes.locationAddressCardLabels}>
                      {t("Region_Code")}
                    </Typography>

                    {openEditUI ? (
                      <TextField
                        // disabled={loading}

                        type="text"
                        placeholder={t("Region_Code")}
                        defaultValue=""
                        autoComplete="off"
                        fullWidth
                        value={addressInfo?.regionCode}
                        error={regionCodeRequired}
                        variant="outlined"
                        helperText={regionCodeRequired && t("Region_Code_Req")}
                        onChange={(e) => {
                          setTouchedValue(true);
                          setAddressInfo({
                            ...addressInfo,
                            regionCode: e.target.value,
                          });
                          if (e.target.value?.length > 0) {
                            setRegionCodeRequired(false);
                          } else {
                            setRegionCodeRequired(true);
                          }
                        }}
                      />
                    ) : (
                      <Typography className={classes.adressField}>
                        {addressInfo?.regionCode
                          ? addressInfo?.regionCode
                          : locationDetailsData?.result?.google
                              ?.storefrontAddress?.regionCode
                          ? locationDetailsData?.result?.google
                              ?.storefrontAddress?.regionCode
                          : "--"}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    sx={{ marginTop: "8px" }}
                  >
                    <Typography className={classes.locationAddressCardLabels}>
                      {t("Locality")}
                    </Typography>
                    {openEditUI ? (
                      <TextField
                        // disabled={loading}

                        type="text"
                        placeholder={t("Locality")}
                        value={addressInfo?.locality}
                        variant="outlined"
                        fullWidth
                        error={localityRequired}
                        helperText={localityRequired && t("Locality_Req")}
                        onChange={(e) => {
                          setTouchedValue(true);
                          setAddressInfo({
                            ...addressInfo,
                            locality: e.target.value,
                          });
                          if (e.target.value?.length > 0) {
                            setLocalityRequired(false);
                          } else {
                            setLocalityRequired(true);
                          }
                        }}
                      />
                    ) : (
                      <Typography className={classes.adressField}>
                        {addressInfo?.locality
                          ? addressInfo?.locality
                          : locationDetailsData?.result?.google
                              ?.storefrontAddress?.locality
                          ? locationDetailsData?.result?.google
                              ?.storefrontAddress?.locality
                          : "--"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Divider />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Close")}
            onSubmit={handleCancelButton}
          />
          {requestSent ? (
            <BootstrapTooltip title={t("request_sent")}>
              <span>
                <CommonButton
                  disabled={true}
                  //  type="submit"
                  onSubmit={handleRequestAddressChange}
                  label={t("Save")}
                />
              </span>
            </BootstrapTooltip>
          ) : (
            <BootstrapTooltip title={t("save_and_send_request")}>
              <span>
                <CommonButton
                  disabled={addressLoading}
                  isLoading={addressLoading}
                  onSubmit={handleRequestAddressChange}
                  label={t("Save")}
                />
              </span>
            </BootstrapTooltip>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default LocationMap;
