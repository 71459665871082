import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
} from "@mui/material";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { api } from "../../../../../../../contexts/JWTContext";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Facebook from "../../../../../../../assets/images/facebookround.svg";
import Google from "../../../../../../../assets/images/googleround.svg";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/Check";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const CompanyDetailsModal = ({
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  indexValue,
  setSelectedTab,
  selectedLocationsBulkUpdate,
  onCancel,
  setIndexValue,
  handleCloseFilterModel,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFacebookCategory, setSelectedFacebookCategory] =
    useState(null);
  const [additionalCategories, setAddtionalCategories] = useState([]);
  const [categoryRequired, setCategoryRequired] = useState(false);
  const [facebookCategoryRequired, setFacebookCategoryRequired] =
    useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [locationCategory, setLocationCategory] = useState(null);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (selectedLocationsBulkUpdate?.length > 0) {
      const disabledFacebook = selectedLocationsBulkUpdate?.every(
        (item) =>
          item?.socialLink?.length &&
          item?.socialLink?.some(
            (link) => link?.type === "facebook" && item?.enableFacebook === true
          )
      );
      setDisableButton(!disabledFacebook);
    }
  }, []);

  useEffect(() => {
    getLocationCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory !== null) {
    }
    const selectedOptions = [selectedCategory, ...additionalCategories];
    const updatedOptions =
      locationCategory !== null &&
      locationCategory?.google?.filter(
        (option) =>
          !selectedOptions.some(
            (selectedOption) => selectedOption?.name === option?.name
          )
      );
    setFilteredOptions(updatedOptions);
  }, [selectedCategory, additionalCategories, locationCategory]);

  const getLocationCategories = async (locationData) => {
    let locationIds = [];
    if (selectedLocationsBulkUpdate?.length > 0) {
      selectedLocationsBulkUpdate.map((item) => {
        locationIds.push(item.id);
      });
    }
    setCategoryLoading(true);
    try {
      const response = await api.get(
        `/locationListing/getCommonCategories?ids=${locationIds?.toString()}`
      );

      if (response.status === 200) {
        setCategoryLoading(false);
        setLocationCategory(response?.data?.data);
      }
    } catch (error) {
      setCategoryLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleUpdateCategories = async () => {
    setTouchedValue(false);

    if (additionalCategories?.length >= 10) {
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    } else {
      let locationIds = [];
      if (selectedLocationsBulkUpdate?.length > 0) {
        selectedLocationsBulkUpdate.map((item) => {
          locationIds.push(item.id);
        });
      }
      try {
        const body = {
          locationIds: locationIds,
        };

        if (selectedCategory || additionalCategories?.length > 0) {
          body.google = {};

          if (selectedCategory) {
            body.google.categories = {
              primaryCategory: {
                name: selectedCategory?.name,
                displayName: selectedCategory?.displayName,
              },
            };
          }

          if (additionalCategories?.length > 0) {
            body.google.categories = {
              ...body.google.categories,
              additionalCategories,
            };
          }
        }

        if (selectedFacebookCategory) {
          body.facebook = {
            category_list: [
              {
                id: selectedFacebookCategory?.id || "",
                name: selectedFacebookCategory?.name || "",
              },
            ],
          };
        }

        setDisplayConfirmationBox(false);
        setIsLoading(true);
        const res = await api.patch(`locationListing/bulkCategories`, body);

        if (indexValue !== null) {
          setSelectedTab(indexValue);
        }

        if (cancelState === true) {
          onCancel();
          handleCloseFilterModel();
        }

        if (res.status === 200) {
          setIsLoading(false);

          toast.success("Updated Successfully");
          setTouchedValue(false);
        }
      } catch (error) {
        console.error(error);
        toast.success(error);
        setIsLoading(false);
      }
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      handleCloseFilterModel();
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleCategoryChange = (option) => {
    setTouchedValue(true);
    setSelectedCategory(option);
  };
  const handleFacebookCategoryChange = (option) => {
    setTouchedValue(true);
    setSelectedFacebookCategory(option);
  };

  const handleAdditionalCategoryChange = (option) => {
    setAddtionalCategories(option);
    setTouchedValue(true);
  };

  const customFilterToSearchCategory = (options, { inputValue }) => {
    const startsWithInput = options.filter((option) =>
      option?.displayName?.toLowerCase().startsWith(inputValue?.toLowerCase())
    );

    const containsInput = options.filter(
      (option) =>
        option?.displayName
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) &&
        !option?.displayName
          ?.toLowerCase()
          .startsWith(inputValue?.toLowerCase())
    );

    return [...startsWithInput, ...containsInput];
  };

  return (
    <>
      {displayConfirmationBox && (
        <Dialog
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateCategories}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
          />
        </Dialog>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container sx={{ padding: "18px" }}>
          <Grid
            item
            xs={10}
            sm={10}
            md={11.5}
            lg={11.5}
            sx={{ alignItems: "center", display: "flex" }}
          >
            <Typography className={classes.modalHeader}>
              {t("Categories")}
            </Typography>
            <BootstrapTooltip title={t("Primary_Category_subHeading")}>
              <Typography
                sx={{
                  marginLeft: "5px",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
              >
                <InfoOutlinedIcon />
              </Typography>
            </BootstrapTooltip>
          </Grid>
          <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
            <IconButton
              autoFocus
              onClick={() => {
                handleOpen();
                setCancelState(true);
              }}
              className="delete_button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid container className={classes.gridContainertwoBulk}>
          {isLoading || categoryLoading ? (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.loaderBoxBulk}
              >
                <Loader />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container sx={{ padding: "18px" }}>
                  <Grid item md={12} sm={12} xs={12} lg={12}>
                    <Box className={classes.categoriesSocialBox}>
                      <img src={Google} />
                      <Typography className={classes.fieldTextSocial}>
                        {t("Google")}{" "}
                        <span style={{ color: "#FC3652" }}>*</span>
                      </Typography>
                      <BootstrapTooltip title={t("disabled_tooltip_category")}>
                        <ErrorOutlineIcon
                          sx={{
                            color: "#1B2430",
                          }}
                        />
                      </BootstrapTooltip>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        padding: "10px",
                        borderRadius: "8px",
                        marginTop: "12px",
                      }}
                    >
                      <Grid container sx={{ padding: "0px" }}>
                        {" "}
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                          <Box className={classes.categoriesSocialBox}>
                            <Typography className={classes.fieldTextCategories}>
                              {t("Primary_Category")}
                            </Typography>
                          </Box>
                          <Autocomplete
                            disablePortal
                            error={categoryRequired}
                            id="combo-box-demo"
                            options={
                              filteredOptions?.length > 0 ? filteredOptions : []
                            }
                            getOptionLabel={(option) =>
                              option?.displayName ? option?.displayName : ""
                            }
                            filterOptions={customFilterToSearchCategory}
                            sx={{ width: "100%" }}
                            value={selectedCategory}
                            onChange={(e, value) => {
                              handleCategoryChange(value);
                              if (value === null) {
                                setCategoryRequired(true);
                              } else {
                                setCategoryRequired(false);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                // required
                                name="locationPrimarycategory"
                                placeholder={t("Select_primary_category")}
                              />
                            )}
                          ></Autocomplete>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ padding: "0px" }}>
                        {" "}
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                          lg={12}
                          sx={{ marginTop: "12px" }}
                        >
                          <Box className={classes.categoriesSocialBox}>
                            <Typography className={classes.fieldTextCategories}>
                              {t("Additional_Categories")}
                            </Typography>
                          </Box>

                          <Autocomplete
                            multiple
                            disablePortal
                            id="combo-box-demo"
                            disableCloseOnSelect
                            options={
                              filteredOptions?.length > 0 ? filteredOptions : []
                            }
                            getOptionLabel={(option) =>
                              option?.displayName ? option?.displayName : ""
                            }
                            sx={{ width: "100%" }}
                            value={additionalCategories}
                            filterOptions={customFilterToSearchCategory}
                            onChange={(e, value) => {
                              handleAdditionalCategoryChange(value);
                            }}
                            renderOption={(props, option, { selected }) => {
                              return selected &&
                                additionalCategories?.length > 0 &&
                                additionalCategories?.find(
                                  (item) => item?.name === option?.name
                                ) ? (
                                <li {...props}>
                                  <div
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      // border: "2px solid gray",
                                      backgroundColor: "#06BDFF",
                                      cursor: "pointer",
                                      display: "inlineBlock",
                                      marginRight: "10px",
                                      textAlign: "center",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    <CheckIcon
                                      sx={{
                                        color: "#FFFF",
                                        marginBottom: "3px",
                                        width: "14px",
                                      }}
                                      fontSize="small"
                                    />
                                  </div>
                                  {option?.displayName
                                    ? option.displayName
                                    : ""}
                                </li>
                              ) : (
                                <li {...props}>
                                  <div
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      border: "2px solid #aaaaaa",

                                      cursor: "pointer",
                                      display: "inlineBlock",
                                      marginRight: "10px",
                                      textAlign: "center",
                                      borderRadius: "3px",
                                    }}
                                  ></div>
                                  {option?.displayName
                                    ? option.displayName
                                    : ""}
                                </li>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // required
                                name="selectedCategory"
                                placeholder={t("Select_Category")}
                                error={selectedCategory?.length >= 10}
                              />
                            )}
                          ></Autocomplete>
                          {additionalCategories?.length >= 10 && (
                            <Typography className={classes.errorText}>
                              {t("Categories_limit_text")}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>

                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      lg={12}
                      sx={{ marginTop: "12px", padding: "10px" }}
                    >
                      <Box className={classes.categoriesSocialBox}>
                        <img src={Facebook} />
                        <Typography className={classes.fieldTextSocial}>
                          {t("Facebookk")}{" "}
                          <span style={{ color: "#FC3652" }}>*</span>
                        </Typography>
                        {disableButton && (
                          <BootstrapTooltip title={t("facebookDisabledText")}>
                            <ErrorOutlineIcon
                              sx={{
                                color: "#1B2430",
                              }}
                            />
                          </BootstrapTooltip>
                        )}
                      </Box>

                      <Autocomplete
                        disablePortal
                        error={facebookCategoryRequired}
                        id="combo-box-demo"
                        disabled={disableButton}
                        options={
                          locationCategory !== null &&
                          locationCategory?.facebook?.length > 0
                            ? locationCategory?.facebook
                            : []
                        }
                        getOptionLabel={(option) =>
                          option?.name ? option?.name : ""
                        }
                        sx={{ width: "100%" }}
                        value={
                          selectedFacebookCategory !== null
                            ? selectedFacebookCategory
                            : {}
                        }
                        onChange={(e, value) => {
                          handleFacebookCategoryChange(value);
                          if (value === null) {
                            setFacebookCategoryRequired(true);
                          } else {
                            setFacebookCategoryRequired(false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            name="locationPrimarycategory"
                            placeholder={t("Select_primary_category")}
                          />
                        )}
                      ></Autocomplete>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Divider />

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Back")}
            onSubmit={handleCancelButton}
          />
          {console.log(
            selectedFacebookCategory,
            selectedCategory,
            additionalCategories,
            "additionalCategories"
          )}
          <CommonButton
            disabled={
              isLoading ||
              additionalCategories?.length >= 10 ||
              (selectedCategory == null &&
                selectedFacebookCategory == null &&
                additionalCategories?.length <= 0)
            }
            onSubmit={handleUpdateCategories}
            label={t("Save")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyDetailsModal;
